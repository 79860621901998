import Box from '@mui/material/Box';
import Section from '../Section/Section';
import Summary from '../Summary/Summary';
import {type Guide as GuideType} from '../types';
import {GuideContextProvider} from '../utilities/GuideContext';

const styles = {
  root: {
    display: 'grid',
    gap: 2,
    gridTemplateColumns: 'minmax(500px, 5fr) minmax(225px, 1fr)',
    gridTemplateAreas: `
      "guide summary"
    `,
  },
  guide: {
    gridArea: 'guide',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  summary: {
    gridArea: 'summary',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
};

function Guide({guide, projectId}: {guide: GuideType; projectId: string}) {
  return (
    <GuideContextProvider guide={guide} projectId={projectId}>
      <Box sx={styles.root}>
        <Box sx={styles.guide}>
          {Object.values(guide.sections).map((section) => {
            return <Section key={section.title} section={section} />;
          })}
        </Box>
        <Box sx={styles.summary}>
          <Summary guide={guide} />
        </Box>
      </Box>
    </GuideContextProvider>
  );
}

export default Guide;
