import {keyframes} from '@emotion/react';
import Box from '@mui/material/Box';
import {type Theme, alpha, useTheme} from '@mui/material/styles';
import {type ReactNode} from 'react';

const pulse = (theme: Theme, radius: number) => keyframes`
  0% {
      box-shadow: 0 0 0 0 ${alpha(theme.palette.primary.main, 0.4)}
  }
  100% {
      box-shadow: 0 0 5px ${radius}px ${alpha(theme.palette.primary.main, 0)}
  }
`;

const GlowWrapper = ({
  children,
  doGlow,
  radius = 12,
  sx,
}: {
  children: ReactNode;
  doGlow: boolean;
  radius?: number;
  sx?: object;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-block',
        '&::before': doGlow
          ? {
              animation: `${pulse(theme, radius)} 2s infinite`,
              borderRadius: 1,
              transition: 'opacity .3s transform .3s',
              content: '""',
              height: '100%',
              width: '100%',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 0,
              ...sx,
            }
          : {},
      }}
    >
      {children}
    </Box>
  );
};

export default GlowWrapper;
