import {type Dispatch, createAction} from '@reduxjs/toolkit';
import type Orb from 'orb-billing';
import {push} from 'redux-first-history';
import type Stripe from 'stripe';
import DashboardApi, {type EditSsoRules} from '../api/DashboardApi';
import {PlanNameMapping, Plans, type Roles, type SupportTiers} from '../constants';
import {type ChangeSupportAction} from '../pages/org-settings/GetHelpPage/SupportPlanPage/utils/supportPlans';
import {type CreateConfigType} from '../pages/org-settings/ManageOrgPage/SsoControls/SsoForm/utilities/schema';
import {
  type Invite,
  type Marketplace,
  type MarketplaceSetupOptions,
  type MemberOrganization,
  type MemberProject,
  type Organization,
  type SsoConnectionConfig,
  type SsoConnectionRule,
  type User,
  type UserMember,
} from '../types';
import {HEAP_TRACKING_EVENTS, sendToHeap} from '../utils/tracking';
import {NotificationTypes, enqueNotification} from './notificationActions';
import type * as OrganizationActionTypes from './types/organizationActions.types';
import {decodeErrorMessage} from './utils/errors';

export const getOrganizationsRequest = createAction<undefined>('GET_ORGANIZATIONS_REQUEST');
export const getOrganizationsSuccess = createAction<{
  projects: MemberProject[];
  newOrgs: MemberOrganization[];
  userId: string;
}>('GET_ORGANIZATION_SUCCESS');

export function getOrganizations(token: string) {
  return (dispatch: Dispatch) => {
    dispatch(getOrganizationsRequest());
    return new DashboardApi(token).getOrganizations().then((res) => {
      dispatch(
        getOrganizationsSuccess({
          newOrgs: res.newOrgs,
          userId: res.userId,
          projects: res.projects,
        }),
      );
    });
  };
}

export const createOrganizationRequest = createAction<undefined>('CREATE_ORGANIZATION_REQUEST');
export const createOrganizationSuccess = createAction<{
  org: Organization;
  userId: string;
}>('CREATE_ORGANIZATION_SUCCESS');
export const createOrganizationFailure = createAction<undefined>('CREATE_ORGANIZATION_FAILURE');

export function createOrganization(
  token: string,
  data: {name: string; userId: string; marketplaceSetupOptions?: MarketplaceSetupOptions},
) {
  return (dispatch: Dispatch) => {
    dispatch(createOrganizationRequest());
    return new DashboardApi(token)
      .createOrganization(data.name, data.marketplaceSetupOptions)
      .then((res) => {
        dispatch(createOrganizationSuccess({org: res.organization, userId: data.userId}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Organization ${res.organization.name} successfully created.`,
          }),
        );
        dispatch(push(`/organizations/${res.organization.id}`));
      })
      .catch((err) => {
        dispatch(createOrganizationFailure());
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to create organization. ${err.message}`,
          }),
        );
      });
  };
}

export const deleteOrganizationRequest = createAction<undefined>('DELETE_ORGANIZATION_REQUEST');
export const deleteOrganizationSuccess = createAction<string>('DELETE_ORGANIZATION_SUCCESS');
export const deleteOrganizationFailure = createAction<undefined>('DELETE_ORGANIZATION_FAILURE');

export function deleteOrganization(token: string, data: {organization: Organization}) {
  return (dispatch: Dispatch) => {
    dispatch(deleteOrganizationRequest());
    return new DashboardApi(token)
      .deleteOrganization(data.organization.id)
      .then(() => {
        dispatch(deleteOrganizationSuccess(data.organization.id));
        dispatch(push('/organizations'));
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Organization ${data.organization.name} successfully deleted.`,
          }),
        );
      })
      .catch((err) => {
        dispatch(deleteOrganizationFailure());
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to delete organization. ${err.message}`,
          }),
        );
      });
  };
}

export const editOrganizationRequest = createAction<undefined>('EDIT_ORGANIZATION_REQUEST');
export const editOrganizationSuccess = createAction<{organizationId: string; name: string}>(
  'EDIT_ORGANIZATION_SUCCESS',
);
export const editOrganizationFailure = createAction<undefined>('EDIT_ORGANIZATION_FAILURE');

export function editOrganization(token: string, data: {organizationId: string; name: string}) {
  return (dispatch: Dispatch) => {
    dispatch(editOrganizationRequest());
    return new DashboardApi(token)
      .editOrganization(data.organizationId, data.name)
      .then(() => {
        dispatch(editOrganizationSuccess({organizationId: data.organizationId, name: data.name}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: 'Organization updated successfully',
          }),
        );
      })
      .catch((err) => {
        dispatch(editOrganizationFailure());
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to update organization. ${err.message}`,
          }),
        );
      });
  };
}

export const getOrganizationUsersRequest = createAction<undefined>(
  'GET_ORGANIZATION_USERS_REQUEST',
);
export const getOrganizationUsersSuccess = createAction<{
  organizationId: string;
  users: UserMember[];
}>('GET_ORGANIZATION_USERS_SUCCESS');

export function getOrganizationUsers(token: string, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getOrganizationUsersRequest());
    return new DashboardApi(token).getOrganizationUsers(data.organizationId).then((res) => {
      dispatch(
        getOrganizationUsersSuccess({
          organizationId: data.organizationId,
          users: res.userRoles,
        }),
      );
    });
  };
}

export const inviteToOrgRequest = createAction<undefined>('INVITE_TO_ORG_REQUEST');
export const inviteToOrgSuccess = createAction<{invites: Invite[]; organizationId: string}>(
  'INVITE_TO_ORG_SUCCESS',
);
export const inviteToOrgFailure = createAction<undefined>('INVITE_TO_ORG_FAILURE');

export const getPendingInvitesSuccess = createAction<{organizationId: string; invites: Invite[]}>(
  'GET_PENDING_INVITES_SUCCESS',
);
export const getPendingInvitesRequest = createAction<undefined>('GET_PENDING_INVITES_REQUEST');
export const addUsersToOrgSuccess = createAction<{
  organizationId: string;
  users: (User & Invite)[];
}>('ADD_USERS_TO_ORG_SUCCESS');
export const addUsersToProjectSuccess = createAction<{
  organizationId: string;
  projectUsers: Record<string, string[]>;
  inviteData: Invite;
}>('ADD_USERS_TO_PROJECT_SUCCESS');

export function getPendingInvites(token: string, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getPendingInvitesRequest());
    return new DashboardApi(token)
      .getPendingInvites(data.organizationId)
      .then((res) => {
        dispatch(
          getPendingInvitesSuccess({
            organizationId: data.organizationId,
            invites: res.invites,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `The pending invitations could not be fetched. ${error}`,
          }),
        );
      });
  };
}

export function inviteToOrg(
  token: string,
  data: {
    organizationId: string;
    orgRole: Roles;
    organization_roles: Record<string, {role: Roles}>;
    emails: string[];
    projectRole: Roles;
    project_roles: Record<string, {role: Roles}>;
    projects: string[];
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(inviteToOrgRequest());
    return new DashboardApi(token)
      .inviteToOrg(
        data.organizationId,
        data.orgRole,
        data.emails,
        data.projectRole,
        data.projects,
        data.organization_roles,
        data.project_roles,
      )
      .then((res) => {
        const inviteData = {
          organization_id: data.organizationId,
          project_roles: data.project_roles,
          organization_roles: data.organization_roles,
        } as Invite;

        const invites = res.invitesData.map((invite) => ({
          ...invite,
          ...inviteData,
        }));
        const orgUsers = res.orgUsers.map((orgUser) => ({
          ...orgUser,
          ...inviteData,
        }));

        // TODO (zadamjee) - Remove once console migrates to user uuids
        // Mapping to replace user ids with firebase ids
        const newProjectUsers = Object.entries(res.newProjectUsers).reduce(
          (acc, [projectId, userIds]) => {
            acc[projectId] = userIds.map((userId) => {
              const user = res.users.find((u) => u.id === userId);
              return user?.firebase_id || userId;
            });
            return acc;
          },
          {} as Record<string, string[]>,
        );

        dispatch(addUsersToOrgSuccess({organizationId: data.organizationId, users: orgUsers}));
        dispatch(
          addUsersToProjectSuccess({
            organizationId: data.organizationId,
            projectUsers: newProjectUsers,
            inviteData,
          }),
        );
        dispatch(inviteToOrgSuccess({invites, organizationId: data.organizationId}));
      })
      .catch((error) => {
        dispatch(inviteToOrgFailure());
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: decodeErrorMessage(error.message),
          }),
        );
      });
  };
}

export const editOrgRoleSuccess = createAction<{
  organizationId: string;
  userId: string;
  role: Roles;
}>('EDIT_ORGANIZATION_ROLE_SUCCESS');

export function editOrgRole(
  token: string,
  data: {role: Roles; userId: string; organizationId: string},
) {
  return (dispatch: Dispatch) => {
    return new DashboardApi(token)
      .editOrgRole(data.organizationId, data.userId, data.role)
      .then(() => {
        dispatch(
          editOrgRoleSuccess({
            organizationId: data.organizationId,
            userId: data.userId,
            role: data.role,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Something went wrong and the role was not edited: ${error.message}`,
          }),
        );
      });
  };
}

export const deleteInviteRequest = createAction<undefined>('DELETE_INVITE_REQUEST');
export const deleteInviteSuccess = createAction<{organizationId: string; inviteId: string}>(
  'DELETE_INVITE_SUCCESS',
);

export function removeInvite(token: string, data: {organizationId: string; inviteId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(deleteInviteRequest());
    return new DashboardApi(token)
      .removeInvite(data.organizationId, data.inviteId)
      .then(() => {
        dispatch(
          deleteInviteSuccess({organizationId: data.organizationId, inviteId: data.inviteId}),
        );
      })
      .catch((error) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `The invitation could not be removed: ${error}`,
          }),
        );
      });
  };
}

export const removeUserFromOrgRequest = createAction<undefined>('REMOVE_USER_FROM_ORG_REQUEST');
export const removeUserFromOrgSuccess = createAction<{
  organizationId: string;
  userId: string;
}>('REMOVE_USER_FROM_ORG_SUCCESS');

export function removeUserFromOrg(token: string, data: {organizationId: string; userId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(removeUserFromOrgRequest());
    return new DashboardApi(token)
      .removeUserFromOrg(data.organizationId, data.userId)
      .then(() => {
        dispatch(
          removeUserFromOrgSuccess({
            organizationId: data.organizationId,
            userId: data.userId,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `The user could not be removed: ${error}`,
          }),
        );
      });
  };
}

export const exitOrganizationSuccess = createAction<string>('EXIT_ORGANIZATION_SUCCESS');

export function exitOrganization(token: string, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    return new DashboardApi(token)
      .exitOrganization(data.organizationId)
      .then(() => {
        dispatch(exitOrganizationSuccess(data.organizationId));
        dispatch(push(`/organizations`));
      })
      .catch((error) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to exit: ${error.message}`,
          }),
        );
      });
  };
}

export const createSubscriptionRequest = createAction<undefined>('CREATE_SUBSCRIPTION_REQUEST');
export const createSubscriptionSuccess = createAction<{
  organizationId: string;
  subscription: Orb.Subscription;
  plan: Plans;
}>('CREATE_SUBSCRIPTION_SUCCESS');
export const createSubscriptionFailure = createAction<{error: Error}>(
  'CREATE_SUBSCRIPTION_FAILURE',
);

export function createSubscription(
  token: string,
  {organizationId, ...createSubscriptionData}: OrganizationActionTypes.CreateSubscriptionData,
) {
  return (dispatch: Dispatch) => {
    dispatch(createSubscriptionRequest());
    return new DashboardApi(token)
      .createSubscription(organizationId, createSubscriptionData)
      .then((res) => {
        dispatch(
          createSubscriptionSuccess({
            organizationId,
            subscription: res,
            plan: createSubscriptionData.plan,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `You've successfully upgraded to ${
              PlanNameMapping[createSubscriptionData.plan]
            }!`,
          }),
        );
        sendToHeap(HEAP_TRACKING_EVENTS.SUBSCRIPTION_CREATED, {plan: createSubscriptionData.plan});
      })
      .catch((error) => {
        dispatch(createSubscriptionFailure({error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Something went wrong, your subscription did not go through. ${error.message}`,
          }),
        );
      });
  };
}

export const createSetupIntentSuccess = createAction<Stripe.SetupIntent>(
  'CREATE_SETUP_INTENT_SUCCESS',
);

export function createSetupIntent(token: string, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    return new DashboardApi(token)
      .createSetupIntent(data.organizationId)
      .then((res) => {
        dispatch(createSetupIntentSuccess(res));
      })
      .catch(() => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: 'Something went wrong. Please refresh the page, or contact support@pinecone.io if the problem persists.',
          }),
        );
      });
  };
}

export const getBillingDetailsRequest = createAction<undefined>('GET_BILLING_DETAILS_REQUEST');
export const getBillingDetailsSuccess = createAction<OrganizationActionTypes.OrgBillingDetails>(
  'GET_BILLING_DETAILS_SUCCESS',
);
export function getBillingDetails(token: string, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getBillingDetailsRequest());
    return new DashboardApi(token)
      .getBillingDetails(data.organizationId)
      .then((res) => {
        dispatch(getBillingDetailsSuccess(res));
      })
      .catch(() => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: 'There was an error retrieving your billing details.',
          }),
        );
      });
  };
}

export const updateBillingDetailsRequest = createAction<undefined>(
  'UPDATE_BILLING_DETAILS_REQUEST',
);
export const updateBillingDetailsSuccess = createAction<OrganizationActionTypes.OrgBillingDetails>(
  'UPDATE_BILLING_DETAILS_SUCCESS',
);
export const updateBillingDetailsError = createAction<undefined>('UPDATE_BILLING_DETAILS_ERROR');

export function updateBillingDetails(
  token: string,
  {organizationId, ...updateBillingDetailsData}: OrganizationActionTypes.UpdateBillingDetailsData,
) {
  return (dispatch: Dispatch) => {
    dispatch(updateBillingDetailsRequest());
    return new DashboardApi(token)
      .updateBillingDetails(organizationId, updateBillingDetailsData)
      .then((res) => {
        dispatch(updateBillingDetailsSuccess(res));
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: "You've successfully changed your payment info.",
          }),
        );
      })
      .catch((error) => {
        dispatch(updateBillingDetailsError());
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Something went wrong, your payment info was not updated. ${error.message}`,
          }),
        );
      });
  };
}

export const getPastInvoicesRequest = createAction<undefined>('GET_PAST_INVOICES_REQUEST');
export const getPastInvoicesSuccess = createAction<Stripe.Invoice[]>('GET_PAST_INVOICES_SUCCESS');
export const getPastInvoicesFailure = createAction<undefined>('GET_PAST_INVOICES_ERROR');

export function getPastInvoices(token: string, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getPastInvoicesRequest());
    return new DashboardApi(token)
      .getPastInvoices(data.organizationId)
      .then((res) => {
        dispatch(getPastInvoicesSuccess(res));
      })
      .catch((err) => {
        dispatch(getPastInvoicesFailure());
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to fetch past invoices. ${err.message}`,
          }),
        );
      });
  };
}

export const downloadInvoiceRequest = createAction<string>('DOWNLOAD_INVOICE_REQUEST');
export const downloadInvoiceSuccess = createAction<string>('DOWNLOAD_INVOICE_SUCCESS');
export const downloadInvoiceFailure = createAction<string>('DOWNLOAD_INVOICE_ERROR');

export function downloadInvoice(token: string, data: {invoiceId: string; organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(downloadInvoiceRequest(data.invoiceId));
    return new DashboardApi(token)
      .downloadInvoice(data)
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `pinecone-invoice-${data.invoiceId}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        dispatch(downloadInvoiceSuccess(data.invoiceId));
      })
      .catch((err) => {
        dispatch(downloadInvoiceFailure(data.invoiceId));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to download invoice: ${err.message}`,
          }),
        );
      });
  };
}

export const cancelSubscriptionSuccess = createAction(
  'CANCEL_SUBSCRIPTION_SUCCESS',
  (organizationId: string) => {
    return {
      payload: {
        organizationId,
        plan: Plans.FREE,
      },
    };
  },
);

export const cancelSubscriptionRequest = createAction<undefined>('CANCEL_SUBSCRIPTION_REQUEST');
export const cancelSubscriptionError = createAction<{error: Error}>('CANCEL_SUBSCRIPTION_ERROR');

export function cancelSubscription(token: string, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(cancelSubscriptionRequest());
    return new DashboardApi(token)
      .cancelSubscription(data.organizationId)
      .then(() => {
        dispatch(cancelSubscriptionSuccess(data.organizationId));
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: "You've downgraded to the starter plan. You will no longer be charged for usage.",
          }),
        );
        sendToHeap(HEAP_TRACKING_EVENTS.SUBSCRIPTION_DELETED);
      })
      .catch((error) => {
        dispatch(cancelSubscriptionError({error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `You were not able to downgrade: ${error.message}`,
          }),
        );
      });
  };
}

export const updateSubscriptionRequest = createAction<undefined>('UPDATE_SUBSCRIPTION_REQUEST');
export const updateSubscriptionSuccess = createAction<{
  organizationId: string;
  subscription: Orb.Subscription;
  plan: Plans;
}>('UPDATE_SUBSCRIPTION_SUCCESS');
export const updateSubscriptionError = createAction<{error: Error}>('UPDATE_SUBSCRIPTION_ERROR');

export function updateSubscription(token: string, data: {organizationId: string; plan: Plans}) {
  return (dispatch: Dispatch) => {
    dispatch(updateSubscriptionRequest());
    return new DashboardApi(token)
      .updateSubscription(data.organizationId, data.plan)
      .then((res) => {
        dispatch(
          updateSubscriptionSuccess({
            organizationId: res.organizationId,
            subscription: res.subscription,
            plan: res.plan,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `You've successfully updated your plan to ${PlanNameMapping[data.plan]}.`,
          }),
        );
        sendToHeap(HEAP_TRACKING_EVENTS.SUBSCRIPTION_UPDATE, {plan: data.plan});
      })
      .catch((error) => {
        dispatch(updateSubscriptionError({error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `We were not able to update your subscription: ${error.message}`,
          }),
        );
      });
  };
}

export const getSsoConfigRequest = createAction<{organizationId: string}>('GET_SSO_CONFIG_REQUEST');
export const getSsoConfigSuccess = createAction<{
  organizationId: string;
  data: {
    connection: SsoConnectionConfig;
    rules: SsoConnectionRule[];
  };
}>('GET_SSO_CONFIG_SUCCESS');
export const getSsoConfigError = createAction<{organizationId: string; error: Error}>(
  'GET_SSO_CONFIG_ERROR',
);
export function getSsoConfig(token: string, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getSsoConfigRequest({organizationId: data.organizationId}));
    return new DashboardApi(token)
      .getSsoConfig(data.organizationId)
      .then((res) => {
        dispatch(
          getSsoConfigSuccess({
            organizationId: data.organizationId,
            data: res,
          }),
        );
      })
      .catch((error) => {
        dispatch(getSsoConfigError({organizationId: data.organizationId, error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `There was an error loading the SSO config: ${error}`,
          }),
        );
      });
  };
}

export const setSsoConfigRequest = createAction<{organizationId: string}>('SET_SSO_CONFIG_REQUEST');
export const setSsoConfigSuccess = createAction<{
  organizationId: string;
  data: {
    connection: SsoConnectionConfig;
    rules: SsoConnectionRule[];
  };
}>('SET_SSO_CONFIG_SUCCESS');
export const setSsoConfigError = createAction<{organizationId: string; error: Error}>(
  'SET_SSO_CONFIG_ERROR',
);

export function setupSsoConfig(
  token: string,
  data: {organizationId: string; connection: CreateConfigType},
) {
  return (dispatch: Dispatch) => {
    return new DashboardApi(token)
      .setupSsoConfig(data.organizationId, data.connection)
      .then((res) => {
        dispatch(
          setSsoConfigSuccess({
            organizationId: data.organizationId,
            data: res,
          }),
        );
      })
      .catch((error) => {
        dispatch(setSsoConfigError({organizationId: data.organizationId, error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Error setting up SSO config: ${error}`,
          }),
        );
      });
  };
}

export const deleteSsoRequest = createAction<{organizationId: string}>('DELETE_SSO_CONFIG_REQUEST');
export const deleteSsoSuccess = createAction<{organizationId: string}>('DELETE_SSO_CONFIG_SUCCESS');
export const deleteSsoError = createAction<{organizationId: string; error: Error}>(
  'DELETE_SSO_CONFIG_ERROR',
);

export function deleteSsoConfig(token: string, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(deleteSsoRequest(data));
    return new DashboardApi(token)
      .deleteSsoConfig(data.organizationId)
      .then(() => {
        dispatch(deleteSsoSuccess(data));
      })
      .catch((error) => {
        dispatch(deleteSsoError({organizationId: data.organizationId, error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Error removing SSO config: ${error}`,
          }),
        );
      });
  };
}

export const editSsoConfigRequest = createAction<{organizationId: string}>(
  'EDIT_SSO_CONFIG_REQUEST',
);
export const editSsoConfigSuccess = createAction<{
  organizationId: string;
  data: {
    connection: SsoConnectionConfig;
  };
}>('EDIT_SSO_CONFIG_SUCCESS');
export const editSsoConfigError = createAction<{organizationId: string; error: Error}>(
  'EDIT_SSO_CONFIG_ERROR',
);

export function editSsoConfig(
  token: string,
  data: {organizationId: string; connection: CreateConfigType},
) {
  return (dispatch: Dispatch) => {
    dispatch(editSsoConfigRequest({organizationId: data.organizationId}));
    return new DashboardApi(token)
      .editSsoConfig(data.organizationId, data.connection)
      .then((res) => {
        dispatch(
          editSsoConfigSuccess({
            organizationId: data.organizationId,
            data: res,
          }),
        );
      })
      .catch((error) => {
        dispatch(editSsoConfigError({organizationId: data.organizationId, error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Error editing SSO config: ${error}`,
          }),
        );
      });
  };
}
export const editSsoRulesRequest = createAction<{organizationId: string}>('EDIT_SSO_RULES_REQUEST');
export const editSsoRulesSuccess = createAction<{
  organizationId: string;
  data: {
    rules: SsoConnectionRule[];
  };
}>('EDIT_SSO_RULES_SUCCESS');
export const editSsoRulesError = createAction<{organizationId: string; error: Error}>(
  'EDIT_SSO_RULES_ERROR',
);

export function editSsoRules(token: string, data: {organizationId: string; rules: EditSsoRules}) {
  return (dispatch: Dispatch) => {
    dispatch(editSsoRulesRequest({organizationId: data.organizationId}));
    return new DashboardApi(token)
      .editSsoRules(data.organizationId, data.rules)
      .then((res) => {
        dispatch(editSsoRulesSuccess({organizationId: data.organizationId, data: res}));
      })
      .catch((error) => {
        dispatch(editSsoRulesError({organizationId: data.organizationId, error}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Error editing SSO rules: ${error}`,
          }),
        );
      });
  };
}

export const resetStripeState = createAction<undefined>('RESET_STRIPE_STATE');

export const attachMarketplaceToOrganizationRequest = createAction<{
  organizationId: string;
  marketplaceId: string;
  setupToken: string | null;
  marketplaceType: string;
}>('ATTACH_MARKETPLACE_TO_ORGANIZATION_REQUEST');

export const attachMarketplaceToOrganizationRequestSuccess = createAction<{
  marketplace: Marketplace;
  organizationId: string;
}>('ATTACH_MARKETPLACE_TO_ORGANIZATION_REQUEST_SUCCESS');

export const attachMarketplaceToOrganizationRequestError = createAction<{
  organizationId: string;
  marketplaceType: string;
  marketplaceId: string;
  setupToken: string | null;
  error: Error;
}>('ATTACH_MARKETPLACE_TO_ORGANIZATION_REQUEST_ERROR');

export function attachMarketplaceToOrganization(
  token: string,
  data: {
    marketplaceType: string;
    marketplaceId: string;
    setupToken: string | null;
    organizationId: string;
  },
) {
  const {organizationId, marketplaceType, marketplaceId, setupToken} = data;
  return (dispatch: Dispatch) => {
    dispatch(attachMarketplaceToOrganizationRequest(data));
    return new DashboardApi(token)
      .attachMarketplaceEntityToOrganization({
        marketplaceType,
        organizationId,
        marketplaceId,
        setupToken,
      })
      .then((res) => {
        dispatch(attachMarketplaceToOrganizationRequestSuccess({organizationId, marketplace: res}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `${marketplaceType.toUpperCase()} Marketplace successfully connected.`,
          }),
        );
        dispatch(push(`/organizations/${organizationId}`));
      })
      .catch((error) => {
        dispatch(
          attachMarketplaceToOrganizationRequestError({
            organizationId,
            marketplaceType,
            marketplaceId,
            setupToken,
            error,
          }),
        );
      });
  };
}

export const fetchSupportRequest = createAction<undefined>('FETCH_SUPPORT_REQUEST');
export const fetchSupportSuccess =
  createAction<OrganizationActionTypes.SupportData>('FETCH_SUPPORT_SUCCESS');
export const fetchSupportError = createAction<{}>('FETCH_SUPPORT_ERROR');

export function fetchSupport(token: string, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(fetchSupportRequest());
    return new DashboardApi(token)
      .fetchSupport(data.organizationId)
      .then((res) => {
        dispatch(fetchSupportSuccess(res));
      })
      .catch((error) => {
        dispatch(
          fetchSupportError({
            error: error.message,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Something went wrong with fetching support tier: ${error.message}`,
          }),
        );
      });
  };
}

export const changeSupportRequest = createAction<undefined>('CHANGE_SUPPORT_REQUEST');
export const changeSupportSuccess =
  createAction<OrganizationActionTypes.SupportData>('CHANGE_SUPPORT_SUCCESS');
export const changeSupportError = createAction<{error: Error}>('CHANGE_SUPPORT_ERROR');
export const changeSupportReset = createAction<undefined>('CHANGE_SUPPORT_RESET');

export function changeSupport(
  token: string,
  data: {
    organizationId: string;
    desiredSupportTier: SupportTiers;
    actionToComplete: ChangeSupportAction;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(changeSupportRequest());
    return new DashboardApi(token)
      .changeSupport(data.organizationId, data.desiredSupportTier)
      .then((res) => {
        dispatch(
          changeSupportSuccess({
            supportIntervals: res.supportIntervals,
            completedAction: data.actionToComplete,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          changeSupportError({
            error: error.message,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Something went wrong with changing support tier: ${error.message}`,
          }),
        );
      });
  };
}
