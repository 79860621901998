import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BillingPlanItemStatus from '../BillingPlanItemStatus/BillingPlanItemStatus';
import {type BillingPlanItem as BillingPlanItemType} from '../BillingPlans/types/billingPlans.interface';

interface BillingPlanItemProps {
  item: BillingPlanItemType;
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 1,
    alignItems: 'center',
    '@container (max-width: 175px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 0.5,
    },
  },
};

export default function BillingPlanItem({item}: BillingPlanItemProps) {
  return (
    <Box sx={styles.root}>
      <Typography>{item.name}</Typography>
      {item.status && <BillingPlanItemStatus status={item.status} />}
    </Box>
  );
}
