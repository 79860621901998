import {type NavigateProps} from 'react-router-dom';
import {useSubstituteDefaultOrg} from '../../hooks/navigation';
import NavigateWithParams from './NavigateWithParams';

export type SubstituteDefaultOrgProps = Omit<NavigateProps, 'to'>;

/** Navigates to the same path but with the default organization id substituted in */
function SubstituteDefaultOrg(navigateProps: SubstituteDefaultOrgProps) {
  const {path} = useSubstituteDefaultOrg();
  return <NavigateWithParams {...navigateProps} to={path} replace />;
}

export default SubstituteDefaultOrg;
