import {useFlags} from 'launchdarkly-react-client-sdk';

export enum Flags {
  EXAMPLE = 'example', // <responsible-user-id> <date-added> <anticipated-date-removed>
  V4_EARLY_ACCESS = 'shouldShowV4EarlyAccess', // damargulis 10/11/23 01/01/24
  ALLOW_SUGGEST_MODEL = 'shouldAllowSuggestModel', // damargulis 10/20/23 12/15/23
  SHOULD_SHOW_POD_BASED_COPY = 'shouldShowPodBasedCopy', // damarglis 01/12/24 03/01/24
  USE_EXISITNG_VECTOR_IN_EXPLORER = 'useExistingVectorInExplorer', // damargulis 02/07/04 03/01/24
  ENABLE_INTEGRATIONS_PAGE = 'enableIntegrationsPage', // retwere 4/23/24 5/15/24
  SHOULD_SHOW_USAGE_REPORT_EXPERIENCE = 'shouldShowUsageReportExperience',
  SHOW_GCP_SERVERLESS = 'shouldShowProdGcp2', // zadamjee 5/30/24 6/30/24
  SHOW_CREATE_FROM_DATASET = 'shouldShowCreateFromDataset', // zadamjee 6/1/24 6/30/24
  ENABLE_METRICS_REVAMP = 'enableMetricsRevamp', // cboltt 7/03/24 8/15/24
  // NOTE: Despite the name, this also controls allowing api key roles to exist
  API_KEY_SHOWN_ONCE = 'shouldShowApiKeyOnlyOnce', // damargulis 7/19/24
  ENABLE_METRICS_REVAMP_COMPARISON = 'enableMetricsRevampComparison', // cboltt 7/25/24 8/15/24
  ENABLE_GET_STARTED_GUIDE = 'enableGetStartedGuide', // zadamjee 8/01/24 9/15/24
  ENABLE_REQUESTS_BY_STATUS_CHART = 'enableRequestsByStatusChart', // cboltt 8/07/24 9/01/24
  ENABLE_STORAGE_INTEGRATIONS_PAGE = 'enableStorageIntegrationsPage', // retwere 8/12/24 9/01/24
  SHOULD_SHOW_INFERENCE_PAGE = 'shouldShowInferencePage', // kylermintah 8/21/24 8/30/24
  SHOW_BULK_IMPORT = 'showBulkImport', // cboltt 8/22/24 9/15/24
  PRICING_CHANGE_NOTIFICATION = 'pricingChangeNotification', // retwere 8/29/24 9/30/24
  // Can only be targeted with OrgId. Used to grant specific orgs CMEK support.
  ENABLE_CMEK_PROJECTS = 'enableCmekProjects', // cboltt 8/29/24 11/01/24
  ENABLE_IN_CONSOLE_SUPPORT = 'enableInConsoleSupport', // kylermintah 8/30/24 9/15/24
  ENABLE_SUPPORT_PLANS = 'enableSupportPlans', // tinedkim 8/28/24 9/16/24
  SUPPORT_TRIAL_START_NOTIFICATION = 'supportTrialStartNotification', // tinedkim 9/16/24 10/1/24
  ENABLE_GCS_STORAGE_INTEGRATIONS = 'enableGcsStorageIntegrations', // retwere 9/16/24 10/31/24
  ENABLE_AZURE_BLOB_STORAGE_INTEGRATIONS = 'enableAzureBlobStorageIntegrations', // retwere 9/16/24 10/31/24
  ENABLE_SUPPORT_TICKET_MANAGEMENT = 'enableSupportTicketManagement', // kylermintah 9/16/24 10/1/24
  PROJECT_HOME_PAGE = 'projectHomePage', // zadamjee 9/13/24
  ENABLE_NEW_REGISTRATION_FLOW = 'enableNewRegistrationFlow', // zadamjee 9/27/24 11/1/24
}

export function useFlag(flag: Flags) {
  return useFlags()[flag];
}
