import {env, isProduction} from './environment';
import {localStorageGet, localStorageSet} from './storage';

export const HEAP_TRACKING_EVENTS = {
  SIGNUP: 'Auth - Submit - Sign Up',
  CREATE_PROJECT: 'Console - Perform - Project Creation',
  INDEX_DELETED: 'Console - Perform - Index Deletion',
  INDEX_CREATED: 'Console - Perform - Index Creation',
  DATASET_INDEX_CREATED: 'Console - Perform - Dataset Index Creation',
  REGISTRATION_VIEWED: 'Console - View - Registration Details',
  REGISTRATION_SKIPPED: 'Console - Skipped - Registration Details',
  REGISTRATION_VIEWED_STEP_USER_INFORMATION: 'Console - View - RegistrationForm UserInformation',
  REGISTRATION_VIEWED_STEP_USE_CASES: 'Console - View - RegistrationForm UseCases',
  REGISTRATION_VIEWED_STEP_EMBEDDINGS: 'Console - View - RegistrationForm Embeddings',
  REGISTRATION_SUBMITTED_STEP_USER_INFORMATION:
    'Console - Submit - RegistrationForm UserInformation',
  REGISTRATION_SUBMITTED_STEP_USE_CASES: 'Console - Submit - RegistrationForm UseCases',
  REGISTRATION_SUBMITTED_STEP_EMBEDDINGS: 'Console - Submit - RegistrationForm Embeddings',
  SUBSCRIPTION_CREATED: 'Console - Perform - Upgrade',
  SUBSCRIPTION_DELETED: 'Console - Perform - Downgrade',
  SUBSCRIPTION_UPDATE: 'Console - Perform - Change Plan',
  KM_CHAT_NEGATIVE_FEEDBACK: 'Console - Click - KM Chat negative feedback',
};

export const TRACKING_EVENTS = {
  SIGNUP: 'signup',
  UPGRADE: 'upgrade',
  CREATE_PROJECT: 'console_create_project',
  EDIT_PROJECT: 'console_edit_project',
  DELETE_PROJECT: 'console_delete_project',
  CREATE_INDEX: 'console_create_index',
  CREATE_INDEX_EXAMPLE: 'console_create_index_example',
  CREATE_API_KEY: 'console_create_api_key',
  ROTATE_API_KEY: 'console_rotate_api_key',
  DELETE_API_KEY: 'console_delete_api_key',
  SHOW_API_KEY: 'console_show_api_key',
  HIDE_API_KEY: 'console_hide_api_key',
  COPY_API_KEY: 'console_copy_api_key',
  DOWNLOAD_EXAMPLE_DATASET: 'console_download_example_dataset',
  DOWNLOAD_EXAMPLE_QUERIES: 'console_download_example_queries',
  INVITE_USER: 'console_invite_user',
  REMOVE_USER: 'console_remove_user',
  PATCH_INDEX: 'console_patch_index',
  DELETE_INDEX: 'console_delete_index',
  VIEW_INDEX_INFO: 'console_view_index_info',
  VIEW_INDEX_METRICS: 'console_view_index_metrics',
  UPLOAD_FILE: 'console_upload_file',
  UPSERT_DATA: 'console_upsert_data',
  UPDATE_DATA: 'console_update_data',
  FETCH_DATA: 'console_fetch_data',
  QUERY_DATA: 'console_query_data',
  DELETE_DATA: 'console_delete_data',
};

// Enables pushing to google tag manager
declare global {
  interface Window {
    dataLayer?: Record<string, any>[];
    // Added via GTM
    signals?: {
      identify: (email: string) => void;
    };
    heap?: {
      addUserProperties: (properties: Object) => void;
      addEventProperties: (properties: Object) => void;
      identify: (userId: string) => void;
      load: (headId: string) => void;
      track: (eventName: string, data?: object) => void;
    };
    ChurnZero?: {
      push: (data: string[]) => void;
    };
    Cypress?: object;
  }
}

export function identifyWithCommonRoom(email: string) {
  window.signals?.identify(email);
}

export function sendToGa(eventName: string, data = {}) {
  try {
    if (isProduction) {
      window.dataLayer?.push({event: eventName, ...data});
    } else if (env !== 'test') {
      // eslint-disable-next-line no-console
      console.log('[DEV] Following event sent to google Analytics: ', eventName, data);
    }
  } catch (e) {
    // ignore
  }
}

export function sendToHeap(eventName: string, data = {}) {
  try {
    window.heap?.track(eventName, data);
  } catch (e) {
    // ignore
  }
}

const HEAP_PREFIX = 'HEAP';
export function sendToHeapOnce(eventName: string, data = {}) {
  const tag = `${HEAP_PREFIX}-${eventName}`;
  if (!localStorageGet(tag)) {
    localStorageSet(tag, 'true');
    sendToHeap(eventName, data);
  }
}

export function setHeapProperty(properties: object) {
  try {
    if (isProduction && window.heap?.addUserProperties) {
      window.heap.addUserProperties(properties);
    } else if (!isProduction) {
      // eslint-disable-next-line no-console
      console.log('[DEV] Sending properties to heap: ', properties);
    }
  } catch (e) {
    // ignore
  }
}

export function setHeapEventProperty(properties: object) {
  try {
    if (isProduction && window.heap?.addEventProperties) {
      window.heap.addEventProperties(properties);
    } else {
      // eslint-disable-next-line no-console
      console.log('[DEV] Sending event properties to heap: ', properties);
    }
  } catch (e) {
    // ignore
  }
}
