import Typography from '@mui/material/Typography';
import {type UpdateStorageIntegrationRequest} from '../../../../../api/types/controllerApi';
import {STORAGE_INTEGRATION_PROVIDER_INFO} from '../../../../../constants';
import {useSelectedGlobalProject} from '../../../../../selectors/projects';
import {useStorageIntegrations} from '../../../../../selectors/storageIntegrations';
import StorageActions from './StorageActions/StorageActions';
import StorageStatusBox from './StorageStatusBox/StorageStatusBox';

type StorageCellProps = {
  /** Which integration (row) to render. */
  row: number;
  /** Which cell (column) to render. */
  col: number;
  /** Callback for when the edit form is completed. */
  onEdit: (integrationId: string, updateData: UpdateStorageIntegrationRequest) => void;
  /** Callback for when the delete confirmation form is completed. */
  onDelete: (integrationId: string) => void;
};

/** A cell in the table of integrations. */
const StorageCell = ({row, col, onEdit, onDelete}: StorageCellProps) => {
  const selectedProject = useSelectedGlobalProject();
  const storageIntegrations = useStorageIntegrations(selectedProject.id);
  const storageIntegration = storageIntegrations[row];

  switch (col) {
    case 0:
      return <Typography>{storageIntegration.name}</Typography>;
    case 1:
      return <Typography>{storageIntegration.id}</Typography>;
    case 2:
      return (
        <Typography>
          {STORAGE_INTEGRATION_PROVIDER_INFO[storageIntegration.provider].name}
        </Typography>
      );
    case 3:
      return <StorageStatusBox integrationId={storageIntegration.id} />;
    case 4:
      return (
        <StorageActions
          storageIntegration={storageIntegration}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      );
    default:
      return null;
  }
};

export default StorageCell;
