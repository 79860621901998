import BillingPlans from '../../../components/BillingPlans/BillingPlans';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {useCurrentSubscription} from '../../../selectors/billing';
import {useIsSelectedOrganizationSubscribed} from '../../../selectors/organizations';

const PlansPage = () => {
  const currentSub = useCurrentSubscription();
  const isSubscribed = useIsSelectedOrganizationSubscribed();

  return (
    <Page
      width="wide"
      header={<PageHeader title="Billing plans" />}
      loading={isSubscribed && currentSub.loading}
    >
      <BillingPlans />
    </Page>
  );
};

export default PlansPage;
