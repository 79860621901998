import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import {LocalStorageKeys} from '../../../../../constants';
import {useNavigateToGetStartedPage} from '../../../../../hooks/navigation';
import {
  useSelectedGlobalProjectId,
  useSelectedOrganizationId,
} from '../../../../../selectors/params';
import {localStorageGet} from '../../../../../utils/storage';
import type {GuideId} from '../../../GetStartedPage/Guides/types';
import {type GuideCard} from './constants';

const styles = {
  card: {
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& button': {
      mt: 5,
      borderColor: 'divider',
    },
  },
  largeIcon: {
    fontSize: 35,
  },
};

function getIsGuideCompleted(projectId: string, guideId: GuideId) {
  return JSON.parse(
    localStorageGet(`${LocalStorageKeys.GET_STARTED_GUIDES}-${projectId}-${guideId}`) || '{}',
  )?.completed;
}

function GetStartedGuideCard({guide}: {guide: GuideCard}) {
  const {title, description, icon, guideId} = guide;
  const organizationId = useSelectedOrganizationId();
  const projectId = useSelectedGlobalProjectId();
  const completed = getIsGuideCompleted(projectId, guideId);
  const {go: navigateToGetStartedPage} = useNavigateToGetStartedPage(organizationId, projectId);
  return (
    <Card key={title} sx={styles.card}>
      <Box sx={styles.largeIcon}>{icon}</Box>
      <Typography variant="h6" component="h2" gutterBottom>
        {title}
      </Typography>
      <Typography>{description}</Typography>
      <Box sx={{flexGrow: 1}} />
      {completed ? (
        <Button
          variant="text"
          color="success"
          startIcon={<CheckIcon color="success" />}
          onClick={() => navigateToGetStartedPage(guideId)}
        >
          Completed
        </Button>
      ) : (
        <Button variant="outlined" onClick={() => navigateToGetStartedPage(guideId)}>
          Get started
        </Button>
      )}
    </Card>
  );
}

export default GetStartedGuideCard;
