import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import NumberInput from '@pinecone-experience/timber/Inputs/NumberInput';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {fetchVectors, listVectors} from '../../../../../../actions/dataActions';
import {useDataPlane} from '../../../../../../hooks/use-api';
import {useOnce} from '../../../../../../hooks/utils';
import {
  useServiceByGlobalProject,
  useServiceStatusByGlobalProject,
} from '../../../../../../selectors/services';
import {sortNamespaces} from '../../../../../../utils/data';
import {getSpecs} from '../../../../../../utils/services';
import NamespacePicker from '../../NamespacePicker/NamespacePicker';
import {type ListVectorsSchemaType, listSchema} from '../../utilities/schema';
import {type IndexExplorerState} from '../../utilities/utils';

type ListVectorControlsProps = {
  indexExplorerState: IndexExplorerState;
};

const styles = {
  container: {
    display: 'flex',
    gap: 2,
    py: 2,
    pb: 0,
    alignItems: 'center',
  },
};

enum ListOptions {
  LIST = 'List IDs',
  FETCH = 'Fetch by ID',
}

function ListVectorControls({indexExplorerState}: ListVectorControlsProps) {
  const {project, service, indexStats, host} = indexExplorerState;
  const indexInfo = useServiceByGlobalProject(project.id, service);
  const {podSpec} = getSpecs(indexInfo);
  const [queryType, setQueryType] = useState<ListOptions>(
    podSpec ? ListOptions.FETCH : ListOptions.LIST,
  );
  useEffect(() => {
    if (podSpec) {
      setQueryType(ListOptions.FETCH);
    }
  }, [podSpec]);
  const {control, handleSubmit, getValues, setValue, watch} = useForm<ListVectorsSchemaType>({
    defaultValues: {
      namespace: '',
      prefix: '',
      limit: 100,
    },
    resolver: zodResolver(listSchema),
  });
  const {setQueryData} = useDataPlane<{
    prefix: string;
    namespace: string;
    limit: number;
  }>(project, service, host);
  const {setQueryData: setQueryDataFetch} = useDataPlane<{
    namespace: string;
    ids: string[];
  }>(project, service, host);
  const serviceStatus = useServiceStatusByGlobalProject(project.id, service);
  const doListVectors = useCallback(
    (formValues: ListVectorsSchemaType) => {
      setQueryData({
        action: listVectors,
        data: {
          prefix: formValues.prefix || '',
          namespace: formValues.namespace || '',
          limit: formValues.limit,
        },
      });
    },
    [setQueryData],
  );
  const doFetchVectors = (formValues: ListVectorsSchemaType) => {
    setQueryDataFetch({
      action: fetchVectors,
      data: {
        namespace: formValues.namespace || '',
        ids: formValues.prefix.split(',').map((a) => a.trim()),
      },
    });
  };

  const initList = useCallback(() => {
    const defaultNamespace = sortNamespaces(indexStats)[0];
    setValue('namespace', defaultNamespace);
    if (!podSpec) {
      doListVectors(getValues());
    }
  }, [podSpec, doListVectors, setValue, getValues, indexStats]);
  useOnce(initList, !!serviceStatus.ready && !!indexStats);
  const prefix = watch('prefix');
  return (
    <Box sx={styles.container}>
      <NamespacePicker
        indexExplorerState={indexExplorerState}
        control={control}
        id="query-namespace-picker"
      />
      <SelectInput
        label="Operation"
        onChange={setQueryType}
        value={queryType}
        options={[{value: ListOptions.LIST}, {value: ListOptions.FETCH}]}
        disabled={!!podSpec}
      />
      {queryType === ListOptions.LIST ? (
        <>
          <Box sx={{flex: 1}}>
            <TextInput
              fullWidth
              control={control}
              name="prefix"
              placeholder="Prefix value (optional)"
              label="ID Prefix"
            />
          </Box>
          <NumberInput name="limit" control={control} label="Limit" sx={{maxWidth: 120}} />
          <Box>
            <Button
              sx={{mt: 1.5}}
              id="explorer-list-submit"
              variant="contained"
              onClick={handleSubmit(doListVectors)}
            >
              Get
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{flex: 1}}>
            <TextInput
              fullWidth
              control={control}
              name="prefix"
              placeholder="meddoc#001chunk, meddoc#002chunk, "
              label="IDs"
            />
          </Box>
          <Box>
            <Button
              sx={{mt: 1.5}}
              id="explorer-fetch-submit"
              variant="contained"
              disabled={prefix.length <= 0}
              onClick={handleSubmit(doFetchVectors)}
            >
              Get
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default ListVectorControls;
