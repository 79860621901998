import {type Dispatch, createAction} from '@reduxjs/toolkit';
import {push} from 'redux-first-history';
import type AssistantDataApi from '../api/assistantApi';
import type ControllerApi from '../api/controllerApi';
import {ProjectPage} from '../constants';
import {
  type Assistant,
  type AssistantChatConfig,
  type AssistantChatModel,
  type AssistantFormData,
  type AssistantUsage,
  type ChatMessage,
} from '../types';
import {NotificationTypes, enqueNotification} from './notificationActions';
import {decodeErrorMessage} from './utils/errors';

export const getAcceptedAssistantsTermsRequest = createAction<{organizationId: string}>(
  'GET_ACCEPTED_ASSISTANTS_TERMS_REQUEST',
);
export const getAcceptedAssistantsTermsSuccess = createAction<{
  organizationId: string;
  acceptedTerms: boolean;
}>('GET_ACCEPTED_ASSISTANTS_TERMS_SUCCESS');
export const getAcceptedAssistantsTermsFailure = createAction<{
  organizationId: string;
  error: string;
}>('GET_ACCEPTED_ASSISTANTS_TERMS_FAILURE');

export function getAcceptedAssistantsTerms(api: ControllerApi, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getAcceptedAssistantsTermsRequest({organizationId: data.organizationId}));
    return api
      .getAcceptedAssistantsTerms(data.organizationId)
      .then((result) => {
        dispatch(
          getAcceptedAssistantsTermsSuccess({
            organizationId: data.organizationId,
            acceptedTerms: result.enabled === true,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          getAcceptedAssistantsTermsFailure({
            organizationId: data.organizationId,
            error: error.message,
          }),
        );
      });
  };
}

export const acceptAssistantsTermsRequest = createAction<{organizationId: string}>(
  'ACCEPT_ASSISTANTS_TERMS_REQUEST',
);
export const acceptAssistantsTermsSuccess = createAction<{
  organizationId: string;
  acceptedTerms: boolean;
}>('ACCEPT_ASSISTANTS_TERMS_SUCCESS');
export const acceptAssistantsTermsFailure = createAction<{organizationId: string}>(
  'ACCEPT_ASSISTANTS_TERMS_FAILURE',
);

export function acceptAssistantsTerms(api: ControllerApi, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(acceptAssistantsTermsRequest({organizationId: data.organizationId}));
    return api
      .acceptAssistantsTerms(data.organizationId)
      .then((result) => {
        dispatch(
          acceptAssistantsTermsSuccess({
            organizationId: data.organizationId,
            acceptedTerms: !!result.enabled,
          }),
        );
      })
      .catch((error) => {
        dispatch(acceptAssistantsTermsFailure({organizationId: data.organizationId}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to accept Terms of Service. ${decodeErrorMessage(error.message)}`,
          }),
        );
      });
  };
}

export const listAssistantsRequest = createAction<{globalProjectId: string}>(
  'LIST_ASSISTANTS_REQUEST',
);
export const listAssistantsSuccess = createAction<{
  assistants: Assistant[];
  globalProjectId: string;
}>('LIST_ASSISTANTS_SUCCESS');
export const listAssistantsFailure = createAction<{globalProjectId: string}>(
  'LIST_ASSISTANTS_FAILURE',
);

export function listAssistants(api: ControllerApi, data: {globalProjectId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(listAssistantsRequest({globalProjectId: data.globalProjectId}));
    return api
      .listAssistants()
      .then((res) => {
        dispatch(
          listAssistantsSuccess({
            globalProjectId: data.globalProjectId,
            assistants: res.assistants,
          }),
        );
      })
      .catch((error) => {
        dispatch(listAssistantsFailure({globalProjectId: data.globalProjectId}));
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Failed to load assistants. ${decodeErrorMessage(error.message)}`,
          }),
        );
      });
  };
}

export const createAssistantRequest = createAction<{globalProjectId: string}>(
  'CREATE_ASSISTANT_REQUEST',
);
export const createAssistantSuccess = createAction<{
  globalProjectId: string;
  assistant: Assistant;
}>('CREATE_ASSISTANT_SUCCESS');
export const createAssistantFailure = createAction<{globalProjectId: string; error: string}>(
  'CREATE_ASSISTANT_FAILURE',
);

export function createAssistant(
  api: ControllerApi,
  data: {
    globalProjectId: string;
    organizationId: string;
    formData: AssistantFormData;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(createAssistantRequest({globalProjectId: data.globalProjectId}));
    return api
      .createAssistant(data.formData)
      .then((response) => {
        dispatch(
          createAssistantSuccess({
            globalProjectId: data.globalProjectId,
            assistant: response,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Assistant successfully created.`,
          }),
        );
        dispatch(
          push(
            `/organizations/${data.organizationId}/projects/${data.globalProjectId}/${ProjectPage.ASSISTANTS}/${response.name}/files`,
          ),
        );
      })
      .catch((error) => {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Assistant could not be created. ${decodeErrorMessage(error.message)}`,
          }),
        );
        dispatch(
          createAssistantFailure({
            globalProjectId: data.globalProjectId,
            error: `${decodeErrorMessage(error.message)}`,
          }),
        );
      });
  };
}

export const updateAssistantInstructionsRequest = createAction<{globalProjectId: string}>(
  'UPDATE_ASSISTANT_INSTRUCTIONS_REQUEST',
);
export const updateAssistantInstructionsSuccess = createAction<{
  globalProjectId: string;
  assistantName: string;
  instructions: string;
}>('UPDATE_ASSISTANT_INSTRUCTIONS_SUCCESS');
export const updateAssistantInstructionsFailure = createAction<{
  globalProjectId: string;
  error: string;
}>('UPDATE_ASSISTANT_INSTRUCTIONS_FAILURE');

export function updateAssistantInstructions(
  api: ControllerApi,
  data: {
    globalProjectId: string;
    assistantName: string;
    instructions: string;
    metadata: Record<string, string> | null;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(updateAssistantInstructionsRequest({globalProjectId: data.globalProjectId}));
    return api
      .updateAssistantInstructions(data.assistantName, data.instructions, data.metadata)
      .then((response) => {
        dispatch(
          updateAssistantInstructionsSuccess({
            globalProjectId: data.globalProjectId,
            assistantName: data.assistantName,
            instructions: response.instructions,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Assistant instructions updated.`,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          updateAssistantInstructionsFailure({
            globalProjectId: data.globalProjectId,
            error: decodeErrorMessage(error.message),
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Assistant instructions could not be updated. ${decodeErrorMessage(
              error.message,
            )}`,
          }),
        );
      });
  };
}

export const getAssistantRequest = createAction<{globalProjectId: string; assistantName: string}>(
  'GET_ASSISTANT_REQUEST',
);
export const getAssistantSuccess = createAction<{
  assistant: Assistant;
  globalProjectId: string;
}>('GET_ASSISTANT_SUCCESS');
export const getAssistantFailure = createAction<{globalProjectId: string; error: Error}>(
  'GET_ASSISTANT_FAILURE',
);
export const assistantFinishedTerminating = createAction<{
  globalProjectId: string;
  assistantName: string;
}>('ASSISTANT_FINISHED_TERMINATING');

export function getAssistant(
  api: ControllerApi,
  data: {globalProjectId: string; assistantName: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(
      getAssistantRequest({
        globalProjectId: data.globalProjectId,
        assistantName: data.assistantName,
      }),
    );
    return api
      .getAssistant(data.assistantName)
      .then((assistant) => {
        dispatch(getAssistantSuccess({globalProjectId: data.globalProjectId, assistant}));
      })
      .catch((error) => {
        if (error.status === 404) {
          dispatch(
            assistantFinishedTerminating({
              globalProjectId: data.globalProjectId,
              assistantName: data.assistantName,
            }),
          );
        }
        dispatch(getAssistantFailure({globalProjectId: data.globalProjectId, error}));
      });
  };
}

export const deleteAssistantRequest = createAction<{
  globalProjectId: string;
  assistantName: string;
}>('DELETE_ASSISTANT_REQUEST');
export const deleteAssistantSuccess = createAction<{
  globalProjectId: string;
  assistantName: string;
}>('DELETE_ASSISTANT_SUCCESS');
export const deleteAssistantFailure = createAction<{
  globalProjectId: string;
  assistantName: string;
}>('DELETE_ASSISTANT_FAILURE');

export function deleteAssistant(
  api: ControllerApi,
  data: {globalProjectId: string; assistantName: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(
      deleteAssistantRequest({
        globalProjectId: data.globalProjectId,
        assistantName: data.assistantName,
      }),
    );
    return api
      .deleteAssistant(data.assistantName)
      .then(() => {
        dispatch(
          deleteAssistantSuccess({
            globalProjectId: data.globalProjectId,
            assistantName: data.assistantName,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.SUCCESS,
            text: `Assistant marked for deletion.`,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          deleteAssistantFailure({
            globalProjectId: data.globalProjectId,
            assistantName: data.assistantName,
          }),
        );
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Assistant could not be deleted. ${decodeErrorMessage(error.message)}`,
          }),
        );
      });
  };
}
export const streamAssistantChatCompletionRequest = createAction<{
  globalProjectId: string;
  assistantName: string;
  message: ChatMessage;
}>('STREAM_ASSISTANT_CHAT_COMPLETION_REQUEST');
export const streamAssistantChatCompletionResponse = createAction<{
  globalProjectId: string;
  assistantName: string;
  responseChunk: string;
}>('STREAM_ASSISTANT_CHAT_COMPLETION_RESPONSE');
export const streamAssistantChatCompletionChatIdReceived = createAction<{
  globalProjectId: string;
  assistantName: string;
  chatId: string;
}>('STREAM_ASSISTANT_CHAT_COMPLETION_CHAT_ID_RECEIVED');
export const streamAssistantChatCompletionSuccess = createAction<{
  globalProjectId: string;
  assistantName: string;
  stopReason: string;
}>('STREAM_ASSISTANT_CHAT_COMPLETION_SUCCESS');
export const streamAssistantChatCompletionFailure = createAction<{
  globalProjectId: string;
  assistantName: string;
  error: string;
}>('STREAM_ASSISTANT_CHAT_COMPLETION_FAILURE');

export function streamAssistantChatCompletion(
  api: AssistantDataApi,
  data: {
    globalProjectId: string;
    assistantName: string;
    chatHistory: ChatMessage[];
    message: ChatMessage;
    config: AssistantChatConfig;
  },
) {
  return (dispatch: Dispatch) => {
    dispatch(
      streamAssistantChatCompletionRequest({
        globalProjectId: data.globalProjectId,
        assistantName: data.assistantName,
        message: data.message,
      }),
    );

    const onMessage = (responseChunk: string) => {
      dispatch(
        streamAssistantChatCompletionResponse({
          globalProjectId: data.globalProjectId,
          assistantName: data.assistantName,
          responseChunk,
        }),
      );
    };

    const onChatIdReceived = (chatId: string) => {
      dispatch(
        streamAssistantChatCompletionChatIdReceived({
          globalProjectId: data.globalProjectId,
          assistantName: data.assistantName,
          chatId,
        }),
      );
    };

    return api
      .streamAssistantChatCompletion(
        data.assistantName,
        [...data.chatHistory, data.message],
        data.config,
        onMessage,
        onChatIdReceived,
      )
      .then((stopReason) => {
        dispatch(
          streamAssistantChatCompletionSuccess({
            globalProjectId: data.globalProjectId,
            assistantName: data.assistantName,
            stopReason,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          streamAssistantChatCompletionFailure({
            globalProjectId: data.globalProjectId,
            assistantName: data.assistantName,
            error: decodeErrorMessage(error.message),
          }),
        );
      });
  };
}

export const clearAssistantChat = createAction<{
  globalProjectId: string;
  assistantName: string;
}>('CLEAR_ASSISTANT_CHAT');

export const updateAssistantChatModel = createAction<{
  globalProjectId: string;
  assistantName: string;
  model: AssistantChatModel;
}>('UPDATE_ASSISTANT_CHAT_MODEL');

export const getAssistantsUsageRequest = createAction<{
  globalProjectId: string;
}>('GET_ASSISTANTS_USAGE_REQUEST');
export const getAssistantsUsageSuccess = createAction<{
  globalProjectId: string;
  usage: Record<string, AssistantUsage>;
}>('GET_ASSISTANTS_USAGE_SUCCESS');
export const getAssistantsUsageFailure = createAction<{globalProjectId: string; error: Error}>(
  'GET_ASSISTANTS_USAGE_FAILURE',
);

export function getAssistantsUsage(
  api: AssistantDataApi,
  data: {globalProjectId: string; silentPoll?: boolean},
) {
  return (dispatch: Dispatch) => {
    dispatch(
      getAssistantsUsageRequest({
        globalProjectId: data.globalProjectId,
      }),
    );
    return api
      .getAssistantsUsage()
      .then((result) => {
        dispatch(getAssistantsUsageSuccess({globalProjectId: data.globalProjectId, usage: result}));
      })
      .catch((error) => {
        dispatch(getAssistantsUsageFailure({globalProjectId: data.globalProjectId, error}));
      });
  };
}
