import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useFormContext} from 'react-hook-form';
import {sendToHeap} from '../../utils/tracking';
import {RegistrationStepMap} from './contants';
import {RegistrationSteps, useRegistrationFormContext} from './utilities/RegistrationFormContext';

const styles = {
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
    mt: 4,
  },
};

function StepButtons() {
  const {step, setStep} = useRegistrationFormContext();
  const {
    watch,
    trigger,
    formState: {isValid},
  } = useFormContext();

  const fieldValues = watch();
  const {fields, heapSubmittedEvent} = RegistrationStepMap[step];
  const numberOfSteps = Object.keys(RegistrationSteps).filter((key) =>
    Number.isNaN(Number(key)),
  ).length;

  const stepFieldValues = fields.reduce((subset, key) => {
    if (key in fieldValues) {
      subset[key] = fieldValues[key];
    }
    return subset;
  }, {} as Record<string, string>);

  const handleContinue = async () => {
    const isStepValid = await trigger(fields);
    if (isStepValid) {
      sendToHeap(heapSubmittedEvent, stepFieldValues);
      setStep(step + 1);
    }
  };

  return (
    <Box sx={styles.buttons}>
      <Typography variant="body2" noWrap>
        Step {step + 1} of {numberOfSteps}
      </Typography>
      <Box display="flex" gap={2}>
        {step > 0 && (
          <Button color="secondary" onClick={() => setStep(step - 1)}>
            Back
          </Button>
        )}
        {step < numberOfSteps - 1 ? (
          <Button variant="contained" color="primary" onClick={handleContinue}>
            Continue
          </Button>
        ) : (
          <Button
            id="registration-form-submit-button"
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isValid}
          >
            {`Let's Get Started`}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default StepButtons;
