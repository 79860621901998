import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import {ResponsiveLine} from '@nivo/line';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {sub} from 'date-fns/sub';
import {useMemo, useState} from 'react';
import {requestHeartBeatQuery} from '../../../../../actions/prometheusActions';
import {IndexState} from '../../../../../constants';
import {useNivoTheme} from '../../../../../hooks/chart';
import {usePrometheusRangeQuery} from '../../../../../hooks/use-api';
import {useInterval} from '../../../../../hooks/utils';
import {useLegacyMetrics} from '../../../../../selectors/metrics';
import {useSelectedGlobalProjectId} from '../../../../../selectors/params';
import {type PrometheusLegacyResponse, type V4IndexInfoResponse} from '../../../../../types';
import {getSpecs} from '../../../../../utils/services';
import {formatDateToString} from '../../LegacyIndexMetrics/utilities/MetricsUtils';
import HeartbeatTooltip from './HeartbeatTooltip/HeartbeatTooltip';
import {Pulse, PulseSVG} from './Pulse/Pulse';
import {
  HEART_BEAT_POLLING_INTERVAL,
  margin,
  useHeartbeatDataYScales,
  useHeartbeatFormatDateTick,
} from './utilities/HeartbeatUtils';

const styles = {
  container: {
    flexGrow: 1,
    display: 'flex',
    width: 350,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  chart: {
    mt: 'auto',
    height: 300,
    width: '100%',
  },
  vectorCountTag: {
    position: 'absolute',
    p: 1,
    top: 4,
    left: 4,
  },
  nonReadyState: {
    minHeight: 100,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

type ChartProps = {
  formattedData: PrometheusLegacyResponse;
  min: number;
  max: number;
};

function Chart({formattedData, min, max}: ChartProps) {
  const nivoTheme = useNivoTheme();
  const formatTick = useHeartbeatFormatDateTick(formattedData);
  return (
    <ResponsiveLine
      data={formattedData}
      theme={nivoTheme}
      colors={[nivoTheme.primary]}
      margin={margin}
      yScale={{
        type: 'linear',
        min,
        max,
      }}
      curve="monotoneX"
      lineWidth={3}
      axisTop={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        format: formatTick,
      }}
      enableGridY={false}
      enablePoints={false}
      enableGridX={false}
      enableSlices="x"
      layers={[
        'grid',
        'markers',
        'axes',
        'areas',
        'crosshair',
        'lines',
        'points',
        'slices',
        'mesh',
        'legends',
        Pulse,
      ]}
      sliceTooltip={HeartbeatTooltip}
    />
  );
}

interface HeartbeatChartProps {
  service: string;
  statusText: string;
  serviceInfo?: V4IndexInfoResponse;
}

function HeartbeatChart({service, statusText, serviceInfo}: HeartbeatChartProps) {
  const theme = useTheme();
  const projectId = useSelectedGlobalProjectId();
  const [refresh, setRefresh] = useState(new Date());
  const {startTime, endTime} = useMemo(() => {
    const start = formatDateToString(sub(refresh, {minutes: 10}));
    const end = formatDateToString(refresh);
    return {startTime: start, endTime: end};
  }, [refresh]);

  // Auto refresh every 10 seconds
  useInterval(() => {
    setRefresh(new Date());
  }, HEART_BEAT_POLLING_INTERVAL);

  const {serverlessSpec} = getSpecs(serviceInfo);
  usePrometheusRangeQuery(
    requestHeartBeatQuery,
    projectId || '',
    service,
    startTime.toString(),
    endTime.toString(),
    !!serverlessSpec,
  );
  const heartbeatMetrics = useLegacyMetrics('heartbeat', service);
  const data = heartbeatMetrics?.data || [];
  const isEmpty = (data[0]?.data?.length || 0) <= 2;
  const {min, max, latestCount} = useHeartbeatDataYScales(data);
  if (serviceInfo?.status.state !== IndexState.READY) {
    return (
      <Paper sx={styles.container}>
        <Box sx={styles.nonReadyState}>
          <PulseSVG
            primaryColor={theme.palette.text.secondary}
            secondaryColor={theme.palette.background.paper}
          />
          <Typography ml={1} variant="overline" color="text.secondary">
            {`Index ${statusText}`}
          </Typography>
        </Box>
      </Paper>
    );
  }
  return (
    <Paper sx={styles.container}>
      {!isEmpty && (
        <Box sx={styles.vectorCountTag}>
          <Typography variant="overline" color="text.secondary">
            Vector Count
          </Typography>
          <Typography fontWeight="bold">{latestCount?.toLocaleString()}</Typography>
        </Box>
      )}
      {isEmpty ? (
        <LoadingContainer loading={heartbeatMetrics?.loading} showChildrenInBackground>
          <Box sx={styles.nonReadyState}>
            <PulseSVG
              primaryColor={theme.palette.text.secondary}
              secondaryColor={theme.palette.background.paper}
            />
            <Typography ml={1} variant="subtitle1" color="text.secondary">
              No Records Found
            </Typography>
          </Box>
        </LoadingContainer>
      ) : (
        <Box sx={styles.chart}>
          <Chart formattedData={data} min={min} max={max} />
        </Box>
      )}
    </Paper>
  );
}

export default HeartbeatChart;
