import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchSystemStatus} from '../../../../actions/systemStatusActions';
import {type RootState} from '../../../../app/store';
import {LinkSection} from '../../../../components/Support/LinkSection';
import ResourceLinks from '../../../../components/Support/ResourceLinks';
import {ChatBot} from '../../../../components/Support/SupportChatbot';
import {HeaderBar} from '../../../../components/Support/SupportHeaderBar';
import Page from '../../../../components/layout/Page/Page';
import PageHeader from '../../../../components/layout/PageHeader/PageHeader';
import {SupportTiers} from '../../../../constants';
import {useNavigateToSupportTicket} from '../../../../hooks/navigation';
import {useInterval} from '../../../../hooks/utils';
import {useCurrentSupportTier, useIsSupportIncluded} from '../../../../selectors/billing';
import {useSelectedOrganizationId} from '../../../../selectors/params';

const styles = {
  pageContainer: {
    display: 'flex',
    gap: 4,
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    gap: 4,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  resourceLinksContainer: {
    display: 'flex',
    gap: 4,
    marginTop: 2.5,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
};

function SupportHomePage() {
  const {data: systemStatus} = useSelector((state: RootState) => state.systemStatus);
  const isSupportIncluded = useIsSupportIncluded();
  const currentSupportTier = useCurrentSupportTier();
  const orgId = useSelectedOrganizationId();
  const {go: goToSupportTicketPage} = useNavigateToSupportTicket(orgId);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSystemStatus());
  }, [dispatch]);

  useInterval(() => {
    dispatch(fetchSystemStatus());
  }, 5 * 60 * 1000);

  const STATUS_PAGE_URL = 'https://status.pinecone.io';
  const canSubmitRequest = currentSupportTier !== SupportTiers.FREE || isSupportIncluded;

  const statusButton = (
    <Button variant="text" sx={{color: 'text.primary'}} href={STATUS_PAGE_URL} target="_blank">
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          marginRight: 1,
          backgroundColor: systemStatus?.isOperational ? 'success.main' : 'warning.main',
        }}
      />
      {systemStatus?.isOperational
        ? 'All systems operational'
        : 'Some systems are experiencing issues'}
    </Button>
  );

  const submitRequestButton = canSubmitRequest && (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        goToSupportTicketPage();
      }}
    >
      Submit a request
    </Button>
  );

  return (
    <Page
      width="narrow"
      header={
        <PageHeader
          title="We're here to help"
          seoTitle="Support"
          actions={
            <>
              {statusButton}
              {submitRequestButton}
            </>
          }
        />
      }
    >
      <Box sx={styles.pageContainer}>
        <HeaderBar />
        <Box sx={styles.contentContainer}>
          <ChatBot />
          <LinkSection />
        </Box>
        <Box sx={styles.resourceLinksContainer}>
          <ResourceLinks />
        </Box>
      </Box>
    </Page>
  );
}

export default SupportHomePage;
