import {useEffect} from 'react';
import {sendToHeap} from '../../../utils/tracking';
import {RegistrationStepMap} from '../contants';
import {
  type RegistrationSteps,
  useRegistrationFormContext,
} from '../utilities/RegistrationFormContext';

function StepWrapper({step, children}: {step: RegistrationSteps; children: React.ReactElement}) {
  const {step: currentStep} = useRegistrationFormContext();
  const {heapViewedEvent} = RegistrationStepMap[step];

  useEffect(() => {
    if (step === currentStep) {
      sendToHeap(heapViewedEvent);
    }
  }, [heapViewedEvent, step, currentStep]);

  if (step !== currentStep) {
    return null;
  }

  return children;
}

export default StepWrapper;
