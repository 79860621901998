import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {type MultiSelectState} from '@pinecone-experience/timber/Table/hooks';
import PageFooter from '../../../../components/layout/PageFooter/PageFooter';
import {useBooleanState} from '../../../../hooks/utils';
import {type Backup, type GlobalProject} from '../../../../types';
import DeleteBackupsConfirmation from '../DeleteBackupsConfirmation/DeleteBackupsConfirmation';

type BackupsDetailsFooterProps = {
  multiSelectState: MultiSelectState<Backup>;
  project: GlobalProject;
};

function BackupsDetailsFooter({multiSelectState, project}: BackupsDetailsFooterProps) {
  const {selectedIndexes, selectedItems, amtSelected, toggleAll} = multiSelectState;
  const {val: deleteIsOpen, setTrue: openDelete, setFalse: closeDelete} = useBooleanState();
  return (
    <>
      {amtSelected > 0 && (
        <PageFooter>
          <Typography>{`${amtSelected} of ${selectedIndexes.length} selected`}</Typography>
          <Box display="flex" gap={2}>
            <Button color="secondary" onClick={() => toggleAll(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={openDelete}
            >{`Delete ${amtSelected} backup`}</Button>
          </Box>
        </PageFooter>
      )}
      <DeleteBackupsConfirmation
        open={deleteIsOpen}
        close={closeDelete}
        project={project}
        backups={selectedItems}
      />
    </>
  );
}

export default BackupsDetailsFooter;
