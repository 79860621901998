import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SelectBoxInput from '@pinecone-experience/timber/Inputs/SelectBoxInput';
import {useFormContext} from 'react-hook-form';
import OtherOption from '../../../components/Utilities/OtherOption';
import {NumDocsOptions, UseCaseOptions} from '../contants';
import {type RegistrationSteps} from '../utilities/RegistrationFormContext';
import StepWrapper from './StepWrapper';

const styles = {
  header: {
    mb: 2,
  },
  inputGrid: {
    display: 'flex',
    gap: 1,
    '> div': {
      flex: 1,
    },
  },
};

function UseCases({step}: {step: RegistrationSteps}) {
  const {watch, control} = useFormContext();
  const useCaseVal = watch('useCase');

  return (
    <StepWrapper step={step}>
      <>
        <Box sx={styles.header}>
          <Typography component="h1" variant="h5">
            What do you plan to build with Pinecone?
          </Typography>
        </Box>
        <Box>
          <SelectBoxInput
            name="useCase"
            control={control}
            options={UseCaseOptions}
            addOption={OtherOption({
              fieldName: 'useCase',
              fieldValue: useCaseVal,
              optionsArray: UseCaseOptions,
              control,
            })}
          />
        </Box>
        <Box>
          <Box mt={3} mb={1}>
            <Typography fontWeight="600">
              How many documents are you looking to retrieve over?
            </Typography>
            <Typography>{`(These can be pdf's, images, audio files, etc.)`}</Typography>
          </Box>
          <Box>
            <SelectBoxInput name="numDocs" control={control} options={NumDocsOptions} />
          </Box>
        </Box>
      </>
    </StepWrapper>
  );
}

export default UseCases;
