import {type Plans} from '../../../constants';

type BillingPlanItemStatus = 'coming-soon' | 'public-preview';

export interface BillingPlanItem {
  name: React.ReactNode;
  status?: BillingPlanItemStatus;
}

export enum BillingPlanAttributes {
  Features = 'Features',
  Regions = 'Regions',
}

export enum BillingPlanProducts {
  Serverless = 'Serverless',
  Inference = 'Inference',
  Assistant = 'Assistant',
}

export interface BillingPlanProduct {
  name: string;
  docsLink: string;
  status?: BillingPlanItemStatus;
  features: BillingPlanItem[];
}

export interface BillingPlan {
  tier: Plans;
  price: string;
  description: string;
  attributes: Record<BillingPlanAttributes, BillingPlanItem[]>;
  productItems: Record<BillingPlanProducts, BillingPlanItem[]>;
  badge?: React.ReactNode;
}
