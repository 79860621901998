import Typography from '@mui/material/Typography';
import Alert from '@pinecone-experience/timber/Alert';
import {type SupportInterval} from '../../../../../actions/types/organizationActions.types';
import {SupportTierNameMapping} from '../../../../../constants';
import {getRemainingDaysOfSupport} from '../utils/supportPlans';

const SupportPlanAlert = ({
  currentSupport,
  scheduledSupport,
}: {
  currentSupport: SupportInterval;
  scheduledSupport: SupportInterval;
}) => {
  const {endDate, daysRemaining} = currentSupport
    ? getRemainingDaysOfSupport(currentSupport)
    : {endDate: '', daysRemaining: 0};

  return (
    <Alert
      variant="standard"
      description={
        <>
          <Typography variant="subtitle1">
            {currentSupport && SupportTierNameMapping[currentSupport.tier]} support ending soon
          </Typography>
          <Typography>
            Your {currentSupport && SupportTierNameMapping[currentSupport.tier]} support plan will
            end in {daysRemaining} days, on {endDate}. After that, you will move to the{' '}
            {SupportTierNameMapping[scheduledSupport.tier]} support plan.
          </Typography>
        </>
      }
    />
  );
};

export default SupportPlanAlert;
