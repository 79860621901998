import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import SelectorMenu from '@pinecone-experience/timber/Menus/SelectorMenu';
import {type Item} from '@pinecone-experience/timber/Menus/types';
import type React from 'react';
import {useRef} from 'react';
import {useBooleanState} from '../../../../../hooks/utils';
import NavButton from '../NavButton/NavButton';

interface NavSelectorProps {
  id: string;
  startIcon: React.ReactNode;
  title: string;
  titleAction?: React.ReactNode;
  placeholder: string;
  items: Item[];
  selectedItem?: {
    id: string;
    name: string;
  };
  onViewAll?: () => void;
}

const styles = {
  menu: {
    minWidth: 250,
    maxWidth: 500,
    mt: 1,
  },
};

function NavSelector({
  id,
  startIcon,
  title,
  placeholder,
  items,
  selectedItem,
  onViewAll,
  titleAction,
}: NavSelectorProps) {
  const {
    val: showSelector,
    setTrue: setShowSelector,
    setFalse: setHideSelector,
  } = useBooleanState();
  const menuAnchor = useRef(null);

  return (
    <>
      <NavButton
        id={`${id}-selector`}
        ref={menuAnchor}
        onClick={setShowSelector}
        startIcon={startIcon}
        endIcon={showSelector ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
      >
        {selectedItem?.name || placeholder}
      </NavButton>
      <SelectorMenu
        id={`${id}-selector-menu`}
        open={showSelector}
        anchor={menuAnchor.current}
        onClose={setHideSelector}
        sx={styles.menu}
        placement="bottom-start"
        title={title}
        titleAction={titleAction}
        items={items}
        selectedItemId={selectedItem?.id}
        onViewAll={onViewAll}
      />
    </>
  );
}

export default NavSelector;
