import {keyframes} from '@emotion/react';
import Box from '@mui/material/Box';
import ThemeAwarePineconeLogo from '../../../../components/Utilities/ThemeAwarePineconeLogo';

const spin = keyframes`
from {
    transform:rotate(0deg);
}
to {
    transform:rotate(360deg);
}`;

function AnimatedPineconeLogo() {
  return (
    <Box
      sx={{
        animation: `${spin} 1s ease-in-out infinite`,
      }}
    >
      <ThemeAwarePineconeLogo sx={{height: 35}} />
    </Box>
  );
}

export default AnimatedPineconeLogo;
