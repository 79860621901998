import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SelectBoxInput from '@pinecone-experience/timber/Inputs/SelectBoxInput';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useFormContext} from 'react-hook-form';
import OtherOption from '../../../components/Utilities/OtherOption';
import {Purpose} from '../../../constants';
import {LanguageOptions} from '../contants';
import {type RegistrationSteps} from '../utilities/RegistrationFormContext';
import StepWrapper from './StepWrapper';

const styles = {
  header: {
    mb: 3,
  },
  inputGrid: {
    display: 'flex',
    gap: 2,
    '> div': {
      flex: 1,
    },
  },
  languageOption: {
    px: 2.5,
    alignItems: 'center',
  },
};

function UserInformation({step}: {step: RegistrationSteps}) {
  const {watch, control} = useFormContext();
  const languageVal = watch('language');

  return (
    <StepWrapper step={step}>
      <>
        <Box sx={styles.header}>
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
          >{`Let's customize your Pinecone setup`}</Typography>
          <Typography>
            This will help us provide relevant reference architectures and resources.
          </Typography>
        </Box>
        <Box>
          <Box sx={styles.inputGrid}>
            <TextInput name="firstName" control={control} label="First Name" fullWidth />
            <TextInput name="lastName" control={control} label="Last Name" fullWidth />
          </Box>
          <Box sx={styles.inputGrid}>
            <SelectInput
              name="purpose"
              control={control}
              label="Purpose of use"
              options={[{value: Purpose.BUSINESS}, {value: Purpose.PERSONAL}]}
              fullWidth
            />
            {watch('purpose') === Purpose.BUSINESS && (
              <TextInput name="company" control={control} label="Company" fullWidth />
            )}
          </Box>
          <SelectBoxInput
            label="Preferred coding language"
            name="language"
            control={control}
            options={LanguageOptions}
            containerSx={styles.languageOption}
            addOption={OtherOption({
              fieldName: 'language',
              fieldValue: languageVal,
              optionsArray: LanguageOptions,
              control,
            })}
          />
        </Box>
      </>
    </StepWrapper>
  );
}

export default UserInformation;
