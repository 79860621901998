import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {PaymentStatus} from '../../../constants';
import {useNavigateToOrgMembers} from '../../../hooks/navigation';
import {
  useIsOwnerOfOrganization,
  useSelectedOrganizationPaymentStatus,
} from '../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../selectors/params';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
    mx: 'auto',
    mt: 4,
    maxWidth: '70%',
  },
};

const SuspendedPageText = {
  [PaymentStatus.ACTIVE]: null,
  [PaymentStatus.PAST_DUE]: null,
  [PaymentStatus.RESTRICTED]: {
    title: 'Alert! Your Account Has Been Temporarily Suspended',
    text: 'It looks like there was an issue with your payment.',
  },
  [PaymentStatus.SUSPENDED]: {
    title: 'Account Suspended: Immediate Action Required',
    text: 'We regret to inform you that your account is currently suspended.',
  },
  [PaymentStatus.DEACTIVATED]: {
    title: 'Payment Issue: Account and Indexes Deactivated',
    text: 'We regret to inform you we have deactivated your account and all running indexes have been archived.',
  },
};

const OWNER_TEXT = 'Please update your payment information to reactivate';
const USER_TEXT = 'Please reach out to your organization owner to reactivate';

interface SuspendedPageProps {
  billingPath: string;
}

function SuspendedPage({billingPath}: SuspendedPageProps) {
  const isOwner = useIsOwnerOfOrganization();
  const paymentStatus = useSelectedOrganizationPaymentStatus();
  const textInfo = SuspendedPageText[paymentStatus];
  const selectedOrgId = useSelectedOrganizationId();
  const {path: orgMembersPath} = useNavigateToOrgMembers(selectedOrgId);
  return (
    <Box sx={styles.container}>
      <Typography textAlign="center" fontWeight="bold" variant="h4">
        {textInfo?.title}
      </Typography>
      {isOwner ? (
        <>
          <Typography textAlign="center" pt={2} pb={2}>
            {textInfo?.text} {isOwner ? OWNER_TEXT : USER_TEXT}
          </Typography>
          <Button
            href={isOwner ? billingPath : orgMembersPath}
            variant="contained"
            endIcon={<ArrowOutwardIcon />}
          >
            {isOwner ? 'Update Payment' : 'View Owners'}
          </Button>
        </>
      ) : (
        <Typography textAlign="center">
          Please contact your organization owner to update the billing details.
        </Typography>
      )}
    </Box>
  );
}

export default SuspendedPage;
