import Box from '@mui/material/Box';
import InkeepCustomTriggerComponent from './InkeepSearchTrigger';
import {SearchBar} from './SearchBar';

const styles = {
  headerBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
};

export function HeaderBar() {
  return (
    <Box sx={styles.headerBarContainer}>
      <InkeepCustomTriggerComponent>
        <SearchBar />
      </InkeepCustomTriggerComponent>
    </Box>
  );
}
