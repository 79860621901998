import Box from '@mui/material/Box';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {useUserData} from '../../../selectors/userSession';
import {getUserFirstNameIfExists} from '../../../utils/user';
import AssistantSection from './Sections/Assistants/AssistantSection';
import DocumentationLinks from './Sections/Documentation/DocumentationLinks';
import GetStartedGuideSection from './Sections/GetStartedGuides/GetStartedGuideSection';
import IndexSection from './Sections/Indexes/IndexSection';
import {useHomePageConfiguration} from './utilities';

function HomePage() {
  const configuration = useHomePageConfiguration();
  const user = useUserData();
  const userFirstName = getUserFirstNameIfExists(user);

  return (
    <Page header={<PageHeader title={`Welcome ${userFirstName}`} />} width="narrow">
      <Box>
        {configuration.showGetStartedGuides && <GetStartedGuideSection />}
        <IndexSection />
        <AssistantSection />
        <DocumentationLinks />
      </Box>
    </Page>
  );
}

export default HomePage;
