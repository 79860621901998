import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import {
  getImportOperation,
  getMigrationOperation,
  listMigrationOperations,
} from '../../../actions/serviceActions';
import {POLLING_INTERVAL} from '../../../constants';
import {useControllerApi} from '../../../hooks/use-api';
import {useBooleanState, useInterval} from '../../../hooks/utils';
import {useMigrationOperations} from '../../../selectors/services';
import {MODAL_WIDTH_SMALL} from '../../../styles/theme';
import {type GlobalProject, type MigrationOperationDetails, OperationStatus} from '../../../types';
import MigrationInProgress from '../MigrationInProgress/MigrationInProgress';
import OperationDisplay from '../OperationDisplay/OperationDisplay';

type MigrationOperationTrackerProps = {
  project: GlobalProject;
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    p: 2,
  },
  container: {
    width: MODAL_WIDTH_SMALL,
    p: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

function OperationDisplayContainer({
  project,
  target,
  ops,
}: {
  project: GlobalProject;
  target: string;
  ops: MigrationOperationDetails;
}) {
  const {setQueryData} = useControllerApi<{
    operationId: string;
    globalProjectId: string;
    targetIndex: string;
  }>(project);
  const {setQueryData: setQueryDataImportOperation} = useControllerApi<{
    operationId: string;
    globalProjectId: string;
    targetIndex: string;
  }>(project);
  const queryForMigrationOp =
    ops.migrationOperation.status === OperationStatus.PENDING &&
    !ops.migrationOperation.import_operation_id;
  useInterval(
    () => {
      setQueryData({
        action: getMigrationOperation,
        data: {
          operationId: ops.migrationOperation.operation_id,
          globalProjectId: project.id,
          targetIndex: target,
        },
      });
    },
    queryForMigrationOp ? POLLING_INTERVAL : null,
  );
  const queryForImportOp =
    !!ops.migrationOperation.import_operation_id &&
    (ops.importOperation?.status === OperationStatus.PENDING || !ops.importOperation);
  useInterval(
    () => {
      if (ops.migrationOperation.import_operation_id) {
        setQueryDataImportOperation({
          action: getImportOperation,
          data: {
            operationId: ops.migrationOperation.import_operation_id,
            globalProjectId: project.id,
            targetIndex: target,
          },
        });
      }
    },
    queryForImportOp ? POLLING_INTERVAL : null,
  );

  return <OperationDisplay project={project} ops={ops} />;
}

function MigrationOperationTracker({project}: MigrationOperationTrackerProps) {
  const operations = useMigrationOperations(project.id);
  const operationTargets = Object.keys(operations || {});
  useControllerApi<{
    globalProjectId: string;
  }>(project, listMigrationOperations, {globalProjectId: project.id});
  const {val: isOpen, setTrue: openProgress, setFalse: closeProgress} = useBooleanState();
  if (operationTargets.length === 0) {
    return null;
  }

  return (
    <Paper sx={styles.root}>
      <Box>
        <Typography variant="overline">Migration In Progress</Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        {operationTargets.map((target) => {
          const ops = operations[target];
          return (
            <OperationDisplayContainer key={target} target={target} project={project} ops={ops} />
          );
        })}
      </Box>
      <Box>
        <Button
          fullWidth
          color="secondary"
          variant="outlined"
          sx={{color: 'text.primary'}}
          onClick={openProgress}
        >
          View progress
        </Button>
      </Box>
      <BaseModal open={isOpen} onClose={closeProgress} showCloseIcon>
        <Box sx={styles.container}>
          <MigrationInProgress close={closeProgress} project={project} />
        </Box>
      </BaseModal>
    </Paper>
  );
}

export default MigrationOperationTracker;
