import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {PROVIDER_INFORMATION_MAP, type Providers} from '../../../constants';
import {type Marketplace} from '../../../types';
import Image from '../../Utilities/Image/Image';
import ThemeAwarePineconeLogo from '../../Utilities/ThemeAwarePineconeLogo';
import {getIdentifierFromMarketplaceRecord} from '../utils';

const styles = {
  accountBoxWrapper: {
    mt: 6,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    gap: 3,
    minWidth: 650,
  },
  accountBox: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  logoBox: {
    height: 60,
  },
  logo: {
    height: 40,
  },
  buttonContainer: {
    mt: 6,
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

interface MarketplaceSetupConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  marketplaceRecord: Marketplace;
  selectedOrganizationName: string;
  selectedOrganizationId?: string;
  marketplaceType: Providers;
}

function MarketplaceSetupConfirmationModal({
  open,
  onClose,
  onConfirm,
  marketplaceRecord,
  selectedOrganizationName,
  selectedOrganizationId,
  marketplaceType,
}: MarketplaceSetupConfirmationModalProps) {
  const marketplaceProviderInformation = PROVIDER_INFORMATION_MAP[marketplaceType];
  const marketplaceIdentifier = getIdentifierFromMarketplaceRecord(marketplaceRecord);

  return (
    <BaseModal open={open} onClose={onClose}>
      <ModalHeader
        title={`Confirm ${marketplaceProviderInformation.name} Marketplace Connection`}
        close={onClose}
      />
      <Box>
        <Box sx={styles.accountBoxWrapper}>
          <Box sx={styles.accountBox}>
            <Box sx={styles.logoBox}>
              <Image
                srcLight={marketplaceProviderInformation.logo}
                srcDark={marketplaceProviderInformation.logoDark}
                sx={styles.logo}
              />
            </Box>
            <Typography
              variant="h6"
              gutterBottom
            >{`${marketplaceProviderInformation.name} account`}</Typography>
            <Typography>
              <strong>ID</strong>: {marketplaceIdentifier}
            </Typography>
            {marketplaceRecord?.email && (
              <Typography>
                <strong>Email</strong>: {marketplaceRecord.email}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              fontSize: 64,
              mt: 6,
            }}
          >
            <SwapHorizRoundedIcon fontSize="inherit" />
          </Box>
          <Box sx={styles.accountBox}>
            <Box sx={styles.logoBox}>
              <ThemeAwarePineconeLogo sx={styles.logo} />
            </Box>
            <Typography variant="h6" gutterBottom>
              Pinecone organization
            </Typography>
            {selectedOrganizationId && <Typography>{`ID: ${selectedOrganizationId}`}</Typography>}
            <Typography>
              <strong>Name</strong>: {selectedOrganizationName}
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.buttonContainer}>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onConfirm();
              onClose();
            }}
            sx={{ml: 2}}
          >
            Connect
          </Button>
        </Box>
      </Box>
    </BaseModal>
  );
}

export default MarketplaceSetupConfirmationModal;
