import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {type Theme} from '@mui/material/styles';
import {type Backup, type V4IndexInfoResponse} from '../../../../types';
import {getSpecs} from '../../../../utils/services';

type BackupDetailsHeaderProps = {
  backups: Backup[];
  sourceIndex?: V4IndexInfoResponse;
};

const styles = {
  divider: {
    borderRadius: '100%',
    border: 'solid 2px',
    borderColor: (theme: Theme) => {
      return theme.palette.secondary.light;
    },
    height: 4,
    width: 4,
  },
};

function InfoItem({title, value}: {title: string; value?: string}) {
  return (
    <Box display="flex" gap={0.5}>
      <Typography fontWeight="bold">{`${title}:`}</Typography>
      <Typography>{value}</Typography>
    </Box>
  );
}

function BackupDetailsHeader({backups, sourceIndex}: BackupDetailsHeaderProps) {
  const {serverlessSpec} = getSpecs(sourceIndex);
  return (
    <Box>
      <Box display="flex" gap={1} alignItems="center">
        <InfoItem title="Region" value={serverlessSpec?.region || backups[0]?.region} />
        <Divider orientation="vertical" sx={styles.divider} />
        <InfoItem
          title="Cloud"
          value={(serverlessSpec?.cloud || backups[0]?.cloud || '').toUpperCase()}
        />
        <Divider orientation="vertical" sx={styles.divider} />
        <InfoItem
          title="Dimension"
          value={String(sourceIndex?.dimension || backups[0]?.dimension || 0)}
        />
        <Divider orientation="vertical" sx={styles.divider} />
        <InfoItem title="Backups" value={String(backups.length)} />
      </Box>
    </Box>
  );
}

export default BackupDetailsHeader;
