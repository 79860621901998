import Box from '@mui/material/Box';
import ConnectIndexButton from '../../../../components/indexes/ConnectIndexButton/ConnectIndexButton';
import IndexActions from '../../../../components/indexes/IndexActions/IndexActions';
import PageHeader from '../../../../components/layout/PageHeader/PageHeader';
import {useIndexStatsByGlobalProject} from '../../../../selectors/explorer';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import {useServiceByGlobalProject} from '../../../../selectors/services';

type IndexDetailHeaderProps = {
  goToIndexList: () => void;
  service: string;
};

const styles = {
  expandMore: {
    transform: 'rotate(90deg)',
    color: 'primary.dark',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
};

function IndexDetailHeader({goToIndexList, service}: IndexDetailHeaderProps) {
  const selectedProject = useSelectedGlobalProject();
  const serviceStats = useIndexStatsByGlobalProject(selectedProject?.id, service);
  const serviceInfo = useServiceByGlobalProject(selectedProject?.id, service);
  const empty = serviceStats?.totalVectorCount === 0;

  return (
    <PageHeader
      title={serviceInfo?.name ?? ''}
      titleHidden
      breadcrumb={{text: 'Back to indexes', action: goToIndexList}}
      actions={
        <Box sx={styles.actions}>
          <IndexActions service={service} serviceInfo={serviceInfo} />
          <ConnectIndexButton doGlow={empty} />
        </Box>
      }
    />
  );
}

export default IndexDetailHeader;
