import {type Theme} from '@mui/material/styles';

export default {
  label: {
    fontSize: '14px',
    mt: 1.5,
  },
  text: {
    mt: 1.5,
  },
  secondaryText: (theme: Theme) => ({
    color: theme.palette.text.secondary,
    mt: 1.5,
  }),
  error: (theme: Theme) => ({
    color: theme.palette.error.main,
    mt: 1.5,
  }),
  submit: {
    mt: 2.5,
  },
  cancel: {
    mt: 1,
  },
};
