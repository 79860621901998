import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import {PUBLIC_DOCS_URL} from '../../constants';
import {useNavigateToBillingPlans} from '../../hooks/navigation';
import {useSelectedOrganizationId} from '../../selectors/params';
import RowWithCheck from '../Utilities/RowWithCheck';
import RowWithIcon from '../Utilities/RowWithIcon';
import {benefits, limits} from './content';

interface StarterBenefitsModalProps {
  open: boolean;
  onClose: () => void;
}

const styles = {
  root: {width: '600px', display: 'grid', gap: 4},
  content: {
    display: 'flex',
    gap: 2,
  },
  section: {
    flex: '1',
  },
  title: {
    fontWeight: 'bold',
    mb: 1,
  },
  list: {
    m: 0,
    p: 0,
  },
  listItem: {
    display: 'list-item',
    m: 0,
    p: 0,
  },
  ctaContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

function StarterBenefitsModal({open, onClose}: StarterBenefitsModalProps) {
  const orgId = useSelectedOrganizationId();
  const goToBillingPlans = useNavigateToBillingPlans(orgId);

  return (
    <BaseModal
      sx={styles.root}
      open={open}
      onClose={onClose}
      id="starter-benefits-modal"
      showCloseIcon
    >
      <Typography variant="h5" component="h1">
        Your Starter plan includes:
      </Typography>
      <Box sx={styles.content}>
        <Box sx={styles.section}>
          <Typography sx={styles.title} variant="body1" component="h2">
            Free features
          </Typography>
          <List sx={styles.list}>
            {benefits.map((benefit, idx) => (
              <ListItem key={idx} sx={styles.listItem}>
                <RowWithCheck text={benefit} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={styles.section}>
          <Typography sx={styles.title} variant="body1" component="h2">
            <Link
              href={`${PUBLIC_DOCS_URL}/reference/quotas-and-limits`}
              target="_blank"
              rel="noreferrer"
            >
              Limits
            </Link>
          </Typography>
          <List sx={styles.list}>
            {limits.map((limit, idx) => (
              <ListItem key={idx} sx={styles.listItem}>
                <RowWithIcon icon={<Close color="error" />}>{limit}</RowWithIcon>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Box sx={styles.ctaContainer}>
        <Typography>
          <strong>Build without limits! Upgrade today.</strong>
        </Typography>

        <Button type="submit" variant="contained" color="primary" onClick={goToBillingPlans}>
          See pricing plans
        </Button>
      </Box>
    </BaseModal>
  );
}

export default StarterBenefitsModal;
