import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import NumberInput from '@pinecone-experience/timber/Inputs/NumberInput';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {NotificationTypes, enqueNotification} from '../../../../../../actions/notificationActions';
import {createService, listServices} from '../../../../../../actions/serviceActions';
import type {Vector} from '../../../../../../api/dataPlaneApi';
import {useSelectedLanguage} from '../../../../../../components/CodeSnippets/utils';
import {moviesDataset} from '../../../../../../components/datasets/data/movies';
import ModelCard from '../../../../../../components/models/ModelCard/ModelCard';
import {DEFAULT_INDEX_SETTINGS} from '../../../../../../constants';
import {useControllerApi} from '../../../../../../hooks/use-api';
import {useSelectedGlobalProject} from '../../../../../../selectors/projects';
import {useServiceSpecsByGlobalProject} from '../../../../../../selectors/services';
import type {GlobalProject} from '../../../../../../types';
import {HEAP_TRACKING_EVENTS, sendToHeap} from '../../../../../../utils/tracking';
import {
  type IndexSchema,
  type ServerlessIndexSchemaType,
} from '../../../../../indexes/IndexCreationPage/utilities/schema';
import NextButton from '../../NextButton/NextButton';
import type {BlockComponentProps} from '../../types';
import {
  INDEX_NAME,
  INTERACTIVE_DEMO_LANGUAGE_OPTIONS,
  UPSERT_MOVIE_VECTORS,
  movieVectorData,
  schema,
} from './constants';

const styles = {
  inputs: {
    display: 'flex',
    gap: 1,
    mb: -1,
    '> div': {
      flex: 1,
    },
  },
};

export const ConfigurePineconeIndex = ({
  handleNext,
  status,
  blockTitle,
  sectionIndex,
  blockIndex,
}: BlockComponentProps) => {
  const project = useSelectedGlobalProject();
  const moviesIndex = useServiceSpecsByGlobalProject(project.id).find((s) => s.name === INDEX_NAME);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: {isValid},
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: INDEX_NAME,
      provider: DEFAULT_INDEX_SETTINGS.provider,
      region: DEFAULT_INDEX_SETTINGS.region,
      dimensions: moviesDataset.datasetInformation.dimension,
      metric: moviesDataset.datasetInformation.metric as ServerlessIndexSchemaType['metric'],
    },
    resolver: zodResolver(schema),
  });

  const {setQueryData} = useControllerApi<
    IndexSchema & {
      from_collection?: string;
      project: GlobalProject;
      region: string;
      isServerless: boolean;
      dataset?: Vector[];
      skipRedirect?: boolean;
    }
  >(project);

  const handleCreate = (formData: ServerlessIndexSchemaType) => {
    if (!moviesIndex) {
      sendToHeap(HEAP_TRACKING_EVENTS.INDEX_CREATED, {
        indexType: 'Serverless',
      });
      sendToHeap(HEAP_TRACKING_EVENTS.DATASET_INDEX_CREATED, {
        dataset: formData.name,
      });
      setQueryData({
        action: createService,
        data: {
          ...formData,
          project,
          dataset: movieVectorData,
          from_collection: undefined,
          isServerless: true,
          engineType: 'approximated',
          pods: 1,
          replicas: 1,
          podType: 'nano',
          podSize: 'x1',
          deletionProtection: false,
          environment: '---',
          skipRedirect: true,
        },
      });
    } else {
      dispatch(
        enqueNotification({
          type: NotificationTypes.INFO,
          text: 'Index already exists. Skipping creation.',
        }),
      );
    }
    handleNext();
  };

  return (
    <Box>
      <Typography>
        Since we have used the <strong>{moviesDataset.embeddingModel.name}</strong> model, we will
        need to configure the Pinecone index with the same dimension and similarity metric. Type in
        the correct dimension and metric below in order to proceed to the the next step.
      </Typography>
      <Box my={2}>
        <Typography>
          <strong>Index name:</strong> {INDEX_NAME}
        </Typography>
        <Typography>
          <strong>Cloud:</strong> {DEFAULT_INDEX_SETTINGS.provider.toUpperCase()}
        </Typography>
        <Typography>
          <strong>Region:</strong> {DEFAULT_INDEX_SETTINGS.region}
        </Typography>
      </Box>
      <ModelCard model={moviesDataset.embeddingModel} />
      <Box sx={styles.inputs}>
        <NumberInput
          label="Dimension"
          name="dimensions"
          tooltip="The embedding dimension that your index will use.
          Find this information in the model card above."
          control={control}
          fullWidth
        />
        <SelectInput
          label="Metric"
          name="metric"
          control={control}
          tooltip="The distance metric that your index will use.
          Find this information in the model card above."
          options={[
            {
              value: 'cosine',
            },
            {
              value: 'dotproduct',
            },
            {
              value: 'euclidean',
            },
          ]}
          fullWidth
        />
      </Box>
      <NextButton
        sectionIndex={sectionIndex}
        blockIndex={blockIndex}
        title="Create index"
        blockTitle={blockTitle}
        status={status}
        onClick={handleSubmit(handleCreate)}
        disabled={!isValid}
        loadingTimeout={0.5}
      />
    </Box>
  );
};

export const UpsertRecords = ({
  handleNext,
  status,
  blockTitle,
  sectionIndex,
  blockIndex,
}: BlockComponentProps) => {
  const selectedLanguage = useSelectedLanguage(INTERACTIVE_DEMO_LANGUAGE_OPTIONS);
  const project = useSelectedGlobalProject();

  const {setQueryData: queryServices} = useControllerApi<{
    globalProjectId: string;
  }>(project);

  const onNext = () => {
    // needed to trigger the Tracker Segment component that will upsert data
    queryServices({
      action: listServices,
      data: {globalProjectId: project.id},
    });
    handleNext();
  };

  return (
    <Box>
      <Typography>
        Now, vector embeddings can be uploaded to the index using the <strong>upsert</strong>{' '}
        operation:
      </Typography>
      <Box>
        <Typography mb={2}>
          Now lets upsert these vectors into <strong>{INDEX_NAME}</strong> with the following code.
        </Typography>
        <CodeBlock
          language={selectedLanguage}
          code={UPSERT_MOVIE_VECTORS[selectedLanguage]}
          copyButton={false}
          sx={{my: 1}}
        />
      </Box>
      <NextButton
        sectionIndex={sectionIndex}
        blockIndex={blockIndex}
        title="Upsert records"
        blockTitle={blockTitle}
        status={status}
        onClick={onNext}
        loadingTimeout={0.5}
      />
    </Box>
  );
};
