import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {type Theme, useTheme} from '@mui/material/styles';
import {useState} from 'react';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {BGERerankerV2M3, MicrosoftE5Large} from '../../../components/models/constants';
import {useBooleanState} from '../../../hooks/utils';
import ModelSuggestionForm from '../../indexes/IndexCreationPage/SetupWithModelButton/ModelSuggestionForm/ModelSuggestionForm';
import CustomMarkdown from '../Markdown/InferenceMarkdown';
import {bgeRerankerV2M3Markdown, multilingualE5LargeMarkdown} from '../ModelGuides/modelGuides';
import SelectableModelCard from './SelectableCard/SelectableCard';

const styles = {
  icon: {
    height: 35,
    width: 35,
  },
  title: {
    fontWeight: 600,
  },
  newBadge: {
    fontStyle: 'italic',
    fontSize: 20,
    ml: 1,
    fontWeight: 400,
    color: 'primary.main',
    alignSelf: 'end',
  },
  description: {
    py: 1,
  },
  modelContainer: {
    mb: 20,
  },
  modelCards: {
    display: 'flex',
    justifyContent: 'space-between',
    my: 3,
  },
  backdrop: (theme: Theme) => ({
    zIndex: theme.zIndex.modal - 1,
  }),
};

function InferencePage() {
  const [selectedCard, setSelectedCard] = useState<string>(MicrosoftE5Large.name);
  const {
    val: isSuggestModelOpen,
    setTrue: openSuggestModel,
    setFalse: closeSuggestModel,
  } = useBooleanState();
  const theme = useTheme();

  const titleBadge = (
    <Typography component="span" variant="h6" sx={styles.newBadge}>
      New
    </Typography>
  );

  return (
    <Page
      header={
        <PageHeader
          title="Inference API"
          titleSx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
          description="Simplify your stack with models hosted by Pinecone"
          actions={
            <Button variant="outlined" onClick={openSuggestModel}>
              Request a model
            </Button>
          }
          leadingIcon={<OfflineBoltOutlinedIcon sx={styles.icon} />}
          badge={titleBadge}
        />
      }
    >
      <Box sx={styles.modelContainer}>
        <Backdrop
          open={isSuggestModelOpen}
          onClick={closeSuggestModel}
          sx={{zIndex: theme.zIndex.modal - 1}}
        >
          <ModelSuggestionForm
            open={isSuggestModelOpen}
            goBack={closeSuggestModel}
            close={closeSuggestModel}
          />
        </Backdrop>
        <Box sx={styles.modelCards}>
          <SelectableModelCard
            model={MicrosoftE5Large}
            isSelected={selectedCard === MicrosoftE5Large.name}
            onSelect={() => setSelectedCard(MicrosoftE5Large.name)}
          />
          <SelectableModelCard
            model={BGERerankerV2M3}
            isSelected={selectedCard === BGERerankerV2M3.name}
            onSelect={() => setSelectedCard(BGERerankerV2M3.name)}
          />
        </Box>
        {selectedCard === MicrosoftE5Large.name && (
          <Box mt={2}>
            <CustomMarkdown>{multilingualE5LargeMarkdown}</CustomMarkdown>
          </Box>
        )}
        {selectedCard === BGERerankerV2M3.name && (
          <Box mt={2}>
            <CustomMarkdown>{bgeRerankerV2M3Markdown}</CustomMarkdown>
          </Box>
        )}
      </Box>
    </Page>
  );
}

export default InferencePage;
