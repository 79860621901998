import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import {useEffect, useMemo} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {getAssistantsUsage} from '../../../actions/assistantActions';
import {useNavigateToBilling} from '../../../hooks/navigation';
import {useAssistantDataApi} from '../../../hooks/use-api';
import {useBooleanState} from '../../../hooks/utils';
import {
  useAssistantChat,
  useAssistantFiles,
  useAssistantQuotas,
} from '../../../selectors/assistants';
import {useSelectedAssistantName} from '../../../selectors/params';
import QuotaUtilizationTracker from '../QuotaUtilizationTracker/QuotaUtilizationTracker';
import {type EnrichedUsageQuota} from '../QuotaUtilizationTracker/types';
import AssistantQuotaInfoModalContent from './AssistantQuotaInfoModalContent/AssistantQuotaInfoModalContent';

interface AssistantQuotaUtilizationTrackerProps {
  orgId?: string;
  projectId: string;
}

const styles = {
  upgradeButton: {
    color: 'text.primary',
  },
};

const AssistantQuotaUtilizationTracker = ({
  orgId,
  projectId,
}: AssistantQuotaUtilizationTrackerProps) => {
  const {setQueryData} = useAssistantDataApi<{globalProjectId: string; silentPoll?: boolean}>(
    projectId,
  );
  const selectedAssistantName = useSelectedAssistantName() || '';
  const chatMessagesLoading = useAssistantChat(projectId, selectedAssistantName)?.loading;
  const files = useAssistantFiles(selectedAssistantName).length;
  const quotasEntry = useAssistantQuotas(projectId);
  const {go: navigateToBilling, path: billingPath} = useNavigateToBilling(orgId);

  const {
    val: showAssistantLimitsModal,
    setTrue: setShowAssistantLimitsModal,
    setFalse: setHideAssistantLimitsModal,
  } = useBooleanState();

  const selectedModelData = useMemo(() => {
    return {assistantName: selectedAssistantName, chatMessagesLoading, files};
  }, [selectedAssistantName, chatMessagesLoading, files]);

  useEffect(() => {
    setQueryData({
      action: getAssistantsUsage,
      data: {globalProjectId: projectId},
    });
  }, [projectId, setQueryData, selectedModelData]);

  if (!quotasEntry) {
    return null;
  }

  const {data: quotas} = quotasEntry;

  if (quotas === undefined || !quotas.length) {
    return null;
  }

  const getBlockedText = (enrichedQuotas: EnrichedUsageQuota[]) => {
    const exceededQuotas = enrichedQuotas.filter((quota) => quota.utilization >= quota.limit);
    if (exceededQuotas.length) {
      return {
        header: 'Assistant access paused',
        body: (
          <>
            Looks like you’ve hit your plan limits! To remove these limits,{' '}
            <Link component={RouterLink} to={billingPath}>
              upgrade your plan
            </Link>
            .
          </>
        ),
      };
    }

    return null;
  };

  return (
    <QuotaUtilizationTracker
      title="Assistant Usage"
      quotas={quotas}
      isLoading={false}
      getBlockedText={getBlockedText}
      titleAction={
        <>
          <IconButton
            id="assistant-limits-modal-button"
            sx={{m: 0, mr: -1}}
            onClick={setShowAssistantLimitsModal}
            color="secondary"
            icon={<InfoOutlinedIcon fontSize="small" />}
          />
          <BaseModal open={showAssistantLimitsModal} onClose={setHideAssistantLimitsModal}>
            <AssistantQuotaInfoModalContent
              orgId={orgId}
              onClose={setHideAssistantLimitsModal}
              quotas={quotas}
            />
          </BaseModal>
        </>
      }
      action={
        <Button
          id="assistant-upgrade-plan-button"
          onClick={navigateToBilling}
          variant="outlined"
          color="secondary"
          size="small"
          sx={styles.upgradeButton}
          fullWidth
        >
          Upgrade now
        </Button>
      }
    />
  );
};

export default AssistantQuotaUtilizationTracker;
