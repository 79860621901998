import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useNavigateToIndexListGlobal} from '../../../../../hooks/navigation';
import {
  useSelectedGlobalProjectId,
  useSelectedOrganizationId,
} from '../../../../../selectors/params';
import {
  useGlobalProjectServices,
  useServicesAreLoadingByGlobalProject,
} from '../../../../../selectors/services';
import {SECTION_ITEMS_MAX_BEFORE_OVERFLOW, styles} from '../styles';
import IndexCard from './IndexCard';

function IndexSection() {
  const organizationId = useSelectedOrganizationId();
  const projectId = useSelectedGlobalProjectId();
  const indexesLoading = useServicesAreLoadingByGlobalProject(projectId);
  const indexes = useGlobalProjectServices(projectId);
  const {go: goToIndexListPage} = useNavigateToIndexListGlobal(organizationId, projectId);

  const hasIndexes = Object.keys(indexes).length > 0;

  const sectionItemsStyle =
    Object.keys(indexes).length >= SECTION_ITEMS_MAX_BEFORE_OVERFLOW
      ? [styles.sectionItems, styles.sectionItemsOverflow]
      : styles.sectionItems;

  return (
    <Box sx={styles.section}>
      <Box sx={styles.sectionHeader}>
        <Typography variant="h6" component="h2">
          Indexes
        </Typography>
        {hasIndexes && <Button onClick={goToIndexListPage}>View All</Button>}
      </Box>
      <LoadingContainer loading={indexesLoading}>
        {hasIndexes ? (
          <Box sx={sectionItemsStyle}>
            {Object.values(indexes).map((index) => (
              <IndexCard key={index.id} index={index} />
            ))}
          </Box>
        ) : (
          <Typography>No indexes found</Typography>
        )}
      </LoadingContainer>
    </Box>
  );
}

export default IndexSection;
