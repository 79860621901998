import CorporateFare from '@mui/icons-material/CorporateFare';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ElectricalServicesOutlinedIcon from '@mui/icons-material/ElectricalServicesOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Phone from '@mui/icons-material/Phone';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {listIntegrations} from '../../../../../actions/integrationActions';
import {OrgSettingsPage, OrgSupportPage} from '../../../../../constants';
import {Flags, useFlag} from '../../../../../hooks/flags';
import {getOrgSettingsPath, getOrgSupportPath} from '../../../../../hooks/navigation';
import {useDashboardApi} from '../../../../../hooks/use-api';
import {
  useShouldShowBilling,
  useShouldShowBillingPlans,
} from '../../../../../pages/org-settings/hooks/billing';
import {useOrgIntegrations} from '../../../../../selectors/integrations';
import {useIsOwnerOfOrganization} from '../../../../../selectors/organizations';
import {type Organization} from '../../../../../types';
import {type NavigationItem} from '../../types/navigation.interface';
import NavSection from '../NavSection/NavSection';

const styles = {
  titleContainer: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    py: 0.75,
    pl: 0.5,
  },
  title: {
    color: 'text.primary',
    fontSize: '16px',
    fontWeight: '600',
    ml: 0.75,
    textTransform: 'none',
  },
};

function OrgSettingsNav({selectedOrg}: {selectedOrg: Organization}) {
  const isOwner = useIsOwnerOfOrganization();
  const shouldShowSupportPage = useFlag(Flags.ENABLE_IN_CONSOLE_SUPPORT);
  const shouldShowSupportPlans = useFlag(Flags.ENABLE_SUPPORT_PLANS);
  const shouldShowSupportTicketManagement = useFlag(Flags.ENABLE_SUPPORT_TICKET_MANAGEMENT);
  const enableIntegrationsPage = useFlag(Flags.ENABLE_INTEGRATIONS_PAGE);

  const orgSettingsPath = getOrgSettingsPath(selectedOrg.id);
  const orgSupportPath = getOrgSupportPath(selectedOrg.id);

  const integrations = useOrgIntegrations(selectedOrg.id);
  const shouldShowIntegrationsPage = enableIntegrationsPage && integrations.length > 0;
  useDashboardApi(listIntegrations, {organizationId: selectedOrg.id});

  const {showBillingPage} = useShouldShowBilling();
  const showBillingPlansPage = useShouldShowBillingPlans();

  if (!selectedOrg.id) {
    return null;
  }
  return (
    <nav>
      <Box sx={styles.titleContainer}>
        <CorporateFare fontSize="small" color="secondary" />
        <Typography sx={styles.title} component="h2">
          {selectedOrg.name}
        </Typography>
      </Box>
      <NavSection
        section={{
          id: 'organization-settings-usage',
          icon: <ShowChartOutlinedIcon color="secondary" />,
          title: 'Usage',
          path: `${orgSettingsPath}/${OrgSettingsPage.USAGE}`,
        }}
      />
      <NavSection
        section={{
          id: 'organization-setting-billing',
          icon: <DescriptionOutlinedIcon color="secondary" />,
          title: 'Billing',
          items: [
            showBillingPage
              ? {
                  title: 'Invoices',
                  path: `${orgSettingsPath}/${OrgSettingsPage.BILLING}`,
                  pathMatch: `${orgSettingsPath}/${OrgSettingsPage.BILLING}`,
                }
              : undefined,
            showBillingPlansPage
              ? {
                  title: 'Plans',
                  path: `${orgSettingsPath}/${OrgSettingsPage.BILLING_PLANS}`,
                }
              : undefined,
          ].filter((item) => item !== undefined),
        }}
      />

      <NavSection
        section={{
          id: 'organization-settings-projects',
          icon: <FolderOutlinedIcon color="secondary" />,
          title: 'Projects',
          path: `${orgSettingsPath}/${OrgSettingsPage.PROJECTS}`,
        }}
      />
      <NavSection
        section={{
          id: 'organization-settings-members',
          icon: <PersonOutlineOutlinedIcon color="secondary" />,
          title: 'Members',
          path: `${orgSettingsPath}/${OrgSettingsPage.MEMBERS}`,
        }}
      />
      {shouldShowSupportPage && (
        <NavSection
          section={{
            id: 'organization-settings-support',
            icon: <Phone color="secondary" />,
            title: 'Support',
            items: [
              {
                title: 'Help center',
                pathMatch: `${orgSupportPath}`,
                path: `${orgSupportPath}`,
              },
              // TODO: @tinedkim remove when support is launched
              shouldShowSupportPlans &&
                isOwner && {
                  title: 'Plan options',
                  path: `${orgSupportPath}/${OrgSupportPage.SUPPORT_PLANS}`,
                },
              shouldShowSupportTicketManagement && {
                title: 'Tickets',
                path: `${orgSupportPath}/${OrgSupportPage.SUPPORT_TICKETS}`,
              },
            ].filter((item): item is NavigationItem => Boolean(item)),
          }}
        />
      )}
      <NavSection
        section={{
          id: 'organization-settings-manage',
          icon: <SettingsOutlinedIcon color="secondary" />,
          title: 'Manage',
          path: `${orgSettingsPath}/${OrgSettingsPage.MANAGE}`,
        }}
      />
      {shouldShowIntegrationsPage && (
        <NavSection
          section={{
            id: 'organization-settings-integrations',
            icon: <ElectricalServicesOutlinedIcon color="secondary" />,
            title: 'Integrations',
            path: `${orgSettingsPath}/${OrgSettingsPage.INTEGRATIONS}`,
          }}
        />
      )}
    </nav>
  );
}

export default OrgSettingsNav;
