import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@pinecone-experience/timber/Alert';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import InlineCode from '@pinecone-experience/timber/InlineCode';
import {useSelectedLanguage} from '../../../../../../components/CodeSnippets/utils';
import {
  CREATE_A_QUERY_VECTOR,
  DEVELOPER_QUICKSTART_EMBEDDINGS_LANGUAGE_OPTIONS,
  DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS,
  RUN_A_SIMILARITY_SEARCH_CODE,
} from './constants';

export const Section4Header = () => {
  return (
    <Box>
      <Typography>
        Search through the data to find items that are semantically similar to a query vector.
      </Typography>
    </Box>
  );
};

export const CreateQueryVector = () => {
  const selectedLanguage = useSelectedLanguage(DEVELOPER_QUICKSTART_EMBEDDINGS_LANGUAGE_OPTIONS);
  return (
    <Box>
      <Typography mb={2}>
        Use Pinecone Inference to convert a question about the tech company &quot;Apple&quot; into a
        query vector:
      </Typography>
      <CodeBlock code={CREATE_A_QUERY_VECTOR[selectedLanguage]} language={selectedLanguage} />
    </Box>
  );
};

export const RunSimilaritySearch = () => {
  const selectedLanguage = useSelectedLanguage(DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS);
  return (
    <Box>
      {DEVELOPER_QUICKSTART_EMBEDDINGS_LANGUAGE_OPTIONS.some((l) => l === selectedLanguage) ? (
        <Box mb={2}>
          <Typography mb={2}>
            Query the <InlineCode text="ns1" /> namespace for the three vectors that are most
            similar to the query vector, i.e., the vectors that represent the most relevant answers
            to your question:
          </Typography>
        </Box>
      ) : (
        <Box mb={2}>
          <Typography mb={2}>
            Query each namespace in your index for the 3 vectors that are most similar to an example
            2-dimensional vector:
          </Typography>
        </Box>
      )}
      <CodeBlock
        code={RUN_A_SIMILARITY_SEARCH_CODE[selectedLanguage]}
        language={selectedLanguage}
      />
      <Alert
        severity="info"
        title="Scale"
        description="As you put more demands on Pinecone, you'll see it returning low-latency, accurate results at huge scales, with indexes of up to billions of vectors."
      />
      <Alert
        severity="info"
        title="Scores"
        description="Your index uses the cosine distance metric, which measures similarity based on the angle between two vectors. Scores range between 1 and -1. The greater the score, the greater the similarity between the vectors."
      />
    </Box>
  );
};
