import Link from '@mui/material/Link';
import {useCallback} from 'react';
import {
  createStorageIntegration,
  deleteStorageIntegration,
  listStorageIntegrations,
  updateStorageIntegration,
} from '../../../actions/storageIntegrationActions';
import {
  type CreateStorageIntegrationRequest,
  type UpdateStorageIntegrationRequest,
} from '../../../api/types/controllerApi';
import EmptyState from '../../../components/layout/EmptyState/EmptyState';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {PUBLIC_DOCS} from '../../../constants';
import {useControllerApi} from '../../../hooks/use-api';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import {
  useStorageIntegrations,
  useStorageIntegrationsAreLoading,
} from '../../../selectors/storageIntegrations';
import CreateStorageButton from './CreateStorageButton/CreateStorageButton';
import StorageList from './StorageList/StorageList';

/** A page for users to manage their project's storage integrations. */
function StoragePage() {
  const selectedProject = useSelectedGlobalProject();
  const globalProjectId = selectedProject.id;
  const loading = useStorageIntegrationsAreLoading(globalProjectId);
  const hasStorageIntegrations = !!useStorageIntegrations(selectedProject.id)?.length;

  useControllerApi(selectedProject, listStorageIntegrations, {globalProjectId});

  const {setQueryData: setCreateData} = useControllerApi<{
    globalProjectId: string;
    data: CreateStorageIntegrationRequest;
  }>(selectedProject);
  const {setQueryData: setUpdateData} = useControllerApi<{
    globalProjectId: string;
    integrationId: string;
    data: UpdateStorageIntegrationRequest;
  }>(selectedProject);
  const {setQueryData: setDeleteData} = useControllerApi<{
    globalProjectId: string;
    integrationId: string;
  }>(selectedProject);

  const onCreate = useCallback(
    (data: CreateStorageIntegrationRequest) => {
      setCreateData({
        action: createStorageIntegration,
        data: {globalProjectId, data},
      });
    },
    [globalProjectId, setCreateData],
  );

  const onEdit = useCallback(
    (integrationId: string, data: UpdateStorageIntegrationRequest) => {
      setUpdateData({
        action: updateStorageIntegration,
        data: {globalProjectId, integrationId, data},
      });
    },
    [globalProjectId, setUpdateData],
  );

  const onDelete = useCallback(
    (integrationId: string) => {
      setDeleteData({
        action: deleteStorageIntegration,
        data: {globalProjectId, integrationId},
      });
    },
    [globalProjectId, setDeleteData],
  );

  return (
    <Page
      header={
        <PageHeader
          title="Storage integrations"
          actions={hasStorageIntegrations && <CreateStorageButton onCreate={onCreate} />}
        />
      }
      loading={loading}
    >
      {hasStorageIntegrations ? (
        <StorageList onEdit={onEdit} onDelete={onDelete} />
      ) : (
        <EmptyState
          heading="No storage integrations found"
          description={
            <>
              Connect your project to a storage provider to get started.
              <br />
              Read more in{` `}
              <Link href={PUBLIC_DOCS.STORAGE_INTEGRATIONS} target="_blank">
                our documentation
              </Link>
              .
            </>
          }
        >
          <CreateStorageButton onCreate={onCreate} />
        </EmptyState>
      )}
    </Page>
  );
}

export default StoragePage;
