import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {type ReactElement} from 'react';
import EmptyStateScreen from './EmptyStateScreen';

const styles = {
  container: {
    height: '95%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
  },
  content: {
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

type EmptyStateCardProps = {
  title: React.ReactNode;
  description?: ReactElement;
  cta?: ReactElement;
  footer?: ReactElement | false;
  overline?: React.ReactNode;
  withPageComponent?: boolean;
  maxWidth?: string;
};
function EmptyStateCard({
  title,
  description,
  cta,
  footer,
  overline,
  withPageComponent,
  maxWidth = '800px',
}: EmptyStateCardProps) {
  return (
    <Box sx={styles.container} maxWidth={maxWidth}>
      <Box sx={styles.content} mt={!withPageComponent ? 10 : undefined}>
        {overline ? (
          <Typography variant="overline" mb={2}>
            {overline}
          </Typography>
        ) : null}
        <Typography variant="h4" component="h1" color="text.primary" textAlign="center">
          {title}
        </Typography>
        {description && (
          <Box my={2} color="text.primary">
            {description}
          </Box>
        )}
        <Box my={2}>{cta}</Box>
      </Box>
      {footer === false ? null : footer || <EmptyStateScreen />}
    </Box>
  );
}
export default EmptyStateCard;
