import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@pinecone-experience/timber/Table';
import type * as React from 'react';
import {GUTTER} from '../../../../../styles/theme';
import {formatMoney} from '../../../../../utils/format';
import {GroupByOptions, type GroupByTypes, type UsageDatapoint} from '../config';

enum UnitLabel {
  HOURS = 'hours',
  UNITS = 'units',
  GB_HOURS = 'gb/hours',
}

const getUnits = (quantity: number, unitLabel: string) => {
  const units = unitLabel === UnitLabel.HOURS ? quantity / 60 : quantity;
  return units.toFixed(2);
};
function formatData(data: UsageDatapoint[], groupBy: GroupByTypes, keys: string[]) {
  if (!data || data.length < 1) {
    return [];
  }
  const formatted = keys
    .map((key) => {
      const filtered = data.filter((point) => point[groupBy] === key);
      return filtered.length > 0 ? filtered : [{[groupBy]: key, subtotalCharge: 0, quantity: 0}];
    })
    .map((groupedUsage: any[]) => ({
      name: groupedUsage[0][groupBy],
      unitLabel: groupedUsage[0].unitLabel,
      service: groupedUsage[0].service,
      totalCost: groupedUsage.reduce((tot, event) => tot + event.subtotalCharge, 0),
      totalQuantity: groupedUsage.reduce((tot, event) => tot + event.quantity, 0),
    }))
    .sort((a, b) => b.totalCost - a.totalCost);
  return formatted;
}

type UsageTableProps = {
  data: UsageDatapoint[];
  groupBy: GroupByTypes;
  keys: string[];
  keyToColor: Record<string, string>;
  loading: boolean;
};

const UsageTable: React.FC<UsageTableProps> = ({data, groupBy, keys, keyToColor, loading}) => {
  const formattedData = formatData(data, groupBy, keys);
  const cellRenderer = (row: number, col: number) => {
    const dataRow = formattedData[row];
    if (col === 0) {
      return (
        <Box display="flex" alignItems="center">
          <Box
            width={20}
            height={20}
            borderRadius="100%"
            bgcolor={keyToColor[dataRow.name]}
            mr={2}
          />
          <Typography>{dataRow.name}</Typography>
        </Box>
      );
    }
    if (col === 1) {
      return '';
    }
    if (col === 2) {
      const label = dataRow.unitLabel;
      const units = getUnits(dataRow.totalQuantity, label);
      return <Typography>{`${units} ${label}`}</Typography>;
    }
    if (col === 3) {
      return (
        <Box>
          <Typography>{formatMoney(dataRow.totalCost)}</Typography>
        </Box>
      );
    }
    return '';
  };
  return (
    <Table
      title="Usage Totals"
      isLoading={loading}
      columns={[
        {name: GroupByOptions[groupBy], dataType: 'string'},
        {name: '', dataType: 'string'},
        {name: 'Usage', dataType: 'number'},
        {name: 'Cost', dataType: 'number'},
      ]}
      numRows={formattedData.length}
      cellRenderer={cellRenderer}
      gutter={GUTTER}
    />
  );
};

export default UsageTable;
