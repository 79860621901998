import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import type * as React from 'react';
import {GroupByOptions, type GroupByTypes, TimespanMap, type TimespanTypes} from '../config';

const styles = {
  container: {
    flexGrow: 2,
    p: 2,
  },
  filterBox: {
    display: 'flex',
    flexWrap: 'wrap',
    mt: 2,
  },
  filter: {
    minWidth: 150,
    px: 2,
    py: 1,
  },
};
type UsageReportControlsProps = {
  timespan: TimespanTypes;
  setTimespan: (timespan: TimespanTypes) => void;
  groupBy: GroupByTypes;
  setGroupBy: (groupBy: GroupByTypes) => void;
  keys: string[];
  selectedKeys: string[];
  setSelectedKeys: (selectedKeys: string[]) => void;
};
const UsageReportControls: React.FC<UsageReportControlsProps> = ({
  timespan,
  setTimespan,
  groupBy,
  setGroupBy,
  keys,
  selectedKeys,
  setSelectedKeys,
}) => {
  return (
    <Paper sx={styles.container}>
      <Typography variant="h6">Filters</Typography>
      <Divider sx={{my: 1}} />
      <Box sx={styles.filterBox}>
        <Box sx={styles.filter}>
          <SelectInput
            fullWidth
            value={timespan}
            onChange={(val) => setTimespan(val as TimespanTypes)}
            label="Timespan"
            options={Object.keys(TimespanMap).map((key) => ({
              value: key as TimespanTypes,
            }))}
          />
        </Box>
        <Box sx={styles.filter}>
          <SelectInput
            fullWidth
            value={groupBy}
            onChange={(val) => setGroupBy(val as GroupByTypes)}
            label="Group By"
            options={(Object.keys(GroupByOptions) as GroupByTypes[]).map((key) => ({
              value: key,
              label: `${GroupByOptions[key]}s`,
            }))}
          />
        </Box>
        <Box sx={styles.filter}>
          <SelectInput
            label={`${GroupByOptions[groupBy]}s`}
            fullWidth
            multiple
            checkBox
            value={selectedKeys}
            onChange={(vals) => setSelectedKeys(vals as string[])}
            renderValue={(selected) => (
              <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                {selected.length === keys.length
                  ? `All ${GroupByOptions[groupBy]}s (${keys.length})`
                  : `(${selected.length}/${keys.length}) ${GroupByOptions[groupBy]}s`}
              </Box>
            )}
            options={keys.map((key) => ({value: key}))}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default UsageReportControls;
