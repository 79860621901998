import Switch from '@mui/material/Switch';
import {type Control, Controller, type Path} from 'react-hook-form';
import InputWrapper from './Utils/InputWrapper';
import {type InputWrapperProps} from './Utils/types';

type SwitchInputSpecificProps = Omit<InputWrapperProps, 'children'>;

interface SwitchInputBaseProps extends SwitchInputSpecificProps {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

interface UncontrolledSwitchProps extends SwitchInputBaseProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

interface ControlledSwitchProps<T extends Record<string, any>> extends SwitchInputBaseProps {
  name: Path<T>;
  control: Control<T>;
}

type SwitchInputProps<T extends Record<string, any>> =
  | ControlledSwitchProps<T>
  | UncontrolledSwitchProps;

function SwitchInput<T extends Record<string, any>>(props: SwitchInputProps<T>) {
  if ('control' in props) {
    return (
      <Controller
        name={props.name}
        control={props.control}
        render={({field}) => (
          <InputWrapper {...props}>
            <Switch {...field} checked={field.value} inputProps={props.inputProps} />
          </InputWrapper>
        )}
      />
    );
  }
  return (
    <InputWrapper {...props}>
      <Switch checked={props.value} onChange={(evt) => props.onChange(!!evt.target.value)} />
    </InputWrapper>
  );
}

export default SwitchInput;
