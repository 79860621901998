import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Providers as MarketplaceProviders, PROVIDER_INFORMATION_MAP} from '../../../../constants';
import Image from '../../../Utilities/Image/Image';

export type MarketplaceSelectionProps = {
  show?: boolean;
  setCreditCard: () => void;
};

const styles = {
  paymentMethodSelection: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
    justifyContent: 'space-between',
  },
  paymentMethodButton: {
    display: 'flex',
    flexDirection: 'column',
    color: 'primary.main',
    py: 1,
    px: 2,
    width: 200,
    height: 150,
  },
  paymentMethodIcon: {
    fontSize: 50,
    color: 'blue',
  },
  paymentMethodLogo: {
    height: 40,
    margin: 3,
  },
};

function MarketplaceSelection({show, setCreditCard}: MarketplaceSelectionProps) {
  if (!show) return null;

  return (
    <Box sx={styles.paymentMethodSelection}>
      <Button
        color="secondary"
        onClick={setCreditCard}
        id="subscribe-select-credit-card"
        sx={styles.paymentMethodButton}
        variant="outlined"
      >
        <Box
          // this is a decorative icon, does not have semantic meaning
          aria-hidden
          sx={styles.paymentMethodLogo}
        >
          <AddIcon sx={styles.paymentMethodIcon} />
        </Box>
        <Typography fontWeight={600}>Payment method</Typography>
      </Button>
      {Object.values(MarketplaceProviders).map((provider) => {
        const providerInfo = PROVIDER_INFORMATION_MAP[provider];
        return (
          // TODO: we may want a button variant to cover this kind of button/link
          <Button
            color="secondary"
            href={providerInfo.link}
            id={`subscribe-${provider}-marketplace-link`}
            key={provider}
            sx={styles.paymentMethodButton}
            target="_blank"
            rel="noreferrer"
            variant="outlined"
          >
            <Image
              aria-hidden
              alt={providerInfo.name}
              srcLight={providerInfo.logo}
              srcDark={providerInfo.logoDark}
              sx={styles.paymentMethodLogo}
            />
            <Typography fontWeight={600}>{providerInfo.fullname}</Typography>
          </Button>
        );
      })}
    </Box>
  );
}

export default MarketplaceSelection;
