import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {getService, listServices} from '../../../../actions/serviceActions';
import {useControllerApi} from '../../../../hooks/use-api';
import {
  useServiceByGlobalProject,
  useServicesAreLoadingByGlobalProject,
  useServicesByGlobalProject,
} from '../../../../selectors/services';
import {type GlobalProject} from '../../../../types';
import {CreateIndex} from './CreateIndex/CreateIndex';

export const IndexesSelector = ({selectedProject}: {selectedProject: GlobalProject}) => {
  const {control, setValue, watch} = useFormContext();
  const indexName = watch('indexName');

  const {setQueryData: queryProjectServices} = useControllerApi<{
    globalProjectId: string;
  }>(selectedProject);
  useEffect(() => {
    if (selectedProject?.id) {
      queryProjectServices({
        action: listServices,
        data: {globalProjectId: selectedProject.id},
      });
      setValue('indexName', '');
    }
  }, [selectedProject, queryProjectServices, setValue]);

  const indexes = useServicesByGlobalProject(selectedProject?.id);
  const servicesAreLoading = useServicesAreLoadingByGlobalProject(selectedProject?.id);

  useEffect(() => {
    if (indexes.length && !indexName) {
      setValue('indexName', indexes[0]);
    }
  }, [indexName, indexes, setValue]);

  const {setQueryData: queryServiceStatus} = useControllerApi<{
    globalProjectId: string;
    name: string;
  }>(selectedProject);
  useEffect(() => {
    if (indexName) {
      queryServiceStatus({
        action: getService,
        data: {globalProjectId: selectedProject?.id, name: indexName},
      });
    }
  }, [indexName, queryServiceStatus, selectedProject?.id]);
  const serviceData = useServiceByGlobalProject(selectedProject?.id, indexName);
  useEffect(() => {
    if (indexName && serviceData?.host) {
      setValue('indexHostName', serviceData.host);
    }
  }, [serviceData, indexName, setValue]);

  if (!selectedProject) return null;

  if (servicesAreLoading) {
    return (
      <Box>
        <Typography color="secondary">
          <CircularProgress size={16} color="secondary" sx={{mr: 1}} />
          Checking for existing indexes
        </Typography>
      </Box>
    );
  }

  if (!indexes.length) return <CreateIndex />;
  return (
    <SelectInput
      label="Index"
      name="indexName"
      control={control}
      fullWidth
      options={indexes.map((index) => ({
        label: index,
        value: index,
      }))}
      optionsAreLoading={selectedProject && servicesAreLoading}
    />
  );
};

export default IndexesSelector;
