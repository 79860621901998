import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete, {
  type AutocompleteProps,
  type AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput, {type OutlinedInputProps} from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {type Control, Controller, type Path} from 'react-hook-form';
import InputWrapper from './Utils/InputWrapper';
import {inputStyles, menuStyles} from './Utils/styles';
import {type InputProps} from './Utils/types';

const styles = {
  selectedValueDropdown: {
    border: 1,
    borderColor: 'divider',
    p: 1,
    pt: 0,
  },
};

export interface Option {
  label?: string;
  description?: string;
  icon?: React.ReactNode;
  value: number | string | undefined | null;
  disabled?: boolean;
}

export type ComboBoxOption = Option | string;

interface ComboBoxSpecificInputProps {
  options: ComboBoxOption[];
  filterSelectedOptions?: boolean;
  searchIcon?: boolean;
  optionsAreLoading?: boolean;
  checkMark?: boolean;
  freeSolo?: boolean;
  groupBy?: AutocompleteProps<ComboBoxOption, boolean, boolean, boolean>['groupBy'];
  selectOnFocus?: boolean;
  listFooter?: React.ReactNode;
  defaultSelectedDisplay?: string;
}

interface UnControlledComboBoxInputProps extends InputProps, ComboBoxSpecificInputProps {
  value: ComboBoxOption | undefined;
  onChange: (value: ComboBoxOption) => void;
}

interface ControlledComboBoxInputProps<T extends Record<string, any>>
  extends InputProps,
    ComboBoxSpecificInputProps {
  name: Path<T>;
  control: Control<T>;
}

type ComboBoxInputProps<T extends Record<string, any>> =
  | UnControlledComboBoxInputProps
  | ControlledComboBoxInputProps<T>;

function getOptionLabel(option: ComboBoxOption) {
  if (typeof option === 'string') return option;
  if (option.label) return option.label;
  if (option.value === null || option.value === undefined) return '';
  return String(option.value);
}

const getValueFromOption = (opt?: ComboBoxOption, defaultVal?: string) => {
  if (!opt) {
    return defaultVal;
  }
  if (typeof opt === 'string') {
    return opt;
  }
  return opt.label || opt.value;
};

function GroupRenderer<T extends Record<string, any>>(props: {
  value?: ComboBoxOption;
  comboBoxProps: ComboBoxInputProps<T>;
  children?: React.ReactNode;
}) {
  const value = getValueFromOption(props.value, props.comboBoxProps.defaultSelectedDisplay);
  return (
    <Box>
      {value && (
        <Box sx={styles.selectedValueDropdown}>
          <CheckIcon color="primary" sx={{position: 'relative', top: 4, pr: 1}} />
          <Typography component="span">{value}</Typography>
        </Box>
      )}
      <Box sx={{pl: 3}}>{props.children}</Box>
      {props.comboBoxProps.listFooter}
    </Box>
  );
}

const OPTION_COUNT = 50;

function filterOptions(
  options: ComboBoxOption[],
  state: {inputValue: string},
  setOpen: (open: boolean) => void,
) {
  const filteredAndSlicedOptions = options
    .filter((option) => getOptionLabel(option).includes(state.inputValue))
    .slice(0, OPTION_COUNT);
  setOpen(filteredAndSlicedOptions.length > 0);
  return filteredAndSlicedOptions;
}
function renderOption({
  props,
  option: propOption,
  state,
  defaultSelectedDisplay,
  checkMark = true,
}: {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: ComboBoxOption;
  state: AutocompleteRenderOptionState;
  defaultSelectedDisplay?: string;
  checkMark?: boolean;
}) {
  const option =
    typeof propOption === 'string'
      ? ({label: propOption, value: propOption} as Option)
      : propOption;
  return (
    <MenuItem
      key={`${option.label}`}
      value={option.value as any}
      disabled={option.disabled}
      {...props}
      sx={[
        {height: option.description ? 'auto' : 35},
        {backgroundColor: props['aria-selected'] === true ? 'transparent !important' : ''},
      ]}
    >
      <Box display="flex" alignItems="center">
        {option.icon && <Box sx={menuStyles.menuIcon}>{option.icon}</Box>}
        <Box>
          <Typography noWrap fontWeight={state.selected ? 'bold' : ''}>
            {option.label || option.value || defaultSelectedDisplay}
          </Typography>
          {option.description && <Typography variant="body2">{option.description}</Typography>}
        </Box>
      </Box>
      <Box sx={menuStyles.menuCheckmark}>{state.selected && checkMark && <CheckIcon />}</Box>
    </MenuItem>
  );
}

function popupIndicator(
  popupProps: {title: string; onClick: () => void},
  optionsAreLoading: boolean,
) {
  if (optionsAreLoading) {
    return <CircularProgress size={20} color="secondary" sx={{my: 0.5}} />;
  }
  return <IconButton {...popupProps} title={undefined} size="small" />;
}

function clearIndicator(clearProps: {title: string; onClick: () => void}) {
  return <IconButton {...clearProps} title={undefined} size="small" />;
}

function ComboBoxInput<T extends Record<string, any>>(props: ComboBoxInputProps<T>) {
  const [open, setOpen] = React.useState(false);
  const autocompleteProps: Omit<
    AutocompleteProps<ComboBoxOption, boolean, boolean, boolean>,
    'renderInput'
  > = {
    id: props.id,
    disabled: props.disabled || props.optionsAreLoading,
    freeSolo: props.freeSolo,
    groupBy: props.groupBy,
    autoSelect: true,
    clearOnEscape: true,
    filterSelectedOptions: props.filterSelectedOptions,
    selectOnFocus: props.selectOnFocus,
    noOptionsText: 'No Options',
    popupIcon: <ExpandMoreIcon />,
    fullWidth: props.fullWidth,
    slotProps: {
      paper: {
        sx: menuStyles.menu,
      },
      popupIndicator: {
        component: ((popupParams: {title: string; onClick: () => {}}) =>
          popupIndicator(popupParams, !!props.optionsAreLoading)) as unknown as 'button',
      },
      clearIndicator: {
        component: clearIndicator as unknown as 'button',
      },
    },
    isOptionEqualToValue: (option, val) => {
      if (typeof option === 'object' && typeof val === 'object') {
        return option?.value === val?.value;
      }
      return option === val;
    },
    options: props.options,
    filterOptions: (options, state) => filterOptions(options, state, setOpen),
    open,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    renderOption: (
      optionProps: React.HTMLAttributes<HTMLLIElement>,
      option: ComboBoxOption,
      state: AutocompleteRenderOptionState,
    ) =>
      renderOption({
        props: optionProps,
        state,
        option,
        defaultSelectedDisplay: props.defaultSelectedDisplay,
        checkMark: props.checkMark,
      }),
    getOptionLabel,
    sx: props.sx,
  };

  const inputProps = {
    'data-testid': props.id || props.name || props.label?.toLowerCase(),
    'aria-label': props.label?.toLowerCase(),
    readOnly: props.readOnly,
  };

  const outlineInputProps: Partial<OutlinedInputProps> = {
    startAdornment: props.searchIcon ? (
      <InputAdornment position="start" sx={{mr: 0}}>
        <SearchIcon fontSize="small" />
      </InputAdornment>
    ) : undefined,
    size: 'small',
    margin: 'dense',
    fullWidth: props.fullWidth,
    color: props.color || 'primary',
    placeholder: props.placeholder,
    sx: {...inputStyles.input, ...props.sx},
  };

  if ('control' in props) {
    return (
      <Controller
        name={props.name}
        control={props.control}
        render={({field: {value, onChange}, fieldState: {error}}) => {
          return (
            <InputWrapper {...props} error={props.error || error?.message}>
              <Autocomplete
                value={value}
                onChange={(_event, newValue, reason) => {
                  if (reason === 'selectOption' || reason === 'clear' || reason === 'blur') {
                    onChange(newValue);
                  }
                }}
                onInputChange={(_event, newInputValue, reason) => {
                  if (props.freeSolo && reason === 'input') {
                    onChange(newInputValue);
                  }
                }}
                renderInput={(params) => (
                  <OutlinedInput
                    ref={params.InputProps.ref}
                    {...outlineInputProps}
                    endAdornment={params.InputProps.endAdornment}
                    inputProps={{
                      ...inputProps,
                      ...params.inputProps,
                    }}
                    error={!!(props?.error || error?.message)}
                  />
                )}
                renderGroup={(params) => (
                  <GroupRenderer value={value} comboBoxProps={props} {...params} />
                )}
                {...autocompleteProps}
              />
            </InputWrapper>
          );
        }}
      />
    );
  }
  return (
    <InputWrapper {...props}>
      <Autocomplete
        value={props.value}
        onChange={(_event, newValue, reason) => {
          if (reason === 'selectOption' || props.freeSolo) {
            props.onChange(newValue as ComboBoxOption);
          }
        }}
        onInputChange={(_event, newInputValue, reason) => {
          if (props.freeSolo && reason === 'input') {
            props.onChange(newInputValue as ComboBoxOption);
          }
        }}
        renderInput={(params) => (
          <OutlinedInput
            ref={params.InputProps.ref}
            {...outlineInputProps}
            endAdornment={params.InputProps.endAdornment}
            inputProps={{
              ...inputProps,
              ...params.inputProps,
            }}
            error={!!props?.error}
          />
        )}
        renderGroup={(params) => (
          <GroupRenderer value={props.value} comboBoxProps={props} {...params} />
        )}
        {...autocompleteProps}
      />
    </InputWrapper>
  );
}

export default ComboBoxInput;
