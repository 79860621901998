import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {SupportTierNameMapping} from '../../../../../../constants';
import {type SupportPlan} from '../types/supportPlans.interface';
import ChangeSupportTierButton from './ChangeSupportTierButton/ChangeSupportTierButton';
import PlanInfo from './PlanInfo/PlanInfo';

const styles = {
  container: {
    borderRadius: 1,
    p: 3,
    minWidth: 175,
    display: 'grid',
    gridRow: '1 / 7',
    gridTemplateRows: 'subgrid',
  },
};

interface SupportPlanCardProps {
  supportPlan: SupportPlan;
  showPlanInfo?: boolean;
}

const SupportPlanCard = ({supportPlan, showPlanInfo}: SupportPlanCardProps) => (
  <Paper sx={styles.container}>
    <Typography variant="h5" color="text.primary">
      {SupportTierNameMapping[supportPlan.tier]}
    </Typography>
    <Typography variant="subtitle1" color="primary.light">
      {supportPlan.subtitle}
    </Typography>
    <Typography variant="body1" color="text.primary">
      {supportPlan.description}
    </Typography>
    <ChangeSupportTierButton desiredTier={supportPlan.tier} variant="contained" fullWidth />
    {showPlanInfo && <PlanInfo plan={supportPlan} allowCollapse={false} />}
    {supportPlan.footer && (
      <Typography sx={{fontStyle: 'italic'}} variant="body1" mb={1} color="text.primary">
        {supportPlan.footer}
      </Typography>
    )}
  </Paper>
);

export default SupportPlanCard;
