import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link, {type LinkProps} from '@mui/material/Link';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useState} from 'react';
import {type Control} from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export interface RichTextEditorProps {
  name: string;
  control: Control<any>;
  label: string;
  value: string;
  placeholder?: string;
  required?: boolean;
  minHeight?: string;
}

const DEFAULT_CONTENT_HEIGHT = '200px';

const LinkComponent = (props: LinkProps) => {
  return <Link {...props} target="_blank" rel="noreferrer" />;
};

const styles = {
  header: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    gap: 2,
  },
  toggle: {
    my: 0,
    py: 0,
  },
  content: {
    position: 'relative',
  },
  input: (minHeight: string) => ({
    minHeight,
    alignItems: 'start',
  }),
  preview: (minHeight: string) => ({
    minHeight,
    mt: 1,
    mb: 2,
    py: 1,
    px: 1.5,
    '> *:first-child': {mt: 0},
    '> *:last-child': {mb: 0},
    h1: {
      fontSize: '1.45rem',
    },
  }),
};

function RichTextEditor({
  name,
  control,
  value,
  label,
  placeholder,
  required = false,
  minHeight = DEFAULT_CONTENT_HEIGHT,
}: RichTextEditorProps) {
  const [view, setView] = useState<'write' | 'preview'>('write');

  return (
    <Box>
      <Box sx={styles.header}>
        <Typography color="text.primary" fontWeight="500">
          {label}
        </Typography>
        <ToggleButtonGroup exclusive value={view} onChange={(_, viewParam) => setView(viewParam)}>
          <ToggleButton size="small" sx={styles.toggle} disabled={view === 'write'} value="write">
            Write
          </ToggleButton>
          <ToggleButton
            size="small"
            sx={styles.toggle}
            disabled={view === 'preview'}
            value="preview"
          >
            Preview
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box sx={styles.content}>
        {view === 'write' && (
          <TextInput
            sx={styles.input(minHeight)}
            name={name}
            control={control}
            placeholder={placeholder}
            fullWidth
            multiline
            required={required}
          />
        )}
        {view === 'preview' && (
          <Card sx={styles.preview(minHeight)}>
            {value ? (
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  a: LinkComponent,
                }}
              >
                {value}
              </ReactMarkdown>
            ) : (
              <Typography color="text.secondary" fontStyle="italic">
                No content
              </Typography>
            )}
          </Card>
        )}
      </Box>
    </Box>
  );
}

export default RichTextEditor;
