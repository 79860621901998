import {Flags, useFlag} from '../../../hooks/flags';

interface HomePageConfiguration {
  enableHomePage: boolean;
  showGetStartedGuides: boolean;
}

export enum HomePageConfigurationFlags {
  ENABLE_HOME_PAGE = 'enableHomePage',
  SHOW_GET_STARTED_GUIDE = 'showGetStartedGuides',
}

export const useHomePageConfiguration = (): HomePageConfiguration => {
  const flag = useFlag(Flags.PROJECT_HOME_PAGE);
  return {
    enableHomePage: flag?.[HomePageConfigurationFlags.ENABLE_HOME_PAGE] ?? false,
    showGetStartedGuides: flag?.[HomePageConfigurationFlags.SHOW_GET_STARTED_GUIDE] ?? false,
  };
};

export const useHomePageConfigurationKey = (key: HomePageConfigurationFlags): boolean => {
  const configuration = useHomePageConfiguration();
  return configuration[key];
};
