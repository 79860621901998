import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {createBackup} from '../../../actions/backupActions';
import {useControllerApi} from '../../../hooks/use-api';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import {useServiceSpecsByGlobalProject} from '../../../selectors/services';
import {type V4IndexInfoResponse} from '../../../types';
import {canBackup} from '../../../utils/services';
import ModalForm from '../../ModalForm';

type CreateBackupFormProps = {
  open: boolean;
  close: () => void;
  sourceIndex?: V4IndexInfoResponse;
};

const MAX_BACKUP_NAME_LENGTH = 45;

const schema = z.object({
  name: z
    .string()
    .nonempty({message: 'Name must not be empty'})
    .max(MAX_BACKUP_NAME_LENGTH, {
      message: `Backup name must not be longer than ${MAX_BACKUP_NAME_LENGTH} characters`,
    }),
  indexId: z.string().nonempty(),
});

type SchemaType = z.infer<typeof schema>;

function CreateBackupForm({open, close, sourceIndex}: CreateBackupFormProps) {
  const selectedProject = useSelectedGlobalProject();
  const services = useServiceSpecsByGlobalProject(selectedProject.id);
  const {
    handleSubmit,
    control,
    formState: {isValid},
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      indexId: sourceIndex?.id || services[0]?.id,
    },
    resolver: zodResolver(schema),
  });
  const {setQueryData} = useControllerApi<{
    globalProjectId: string;
    indexId: string;
    name: string;
  }>(selectedProject);
  const onSubmit = (data: SchemaType) => {
    setQueryData({
      action: createBackup,
      data: {
        globalProjectId: selectedProject.id,
        indexId: data.indexId,
        name: data.name,
      },
    });
    close();
  };
  return (
    <ModalForm
      title="Create Backup"
      customId="create-backup-form"
      open={open}
      close={close}
      form={
        <Box>
          {sourceIndex ? (
            <Typography variant="overline" position="relative" top={-16}>
              {sourceIndex.name}
            </Typography>
          ) : (
            <SelectInput
              name="indexId"
              control={control}
              fullWidth
              label="Select index"
              noOptionsText="No serverless indexes."
              options={services
                .filter(canBackup)
                .map((service) => ({value: service.id, label: service.name}))}
            />
          )}
          <TextInput
            name="name"
            control={control}
            required
            autoFocus
            label="Backup name"
            fullWidth
          />
        </Box>
      }
      isValid={isValid}
      onSubmit={handleSubmit(onSubmit)}
      submitText="Create a backup"
    />
  );
}

export default CreateBackupForm;
