import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select, {type SelectChangeEvent, type SelectProps} from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {useMemo} from 'react';
import {type Control, Controller, type Path} from 'react-hook-form';
import InputWrapper from './Utils/InputWrapper';
import {inputStyles, menuStyles} from './Utils/styles';
import {type ElementType, type InputProps} from './Utils/types';

interface SelectSpecificInputProps<U> {
  options: (Option<U> | OptionGroup<U>)[];
  autoWidth?: boolean;
  multiple?: boolean;
  native?: boolean;
  optionsAreLoading?: boolean;
  checkMark?: boolean;
  checkBox?: boolean;
  renderValue?: (value: U) => React.ReactNode;
  noOptionsText?: string;
}

interface UnControlledSelectInputProps<U> extends InputProps, SelectSpecificInputProps<U> {
  value: U;
  onChange: (value: U) => void;
}

interface ControlledSelectInputProps<U, T extends Record<string, any>>
  extends InputProps,
    SelectSpecificInputProps<U> {
  name: Path<T>;
  control: Control<T>;
  validate?: (data: T) => boolean;
  hideError?: boolean;
}

type SelectInputProps<U, T extends Record<string, any>> =
  | UnControlledSelectInputProps<U>
  | ControlledSelectInputProps<U, T>;

interface Option<U> {
  label?: string;
  description?: string;
  icon?: React.ReactNode;
  value: ElementType<U>;
  disabled?: boolean;
}

interface OptionGroup<U> {
  title?: string;
  options: Option<U>[];
}

export type Options<U> = (Option<U> | OptionGroup<U>)[];

function renderOption<U>({
  selected,
  option,
  checkMark,
  checkBox,
  multiple,
  native,
  index,
}: {
  selected: boolean;
  option: Option<U>;
  checkMark?: boolean;
  checkBox?: boolean;
  multiple?: boolean;
  native?: boolean;
  index: number;
}) {
  if (native) {
    return (
      <option
        key={`${option.label}-${index}`}
        value={option.value as any}
        disabled={option.disabled}
      >
        {option.label || option.value}
      </option>
    );
  }
  return (
    <MenuItem
      key={`${option.label}-${index}`}
      value={option.value as any}
      disabled={option.disabled}
      sx={{height: option.description ? 'auto' : 35}}
    >
      {checkBox && multiple && (
        <Checkbox size="small" disableRipple checked={selected} sx={menuStyles.menuCheckBox} />
      )}
      <Box display="flex" alignItems="center">
        {option.icon && <Box sx={menuStyles.menuIcon}>{option.icon}</Box>}
        <Box>
          <Typography noWrap fontWeight={selected ? 'bold' : ''}>
            {option.label || option.value}
          </Typography>
          {option.description && <Typography variant="body2">{option.description}</Typography>}
        </Box>
      </Box>
      <Box sx={menuStyles.menuCheckmark}>{selected && checkMark && !checkBox && <CheckIcon />}</Box>
    </MenuItem>
  );
}

function valueCheck<U>(value: U, option: ElementType<U>) {
  if (Array.isArray(value)) {
    return value.includes(option);
  }
  return value === option;
}

function renderOptions<U>({
  value,
  options,
  checkMark,
  checkBox,
  multiple,
  native,
  noOptionsText = 'No Options',
}: {
  value: U;
  options: (Option<U> | OptionGroup<U>)[];
  checkMark?: boolean;
  checkBox?: boolean;
  multiple?: boolean;
  native?: boolean;
  noOptionsText?: string;
}) {
  if (!options.length) {
    return <MenuItem disabled>{noOptionsText}</MenuItem>;
  }
  return options.map((option, i) => {
    if ('options' in option) {
      if (native) {
        return (
          <optgroup key={option.title} label={option.title}>
            {option.options.map((opt, j) =>
              renderOption({
                option: opt,
                selected: valueCheck(value, opt.value),
                checkMark,
                checkBox,
                multiple,
                native,
                index: j,
              }),
            )}
          </optgroup>
        );
      }
      return (
        <Box key={option.title}>
          <Typography ml={2} variant="subtitle2">
            {option.title}
          </Typography>
          {option.options.map((opt, j) =>
            renderOption({
              option: opt,
              selected: valueCheck(value, opt.value),
              checkMark,
              checkBox,
              multiple,
              native,
              index: j,
            }),
          )}
          {!option.options.length && <MenuItem disabled>{noOptionsText}</MenuItem>}
          {i !== options.length - 1 && <Divider sx={{mx: 1}} />}
        </Box>
      );
    }
    return renderOption({
      option,
      selected: valueCheck(value, option.value),
      checkMark,
      checkBox,
      multiple,
      native,
      index: i,
    });
  });
}

function findLabelByValue<U>(value: U | U[], options: Options<U>): (string | U) | (string | U)[] {
  const flatOptions = options.flatMap((option) => ('options' in option ? option.options : option));

  if (Array.isArray(value)) {
    return value.map((val) => {
      const foundOption = flatOptions.find((option) => option.value === val);
      return foundOption?.label || val;
    });
  }
  const foundOption = flatOptions.find((option) => option.value === value);
  return foundOption?.label || value;
}

function renderValue<U>({
  value,
  options,
  disabled,
  placeholder,
}: {
  value: (string | U) | (string | U)[];
  options: (Option<U> | OptionGroup<U>)[];
  disabled?: boolean;
  placeholder?: string;
}) {
  if (!options.length && !disabled) {
    return function returnNoOptions() {
      return <Typography color="action.disabled">No Options</Typography>;
    };
  }
  if (value === '' || value === undefined) {
    return function returnPlaceholder() {
      return <Typography color="action.disabled">{placeholder}</Typography>;
    };
  }
  const label = findLabelByValue(value, options);
  if (Array.isArray(label)) {
    return function returnChips() {
      return <Typography noWrap>{label.join(',')}</Typography>;
    };
  }
  return function returnStringLabel() {
    return <Typography noWrap>{label}</Typography>;
  };
}

function SelectInput<U, T extends Record<string, any>>(props: SelectInputProps<U, T>) {
  const selectProps: Partial<Omit<SelectProps<U>, 'value' | 'onChange' | 'endAdornment'>> = {
    id: props.id,
    name: props.name,
    size: 'small',
    margin: 'dense',
    color: props.color || 'primary',
    required: props.required,
    disabled: props.disabled || props.optionsAreLoading,
    autoFocus: props.autoFocus,
    displayEmpty: true,
    fullWidth: props.fullWidth,
    placeholder: props.placeholder,
    'aria-placeholder': props.placeholder,
    autoWidth: props.autoWidth,
    multiple: props.multiple,
    native: props.native,
    MenuProps: {
      PaperProps: {
        sx: menuStyles.menu,
      },
    },
    inputProps: {
      'data-testid': props.id || props.name || props.label?.toLowerCase(),
      'aria-label': props.label?.toLowerCase() || props.id,
      readOnly: props.readOnly,
    },
    IconComponent: props.optionsAreLoading ? () => null : ExpandMoreIcon,
    endAdornment: props.optionsAreLoading && (
      <InputAdornment position="end">
        <CircularProgress size={20} color={props.color || 'primary'} />
      </InputAdornment>
    ),
    sx: {...inputStyles.input, ...props.sx},
    ...props.outlineInputProps,
  };

  const renderValueProps = {
    options: props.options,
    placeholder: selectProps.placeholder,
    disabled: selectProps.disabled,
  };

  const renderOptionsProps = {
    options: props.options,
    checkMark: props.checkMark || true,
    checkBox: props.checkBox,
    multiple: props.multiple,
    native: props.native,
    noOptionsText: props.noOptionsText,
  };

  const allValues = useMemo(() => {
    return props.options
      .flatMap((option) => ('options' in option ? option.options : option))
      .map((opt) => opt.value);
  }, [props.options]);

  if ('control' in props) {
    return (
      <Controller
        name={props.name}
        control={props.control}
        rules={{validate: props.validate}}
        render={({field: {value, ref, onBlur, onChange}, fieldState: {error}}) => {
          // Mui doesn't blur the select element until it losses focus,
          // but we want to consider any change a blur
          const onChangeInner = (a: any, b: any) => {
            onBlur();
            onChange(a, b);
          };
          const fieldValue =
            allValues.includes(value as ElementType<U>) || props.multiple ? value : ('' as U);
          return (
            <InputWrapper
              {...props}
              error={props.error || error?.message}
              hideError={props.hideError}
            >
              <Select
                ref={ref}
                value={fieldValue}
                onChange={onChangeInner}
                onBlur={onBlur}
                error={!!(props.error || error?.message)}
                renderValue={
                  props.renderValue || renderValue({value: fieldValue, ...renderValueProps})
                }
                {...selectProps}
              >
                {renderOptions({value: fieldValue, ...renderOptionsProps})}
              </Select>
            </InputWrapper>
          );
        }}
      />
    );
  }
  return (
    <InputWrapper {...props}>
      <Select
        value={props.value}
        onChange={(event: SelectChangeEvent<U>) => props.onChange(event.target.value as U)}
        error={!!props.error}
        renderValue={props.renderValue || renderValue({value: props.value, ...renderValueProps})}
        {...selectProps}
      >
        {renderOptions({value: props.value, ...renderOptionsProps})}
      </Select>
    </InputWrapper>
  );
}

export default SelectInput;
