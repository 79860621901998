import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {type Theme, alpha} from '@mui/material/styles';
import {type QueryData} from '../../../../../api/dataPlaneApi';
import {LocalStorageKeys} from '../../../../../constants';
import {useIsHovering} from '../../../../../hooks/utils';
import {type VectorState} from '../../../../../reducers/explorerReducer';
import {localStorageSet} from '../../../../../utils/storage';
import {type IndexExplorerState} from '../utilities/utils';
import VectorActions from './VectorActions/VectorActions';
import VectorData from './VectorData/VectorData';

const styles = {
  container: {
    my: 2,
    p: 2,
    pb: 1,
    display: 'flex',
    position: 'relative',
    boxShadow: 4,
  },
  rankContainer: {
    display: 'flex',
    flexDirection: 'column',
    p: 1,
    width: 120,
    flexShrink: 0,
  },
  rankHighlight: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.primary.light, 0.04),
    borderRadius: 1,
    height: 40,
    width: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    mb: 3,
    ml: 0.5,
  },
};

type VectorCardProps = {
  indexExplorerState: IndexExplorerState;
  vector: VectorState & {score?: number};
  rank: number;
  namespace: string;
  setFormQuery: (data: QueryData, namespace: string) => void;
  onSubmit: () => void;
  first?: boolean;
};

function VectorCard({
  indexExplorerState,
  vector,
  rank,
  namespace,
  setFormQuery,
  onSubmit,
  first = false,
}: VectorCardProps) {
  const {isHovering, props: hoverProps} = useIsHovering();
  const doQuery = () => {
    setFormQuery({id: vector.id}, namespace);
    onSubmit();
    localStorageSet(LocalStorageKeys.SERVERLESS_RELEASE_HAS_QUERIED, 'true');
  };
  return (
    <Paper sx={styles.container} data-testid="vector-card" {...hoverProps}>
      <Box sx={styles.rankContainer}>
        <Box sx={styles.rankHighlight} data-testid="rank">
          <Typography fontWeight="bold">{rank}</Typography>
        </Box>
        {
          /* score can be 0, so explicit check to undefined is intentional */ vector.score !==
            undefined && (
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="overline" color="text.secondary">
                Score
              </Typography>
              <Typography>{vector.score.toFixed(4)}</Typography>
            </Box>
          )
        }
      </Box>
      <Box sx={{width: 'calc(100% - 140px)'}}>
        <VectorData vector={vector} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          minWidth: 120,
          opacity: first || isHovering ? 1 : 0,
          transition: 'opacity .25s',
        }}
      >
        <VectorActions
          indexExplorerState={indexExplorerState}
          namespace={namespace}
          vectorId={vector.id}
          doQuery={doQuery}
          first={first}
        />
      </Box>
    </Paper>
  );
}

export default VectorCard;
