import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {styles} from '../styles';
import GetStartedGuideCard from './GetStartedGuideCard';
import {GuideCardInfo} from './constants';

function GetStartedGuideSection() {
  return (
    <Box sx={styles.section}>
      <Box sx={styles.sectionHeader}>
        <Typography variant="h6">Get Started Guides</Typography>
      </Box>
      <Box sx={styles.sectionItems}>
        <GetStartedGuideCard guide={GuideCardInfo[0]} />
        <GetStartedGuideCard guide={GuideCardInfo[1]} />
      </Box>
    </Box>
  );
}

export default GetStartedGuideSection;
