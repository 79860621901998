import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@pinecone-experience/timber/Alert';
import {useDispatch} from 'react-redux';
import {dismissBlockingError} from '../../../../../../actions/globalErrorActions';

type AuthErrorProps = {
  errorType: string;
  errorMessage: string;
  isAuthenticated?: boolean;
};
function GenericAuthError({
  errorType = 'authentication error',
  errorMessage = 'An error has been raised during authentication.',
  isAuthenticated = false,
}: AuthErrorProps) {
  const dispatch = useDispatch();

  const dismiss = () => {
    dispatch(dismissBlockingError());
  };

  return (
    <Box p={3}>
      <Alert
        severity="error"
        title={`Error: ${errorType}`}
        description={<Typography>{errorMessage}</Typography>}
      />
      {isAuthenticated ? (
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={dismiss}>Continue to Console</Button>
        </Box>
      ) : null}
    </Box>
  );
}

export default GenericAuthError;
