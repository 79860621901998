import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@pinecone-experience/timber/Menus/Menu';
import {useCallback, useRef} from 'react';
import {
  OrgSettingsPage,
  OrgSupportPage,
  PINECONE_COMMUNITY_FORUM_URL,
  PUBLIC_DOCS_URL,
  SupportTiers,
} from '../../../../../constants';
import {
  useNavigateToOrgSettingsPage,
  useNavigateToOrgSupportPages,
} from '../../../../../hooks/navigation';
import {useBooleanState} from '../../../../../hooks/utils';
import {useCurrentSupportTier} from '../../../../../selectors/billing';
import {useSelectedOrganization} from '../../../../../selectors/organizations';
import NavButton from '../NavButton/NavButton';

const styles = {
  menu: {
    m: 1,
    width: 250,
  },
  menuItem: {
    justifyContent: 'space-between',
    borderRadius: 1,
    px: 1,
    py: 0.5,
    gap: 1.5,
  },
  menuBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  divider: {
    my: 1,
  },
};

function GetHelpMenu() {
  const selectedOrg = useSelectedOrganization();
  const supportTier = useCurrentSupportTier();
  const goToOrgSupportPages = useNavigateToOrgSupportPages(selectedOrg?.id);
  const goToOrgSettingsPages = useNavigateToOrgSettingsPage(selectedOrg?.id);
  const isFreeSupportTier = supportTier === SupportTiers.FREE;

  const {
    val: showGetHelpSelector,
    setTrue: setShowGetHelpSelector,
    setFalse: setHideGetHelpSelector,
  } = useBooleanState();

  const handleHelpCenterNavigation = useCallback(() => {
    setHideGetHelpSelector();
    goToOrgSettingsPages(OrgSettingsPage.SUPPORT);
  }, [setHideGetHelpSelector, goToOrgSettingsPages]);

  const handleSupportPlanNavigation = useCallback(() => {
    setHideGetHelpSelector();
    goToOrgSupportPages(OrgSupportPage.SUPPORT_PLANS);
  }, [setHideGetHelpSelector, goToOrgSupportPages]);

  // TODO: @tinedkim update when tickets page is implemented
  // const handleTicketNavigation = useCallback(() => {
  //   setHideGetHelpSelector();
  //   goToOrgSupportPages(OrgSupportPage.SUPPORT_TICKETS);
  // }, [setHideGetHelpSelector, goToOrgSupportPages]);

  const handleSubmitTicket = useCallback(() => {
    setHideGetHelpSelector();
    goToOrgSupportPages(OrgSupportPage.SUPPORT_TICKETS);
  }, [setHideGetHelpSelector, goToOrgSupportPages]);

  const menuAnchor = useRef(null);
  return (
    <>
      <NavButton
        ref={menuAnchor}
        onClick={setShowGetHelpSelector}
        endIcon={
          showGetHelpSelector ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />
        }
      >
        Get help
      </NavButton>
      <Menu
        id="get-help-menu"
        labelledBy="get-help-menu-button"
        anchor={menuAnchor.current}
        open={showGetHelpSelector}
        onClose={setHideGetHelpSelector}
        sx={styles.menu}
        placement="bottom-end"
      >
        <MenuItem sx={styles.menuItem} onClick={handleHelpCenterNavigation}>
          Help center
        </MenuItem>
        <MenuItem sx={styles.menuItem} onClick={handleSupportPlanNavigation}>
          <Box sx={styles.menuBox}>
            Support plan
            {isFreeSupportTier && (
              <Chip
                label="Upgrade"
                variant="outlined"
                size="small"
                color="info"
                sx={{cursor: 'pointer'}}
              />
            )}
          </Box>
        </MenuItem>
        {/* TODO: @tinedkim uncomment when ticket management page is released
        {!isFreeSupportTier && (
          <MenuItem sx={styles.menuItem} onClick={handleTicketNavigation}>
            Tickets
          </MenuItem>
        )} */}
        <Link href={PUBLIC_DOCS_URL} target="_blank" color="inherit" underline="none">
          <MenuItem sx={styles.menuItem}>
            <Box sx={styles.menuBox}>Pinecone docs</Box>
          </MenuItem>
        </Link>
        <Link href={PINECONE_COMMUNITY_FORUM_URL} target="_blank" color="inherit" underline="none">
          <MenuItem sx={styles.menuItem}>
            <Box sx={styles.menuBox}>Community forum</Box>
          </MenuItem>
        </Link>
        {!isFreeSupportTier && (
          <>
            <Divider sx={styles.divider} />
            <MenuItem sx={styles.menuItem} onClick={handleSubmitTicket}>
              Submit a ticket
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
}

export default GetHelpMenu;
