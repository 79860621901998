import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useEffect, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {type z} from 'zod';
import {editRolesForPrincipal} from '../../../../actions/rbacActions';
import ModalForm from '../../../../components/ModalForm';
import {Plans} from '../../../../constants';
import {useControllerApi} from '../../../../hooks/use-api';
import {useSelectedOrganizationPlan} from '../../../../selectors/organizations';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import {useRolesByPrincipalIdForProject} from '../../../../selectors/rbac';
import {type ApiKey, PrincipalTypes, type RbacRoles} from '../../../../types';
import ApiKeyPermissionControl from '../ApiKeyPermissionControl/ApiKeyPermissionControl';
import {apiKeyPermissionsSchema} from '../types';
import {getFormValuesFromRoles, getRoles} from '../utils';

type EditApiKeyFormProps = {
  open: boolean;
  close: () => void;
  apiKey: ApiKey;
};

type SchemaType = z.infer<typeof apiKeyPermissionsSchema>;

function EditApiKeyForm({open, close, apiKey}: EditApiKeyFormProps) {
  const project = useSelectedGlobalProject();
  const roleBindings = useRolesByPrincipalIdForProject(apiKey.id, project.id);
  const roles = useMemo(() => {
    return roleBindings.map((role) => role.role);
  }, [roleBindings]);
  const formState = useForm({
    defaultValues: getFormValuesFromRoles(roles),
    resolver: zodResolver(apiKeyPermissionsSchema),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: {isValid, isDirty},
  } = formState;
  useEffect(() => {
    reset(getFormValuesFromRoles(roles));
  }, [roles, reset]);
  const doClose = () => {
    reset(getFormValuesFromRoles(roles));
    close();
  };
  const {setQueryData} = useControllerApi<{
    principalId: string;
    principalType: PrincipalTypes;
    roles: RbacRoles[];
    globalProjectId: string;
  }>(project);
  const onEdit = (data: SchemaType) => {
    setQueryData({
      action: editRolesForPrincipal,
      data: {
        globalProjectId: project.id,
        principalType: PrincipalTypes.API_KEY,
        principalId: apiKey.id,
        roles: getRoles(data),
      },
    });
    close();
  };
  const plan = useSelectedOrganizationPlan();
  return (
    <ModalForm
      customId="edit-api-key-form"
      title="Manage API key"
      subtitle={apiKey.display_label}
      isValid={isValid && isDirty}
      onSubmit={handleSubmit(onEdit)}
      submitText="Update"
      open={open}
      close={doClose}
      secondaryButton={
        <Button onClick={close} sx={{ml: 1, color: 'secondary.main', mr: 3}}>
          Cancel
        </Button>
      }
      form={
        <FormProvider {...formState}>
          <Typography pb={2}>
            Specify the name and permissions for your API key.
            <br />
            <br />
            <Typography component="span" sx={{fontStyle: 'italic'}}>
              Note: Permissions apply only to indexes and backups and do not affect assistants or
              inference services.
            </Typography>
          </Typography>
          <Box>
            <ApiKeyPermissionControl control={control} locked={plan === Plans.FREE} />
          </Box>
        </FormProvider>
      }
    />
  );
}

export default EditApiKeyForm;
