import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';

interface PaymentModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  subtitle?: string | React.ReactNode;
  withHeader?: boolean;
}

const styles = {
  modalContainer: {
    maxWidth: 900,
    minWidth: 420,
  },
};

function PaymentModal({
  children,
  onClose,
  open,
  title,
  subtitle,
  withHeader = true,
}: React.PropsWithChildren<PaymentModalProps>) {
  return (
    <BaseModal
      open={open}
      onClose={onClose}
      id="billing-form"
      sx={styles.modalContainer}
      showCloseIcon={withHeader}
    >
      <Box mb={4}>
        {withHeader && (
          <>
            <Typography variant="h5" mb={1}>
              {title}
            </Typography>
            {subtitle}
          </>
        )}
      </Box>
      {children}
    </BaseModal>
  );
}

export default PaymentModal;
