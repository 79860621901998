import ForumIcon from '@mui/icons-material/ForumOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SchoolIcon from '@mui/icons-material/SchoolOutlined';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const styles = {
  root: {
    mt: 4,
    mb: 4,
  },
  description: {
    color: 'secondary.main',
    fontSize: 14,
    lineHeight: 1.5,
  },
  card: {
    p: 3,
    '&:hover': {
      boxShadow: 8,
    },
    '&:focus-visible': {
      outline: '2px solid',
      outlineColor: 'primary.main',
    },
    display: 'block',
    textDecoration: 'none',
    color: 'inherit',
  },
  icon: {
    fontSize: 40,
    color: 'primary.main',
    mb: 1,
  },
};

const resourceData = [
  {
    title: 'Docs',
    description:
      'Jumpstart building with interactive developer docs, example notebooks, and API reference guides.',
    icon: <MenuBookIcon sx={styles.icon} fontWeight="light" />,
    href: 'https://docs.pinecone.io',
  },
  {
    title: 'Forum',
    description:
      'Connect with thousands of developers using Pinecone to build fast, scalable applications in production.',
    icon: <ForumIcon sx={styles.icon} />,
    href: 'https://community.pinecone.io',
  },
  {
    title: 'Learning center',
    description:
      'Uplevel your understanding and skillsets with an extensive library of educational resources and guides.',
    icon: <SchoolIcon sx={styles.icon} />,
    href: 'https://www.pinecone.io/learn',
  },
];

function ResourceLinks() {
  return (
    <Box sx={styles.root}>
      <Typography variant="h6" sx={{mb: 3}}>
        Visit our Docs, Forums and Learning Center for further assistance
      </Typography>
      <Grid container spacing={3}>
        {resourceData.map((resource, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Card
              sx={styles.card}
              component="a"
              href={resource.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <CardContent style={{padding: 0}}>
                {resource.icon}
                <Typography variant="h6" sx={{mb: 1}}>
                  {resource.title}
                </Typography>
                <Typography sx={styles.description} variant="body2" color="text.secondary">
                  {resource.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ResourceLinks;
