import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@pinecone-experience/timber/Alert';
import NumberInput from '@pinecone-experience/timber/Inputs/NumberInput';
import {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import {z} from 'zod';
import {patchService} from '../../../../actions/serviceActions';
import {DeletionProtection} from '../../../../constants';
import {useNavigateToBilling} from '../../../../hooks/navigation';
import {useIsFreeIndex} from '../../../../hooks/specUtils';
import {useControllerApi} from '../../../../hooks/use-api';
import {useIsSelectedOrganizationSubscribed} from '../../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../../selectors/params';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import {useServiceByGlobalProject} from '../../../../selectors/services';
import {getSpecs} from '../../../../utils/services';
import {TRACKING_EVENTS, sendToGa} from '../../../../utils/tracking';
import ModalForm from '../../../ModalForm';
import DeletionProtectionControl from '../../DeletionProtectionControl/DeletionProtectionControl';
import PodSizeSelector from './PodSizeSelector/PodSizeSelector';

const schema = z.object({
  replicas: z.number().int().positive().min(1),
  podSize: z.string().nonempty(),
});

type ConfigureIndexFormProps = {
  service: string;
  open: boolean;
  close: () => void;
};

const ConfigureIndexForm = ({service, open, close}: ConfigureIndexFormProps) => {
  const selectedProject = useSelectedGlobalProject();
  const serviceData = useServiceByGlobalProject(selectedProject?.id, service);
  const isFreeIndex = useIsFreeIndex(serviceData);
  const isSubscribed = useIsSelectedOrganizationSubscribed();
  const {podSpec} = getSpecs(serviceData);
  const podType = podSpec?.pod_type || '';
  const currentSize = podType.split('.')[1] || 'x1';
  const currentType = podType.split('.')[0] || 'p1';
  const currentReplicas = podSpec?.replicas || 1;
  const {
    handleSubmit,
    formState: {isValid},
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      replicas: currentReplicas,
      podSize: currentSize,
      deletionProtection: serviceData?.deletion_protection === DeletionProtection.ENABLED,
    },
    resolver: zodResolver(schema),
  });
  useEffect(() => {
    if (serviceData?.deletion_protection) {
      setValue(
        'deletionProtection',
        serviceData?.deletion_protection === DeletionProtection.ENABLED,
      );
    }
  }, [serviceData, setValue]);
  const data = watch();
  const showAlert =
    data.replicas != null && (currentSize !== data.podSize || currentReplicas !== data.replicas);
  const doReset = useCallback(() => {
    reset({
      replicas: currentReplicas,
      podSize: currentSize,
    });
  }, [currentReplicas, reset, currentSize]);
  useEffect(() => {
    if (currentReplicas || currentSize) {
      doReset();
    }
  }, [currentReplicas, doReset, currentSize]);
  const {setQueryData} = useControllerApi<{
    name: string;
    replicas: string;
    podType: string;
    deletionProtection: boolean;
    isServerless: boolean;
    globalProjectId: string;
  }>(selectedProject);
  const onSubmit = () => {
    setQueryData({
      action: patchService,
      data: {
        name: service,
        replicas: String(data.replicas),
        podType: `${currentType}.${data.podSize}`,
        deletionProtection: data.deletionProtection,
        isServerless: !podSpec,
        globalProjectId: selectedProject.id,
      },
    });
    sendToGa(TRACKING_EVENTS.PATCH_INDEX);
    close();
    doReset();
  };

  const selectedOrg = useSelectedOrganizationId();
  const {go: goToBilling} = useNavigateToBilling(selectedOrg);

  return (
    <ModalForm
      open={open}
      close={close}
      title="Configure Index"
      subtitle={<Typography variant="overline">{service}</Typography>}
      isValid={isValid}
      onSubmit={handleSubmit(onSubmit)}
      submitText="Confirm"
      form={
        <Box>
          {!!podSpec && (
            <>
              <NumberInput
                name="replicas"
                control={control}
                label="Replicas"
                disabled={isFreeIndex || !isSubscribed}
                fullWidth
                placeholder="Replicas"
                tooltip="Replicas improve availability and throughput of the index, and multiply the number of pods. The storage capacity remains the same."
              />
              <PodSizeSelector
                control={control}
                currentSize={currentSize}
                isFreeIndex={isFreeIndex}
              />
              {isFreeIndex && (
                <Alert
                  description="Scaling is not supported in Free environments."
                  action={
                    <Button color="inherit" size="small" onClick={goToBilling}>
                      Upgrade
                    </Button>
                  }
                />
              )}
              {showAlert && (
                <Alert
                  id="configure-index-alert"
                  title="Pod Usage"
                  description={
                    <ReactMarkdown>
                      {`The index **${service}** uses **${podSpec?.replicas} ${currentType}.${currentSize}** pods. With the new configuration, it will use **${data.replicas} ${currentType}.${data.podSize}** pods.`}
                    </ReactMarkdown>
                  }
                />
              )}
            </>
          )}
          <DeletionProtectionControl control={control} />
        </Box>
      }
    />
  );
};

export default ConfigureIndexForm;
