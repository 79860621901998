import Button, {type ButtonProps} from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {alpha} from '@mui/material/styles';
import {type Theme} from '@mui/material/styles/';
import {SupportTiers} from '../../../../../../../constants';
import {useBooleanState} from '../../../../../../../hooks/utils';
import {
  useCurrentSubscription,
  useCurrentSupportTier,
  useIsSupportIncluded,
  useScheduledSupport,
} from '../../../../../../../selectors/billing';
import {
  ChangeSupportAction,
  evaluateChangeSupportAction,
  getSupportButton,
} from '../../../utils/supportPlans';
import ConfirmChangePlan from '../ConfirmChangePlan/ConfirmChangePlan';

interface ChangeSupportTierButtonProps {
  desiredTier: SupportTiers;
  variant?: ButtonProps['variant'];
  fullWidth?: ButtonProps['fullWidth'];
}

const buttonStyles = {
  current: {
    '&.Mui-disabled': {
      color: 'primary.light',
      borderColor: 'primary.light',
    },
  },
  downgrade: (theme: Theme) => ({
    color: 'text.primary',
    borderColor: alpha(theme.palette.secondary.light, 0.5),
  }),
};

const ChangeSupportTierButton = ({
  desiredTier,
  variant = 'contained',
  fullWidth = false,
}: ChangeSupportTierButtonProps) => {
  const currentSubscription = useCurrentSubscription();
  const isSubscriptionLoading = currentSubscription?.loading;
  const currentTier = useCurrentSupportTier();
  const scheduledSupport = useScheduledSupport();
  const isSupportIncluded = useIsSupportIncluded() || false;

  const action = currentTier
    ? evaluateChangeSupportAction(currentTier, desiredTier, scheduledSupport)
    : ChangeSupportAction.NO_ACTION;
  const {val: showConfirm, setTrue: setShowConfirm, setFalse: setHideConfirm} = useBooleanState();

  const isCurrent = action === ChangeSupportAction.NO_ACTION;
  const isDowngrade = action === ChangeSupportAction.DOWNGRADE;

  const [buttonId, buttonName] = getSupportButton(desiredTier, action, isSupportIncluded);

  const disableButton = () => {
    if (desiredTier === SupportTiers.ENTERPRISE && !isCurrent) {
      return false;
    }
    return (!scheduledSupport && isCurrent) || isSupportIncluded || isSubscriptionLoading;
  };

  const handleClick = () => {
    if (desiredTier === SupportTiers.ENTERPRISE) {
      window.open(
        'https://www.pinecone.io/contact/?contact_form_inquiry_type=Enterprise+Pricing',
        '_blank',
      );
      return;
    }
    setShowConfirm();
  };

  return (
    <>
      <Button
        id={buttonId}
        disabled={disableButton()}
        variant={
          action === ChangeSupportAction.CANCEL_DOWNGRADE ||
          action === ChangeSupportAction.NO_ACTION ||
          (action === ChangeSupportAction.DOWNGRADE && !isSupportIncluded)
            ? 'outlined'
            : variant
        }
        fullWidth={fullWidth}
        onClick={handleClick}
        sx={[isCurrent && buttonStyles.current, isDowngrade && buttonStyles.downgrade]}
        data-cy={`upgrade-to-${desiredTier}-button`}
      >
        {currentTier === undefined ? <CircularProgress size={24} /> : buttonName}
      </Button>
      <ConfirmChangePlan
        open={showConfirm}
        onClose={setHideConfirm}
        desiredSupportTier={desiredTier}
      />
    </>
  );
};

export default ChangeSupportTierButton;
