import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StartBulkImportModal from '../../../../../components/StartBulkImportModal/StartBulkImportModal';
import {useBooleanState} from '../../../../../hooks/utils';
import {ExplorerPages} from '../ExplorerPage/ExplorerPage';

type EmptyExplorerProps = {
  indexName: string;
  setExplorerTab: (tab: ExplorerPages) => void;
  showBulkImportAction: boolean;
};

function EmptyExplorer({indexName, setExplorerTab, showBulkImportAction}: EmptyExplorerProps) {
  const {
    val: showImportForm,
    setTrue: setOpenImportForm,
    setFalse: setCloseImportForm,
  } = useBooleanState();

  return (
    <Box width="100%" textAlign="center" pt={6}>
      <Typography variant="h5" color="text.primary" component="h3">
        No records yet
      </Typography>
      <Typography py={2} color="text.primary">
        A record is an object you add to an index containing a vector and, optionally, its metadata
      </Typography>
      <Box display="flex" gap={2} justifyContent="center">
        <Button
          id="explorer-blank-state-add-record-open-form"
          variant="contained"
          onClick={() => setExplorerTab(ExplorerPages.ADD)}
          startIcon={<Add />}
        >
          Add a Record
        </Button>
        {showBulkImportAction && (
          <Button
            id="explorer-blank-state-bulk-import-open-form"
            variant="outlined"
            onClick={setOpenImportForm}
            color="secondary"
          >
            Import data
          </Button>
        )}
        <StartBulkImportModal
          selectedIndexName={indexName}
          open={showImportForm}
          close={setCloseImportForm}
        />
      </Box>
    </Box>
  );
}

export default EmptyExplorer;
