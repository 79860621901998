import Typography, {type TypographyProps} from '@mui/material/Typography';
import {type Theme, alpha} from '@mui/material/styles';

export type InlineCodeProps = {
  text: string;
  variant?: TypographyProps['variant'];
  noWrap?: boolean;
};

const styles = {
  code: {
    p: 0.35,
    borderRadius: 1,
    backgroundColor: (theme: Theme) => alpha(theme.palette.primary.dark, 0.08),
    color: 'primary.dark',
  },
};

function InlineCode({text, variant = 'body2', noWrap}: InlineCodeProps) {
  return (
    <Typography variant={variant} noWrap={noWrap} component="span" sx={styles.code}>
      <code>{text}</code>
    </Typography>
  );
}

export default InlineCode;
