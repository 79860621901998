import Box from '@mui/material/Box';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useMemo} from 'react';
import {
  type Control,
  type UseFormGetFieldState,
  type UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import ClearIcon from '../../../../../../../../components/StyledClearIcon';
import ValueInput from '../../../../ValueInput/ValueInput';
import {type SchemaType} from '../../../../utilities/schema';

type FilterInputRowProps = {
  control: Control<SchemaType>;
  prefix: `filters.${number}.nodes.${number}`;
  remove: () => void;
  getFieldState: UseFormGetFieldState<SchemaType>;
  setValue: UseFormSetValue<SchemaType>;
  isFirst: boolean;
  isLast: boolean;
};

const styles = {
  filterInput: {
    mx: 0.5,
    maxWidth: 120,
  },
  container: {
    display: 'inline-flex',
    position: 'relative',
  },
  borderUp: {
    position: 'absolute',
    borderLeft: 'dotted 2px lightgrey',
    height: `calc(50% - 4px)`,
    borderBottom: 'dotted 2px lightgrey',
    width: '8px',
  },
  borderDown: {
    position: 'absolute',
    borderLeft: 'dotted 2px lightgrey',
    borderTop: 'dotted 2px lightgrey',
    width: '8px',
    top: `calc(50% - 6px)`,
    height: `calc(50% + 4px)`,
  },
};

const opOptions = [
  {
    label: '==',
    value: '$eq',
    allowedTypes: ['string', 'number', 'boolean', 'auto'],
  },
  {
    label: '!=',
    value: '$ne',
    allowedTypes: ['string', 'number', 'boolean', 'auto'],
  },
  {
    label: '>',
    value: '$gt',
    allowedTypes: ['number'],
  },
  {
    label: '>=',
    value: '$gte',
    allowedTypes: ['number'],
  },
  {
    label: '<',
    value: '$lt',
    allowedTypes: ['number'],
  },
  {
    label: '<=',
    value: '$lte',
    allowedTypes: ['number'],
  },
  {
    label: 'In',
    value: '$in',
    allowedTypes: ['array'],
  },
  {
    label: 'Not In',
    value: '$nin',
    allowedTypes: ['array'],
  },
];

const getAllowedTypes = (currentOp: string) => {
  const opVals = opOptions.find((op) => op.value === currentOp);
  return opVals?.allowedTypes || [];
};

function FilterInputRow({
  control,
  prefix,
  remove,
  getFieldState,
  setValue,
  isFirst,
  isLast,
}: FilterInputRowProps) {
  const currentOp = useWatch({control, name: `${prefix}.op`});
  const allowedTypes = useMemo(() => {
    return getAllowedTypes(currentOp);
  }, [currentOp]);
  return (
    <Box sx={styles.container}>
      {!isFirst && <Box sx={styles.borderUp} />}
      {!isLast && <Box sx={styles.borderDown} />}
      <IconButton
        id={`${prefix}-remove`}
        onClick={remove}
        icon={<ClearIcon />}
        sx={{position: 'relative', top: 6}}
        dataHeap="metadata-filter-remove-row"
      />
      <Box sx={styles.filterInput}>
        <TextInput control={control} name={`${prefix}.key`} placeholder="key" />
      </Box>
      <Box sx={styles.filterInput}>
        <SelectInput control={control} name={`${prefix}.op`} options={opOptions} />
      </Box>
      <Box sx={[styles.filterInput, {minWidth: 240}]}>
        <ValueInput
          control={control}
          name={`${prefix}.value`}
          typeName={`${prefix}.valueType`}
          getFieldState={getFieldState}
          setValue={setValue}
          allowedTypes={allowedTypes}
        />
      </Box>
    </Box>
  );
}
export default FilterInputRow;
