import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {createAssistant} from '../../../../actions/assistantActions';
import ModalForm from '../../../../components/ModalForm';
import {PINECONE_URLS, Plans} from '../../../../constants';
import {useControllerApi} from '../../../../hooks/use-api';
import {useSelectedOrganization} from '../../../../selectors/organizations';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import {type AssistantFormData} from '../../../../types';
import {type AssistantSchema, assistantSchema} from './schema';

interface AssistantCreateModalProps {
  open: boolean;
  loading: boolean;
  close: () => void;
}

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
};

export default function AssistantCreateModal({open, loading, close}: AssistantCreateModalProps) {
  const project = useSelectedGlobalProject();
  const organization = useSelectedOrganization();
  const showCostWarning = organization.plan !== Plans.FREE;

  const {setQueryData} = useControllerApi<{
    globalProjectId: string;
    organizationId: string;
    formData: AssistantFormData;
  }>(project);

  const {
    formState: {isValid},
    control,
    handleSubmit,
    reset,
  } = useForm<AssistantSchema>({
    mode: 'onChange',
    defaultValues: {
      name: undefined,
      metadata: {},
    },
    resolver: zodResolver(assistantSchema),
  });

  const onCreateAssistant = (data: AssistantSchema) => {
    setQueryData({
      action: createAssistant,
      data: {
        globalProjectId: project.id,
        organizationId: organization.id,
        formData: {
          name: data.name,
          metadata: data.metadata,
        },
      },
    });
    reset();
  };

  const handleClose = useCallback(() => {
    reset();
    close();
  }, [close, reset]);

  return (
    <ModalForm
      open={open}
      close={handleClose}
      form={
        <Box sx={styles.form}>
          <TextInput
            id="assistant-name-input"
            name="name"
            control={control}
            required
            autoFocus
            placeholder="Assistant name"
            label="Name your assistant"
            fullWidth
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={handleSubmit(onCreateAssistant)}
            disabled={!isValid || loading}
          >
            Create assistant
          </Button>
          {showCostWarning ? (
            <Typography variant="caption" color="secondary">
              Active assistants have a fee of $0.20/day. See{' '}
              <Link color="inherit" href={PINECONE_URLS.PRICING} target="_blank">
                Pricing
              </Link>{' '}
              for details.
            </Typography>
          ) : null}
        </Box>
      }
      title="Set up your new assistant"
      customId="create-assistant-modal"
      isValid={isValid && !loading}
      onSubmit={handleSubmit(onCreateAssistant)}
      submitText="Create assistant"
      includeSubmit={false}
    />
  );
}
