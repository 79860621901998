import CssBaseline from '@mui/material/CssBaseline';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {Route, Routes} from 'react-router-dom';
import {OrgSettingsPage, OrgSupportPage, ProjectPage} from '../../../constants';
import {Flags, useFlag} from '../../../hooks/flags';
import AssistantFilePage from '../../../pages/assistants/AssistantFilePage/AssistantFilePage';
import AssistantListPage from '../../../pages/assistants/AssistantListPage/AssistantListPage';
import AssistantPage from '../../../pages/assistants/AssistantPage/AssistantPage';
import AssistantsGuard from '../../../pages/assistants/AssistantsGuard/AssistantsGuard';
import BackupsDetailPage from '../../../pages/backups/BackupsDetailPage/BackupsDetailPage';
import BackupsPage from '../../../pages/backups/BackupsPage/BackupsPage';
import IndexCreationPage from '../../../pages/indexes/IndexCreationPage/IndexCreationPage';
import IndexDetailPage from '../../../pages/indexes/IndexDetailPage/IndexDetailPage';
import IndexListPage from '../../../pages/indexes/IndexListPage/IndexListPage';
import CreateIndexFromDatasetPage from '../../../pages/indexes/SampleDatasetsPage/CreateIndexFromDatasetPage/CreateIndexFromDatasetPage';
import DatasetGalleryPage from '../../../pages/indexes/SampleDatasetsPage/DatasetGalleryPage/DatasetGalleryPage';
import InferencePage from '../../../pages/inference/InferencePage/InferencePage';
import AwsBedrockLandingPage from '../../../pages/integrations/aws-bedrock/AwsBedrockLandingPage/AwsBedrockLandingPage';
import ConnectFinishPage from '../../../pages/integrations/connect/ConnectFinishPage/ConnectFinishPage';
import GrantPage from '../../../pages/integrations/connect/GrantPage/GrantPage';
import OAuthVercel2 from '../../../pages/integrations/vercel/OAuthVercel2/OAuthVercel2';
import BillingPage from '../../../pages/org-settings/BillingPage/BillingPage';
import SupportPlansPage from '../../../pages/org-settings/GetHelpPage/SupportPlanPage/SupportPlanPage';
import IntegrationsPage from '../../../pages/org-settings/IntegrationsPage/IntegrationsPage';
import ManageOrgPage from '../../../pages/org-settings/ManageOrgPage/ManageOrgPage';
import OrgMembersPage from '../../../pages/org-settings/OrgMembersPage/OrgMembersPage';
import PlansPage from '../../../pages/org-settings/PlansPage/PlansPage';
import SupportPage from '../../../pages/org-settings/SupportPage/SupportHomePage/SupportHomePage';
import SupportTicketPage from '../../../pages/org-settings/SupportPage/SupportTicketPage/SupportTicketPage';
import UsagePage from '../../../pages/org-settings/UsagePage/UsagePage';
import ApiKeysPage from '../../../pages/projects/ApiKeysPage/ApiKeysPage';
import CmekPage from '../../../pages/projects/CmekPage/CmekPage';
import GetStartedPage from '../../../pages/projects/GetStartedPage/GetStartedPage';
import DeveloperQuickstartPage from '../../../pages/projects/GetStartedPage/Guides/DeveloperQuickstartPage/DeveloperQuickstartPage';
import InteractiveDemoPage from '../../../pages/projects/GetStartedPage/Guides/InteractiveDemoPage/InteractiveDemoPage';
import HomePage from '../../../pages/projects/HomePage/HomePage';
import {
  HomePageConfigurationFlags,
  useHomePageConfigurationKey,
} from '../../../pages/projects/HomePage/utilities';
import ProjectNetworkPage from '../../../pages/projects/ProjectNetworkPage/ProjectNetworkPage';
import ProjectsTablePage from '../../../pages/projects/ProjectsTablePage/ProjectsTablePage';
import StoragePage from '../../../pages/projects/StoragePage/StoragePage';
import RegistrationPage from '../../../pages/registration/RegistrationPage';
import useTheme from '../../../styles/theme';
import CreateOrganizationPage from '../../CreateOrganizationPage';
import MarketplaceSetup from '../../MarketplaceSetup/MarketplaceSetup';
import NavigateToDefaultOrg from '../../Navigation/NavigateToDefaultOrg';
import NavigateWithParams from '../../Navigation/NavigateWithParams';
import ProjectUsersTable from '../../ProjectUsersTable';
import AuthenticationGuard from './AuthenticationGuard/AuthenticationGuard';
import IntegrationGuard from './IntegrationGuard/IntegrationGuard';
import OrganizationDataGuard from './OrganizationDataGuard/OrganizationDataGuard';
import OrganizationGuard from './OrganizationGuard/OrganizationGuard';
import ProjectGuard from './ProjectGuard/ProjectGuard';

// Changes in routes should also be reflected in RoutesPath in src/constants.tsx
function MainApp() {
  const theme = useTheme();

  const enableInConsoleSupport = useFlag(Flags.ENABLE_IN_CONSOLE_SUPPORT);
  const enableIntegrationsPage = useFlag(Flags.ENABLE_INTEGRATIONS_PAGE);
  const enableCreateFromDatasets = useFlag(Flags.SHOW_CREATE_FROM_DATASET);
  const enableInferencePage = useFlag(Flags.SHOULD_SHOW_INFERENCE_PAGE);
  const enableGetStarted = useFlag(Flags.ENABLE_GET_STARTED_GUIDE);
  const enableStorageIntegrationsPage = useFlag(Flags.ENABLE_STORAGE_INTEGRATIONS_PAGE);
  const enableSupportPlan = useFlag(Flags.ENABLE_SUPPORT_PLANS);
  const enableHomePage = useHomePageConfigurationKey(HomePageConfigurationFlags.ENABLE_HOME_PAGE);
  const enableNewRegistrationFlow = useFlag(Flags.ENABLE_NEW_REGISTRATION_FLOW);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="aws-bedrock-integration" element={<AwsBedrockLandingPage />} />
          <Route element={<AuthenticationGuard />}>
            <Route
              path="marketplace-setup/:marketplaceType/:marketplaceId"
              element={<MarketplaceSetup />}
            />
            <Route path="connect/:integrationId" element={<IntegrationGuard />}>
              <Route path="" element={<GrantPage />} />
              <Route path="finish" element={<ConnectFinishPage />} />
            </Route>
            <Route path="oauth/vercel" element={<OAuthVercel2 />} />
            {enableNewRegistrationFlow && (
              <Route path="registration" element={<RegistrationPage />} />
            )}
            <Route path="organizations/" element={<OrganizationDataGuard />}>
              <Route path="" element={<NavigateToDefaultOrg replace />} />
              <Route path=":orgId/*" element={<OrganizationGuard />}>
                <Route path="" element={<NavigateWithParams replace to="projects" />} />
                <Route path="projects">
                  <Route path="" element={<ProjectsTablePage showDescription={false} />} />
                  <Route path=":projectId" element={<ProjectGuard />}>
                    {enableHomePage && <Route path="home" element={<HomePage />} />}
                    <Route path="" element={<NavigateWithParams replace to="indexes" />} />
                    <Route path="create-index/*" element={<IndexCreationPage />} />
                    <Route
                      path={`${ProjectPage.DATASET_GALLERY}/:datasetId/*`}
                      element={<CreateIndexFromDatasetPage />}
                    />
                    {enableCreateFromDatasets && (
                      <Route path={ProjectPage.DATASET_GALLERY} element={<DatasetGalleryPage />} />
                    )}
                    <Route path="indexes" element={<IndexListPage />} />
                    {enableInferencePage && (
                      <Route path={ProjectPage.INFERENCE} element={<InferencePage />} />
                    )}
                    <Route path="indexes/:indexName/*" element={<IndexDetailPage />} />
                    <Route path="keys" element={<ApiKeysPage />} />
                    <Route path="backups/*" element={<BackupsPage />} />
                    <Route path="backups/index/:indexId" element={<BackupsDetailPage />} />
                    <Route path="members" element={<ProjectUsersTable />} />
                    <Route path={`${ProjectPage.NETWORK}/*`} element={<ProjectNetworkPage />} />
                    <Route path={`${ProjectPage.ASSISTANTS}/*`} element={<AssistantsGuard />}>
                      <Route path="" element={<AssistantListPage />} />
                      <Route path=":assistantName/*" element={<AssistantPage />} />
                      <Route
                        path=":assistantName/files/:fileId/*"
                        element={<AssistantFilePage />}
                      />
                    </Route>
                    {enableGetStarted && (
                      <>
                        <Route path={`${ProjectPage.GET_STARTED}/*`} element={<GetStartedPage />} />
                        <Route
                          path={`${ProjectPage.GET_STARTED}/developer-quickstart/*`}
                          element={<DeveloperQuickstartPage />}
                        />
                        <Route
                          path={`${ProjectPage.GET_STARTED}/vector-search-101/*`}
                          element={<InteractiveDemoPage />}
                        />
                      </>
                    )}
                    {enableStorageIntegrationsPage && (
                      <Route path={ProjectPage.STORAGE} element={<StoragePage />} />
                    )}
                    <Route path={ProjectPage.CMEK} element={<CmekPage />} />
                    <Route path="*" element={<NavigateWithParams replace to="indexes" />} />
                  </Route>
                </Route>
                <Route path="settings">
                  <Route
                    path={OrgSettingsPage.PROJECTS}
                    element={<ProjectsTablePage showDescription />}
                  />
                  <Route path={OrgSettingsPage.BILLING} element={<BillingPage />} />
                  <Route path={OrgSettingsPage.USAGE} element={<UsagePage />} />
                  <Route path={OrgSettingsPage.MEMBERS} element={<OrgMembersPage />} />
                  <Route path={OrgSettingsPage.MANAGE} element={<ManageOrgPage />} />
                  {enableInConsoleSupport && (
                    <>
                      <Route path={OrgSettingsPage.SUPPORT} element={<SupportPage />} />
                      <Route
                        path={`${OrgSettingsPage.SUPPORT}/${OrgSupportPage.SUPPORT_TICKETS}`}
                        element={<SupportTicketPage />}
                      />
                    </>
                  )}
                  {enableIntegrationsPage && (
                    <Route path={OrgSettingsPage.INTEGRATIONS} element={<IntegrationsPage />} />
                  )}
                  <Route path="billing/plans" element={<PlansPage />} />
                  <Route
                    path=""
                    element={<NavigateWithParams replace to={OrgSettingsPage.PROJECTS} />}
                  />
                  <Route
                    path="*"
                    element={<NavigateWithParams replace to={OrgSettingsPage.PROJECTS} />}
                  />
                  {enableSupportPlan && (
                    <Route
                      path={`${OrgSettingsPage.SUPPORT}/${OrgSupportPage.SUPPORT_PLANS}`}
                      element={<SupportPlansPage />}
                    />
                  )}
                </Route>
              </Route>
            </Route>
            <Route path="/organizations/create-organization" element={<CreateOrganizationPage />} />
            <Route path="*" element={<NavigateWithParams to="/organizations" />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default MainApp;
