import {zodResolver} from '@hookform/resolvers/zod';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Popper, {type PopperProps} from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import {alpha} from '@mui/material/styles';
import SelectBoxInput from '@pinecone-experience/timber/Inputs/SelectBoxInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import InputWrapper from '@pinecone-experience/timber/Inputs/Utils/InputWrapper';
import {useFieldArray, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {NotificationTypes, enqueNotification} from '../../../../../actions/notificationActions';
import {submitFeedback} from '../../../../../actions/userActions';
import {PUBLIC_DOCS_URL} from '../../../../../constants';
import {useNavigateToSupport} from '../../../../../hooks/navigation';
import {useDashboardApi} from '../../../../../hooks/use-api';
import {
  useSelectedGlobalProjectId,
  useSelectedOrganizationId,
} from '../../../../../selectors/params';
import {formatByteSize} from '../../../../../utils/format';
import schema, {type FeedbackData} from './utilities/schema';

type FeedbackModalProps = {
  close: () => void;
  open: boolean;
  anchor: HTMLElement | null;
  placement: PopperProps['placement'];
};

const moodOptions = [
  {
    value: 'positive',
    icon: (color: 'primary' | 'disabled') => <SentimentSatisfiedAltOutlinedIcon color={color} />,
  },
  {
    value: 'neutral',
    icon: (color: 'primary' | 'disabled') => <SentimentNeutralIcon color={color} />,
  },
  {
    value: 'negative',
    icon: (color: 'primary' | 'disabled') => <SentimentVeryDissatisfiedIcon color={color} />,
  },
].map((opts) => ({
  ...opts,
  component: (selected: boolean) => (
    <IconButton sx={{p: 0.5}}>{opts.icon(selected ? 'primary' : 'disabled')}</IconButton>
  ),
}));

const MAX_FILES = 10;
const MAX_FILE_SIZE = 10 * 1000 * 1000;

function FeedbackModal({close, open, anchor, placement}: FeedbackModalProps) {
  const {
    handleSubmit,
    control,
    formState: {isValid},
  } = useForm({
    defaultValues: {
      feedback: '',
      mood: '',
      files: [] as {file: File}[],
    },
    resolver: zodResolver(schema),
  });
  const {fields, remove, append} = useFieldArray({control, name: 'files'});
  const {setQueryData} = useDashboardApi<{
    feedback: FeedbackData;
    context: {projectId?: string; orgId?: string; location: string};
  }>();
  const location = useLocation();
  const projectId = useSelectedGlobalProjectId();
  const orgId = useSelectedOrganizationId();
  const navigateToSupport = useNavigateToSupport(orgId);

  const onSubmit = (data: FeedbackData) => {
    setQueryData({
      action: submitFeedback,
      data: {
        feedback: data,
        context: {
          location: location.pathname + location.search,
          projectId,
          orgId,
        },
      },
    });
    close();
  };
  const dispatch = useDispatch();
  const handleAttach = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length > MAX_FILES) {
      return dispatch(
        enqueNotification({
          type: NotificationTypes.ERROR,
          text: `Limit of ${MAX_FILES} files can be uploaded`,
        }),
      );
    }
    const allowedFiles = files.filter((file) => {
      const allowed = file.size < MAX_FILE_SIZE;
      if (!allowed) {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `File ${file.name} is too large.`,
          }),
        );
      }
      return allowed;
    });
    // react-hook-form doesn't support field arrays that aren't Records at root,
    // so map to a plain base object.
    return append(allowedFiles.map((file) => ({file})));
  };
  return (
    <Popper
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow:
          '0px 9px 46px 8px rgba(163, 169, 175, 0.12), 0px 24px 38px 3px rgba(163, 169, 175, 0.14), 0px 11px 15px -7px rgba(163, 169, 175, 0.20);',
      }}
      open={open}
      anchorEl={anchor}
      placement={placement}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      }}
    >
      <ClickAwayListener onClickAway={close}>
        <Paper sx={{p: 2}}>
          <Box sx={{width: 600}}>
            <Box
              sx={{
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: 'divider',
                borderRadius: 1,
              }}
            >
              <TextInput
                autoFocus
                multiline
                rows={3}
                placeholder="Your feedback is valuable to us. Enter it here..."
                name="feedback"
                control={control}
                fullWidth
                sx={{'& fieldset': {border: 'none'}}}
              />
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{px: 1}}>
                <SelectBoxInput
                  name="mood"
                  control={control}
                  hideError
                  options={moodOptions}
                  containerSx={{
                    border: 'none',
                    boxShadow: 'none',
                    p: 0,
                    '&:hover': {boxShadow: 0},
                  }}
                  gap={0}
                />
                <InputWrapper hideError>
                  <IconButton sx={{p: 0.5}} component="label">
                    <AttachFileIcon />
                    <Input
                      type="file"
                      sx={{display: 'none'}}
                      inputProps={{multiple: true}}
                      onChange={handleAttach}
                    />
                  </IconButton>
                </InputWrapper>
              </Box>
            </Box>
            <Box
              my={1}
              sx={{backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.08)}}
            >
              {fields.map((field, index) => (
                <Box key={field.id} display="flex" alignItems="center" px={1}>
                  <Typography flex={1}>{field.file.name}</Typography>
                  <Typography color="secondary">{formatByteSize(field.file.size, 1)}</Typography>
                  <IconButton size="small" onClick={() => remove(index)}>
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="baseline" py={1}>
              <Typography color="secondary">
                Technical issue? Use{' '}
                <Link
                  component="button"
                  onClick={() => {
                    navigateToSupport.go();
                    close();
                  }}
                  color="secondary"
                >
                  support
                </Link>{' '}
                or{' '}
                <Link href={PUBLIC_DOCS_URL} color="secondary" target="_blank">
                  docs
                </Link>{' '}
                instead.
              </Typography>
              <Button variant="contained" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                Submit
              </Button>
            </Box>
          </Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
}

export default FeedbackModal;
