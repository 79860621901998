import {type GlobalProject} from '../../../../../types';
import {useProjectNavSections} from '../../hooks/projectNavSections';
import NavSection from '../NavSection/NavSection';

function ProjectNav({selectedProject}: {selectedProject: GlobalProject}) {
  const sections = useProjectNavSections(selectedProject);

  if (!selectedProject?.id) {
    return null;
  }

  return (
    <nav>
      {sections.map((section) => (
        <NavSection key={section.id} section={section} />
      ))}
    </nav>
  );
}

export default ProjectNav;
