import {zodResolver} from '@hookform/resolvers/zod';
import {type StripeAddressElementOptions} from '@stripe/stripe-js';
import {useMemo} from 'react';
import {type UseFormReturn, useForm} from 'react-hook-form';
import type {OrgBillingDetails} from '../../../actions/types/organizationActions.types';
import {type TaxId} from '../../../utils/stripe/constants';
import PaymentFormSchema, {type PaymentFormType, TaxIdEmptyValue} from './utilities/schema';

export type UsePaymentFormProps = {
  currentBillingDetails?: Partial<OrgBillingDetails>;
  onCancel: () => void;
};

export type UsePaymentFormReturn = {
  defaultAddressOpts?: StripeAddressElementOptions['defaultValues'];
  cancel: () => void;
  formMethods: UseFormReturn<PaymentFormType>;
};

function parseTaxIdData(taxIdData: OrgBillingDetails['taxId'] | undefined) {
  if (taxIdData) {
    return {
      taxIdType: taxIdData.type as TaxId,
      taxIdValue: taxIdData.value,
    };
  }

  return {
    taxIdType: TaxIdEmptyValue.value,
    taxIdValue: TaxIdEmptyValue.value,
  };
}

function buildDefaultAddressOptions(currentBillingDetails?: Partial<OrgBillingDetails>) {
  if (currentBillingDetails) {
    const {company, billingAddress} = currentBillingDetails;
    // the Stripe Address name field is what we call "company" in the billing details payload
    const options: StripeAddressElementOptions['defaultValues'] = {name: company};
    if (billingAddress?.country) {
      options.address = {
        ...billingAddress,
        country: billingAddress.country,
      };
    }

    return options;
  }
  return {};
}

const usePaymentForm = ({
  currentBillingDetails,
  onCancel,
}: UsePaymentFormProps): UsePaymentFormReturn => {
  const taxIdData = parseTaxIdData(currentBillingDetails?.taxId);

  const defaultValues = {
    name: currentBillingDetails?.name || '',
    email: currentBillingDetails?.email || '',
    ...taxIdData,
  };
  const defaultAddressOpts = useMemo(
    () => buildDefaultAddressOptions(currentBillingDetails),
    [currentBillingDetails],
  );

  const formMethods = useForm<PaymentFormType>({
    defaultValues,
    values: defaultValues,
    resolver: zodResolver(PaymentFormSchema),
  });
  const {reset} = formMethods;

  const cancel = () => {
    reset();
    onCancel();
  };

  return {cancel, defaultAddressOpts, formMethods};
};

export default usePaymentForm;
