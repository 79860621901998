import Box from '@mui/material/Box';
import SuccessCard from '@pinecone-experience/timber/Cards/SuccessCard';
import {OrgSettingsPage} from '../../../../../../../constants';
import {useNavigateToOrgSettingsPage} from '../../../../../../../hooks/navigation';
import {useSupportChangeCompleted} from '../../../../../../../selectors/billing';
import {useSelectedOrganizationId} from '../../../../../../../selectors/params';
import {MODAL_WIDTH_SMALL} from '../../../../../../../styles/theme';
import {ChangeSupportAction} from '../../../utils/supportPlans';
import {supportDowngradeSuccessText, supportUpgradeSuccessText} from '../../constants/supportPlans';

const getSupportDetails = (action?: ChangeSupportAction) => {
  switch (action) {
    case ChangeSupportAction.UPGRADE:
      return {title: 'Support plan upgraded', text: supportUpgradeSuccessText};
    case ChangeSupportAction.DOWNGRADE:
      return {title: 'Support plan downgraded', text: supportDowngradeSuccessText};
    case ChangeSupportAction.CANCEL_DOWNGRADE:
      return {title: 'Support plan downgrade cancelled', text: ''};
    default:
      return {title: 'Support plan unchanged', text: ''};
  }
};

function ChangeSupportSuccessCard({show}: {show: boolean}) {
  const organizationId = useSelectedOrganizationId();
  const actionCompleted = useSupportChangeCompleted();
  const {title, text} = getSupportDetails(actionCompleted);
  const ctaText = 'Go to the Help center';

  const goToSupportPages = useNavigateToOrgSettingsPage(organizationId);
  return (
    <Box width={MODAL_WIDTH_SMALL} position="relative">
      <SuccessCard
        title={title}
        text={text}
        cta={() => goToSupportPages(OrgSettingsPage.SUPPORT)}
        ctaText={ctaText}
        show={show}
      />
    </Box>
  );
}

export default ChangeSupportSuccessCard;
