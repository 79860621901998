import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import type Stripe from 'stripe';
import {usePastInvoices} from '../../../../../selectors/billing';
import {useSelectedOrgMarketplaceInfo} from '../../../../../selectors/organizations';

function getBillingLink(marketplaceInfo?: {link: string} | null, pastInvoices?: Stripe.Invoice[]) {
  if (marketplaceInfo) {
    return marketplaceInfo.link;
  }
  const unpaidInvoice = pastInvoices?.find(
    (invoice) => invoice.status !== 'paid' && invoice.amount_due !== 0,
  );
  return unpaidInvoice?.hosted_invoice_url;
}

function SuspendedPlanCard() {
  const pastInvoices = usePastInvoices();
  const marketplaceInfo = useSelectedOrgMarketplaceInfo();
  const billingLink = getBillingLink(marketplaceInfo, pastInvoices);
  return (
    <Paper sx={{flex: 1, p: 3}}>
      <Typography variant="h6">Your account has been suspended</Typography>
      <Typography py={2} color="error">
        Access to Pinecone has been temporarily disabled due to a payment issue. Please fix.
      </Typography>
      <Button
        variant="contained"
        endIcon={<ArrowOutwardIcon />}
        href={billingLink || ''}
        target="_blank"
      >
        {marketplaceInfo ? 'Visit Marketplace' : 'Pay Invoice'}
      </Button>
    </Paper>
  );
}

export default SuspendedPlanCard;
