import {useEffect, useState} from 'react';
import {useMatch, useSearchParams} from 'react-router-dom';
import {CreateIndexParam, LocalStorageKeys, RoutePath} from '../../../../constants';
import {Flags, useFlag} from '../../../../hooks/flags';
import {useRegistrationNeeded} from '../../../../selectors/userSession';
import {localStorageGet, localStorageSet} from '../../../../utils/storage';

export const useSkipRegistration = () => {
  const skipRegistration = localStorageGet(LocalStorageKeys.SKIP_REGISTRATION_FORM) === 'true';
  const setSkipRegistration = () =>
    localStorageSet(LocalStorageKeys.SKIP_REGISTRATION_FORM, 'true');
  return {skipRegistration, setSkipRegistration};
};

export const useCompletedRegistration = () => {
  const completedRegistration =
    localStorageGet(LocalStorageKeys.COMPLETED_REGISTRATION_FORM) === 'true';
  const setCompletedRegistration = () =>
    localStorageSet(LocalStorageKeys.COMPLETED_REGISTRATION_FORM, 'true');
  return {completedRegistration, setCompletedRegistration};
};

export const useDelayRegistration = () => {
  const onCreateIndexPage = !!useMatch(RoutePath.CREATE_INDEX);
  const [searchParams] = useSearchParams();
  const hasSearchParam = Object.values(CreateIndexParam).some((param) => searchParams.has(param));
  const [delayRegistration, setDelayRegistration] = useState<boolean>(false);
  const shouldDelayRegistration = onCreateIndexPage && hasSearchParam;
  useEffect(() => {
    if (shouldDelayRegistration) setDelayRegistration(true);
  }, [shouldDelayRegistration]);
  return delayRegistration || shouldDelayRegistration;
};

export const useShouldShowRegistration = () => {
  const isRegistrationNeeded = useRegistrationNeeded();
  const {completedRegistration} = useCompletedRegistration();
  const {skipRegistration} = useSkipRegistration();
  const delayRegistration = useDelayRegistration();
  const newRegistrationEnabled = useFlag(Flags.ENABLE_NEW_REGISTRATION_FLOW);
  return (
    isRegistrationNeeded &&
    !completedRegistration &&
    !skipRegistration &&
    !delayRegistration &&
    newRegistrationEnabled
  );
};
