import {useMemo} from 'react';
import {CreateIndexTabs, Providers} from '../../../../../constants';
import {
  useServerlessEnvironments,
  useV3PaidEnvironments,
} from '../../../../../selectors/environments';
import {useSelectedOrganizationId} from '../../../../../selectors/params';

export const useAvailableEnvironmentsForTab = (
  currentTab?: string,
  fromCollection?: boolean,
  projectHasCmekEncryption?: boolean,
) => {
  const orgId = useSelectedOrganizationId();
  const podEnvironments = useV3PaidEnvironments(orgId);
  const serverlessEnvironments = useServerlessEnvironments(orgId);
  const awsServerlessEnvironments = useMemo(() => {
    return serverlessEnvironments.filter((env) => env.provider === Providers.AWS);
  }, [serverlessEnvironments]);

  switch (currentTab) {
    case CreateIndexTabs.SERVERLESS:
      if (fromCollection || projectHasCmekEncryption) {
        return awsServerlessEnvironments;
      }
      return serverlessEnvironments;

    case CreateIndexTabs.PODS:
      return podEnvironments;

    default:
      return [];
  }
};
