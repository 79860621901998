import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import {type Theme, alpha} from '@mui/material/styles';
import {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {type NavigationSection} from '../../types/navigation.interface';
import {matchesCurrentPath} from '../../utils/matchesCurrentPath';
import NavItem from '../NavItem/NavItem';

interface NavSectionProps {
  section: NavigationSection;
}

const styles = {
  root: {
    my: 0.5,
    '&:first-of-type': {
      mt: 0,
    },
  },
  button: {
    display: 'flex',
    borderRadius: 1,
    color: 'text.primary',
  },
  selected: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.primary.light, 0.04),
    color: 'primary.light',
    svg: {
      fill: (theme: Theme) => theme.palette.primary.light,
      color: 'primary.light',
    },
  },
  collapse: {
    ul: {
      p: 0,
    },
    li: {
      borderRadius: 1,
      py: 1,
      pl: 5,
      color: 'text.primary',
      '::before': {
        content: '""',
        width: 1.25,
        height: '100%',
        position: 'absolute',
        left: 13,
        backgroundColor: 'divider',
      },
      '&.Mui-selected': {
        color: 'primary.light',
        backgroundColor: (theme: Theme) => alpha(theme.palette.primary.light, 0.04),
      },
    },
  },
  title: {
    fontWeight: '500',
    ml: 1,
  },
};

/**
 * NavSection
 * Provide either a path and pathMatch to navigate to a page OR a list of items to expand.
 */
function NavSection({section: {id, icon, title, path, pathMatch, items}}: NavSectionProps) {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const isSelected = path && matchesCurrentPath(pathname, path, pathMatch);
  const isExpanded = items?.reduce(
    (acc, item) => matchesCurrentPath(pathname, item.path, item.pathMatch) || acc,
    false,
  );

  const handleClick = useCallback(() => {
    if (path) {
      navigate(path);
      return;
    }

    if (items?.length) {
      navigate(items[0].path);
    }
  }, [navigate, path, items]);

  return (
    <Box sx={styles.root}>
      <Button
        id={id}
        fullWidth
        onClick={handleClick}
        startIcon={icon}
        sx={[styles.button, isSelected ? styles.selected : null]}
      >
        <Typography variant="body1" sx={styles.title}>
          {title}
        </Typography>
        <Box flexGrow={1} />
      </Button>
      {items?.length && (
        <Collapse in={!!isExpanded} sx={styles.collapse}>
          <List component="ul">
            {items
              .filter((item) => !item.hidden)
              .map((item) => (
                <NavItem key={item.path} item={item} />
              ))}
          </List>
        </Collapse>
      )}
    </Box>
  );
}

export default NavSection;
