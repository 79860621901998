import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import {getStartedUtmParameters} from '../../../GetStartedPage/constants';
import {lowerSectionItems} from './constants';

const styles = {
  links: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
    gap: 4,
  },
  link: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 2,
    p: 1,
    borderRadius: 2,
    '&:hover, &:focus': {
      backgroundColor: 'background.surface',
    },
  },
  iconBox: {
    borderRadius: 2,
    backgroundColor: 'background.surface',
    p: 2,
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
  },
};

function DocumentationLinks() {
  return (
    <Box>
      <Typography variant="h6" component="h2" mb={2}>
        Additional resources
      </Typography>
      <Box sx={styles.links}>
        {lowerSectionItems.map((item) => (
          <ButtonBase
            key={item.title}
            sx={styles.link}
            href={`${item.href}${getStartedUtmParameters}`}
            target="_blank"
          >
            <Box sx={styles.iconBox}>{item.icon}</Box>
            <Box sx={styles.text}>
              <Typography fontWeight="bold">{item.title}</Typography>
              <Typography variant="body2">{item.description}</Typography>
            </Box>
          </ButtonBase>
        ))}
      </Box>
    </Box>
  );
}

export default DocumentationLinks;
