import Box from '@mui/material/Box';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {AddressElement, PaymentElement} from '@stripe/react-stripe-js';
import type {StripeAddressElementChangeEvent, StripeAddressElementOptions} from '@stripe/stripe-js';
import {useCallback, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {type TaxCountryCode} from '../../../../utils/stripe/constants';
import {type PaymentFormType} from '../utilities/schema';
import TaxIdFields from './TaxIdFields/TaxIdFields';

type PaymentFormFieldsProps = {
  defaultAddressOpts?: StripeAddressElementOptions['defaultValues'];
};

const styles = {
  formFieldsContainer: {
    display: 'grid',
    gridGap: 48,
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: '"leftFields rightFields"',
  },
  formFieldsLeft: {
    gridArea: 'leftFields',
    // targeting the first input element only
    '& div:first-of-type': {
      mt: 0,
    },
  },
  formFieldsRight: {
    gridArea: 'rightFields',
  },
};

function PaymentFormFields({defaultAddressOpts}: PaymentFormFieldsProps) {
  const [countryCode, setCountryCode] = useState<TaxCountryCode | undefined>(
    defaultAddressOpts?.address?.country,
  );

  useEffect(() => {
    setCountryCode(defaultAddressOpts?.address?.country);
  }, [setCountryCode, defaultAddressOpts?.address?.country]);

  const {control} = useFormContext<PaymentFormType>();

  const trackAddressCountryChange = useCallback(
    (event: StripeAddressElementChangeEvent) => {
      // TODO: set a debounce?
      setCountryCode(event.value?.address.country);
    },
    [setCountryCode],
  );

  return (
    <Box sx={styles.formFieldsContainer}>
      <Box sx={styles.formFieldsLeft}>
        <TextInput
          control={control}
          fullWidth
          label="First and last name"
          name="name"
          placeholder="First and last name"
          required
        />
        <TextInput
          control={control}
          tooltip="This is where we will send your invoices, which may differ from your account email"
          fullWidth
          label="Invoice email"
          name="email"
          placeholder="Invoice email"
          required
        />
        <Box sx={{mt: 1}}>
          <PaymentElement options={{terms: {card: 'never'}}} />
        </Box>
        <TaxIdFields countryCode={countryCode} />
      </Box>
      <Box sx={styles.formFieldsRight}>
        <AddressElement
          onChange={trackAddressCountryChange}
          options={{
            display: {
              name: 'organization',
            },
            mode: 'billing',
            defaultValues: defaultAddressOpts,
          }}
        />
      </Box>
    </Box>
  );
}

export default PaymentFormFields;
