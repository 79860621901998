import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useEffect} from 'react';
import {type UseFormReturn} from 'react-hook-form';
import {fetchVectors} from '../../../../../actions/dataActions';
import ClearIcon from '../../../../../components/StyledClearIcon';
import {useHasNamespaces} from '../../../../../hooks/specUtils';
import {useDataPlane} from '../../../../../hooks/use-api';
import {useBooleanState} from '../../../../../hooks/utils';
import {useVectorInfo} from '../../../../../selectors/explorer';
import {useServiceByGlobalProject} from '../../../../../selectors/services';
import NamespacePicker from '../NamespacePicker/NamespacePicker';
import {type UpsertSchemaType} from '../utilities/schema';
import {type IndexExplorerState} from '../utilities/utils';
import MetadataEditor from './MetadataEditor/MetadataEditor';

type VectorFormControlsProps = {
  indexExplorerState: IndexExplorerState;
  typeName: string;
  vectorId?: string;
  namespace?: string;
  allowAutoType: boolean;
  upsertFormState: UseFormReturn<UpsertSchemaType>;
};

function VectorFormControls({
  typeName,
  indexExplorerState,
  vectorId,
  namespace,
  allowAutoType,
  upsertFormState,
}: VectorFormControlsProps) {
  const {project, service, host} = indexExplorerState;
  const vector = useVectorInfo(project.id, service, vectorId || '', namespace || '');
  const {
    setValue,
    getFieldState,
    control,
    formState: {errors},
  } = upsertFormState;
  const {setQueryData: setFetchQueryData} = useDataPlane<{
    ids: string[];
    namespace: string;
    hidden?: boolean;
  }>(project, service, host);
  useEffect(() => {
    if (vectorId && !vector) {
      setFetchQueryData({
        action: fetchVectors,
        data: {
          ids: [vectorId],
          namespace: namespace || '',
          hidden: true,
        },
      });
    }
  }, [vector, vectorId, namespace, setFetchQueryData]);
  const serviceInfo = useServiceByGlobalProject(project.id, service);
  const hasNamespaces = useHasNamespaces(serviceInfo);
  const {
    val: showSparse,
    setTrue: setShowSparse,
    setFalse: setHideSparse,
  } = useBooleanState(!!vector?.sparseValues);
  const removeSparse = () => {
    setValue('sparse', undefined);
    setHideSparse();
  };
  return (
    <LoadingContainer loading={!!vectorId && !vector}>
      <Box>
        {hasNamespaces && (
          <NamespacePicker
            id="add-vector-namespace-picker"
            indexExplorerState={indexExplorerState}
            control={control}
            disabled={!!vector}
          />
        )}
        <TextInput
          name="id"
          control={control}
          placeholder="Enter ID"
          label="ID"
          fullWidth
          disabled={!!vector}
        />
        <Box sx={{display: 'flex', alignItems: 'center', gap: 4}}>
          <Box sx={{flex: 1}}>
            <TextInput
              name="vector"
              control={control}
              placeholder="Ex: 9.32, 0.12, -2.35"
              label="Values"
              fullWidth
            />
          </Box>
          {!showSparse && (
            <Button
              onClick={setShowSparse}
              color="secondary"
              startIcon={<AddCircleOutlineIcon />}
              sx={{py: 2}}
            >
              Add Sparse Data
            </Button>
          )}
        </Box>
        {showSparse && (
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pb: 2,
              }}
            >
              <Typography fontWeight="bold">Sparse Values</Typography>
              <Button startIcon={<ClearIcon />} onClick={removeSparse} color="secondary">
                Remove
              </Button>
            </Box>
            <Typography>
              Sparse Vectors consist of index-value pairs. Paste both sets below, separated by
              commas.
            </Typography>
            <Box sx={{display: 'flex', gap: 2}}>
              <Box sx={{width: '50%'}}>
                <TextInput
                  name="sparse.indices"
                  control={control}
                  placeholder="Ex., 7, 132, 354..."
                  label="Sparse Indices"
                  fullWidth
                />
              </Box>
              <Box sx={{width: '50%'}}>
                <TextInput
                  name="sparse.values"
                  control={control}
                  placeholder="Ex., .01, 0.45, .1.31..."
                  label="Sparse Values"
                  fullWidth
                />
              </Box>
            </Box>
            <Typography color="error">{errors?.sparse?.message}</Typography>
          </Box>
        )}
        <Divider />
        <Box sx={{maxHeight: 'calc(100vh - 400px)', overflow: 'auto'}}>
          <MetadataEditor
            control={control}
            getFieldState={getFieldState}
            setValue={setValue}
            idPrefix={typeName}
            allowAutoType={allowAutoType}
          />
        </Box>
      </Box>
    </LoadingContainer>
  );
}

export default VectorFormControls;
