import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import {Plans} from '../../../../constants';
import {
  useSelectedOrgMarketplaceInfo,
  useSelectedOrganization,
} from '../../../../selectors/organizations';
import Image from '../../../Utilities/Image/Image';
import ThemeAwarePineconeLogo from '../../../Utilities/ThemeAwarePineconeLogo';

const styles = {
  modal: {
    width: '100vw',
    height: '100vh',
  },
  container: {
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    flexGrow: 1,
    height: '100%',
    backgroundColor: 'background.paper',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backButton: {
    position: 'absolute',
    top: 15,
    left: 15,
  },
};

const MarketplaceLinkModal = ({open, onClose}: {open: boolean; onClose: () => void}) => {
  const organization = useSelectedOrganization();
  const marketplaceInfo = useSelectedOrgMarketplaceInfo();
  const action = organization.plan === Plans.FREE ? 'Upgrade' : 'Downgrade';

  if (!marketplaceInfo) {
    return null;
  }

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Box sx={styles.container}>
        <Box sx={styles.content}>
          <Typography variant="h6" gutterBottom>{`Continue your ${action.toLowerCase()} on ${
            marketplaceInfo.name
          } Marketplace`}</Typography>
          <Box sx={styles.logoContainer}>
            <ThemeAwarePineconeLogo
              sx={{
                height: 50,
                m: 3,
              }}
            />
            <ArrowForwardIcon fontSize="large" color="primary" />
            <Image
              srcLight={marketplaceInfo.logo}
              srcDark={marketplaceInfo.logoDark}
              alt={marketplaceInfo.name}
              sx={{
                height: 50,
                m: 3,
              }}
            />
          </Box>
          <Button
            sx={{mt: 2}}
            endIcon={<ExpandMoreIcon fontSize="large" sx={{transform: 'rotate(-90deg)'}} />}
            variant="contained"
            href={marketplaceInfo.link}
            target="_blank"
            rel="noreferrer"
          >
            Continue to marketplace
          </Button>
        </Box>
        <Button
          size="large"
          startIcon={<ExpandMoreIcon fontSize="large" sx={{transform: 'rotate(90deg)'}} />}
          sx={styles.backButton}
          onClick={onClose}
        >
          Back
        </Button>
      </Box>
    </Modal>
  );
};
export default MarketplaceLinkModal;
