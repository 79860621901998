import {z} from 'zod';
import {Purpose} from '../../../constants';

const schema = z.object({
  firstName: z.string().min(1, 'Required').max(200),
  lastName: z.string().min(1, 'Required').max(200),
  purpose: z.enum([Purpose.BUSINESS, Purpose.PERSONAL]),
  company: z.string().min(1, 'Required').max(200),
  language: z.string().min(1).max(500),
  hasEmbeddingModel: z.string().min(1).max(500),
  useCase: z.string().min(1).max(500),
  numDocs: z.string().min(1).max(500),
});

export type RegistrationData = z.infer<typeof schema>;
export default schema;
