import {type NavigateProps} from 'react-router-dom';
import {useSubstituteDefaultProject} from '../../hooks/navigation';
import NavigateWithParams from './NavigateWithParams';

export type SubstituteDefaultProjectProps = Omit<NavigateProps, 'to'>;

/** Navigates to the same path but with the default project id substituted in */
function SubstituteDefaultProject(navigateProps: SubstituteDefaultProjectProps) {
  const {path} = useSubstituteDefaultProject();
  return <NavigateWithParams {...navigateProps} to={path} replace />;
}

export default SubstituteDefaultProject;
