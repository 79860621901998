import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useNavigateToAssistant} from '../../../../../hooks/navigation';
import {
  useSelectedGlobalProjectId,
  useSelectedOrganizationId,
} from '../../../../../selectors/params';
import {type Assistant} from '../../../../../types';
import {styles} from '../styles';

function AssistantCard({assistant}: {assistant: Assistant}) {
  const {name, status, created_at: createdAt} = assistant;
  const selectedOrgId = useSelectedOrganizationId();
  const selectedProjectId = useSelectedGlobalProjectId();
  const {go: goToAssistantPage} = useNavigateToAssistant(selectedOrgId, selectedProjectId);

  return (
    <Paper sx={styles.sectionItemCard} onClick={() => goToAssistantPage(name)} component="button">
      <Typography variant="body2" color="secondary">
        {status} - {new Date(createdAt).toLocaleString()}
      </Typography>
      <Typography variant="h6" component="h2">
        {name}
      </Typography>
    </Paper>
  );
}

export default AssistantCard;
