import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import {useState} from 'react';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {PINECONE_URLS} from '../../../constants';
import {useOrganizationsAreLoading} from '../../../selectors/organizations';
import {useIsOwnerOfSelectedProject} from '../../../selectors/projects';
import CreateApiKeyModal from '../ApiKeysPage/CreateApiKeyButton/CreateApiKeyModal';
import DocumentationLinks from '../HomePage/Sections/Documentation/DocumentationLinks';
import GetStartedGuideCard from '../HomePage/Sections/GetStartedGuides/GetStartedGuideCard';
import {GuideCardInfo} from '../HomePage/Sections/GetStartedGuides/constants';

const styles = {
  upperSection: {
    display: 'flex',
    gap: 2,
    '& > *': {
      flex: 1,
    },
  },
};

function GetStartedPage() {
  const organizationsLoading = useOrganizationsAreLoading();
  const [openGenerateKeyForm, setOpenGenerateKeyForm] = useState(false);
  const isSelectedProjectOwner = useIsOwnerOfSelectedProject();
  return (
    <Page
      loading={organizationsLoading}
      header={
        <PageHeader
          title="Get started with Pinecone"
          actions={
            <>
              <Tooltip
                title={
                  !isSelectedProjectOwner && 'You must be a project owner to generate an API key'
                }
              >
                <Button
                  variant="contained"
                  onClick={() => setOpenGenerateKeyForm(true)}
                  disabled={!isSelectedProjectOwner}
                >
                  Generate API key
                </Button>
              </Tooltip>
              <CreateApiKeyModal
                open={openGenerateKeyForm}
                onClose={() => setOpenGenerateKeyForm(false)}
              />
            </>
          }
        />
      }
    >
      <Box>
        <Box sx={styles.upperSection}>
          <GetStartedGuideCard guide={GuideCardInfo[0]} />
          <GetStartedGuideCard guide={GuideCardInfo[1]} />
        </Box>
      </Box>
      <DocumentationLinks />
      <Typography mt={4} variant="body2">
        Contact our{' '}
        <Link href={PINECONE_URLS.CONTACT_SALES} target="_blank">
          sales team
        </Link>{' '}
        for further assistance.
      </Typography>
    </Page>
  );
}

export default GetStartedPage;
