import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {Link as RouterLink} from 'react-router-dom';
import {AssistantQuotaKeys} from '../../../../constants';
import {getOrgBillingPlansPath} from '../../../../hooks/navigation';
import {MODAL_WIDTH_SMALL} from '../../../../styles/theme';
import {type UsageQuota} from '../../../../types';
import {formatLargeNumber} from '../../../../utils/format';

interface AssistantQuotaInfoModalContentProps {
  orgId?: string;
  quotas: UsageQuota[];
  onClose: () => void;
}

const styles = {
  modal: {
    width: MODAL_WIDTH_SMALL,
    display: 'flex',
    flexDirection: 'column',
  },
  usageList: {
    ml: 3,
    listStyle: 'disc',
  },
  usageListItem: {
    pl: 0,
    display: 'list-item',
  },
  modalButton: {
    mt: 3,
    alignSelf: 'end',
  },
};

export default function AssistantQuotaInfoModalContent({
  orgId,
  quotas,
  onClose,
}: AssistantQuotaInfoModalContentProps) {
  const storageQuota = quotas.find(
    (quota) => quota.key === AssistantQuotaKeys.STORAGE_GB_PER_PROJECT,
  );
  const queriesQuota = quotas.find((quota) => quota.key === AssistantQuotaKeys.QUERIES_PER_MONTH);
  const promptTokensQuota = quotas.find((quota) => quota.key === AssistantQuotaKeys.PROMPT_TOKENS);
  const completionsTokensQuota = quotas.find(
    (quota) => quota.key === AssistantQuotaKeys.COMPLETIONS_TOKENS,
  );

  return (
    <Box sx={styles.modal}>
      <ModalHeader title="Assistant usage" close={onClose} />
      <Typography>
        Welcome to the Assistant public preview! We’re excited to have you try out this new tool.
        Here are some important details about the usage limits on your plan:
      </Typography>
      <List sx={styles.usageList}>
        {promptTokensQuota && (
          <ListItem sx={styles.usageListItem}>
            <strong>Tokens:</strong> You can use up to {formatLargeNumber(promptTokensQuota.limit)}{' '}
            tokens of input
            {completionsTokensQuota &&
              ` and ${formatLargeNumber(completionsTokensQuota.limit)} tokens of output.`}
          </ListItem>
        )}
        {queriesQuota && (
          <ListItem sx={styles.usageListItem}>
            <strong>Queries:</strong> You can make up to {queriesQuota.limit.toLocaleString()}{' '}
          </ListItem>
        )}
        {storageQuota && (
          <ListItem sx={styles.usageListItem}>
            <strong>Storage:</strong> The storage limit is {storageQuota.limit.toLocaleString()}
            GB.
          </ListItem>
        )}
      </List>
      <Typography>
        If you reach any of these limits, you can{' '}
        <Link
          id="assistant-upgrade-plan-link"
          component={RouterLink}
          to={getOrgBillingPlansPath(orgId)}
        >
          upgrade your plan
        </Link>
        .
      </Typography>
      <Button sx={styles.modalButton} onClick={onClose} variant="contained">
        Okay, got it
      </Button>
    </Box>
  );
}
