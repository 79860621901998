import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import type * as React from 'react';
import dayjs from '../../../../../utils/dayjs';
import {formatMoney} from '../../../../../utils/format';
import {TimespanMap, type TimespanTypes} from '../config';

const styles = {
  container: {
    p: 2,
    mr: 2,
    flexGrow: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    flexGrow: 1,
    p: 1,
  },
};
type UsageSummaryProps = {
  timespan: TimespanTypes;
  totalSelectedCost: number;
  loading: boolean;
};
const UsageSummary: React.FC<UsageSummaryProps> = ({timespan, totalSelectedCost, loading}) => {
  const {start, end} = TimespanMap[timespan];
  const MILLISECONDS_PER_DAY = 86400000;
  return (
    <Paper sx={styles.container}>
      <Box display="flex">
        <Tooltip title="All Dates are in UTC Time">
          <Typography variant="h6">
            {dayjs(end).diff(start, 'day') > 0
              ? `${dayjs.utc(start).format('MMM Do')}   -   ${dayjs.utc(end).format('MMM Do YYYY')}`
              : dayjs.utc(end).format('MMMM Do YYYY')}
          </Typography>
        </Tooltip>
      </Box>
      <Divider sx={{my: 1}} />
      <Box display="flex">
        <Box sx={styles.box}>
          <Typography variant="overline" color="text.secondary">
            Sub Total
          </Typography>
          <Typography id="totalCost" variant="h5" color="primary.dark">
            {loading ? '$---' : `${formatMoney(totalSelectedCost)}`}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            * Excludes Credits
          </Typography>
        </Box>
        <Divider orientation="vertical" />
        <Box sx={styles.box}>
          <Typography variant="overline" color="text.secondary">
            Daily Average
          </Typography>
          <Typography id="dailyAverage" variant="h5" color="primary.dark">
            {loading
              ? '$---'
              : `${formatMoney(totalSelectedCost / ((end - start) / MILLISECONDS_PER_DAY))}`}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default UsageSummary;
