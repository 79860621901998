import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import BillingPlanItem from '../../../../../components/BillingPlanItem/BillingPlanItem';
import {BILLING_PLANS} from '../../../../../components/BillingPlans/constants/billingPlans';
import {
  type BillingPlan,
  BillingPlanAttributes,
} from '../../../../../components/BillingPlans/types/billingPlans.interface';
import RowWithCheck from '../../../../../components/Utilities/RowWithCheck';
import {PlanNameMapping, type Plans} from '../../../../../constants';
import {useNavigateToBillingPlans} from '../../../../../hooks/navigation';
import {useSelectedOrganization} from '../../../../../selectors/organizations';

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 1.5,
    p: 3,
  },
  button: {
    width: 'min-content',
  },
};

const CurrentPlanCard = () => {
  const organization = useSelectedOrganization();
  const mappedPlan = PlanNameMapping[organization.plan as Plans];
  const goToBillingPlans = useNavigateToBillingPlans(organization.id);
  const orgPlan = BILLING_PLANS.find(
    (plan) => plan.tier === (organization.plan as Plans),
  ) as BillingPlan;

  return (
    <Paper sx={styles.root}>
      <Typography variant="h6" component="h2">{`${mappedPlan} Plan`}</Typography>
      <Typography>
        Your{' '}
        <Typography color="primary" component="span">
          current plan
        </Typography>{' '}
        includes:
      </Typography>
      <Box>
        {orgPlan.attributes[BillingPlanAttributes.Features].map((item) => (
          <RowWithCheck key={item.name?.toString()} text={<BillingPlanItem item={item} />} />
        ))}
      </Box>
      <Button sx={styles.button} variant="contained" onClick={goToBillingPlans}>
        Manage Plan
      </Button>
    </Paper>
  );
};

export default CurrentPlanCard;
