import Divider from '@mui/material/Divider';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {clearPendingDataset} from '../../../../../actions/serviceActions';
import {Flags, useFlag} from '../../../../../hooks/flags';
import {useOnce} from '../../../../../hooks/utils';
import {useExplorerResultsByGlobalProject} from '../../../../../selectors/explorer';
import {
  usePendingDatasetByGlobalProject,
  useServiceStatusByGlobalProject,
} from '../../../../../selectors/services';
import {sortNamespaces} from '../../../../../utils/data';
import {type IndexExplorerState} from '../utilities/utils';
import ExplorerResults from './ExplorerResults/ExplorerResults';
import QueryControls from './QueryControls/QueryControls';
import {type QueryExplorerState} from './utils';

type QueryExplorerProps = {
  indexExplorerState: IndexExplorerState;
  queryExplorerState: QueryExplorerState;
};

function QueryExplorer({indexExplorerState, queryExplorerState}: QueryExplorerProps) {
  const {indexStats, project, service, dimensions} = indexExplorerState;
  const {controllerState, paginationState, onClickQuery, setFormQuery} = queryExplorerState;
  const {control, handleSubmit, watch, setValue, getFieldState} = controllerState;
  const serviceStatus = useServiceStatusByGlobalProject(project.id, service);
  const useExistingVectorForInit = useFlag(Flags.USE_EXISITNG_VECTOR_IN_EXPLORER);
  const queryId = watch('queryId');
  const vectorText = watch('queryVectorText');
  const doInitQuery = useCallback(() => {
    if (!queryId && vectorText.length === 0) {
      const randomVector = new Array(dimensions)
        .fill(0)
        .map(() => parseFloat(Math.random().toFixed(2)));
      const defaultNamespace = sortNamespaces(indexStats)[0];
      setFormQuery({vector: randomVector}, defaultNamespace);
      handleSubmit((arg) => onClickQuery(arg, /* retries= */ 3))();
    }
  }, [queryId, vectorText, dimensions, indexStats, onClickQuery, setFormQuery, handleSubmit]);
  useOnce(doInitQuery, !!serviceStatus.ready && !!indexStats);
  const existingVectors = useExplorerResultsByGlobalProject(project.id, service);
  const namespace = watch('namespace');
  const doSecondQuery = useCallback(() => {
    const randomVector = existingVectors[Math.floor(Math.random() * existingVectors.length)];
    setFormQuery({id: randomVector.id}, namespace || '');
    handleSubmit((a) => onClickQuery(a))();
  }, [existingVectors, setFormQuery, onClickQuery, handleSubmit, namespace]);
  useOnce(doSecondQuery, useExistingVectorForInit && existingVectors.length > 0);
  const pendingDataset = usePendingDatasetByGlobalProject(project.id, service);
  const dispatch = useDispatch();
  const initPendingDatasetQuery = useCallback(() => {
    if (pendingDataset) {
      setFormQuery({id: pendingDataset[0].id}, '');
      handleSubmit((args) => onClickQuery(args, /* retries= */ 2))();
      dispatch(clearPendingDataset({globalProjectId: project.id, service}));
    }
  }, [dispatch, onClickQuery, pendingDataset, setFormQuery, project.id, service, handleSubmit]);
  useOnce(initPendingDatasetQuery, (indexStats?.totalVectorCount || 0) > 0);
  return (
    <>
      <QueryControls
        indexExplorerState={indexExplorerState}
        onSubmit={handleSubmit((e) => onClickQuery(e))}
        setFormQuery={setFormQuery}
        setValue={setValue}
        watch={watch}
        control={control}
        getFieldState={getFieldState}
      />
      <Divider />
      <ExplorerResults
        indexExplorerState={indexExplorerState}
        onSubmit={handleSubmit((e) => onClickQuery(e))}
        setFormQuery={setFormQuery}
        paginationState={paginationState}
      />
    </>
  );
}

export default QueryExplorer;
