import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Alert from '@pinecone-experience/timber/Alert';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {type ReactElement} from 'react';
import ProjectEncryptionBadge from '../../../../../components/ProjectEncryptionBadge/ProjectEncryptionBadge';
import StatusBox from '../../../../../components/StatusBox';
import SummaryItem from '../../../../../components/SummaryItem/SummaryItem';
import Image from '../../../../../components/Utilities/Image/Image';
import MigrationModal from '../../../../../components/indexes/MigrationModal/MigrationModal';
import {PROVIDER_INFORMATION_MAP, Plans} from '../../../../../constants';
import {isUpgrading, useCanShowPrice, useIsStarter} from '../../../../../hooks/specUtils';
import {useBooleanState} from '../../../../../hooks/utils';
import calculatePrice from '../../../../../mirrored/calculatePrice';
import {type PodTypes} from '../../../../../mirrored/pricingConstants';
import {useEnvironmentInfo} from '../../../../../selectors/environments';
import {useSelectedOrganizationPlan} from '../../../../../selectors/organizations';
import {useSelectedGlobalProjectId} from '../../../../../selectors/params';
import {usePrivateEndpointLink} from '../../../../../selectors/privateEndpoints';
import {useSelectedGlobalProject} from '../../../../../selectors/projects';
import {
  type IndexStatsDescription,
  type Organization,
  type V4IndexInfoResponse,
} from '../../../../../types';
import {formatMoney} from '../../../../../utils/format';
import {getFlag} from '../../../../../utils/regions';
import {getSpecs} from '../../../../../utils/services';

const styles = {
  root: {
    flexGrow: 2,
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    justifyContent: 'space-between',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    gap: 4,
  },
  action: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
};

const IconValue = ({icon, value}: {icon?: ReactElement | null; value?: string}) => {
  return (
    <Box display="flex" alignItems="center">
      {icon}
      <Typography variant="subtitle2" ml={0.5}>
        {value}
      </Typography>
    </Box>
  );
};

const MigrationAlert = ({
  serverlessMigration,
  isStarter,
}: {
  serverlessMigration: boolean;
  isStarter: boolean;
}) => {
  if (serverlessMigration) {
    return (
      <Alert
        icon={<RocketLaunchIcon sx={{color: 'text.primary'}} />}
        description={
          <>
            <Typography
              component="span"
              sx={{
                fontWeight: 'bold',
                pr: 1,
              }}
            >
              New!
            </Typography>
            {isStarter
              ? ' Convert this into a serverless index and scale past 100k records, all in the Starter tier.'
              : 'Convert this into a serverless index for easier management'}
          </>
        }
      />
    );
  }
  return null;
};

interface DetailsProps {
  service: string;
  organization: Organization;
  serviceInfo?: V4IndexInfoResponse;
  serviceStats?: IndexStatsDescription;
  statusText: string;
  statusColor: string;
  isFree: boolean;
}

const Details = ({
  service,
  organization,
  serviceInfo,
  serviceStats,
  statusText,
  statusColor,
  isFree,
}: DetailsProps) => {
  const {shouldShowHeartbeatChart} = useFlags();
  const globalProjectId = useSelectedGlobalProjectId();
  const project = useSelectedGlobalProject();
  const isDedicated = useSelectedOrganizationPlan() === Plans.DEDICATED;
  const {serverlessSpec, podSpec} = getSpecs(serviceInfo);
  const host = `https://${serviceInfo?.host || ''}`;
  const privateLink = usePrivateEndpointLink(
    globalProjectId,
    serverlessSpec?.cloud || '',
    serverlessSpec?.region || '',
    host,
  );
  const envInfo = useEnvironmentInfo(podSpec?.environment || '', organization.id);
  const [podType, multiplier] = podSpec?.pod_type?.split('.') || [];
  const upgrading = isUpgrading(serviceInfo);
  // ToDo Update Cost Calculation for V4 Paid
  const cost =
    !podSpec || isFree || isDedicated
      ? 0
      : calculatePrice({
          organization,
          environment: podSpec?.environment,
          pods: Number(podSpec?.shards),
          productType: podType as PodTypes,
          podSize: Number(multiplier?.replace('x', '') || 1),
          replicas: Number(podSpec?.replicas),
        });
  const showCost = useCanShowPrice(serviceInfo) && !isDedicated;

  const providerInfo = envInfo?.provider
    ? PROVIDER_INFORMATION_MAP[envInfo.provider]
    : {
        logo: '',
        logoDark: '',
        name: '',
      };

  const serverlessProviderInfo = serverlessSpec
    ? PROVIDER_INFORMATION_MAP[serverlessSpec.cloud]
    : {
        logo: '',
        logoDark: '',
        name: '',
      };

  const isStarter = useIsStarter(serviceInfo);
  const {
    val: showMigration,
    setTrue: setShowMigration,
    setFalse: hideMigration,
  } = useBooleanState();
  const showServerlessMigration = isStarter && !upgrading;
  return (
    <Box sx={styles.root}>
      <MigrationModal open={showMigration} close={hideMigration} service={service} />
      <Box>
        <Box sx={{...styles.flexRow, gap: 2}} mb={2}>
          <Typography variant="h6">{service}</Typography>
          {isFree && !upgrading && (
            <Chip label={isStarter ? 'Starter' : 'Free'} variant="outlined" />
          )}
          <StatusBox color={statusColor} statusText={statusText} />
          <ProjectEncryptionBadge cmekEncrypted={project.force_encryption_with_cmek} />
          {showServerlessMigration && (
            <Button variant="contained" sx={styles.action} onClick={setShowMigration}>
              Convert to serverless
            </Button>
          )}
        </Box>
      </Box>
      <Box sx={styles.flexRow}>
        <SummaryItem label="Metric" value={serviceInfo?.metric} />
        <SummaryItem label="Dimensions" value={serviceInfo?.dimension} />
        {podSpec && <SummaryItem label="Pod Type" value={podSpec.pod_type} />}
        {privateLink ? (
          <SummaryItem
            label="Private Endpoint"
            value={upgrading ? '--' : privateLink}
            enableCopy={!upgrading}
            tooltip={
              upgrading ? 'Your new Private Endpoint will be available once migration is done' : ''
            }
          />
        ) : null}
        <SummaryItem
          label="Host"
          value={upgrading ? '--' : host}
          enableCopy={!upgrading}
          tooltip={upgrading ? 'Your new host will be available once migration is done' : ''}
        />
      </Box>
      <Box sx={styles.flexRow} justifyContent="space-between">
        <Box sx={styles.flexRow}>
          <SummaryItem
            label="Cloud"
            value={
              <IconValue
                icon={
                  serverlessSpec ? (
                    <Image
                      alt={serverlessSpec?.cloud}
                      srcLight={serverlessProviderInfo?.logo}
                      srcDark={serverlessProviderInfo?.logoDark}
                      height={14}
                    />
                  ) : (
                    <Image
                      alt={providerInfo?.name}
                      srcLight={providerInfo?.logo}
                      srcDark={providerInfo?.logoDark}
                      height={14}
                    />
                  )
                }
                value={serverlessProviderInfo?.name || providerInfo?.name}
              />
            }
          />
          {envInfo?.region_location && (
            <SummaryItem
              label="Region"
              value={
                <IconValue
                  icon={getFlag(envInfo?.region_flag || '', {height: 14})}
                  value={`${envInfo.region_location} (${envInfo?.provider_region})`}
                />
              }
            />
          )}
          {serverlessSpec && (
            <SummaryItem label="Region" value={serverlessSpec?.region} enableCopy />
          )}
          {podSpec && <SummaryItem label="Environment" value={podSpec.environment} enableCopy />}
          <SummaryItem label="Type" value={serverlessSpec ? 'Serverless' : 'Pods'} />
        </Box>
        <Box sx={styles.flexRow}>
          {showCost && (
            <Box textAlign="right">
              <Typography variant="overline" color="text.secondary" noWrap>
                Monthly Cost
              </Typography>
              <Typography variant="h6" noWrap>{`${formatMoney(
                cost,
                /* short= */ true,
              )}`}</Typography>
            </Box>
          )}
          {!shouldShowHeartbeatChart && typeof serviceStats?.totalVectorCount === 'number' && (
            <Box textAlign="right" ml={2}>
              <Typography variant="overline" color="text.secondary" noWrap>
                Record Count
              </Typography>
              <Typography variant="h6" noWrap>
                {serviceStats?.totalVectorCount.toLocaleString()}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <MigrationAlert serverlessMigration={showServerlessMigration} isStarter={isStarter} />
    </Box>
  );
};

export default Details;
