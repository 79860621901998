import Box from '@mui/material/Box';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {useEffect} from 'react';
import {LocalStorageKeys} from '../../../constants';
import {Flags, useFlag} from '../../../hooks/flags';
import {useBooleanState} from '../../../hooks/utils';
import {useIsOwnerOfOrganization} from '../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../selectors/params';
import {localStorageGet, localStorageSet} from '../../../utils/storage';
import PricingChangeCopy from './PricingChangeCopy/PricingChangeCopy';

const SHOULD_SHOW_SEGMENTS = ['3.1', '3.2', '4', '5.1', '5.2', '6.1', '6.2', '6.3'];

function PricingChangeNotification() {
  const segment = useFlag(Flags.PRICING_CHANGE_NOTIFICATION);
  const isOrgOwner = useIsOwnerOfOrganization();
  const shouldShow = segment && SHOULD_SHOW_SEGMENTS.includes(segment) && isOrgOwner;
  const orgId = useSelectedOrganizationId();
  const localStorageKey = `${LocalStorageKeys.VIEWED_PRICING_CHANGE_NOTIFICATION}_${orgId}`;
  const hasSeen = localStorageGet(localStorageKey);
  const {val: isOpen, setFalse: close, setTrue: open} = useBooleanState(!hasSeen && shouldShow);

  const onClose = () => {
    localStorageSet(localStorageKey, 'true');
    close();
  };

  // Ensure that the modal opens if the flag value changes.
  useEffect(() => {
    if (shouldShow && !hasSeen) {
      open();
    }
  }, [hasSeen, open, shouldShow]);

  return (
    <BaseModal open={isOpen} onClose={onClose}>
      <ModalHeader close={onClose} title="Important Updates" />
      <Box sx={{minWidth: 400, width: 640}}>
        <PricingChangeCopy segment={segment} onClose={onClose} />
      </Box>
    </BaseModal>
  );
}

export default PricingChangeNotification;
