import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FolderIcon from '@mui/icons-material/InsertDriveFileOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {type ModalProps} from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import {type Theme} from '@mui/material/styles';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {useCallback} from 'react';
import {type DropzoneRootProps, ErrorCode, type FileRejection} from 'react-dropzone';
import RowWithIcon from '../../../components/Utilities/RowWithIcon';
import {MODAL_WIDTH_MEDIUM} from '../../../styles/theme';
import {formatByteSize} from '../../../utils/format';

interface ImportModalProps {
  open: boolean;
  assistantName: string;
  acceptedFiles: File[];
  rejectedFiles: FileRejection[];
  container: ModalProps['container'];
  loading?: boolean;
  maxFiles: number;
  isDragAccept: boolean;
  isDragReject: boolean;
  getDropzoneProps: () => DropzoneRootProps;
  onClose: () => void;
  onAddFiles: () => void;
  onRemoveAcceptedFile: (fileName: string) => void;
  onImportFiles: (files: File[]) => void;
}

const styles = {
  modal: {
    width: MODAL_WIDTH_MEDIUM,
  },
  card: {
    p: 2,
    backgroundColor: 'background.surface',
    mb: 1,
  },
  list: {
    m: 0,
    p: 0,
    gap: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    mt: 2,
    mb: 0,
    gap: 2,
  },
  acceptDrop: {
    borderColor: 'primary.main',
  },
  rejectDrop: {
    borderColor: (theme: Theme) => theme.palette.error.main,
  },
};

export function ImportModal({
  open,
  container,
  loading,
  assistantName,
  maxFiles,
  acceptedFiles,
  rejectedFiles,
  isDragAccept,
  isDragReject,
  getDropzoneProps,
  onAddFiles,
  onClose,
  onRemoveAcceptedFile,
  onImportFiles,
}: ImportModalProps) {
  const hasTooManyFiles = acceptedFiles.length > maxFiles;

  const handleImport = useCallback(() => {
    onImportFiles(acceptedFiles);
    onClose();
  }, [acceptedFiles, onImportFiles, onClose]);

  return (
    <BaseModal container={container} sx={styles.modal} open={open} onClose={onClose}>
      <Box {...getDropzoneProps()}>
        <ModalHeader title="Import Files" subtitle={assistantName} />
        <Card
          sx={[
            styles.card,
            isDragAccept ? styles.acceptDrop : null,
            isDragReject ? styles.rejectDrop : null,
          ]}
        >
          <List sx={styles.list}>
            {acceptedFiles.map((file) => (
              <ListItem
                key={file.name}
                sx={{p: 0, m: 0}}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => onRemoveAcceptedFile(file.name)}
                  >
                    <CloseOutlinedIcon fontSize="small" />
                  </IconButton>
                }
              >
                <ListItemIcon>
                  <FolderIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 1,
                  }}
                  primary={file.name}
                  secondary={formatByteSize(file.size, 2)}
                  secondaryTypographyProps={{sx: {mr: 5, minWidth: 'max-content'}}} // Offset the delete icon
                />
              </ListItem>
            ))}
            {rejectedFiles.map(({file, errors}) => (
              <ListItem
                key={file.name}
                sx={{p: 0, m: 0}}
                secondaryAction={
                  <Box mt={0.5}>
                    <WarningAmberOutlinedIcon color="error" fontSize="small" />
                  </Box>
                }
              >
                <ListItemIcon>
                  <AttachFileIcon fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 1,
                  }}
                  primary={file.name}
                  primaryTypographyProps={{sx: {color: (theme) => theme.palette.error.main}}}
                  secondary={
                    errors[0].code === ErrorCode.FileInvalidType
                      ? 'Unsupported file format'
                      : errors[0].message
                  }
                  secondaryTypographyProps={{
                    sx: {
                      minWidth: 'max-content',
                      mr: 5,
                      color: (theme) => theme.palette.error.main,
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Card>
        {hasTooManyFiles ? (
          <RowWithIcon icon={<WarningAmberOutlinedIcon color="error" />}>
            <Typography color="error">
              More than {maxFiles} files selected. Remove files to continue.
            </Typography>
          </RowWithIcon>
        ) : (
          <Button
            variant="text"
            color="secondary"
            onClick={onAddFiles}
            startIcon={<AddIcon />}
            disabled={acceptedFiles.length === maxFiles}
          >
            Add more files
          </Button>
        )}
      </Box>
      <Box sx={styles.actions}>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleImport}
          disabled={loading || hasTooManyFiles || !acceptedFiles.length}
        >
          Import
        </Button>
      </Box>
    </BaseModal>
  );
}
