import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import {useCallback} from 'react';
import Tooltip from '../Tooltip';
import Menu, {type MenuProps} from './Menu';
import {type Item} from './types';

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '10px',
    my: 1,
    ml: 1,
    pl: 1,
  },
};

interface Props {
  title?: string;
  items: Item[];
  selectedItemId?: string;
  titleAction?: React.ReactNode;
  onViewAll?: () => void;
}

export type SelectorMenuProps = Props & Omit<MenuProps, 'children'>;

function SelectorItem({
  item: {name, icon, onClick, color, tooltip},
  selected,
  onClose,
}: {
  item: Item;
  selected: boolean;
  onClose: () => void;
}) {
  const handleClick = useCallback(
    (evt: React.MouseEvent) => {
      evt.stopPropagation();
      onClick?.();
      onClose();
    },
    [onClose, onClick],
  );

  return (
    <Tooltip title={tooltip}>
      <MenuItem selected={selected} disabled={!onClick} autoFocus={selected} onClick={handleClick}>
        {icon ? <ListItemIcon sx={{mr: -0.5}}>{icon}</ListItemIcon> : null}
        <Typography variant="inherit" noWrap color={color}>
          {name}
        </Typography>
      </MenuItem>
    </Tooltip>
  );
}

function SelectorMenu({
  title,
  items,
  selectedItemId,
  onViewAll,
  titleAction,
  ...props
}: SelectorMenuProps) {
  const handleViewAll = useCallback(
    (evt: React.MouseEvent) => {
      evt.stopPropagation();
      onViewAll?.();
      props.onClose();
    },
    [props, onViewAll],
  );

  return (
    <Menu {...props}>
      {title && (
        <Box sx={styles.header}>
          <Typography variant="overline" sx={styles.title}>
            {title}
          </Typography>
          {titleAction}
        </Box>
      )}

      {items.map((item: Item) => (
        <SelectorItem
          key={item.id}
          selected={item.id === selectedItemId}
          item={item}
          onClose={props.onClose}
        />
      ))}
      {onViewAll ? <Divider /> : null}
      {onViewAll ? <MenuItem onClick={handleViewAll}>View all</MenuItem> : null}
    </Menu>
  );
}

export default SelectorMenu;
