import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {type Theme} from '@mui/material/styles';
import {useEffect, useRef} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {getAssistant} from '../../../actions/assistantActions';
import {listAssistantFiles} from '../../../actions/assistantFilesActions';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {AssistantPagePanel, ProjectPage} from '../../../constants';
import {useNavigateToAssistant, useNavigateToProjectPageGlobal} from '../../../hooks/navigation';
import {useAssistantDataApi, useControllerApi} from '../../../hooks/use-api';
import {useBooleanState} from '../../../hooks/utils';
import {
  useAssistantFiles,
  useAssistantFilesAreLoading,
  useAssistantsAreLoading,
  useSelectedAssistant,
} from '../../../selectors/assistants';
import {useOrganizationsAreLoading} from '../../../selectors/organizations';
import {useSelectedAssistantName} from '../../../selectors/params';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import AssistantChat from './AssistantChat/AssistantChat';
import AssistantDrawer, {DRAWER_WIDTH} from './AssistantDrawer/AssistantDrawer';
import {DRAWER_PANEL_HEADER_HEIGHT} from './AssistantDrawer/DrawerPanel/DrawerPanel';
import AssistantDrawerControls from './AssistantDrawerControls/AssistantDrawerControls';
import AssistantSelector from './AssistantSelector/AssistantSelector';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    pb: 2,
    backgroundColor: 'background.paper',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 1,
    borderBottom: 1,
    borderColor: 'divider',
    // Ensures the header is always the same height as the drawer panel header
    minHeight: DRAWER_PANEL_HEADER_HEIGHT,
    px: 3,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  actions: (hide: boolean) => ({
    overflow: 'hidden',
    width: hide ? 0 : 'auto',
    opacity: hide ? 0 : 1,
    transition: !hide
      ? (theme: Theme) =>
          theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
            delay: theme.transitions.duration.enteringScreen,
          })
      : 'none',
  }),
};

export default function AssistantPage() {
  const project = useSelectedGlobalProject() ?? {};
  const orgsLoading = useOrganizationsAreLoading();
  const selectedAssistantName = useSelectedAssistantName() || '';
  const selectedAssistant = useSelectedAssistant();
  const assistantsAreLoading = useAssistantsAreLoading(project.id);
  const filesAreLoading = useAssistantFilesAreLoading(selectedAssistantName);
  const files = useAssistantFiles(selectedAssistantName);
  const {getPath: getProjectPage} = useNavigateToProjectPageGlobal(project.organization_id);
  const {go: goToAssistant} = useNavigateToAssistant(project.organization_id, project.id);
  const hasCheckedForFiles = useRef('');
  const onFilesPanel = useLocation().pathname.includes(`/${AssistantPagePanel.FILES}`);

  useEffect(() => {
    if (!filesAreLoading && hasCheckedForFiles.current !== selectedAssistantName) {
      hasCheckedForFiles.current = selectedAssistantName;
      if (!onFilesPanel && files.length === 0) {
        goToAssistant(selectedAssistantName, AssistantPagePanel.FILES);
      }
    }
  }, [onFilesPanel, filesAreLoading, files.length, goToAssistant, selectedAssistantName]);

  const {setQueryData: queryAssistant} = useControllerApi<{
    globalProjectId: string;
    assistantName: string;
  }>(project);

  const {setQueryData: queryFiles} = useAssistantDataApi<{assistantName: string}>(project.id);

  useEffect(() => {
    queryAssistant({
      action: getAssistant,
      data: {
        globalProjectId: project.id,
        assistantName: selectedAssistantName,
      },
    });
    queryFiles({
      action: listAssistantFiles,
      data: {
        assistantName: selectedAssistantName,
      },
    });
  }, [queryAssistant, queryFiles, selectedAssistantName, project.id]);

  const {val: showDrawer, setTrue: openDrawer, setFalse: hideDrawer} = useBooleanState(true);

  if (!assistantsAreLoading && !selectedAssistant) {
    return <Navigate to={getProjectPage(project.id, ProjectPage.ASSISTANTS)} />;
  }
  if (orgsLoading || !selectedAssistant) {
    return <Page loading />;
  }
  return (
    <Page
      header={<PageHeader title={selectedAssistantName} titleHidden />}
      width="fullscreen"
      drawer={{
        width: DRAWER_WIDTH,
        open: showDrawer,
        content: (
          <AssistantDrawer
            key={selectedAssistantName}
            controls={{show: showDrawer, onOpen: openDrawer, onClose: hideDrawer}}
            assistant={selectedAssistant}
          />
        ),
      }}
    >
      <Box sx={styles.root}>
        <Box sx={styles.header}>
          <Box sx={styles.title}>
            <Typography variant="h6" component="h1" noWrap>
              Assistant playground
            </Typography>
            <AssistantSelector
              id="assistant-page"
              projectId={project.id}
              selectedItem={selectedAssistantName}
            />
          </Box>
          <Box sx={styles.actions(showDrawer)}>
            <AssistantDrawerControls
              id="assistant-page"
              controls={{show: showDrawer, onOpen: openDrawer, onClose: hideDrawer}}
              hidden={showDrawer}
            />
          </Box>
        </Box>
        <AssistantChat
          globalProjectId={project.id}
          assistantName={selectedAssistantName || ''}
          hasFiles={!!files.length || filesAreLoading}
        />
      </Box>
    </Page>
  );
}
