import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import SelectBoxInput from '@pinecone-experience/timber/Inputs/SelectBoxInput';
import {useMemo} from 'react';
import {type Control} from 'react-hook-form';
import {PUBLIC_DOCS} from '../../../../constants';
import {useIsSelectedOrganizationSubscribed} from '../../../../selectors/organizations';
import {type IndexSchema} from '../utilities/schema';
import {getPodInfo} from './utilities/pod-info';

type SelectPodTypeProps = {
  isFree: boolean;
  control: Control<IndexSchema>;
};

function SelectPodType({isFree, control}: SelectPodTypeProps) {
  const podOptions = useMemo(() => getPodInfo(isFree), [isFree]);
  const isOrgFree = !useIsSelectedOrganizationSubscribed();
  return (
    <Box my={4}>
      <Typography variant="h6" mb={1}>
        Select your Pod Type
      </Typography>
      <Typography mb={2}>
        {`Pick a pod type that aligns with your project's needs. Learn more in our `}
        <Link href={PUBLIC_DOCS.CHOOSING_INDEX_TYPE_AND_SIZE} target="_blank" rel="noreferrer">
          docs
        </Link>
        .
      </Typography>
      <SelectBoxInput
        name="podType"
        control={control}
        options={podOptions}
        layout="grid"
        gridMinWidth={150}
      />
      {isFree && (
        <Typography
          mt={2}
          color="text.secondary"
        >{`* This project is in a free environment. In order to use a different pod type, ${
          isOrgFree ? ' please upgrade and ' : ''
        } create a new project in a paid environment. `}</Typography>
      )}
    </Box>
  );
}

export default SelectPodType;
