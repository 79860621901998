import Brightness7Outlined from '@mui/icons-material/Brightness7Outlined';
import DarkModeOutlined from '@mui/icons-material/DarkModeOutlined';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {useDispatch} from 'react-redux';
import {toggleTheme} from '../../../../../actions/uiActions';
import {useIsDarkTheme} from '../../../../../selectors/ui';

function ThemeSwitcher() {
  const dispatch = useDispatch();
  const {shouldShowThemeToggleExperience} = useFlags();
  const isDarkTheme = useIsDarkTheme();
  if (!shouldShowThemeToggleExperience) {
    return null;
  }
  return (
    <IconButton
      id="toggle-theme"
      ariaLabel="toggle-theme"
      color="secondary"
      icon={
        isDarkTheme ? (
          <Brightness7Outlined fontSize="medium" />
        ) : (
          <DarkModeOutlined fontSize="medium" />
        )
      }
      onClick={() => dispatch(toggleTheme())}
    />
  );
}

export default ThemeSwitcher;
