import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useMemo} from 'react';
import {useForm} from 'react-hook-form';
import VectorFormControls from '../VectorFormControls/VectorFormControls';
import {createUpsertSchema} from '../utilities/schema';
import {type UpsertSchemaType} from '../utilities/schema';
import {type IndexExplorerState, useDoUpsert} from '../utilities/utils';

type AddRecordPageProps = {
  indexExplorerState: IndexExplorerState;
};

function AddRecordPage({indexExplorerState}: AddRecordPageProps) {
  const {project, host, dimensions, service} = indexExplorerState;
  const schema = useMemo(() => createUpsertSchema(dimensions), [dimensions]);
  const upsertFormState = useForm<UpsertSchemaType>({
    defaultValues: {
      id: '',
      vector: [],
      metadata: [],
      namespace: '',
    },
    resolver: zodResolver(schema),
  });
  const {reset, handleSubmit} = upsertFormState;
  const onSubmit = useDoUpsert(project, service, host, () => reset());
  return (
    <Box
      sx={{
        maxWidth: 550,
        borderColor: 'divider',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 2,
        p: 2,
        my: 1,
      }}
    >
      <VectorFormControls
        typeName="Upsert"
        indexExplorerState={indexExplorerState}
        allowAutoType
        upsertFormState={upsertFormState}
      />
      <Box py={2} display="flex" gap={1}>
        <Button onClick={handleSubmit(onSubmit)} variant="contained">
          Upsert
        </Button>
        <Button onClick={() => reset()} color="secondary">
          Reset
        </Button>
      </Box>
    </Box>
  );
}

export default AddRecordPage;
