import {type ButtonProps} from '@mui/material/Button';
import CreateButton from '../../../../components/CreateButton';
import {useNavigateToCreateIndexGlobal} from '../../../../hooks/navigation';
import {useGlobalProjectIsCreating, useSelectedGlobalProject} from '../../../../selectors/projects';

type CreateIndexButtonProps = {
  text?: string;
  customId?: string;
  variant?: ButtonProps['variant'];
  includePlus?: boolean;
  color?: ButtonProps['color'];
};

function CreateIndexButton({
  text = 'Create index',
  customId = 'create-index-button',
  variant = 'contained',
  includePlus = false,
  color = 'primary',
}: CreateIndexButtonProps) {
  const selectedProject = useSelectedGlobalProject();
  const isCreating = useGlobalProjectIsCreating(selectedProject.id);
  const {go: goToIndexCreationPage} = useNavigateToCreateIndexGlobal(
    selectedProject.organization_id,
    selectedProject.id,
  );
  return (
    <CreateButton
      onClick={goToIndexCreationPage}
      text={text}
      customId={customId}
      variant={variant}
      includePlus={includePlus}
      color={color}
      disabled={isCreating}
    />
  );
}

export default CreateIndexButton;
