import Box from '@mui/material/Box';
import CSharpIcon from '../../icons/languages/c_sharp.svg';
import GoIcon from '../../icons/languages/go.svg';
import JavaIcon from '../../icons/languages/java.svg';
import JavascriptIcon from '../../icons/languages/javascript.svg';
import OtherLanguageIcon from '../../icons/languages/other.svg';
import PythonIcon from '../../icons/languages/python.svg';
import {getAssetUrl} from '../../utils/assets';
import {shuffleArray} from '../../utils/data';
import {HEAP_TRACKING_EVENTS} from '../../utils/tracking';
import {RegistrationSteps} from './utilities/RegistrationFormContext';

export const RegistrationStepMap = {
  [RegistrationSteps.USER_INFORMATION]: {
    fields: ['firstName', 'lastName', 'purpose', 'company', 'language'],
    marketingImage: getAssetUrl('registration/notion_testimonial.png'),
    heapViewedEvent: HEAP_TRACKING_EVENTS.REGISTRATION_VIEWED_STEP_USER_INFORMATION,
    heapSubmittedEvent: HEAP_TRACKING_EVENTS.REGISTRATION_SUBMITTED_STEP_USER_INFORMATION,
  },
  [RegistrationSteps.USE_CASES]: {
    fields: ['useCase', 'numDocs'],
    marketingImage: getAssetUrl('registration/gong_testimonial.png'),
    heapViewedEvent: HEAP_TRACKING_EVENTS.REGISTRATION_VIEWED_STEP_USE_CASES,
    heapSubmittedEvent: HEAP_TRACKING_EVENTS.REGISTRATION_SUBMITTED_STEP_USE_CASES,
  },
  [RegistrationSteps.EMBEDDINGS]: {
    fields: ['hasEmbeddingModel'],
    marketingImage: getAssetUrl('registration/frontier_meds_testimonial.png'),
    heapViewedEvent: HEAP_TRACKING_EVENTS.REGISTRATION_VIEWED_STEP_EMBEDDINGS,
    // As this is the last step, the submitted event will not be triggered.
    // Instead, the form submission event will be tracked.
    heapSubmittedEvent: HEAP_TRACKING_EVENTS.REGISTRATION_SUBMITTED_STEP_EMBEDDINGS,
  },
};

export const LanguageOptions = [
  ...shuffleArray([
    {
      value: 'Python',
      icon: <Box component="img" src={PythonIcon} sx={{mt: 0.25}} />,
    },
    {
      value: 'JavaScript',
      icon: <Box component="img" src={JavascriptIcon} sx={{mt: 0.25}} />,
    },
    {
      value: 'Java',
      icon: <Box component="img" src={JavaIcon} sx={{mt: 0.25}} />,
    },
    {
      value: 'C#',
      icon: <Box component="img" src={CSharpIcon} sx={{mt: 0.25}} />,
    },
    {
      value: 'Go',
      icon: <Box component="img" src={GoIcon} sx={{mt: 0.25}} />,
    },
  ]),
  {
    value: `I don't code`,
  },
  {
    value: '',
    label: 'Other',
    icon: <Box component="img" src={OtherLanguageIcon} sx={{mt: 0.25}} />,
  },
];

export enum UseCases {
  QA_CHAT = 'Q&A Chat',
  AI_AGENTS = 'AI Agents',
  SEMANTIC_SEARCH = 'Text/Semantic search',
  MULTI_MODAL_SEARCH = 'Multimodal search',
  CLASSIFICATION = 'Classification',
  RECOMMENDATIONS = 'Recommendations',
  JUST_EXPLORING = 'Just exploring',
}

export const UseCaseOptions = [
  {
    options: [{value: UseCases.QA_CHAT}, {value: UseCases.AI_AGENTS}],
  },
  {
    options: [{value: UseCases.SEMANTIC_SEARCH}, {value: UseCases.MULTI_MODAL_SEARCH}],
  },
  {
    options: [{value: UseCases.CLASSIFICATION}, {value: UseCases.RECOMMENDATIONS}],
  },
  {
    options: [
      {
        value: '',
        label: 'Other',
      },
      {value: UseCases.JUST_EXPLORING},
    ],
  },
];

export const NumDocsOptions = [
  {
    value: 'Less than 100k',
  },
  {
    value: '100k - 1m',
  },
  {
    value: '1m - 10m',
  },
  {
    value: '10m - 100m',
  },
  {
    value: '100m or more',
  },
  {
    value: 'Unsure',
  },
];

export enum HasEmbeddingModel {
  YES = 'I have an embedding model',
  DECIDING = `I'm still evaluating models`,
  WHAT_ARE_EMBEDDINGS = `I'm new to vector embeddings`,
  USING_ASSISTANT = `I'm using Pinecone Assistant`,
}

export const HasEmbeddingModelOptions = [
  {
    divider: true,
    options: [
      {
        value: HasEmbeddingModel.YES,
      },
      {
        value: HasEmbeddingModel.DECIDING,
      },
      {
        value: HasEmbeddingModel.WHAT_ARE_EMBEDDINGS,
      },
    ],
  },
  {
    options: [
      {
        value: HasEmbeddingModel.USING_ASSISTANT,
      },
    ],
  },
];
