import MuiLinearProgress from '@mui/material/LinearProgress';

const style = {
  progress: (error = false) => ({
    backgroundColor: error ? '' : 'action.selected',
    mt: 0.5,
    height: 6,
    borderRadius: 1,
  }),
};

type LinearProgressProps = {
  value: number;
  error?: boolean;
};

function LinearProgress({value, error}: LinearProgressProps) {
  return (
    <MuiLinearProgress
      color={error ? 'error' : undefined}
      sx={style.progress(error)}
      variant="determinate"
      value={value}
      valueBuffer={0}
    />
  );
}

export default LinearProgress;
