import Box from '@mui/material/Box';
import SupportPlanCard from './SupportPlanCard/SupportPlanCard';
import {SUPPORT_PLANS} from './constants/supportPlans';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  plansContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: 3,
  },
};

function SupportPlans({showPlanInfo = true}: {showPlanInfo?: boolean}) {
  return (
    <Box sx={styles.container} id="support-plans">
      <Box sx={styles.plansContainer}>
        {SUPPORT_PLANS.map((supportPlan) => (
          <SupportPlanCard
            showPlanInfo={showPlanInfo}
            supportPlan={supportPlan}
            key={supportPlan.tier}
          />
        ))}
      </Box>
    </Box>
  );
}

export default SupportPlans;
