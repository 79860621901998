import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ThemeAwarePineconeLogo from '../../../../../components/Utilities/ThemeAwarePineconeLogo';
import {PlanNameMapping, type Plans} from '../../../../../constants';
import {useSelectedOrganizationPlan} from '../../../../../selectors/organizations';

const styles = {
  card: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 10,
  },
  logo: {
    height: 50,
    m: 3,
  },
};

const SpecialPlanCard = () => {
  const currentPlan = useSelectedOrganizationPlan();
  const mappedPlan = PlanNameMapping[currentPlan as Plans];

  return (
    <Paper sx={styles.card}>
      <ThemeAwarePineconeLogo sx={{height: 50, m: 3}} />
      <Typography variant="h6">{`You are currently on the ${mappedPlan} Plan`}</Typography>
      <Typography m={2} color="text.secondary">
        Contact <Link href="mailto:support@pinecone.io">support@pinecone.io</Link> for changes or
        billing questions.
      </Typography>
    </Paper>
  );
};

export default SpecialPlanCard;
