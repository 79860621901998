import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FiberSmartRecordOutlinedIcon from '@mui/icons-material/FiberSmartRecordOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import {type PopperPlacementType} from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import Menu from '@pinecone-experience/timber/Menus/Menu';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import {useRef} from 'react';
import {DeletionProtection, IndexState} from '../../../constants';
import {Flags, useFlag} from '../../../hooks/flags';
import {useNavigateToBackupDetailsPage} from '../../../hooks/navigation';
import {useCanCreateCollections} from '../../../hooks/specUtils';
import {useBooleanState} from '../../../hooks/utils';
import {useIndexStatsByGlobalProject} from '../../../selectors/explorer';
import {useIsSelectedOrganizationSubscribed} from '../../../selectors/organizations';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import {type V4IndexInfoResponse} from '../../../types';
import {canBackup, canImport, canMigrate} from '../../../utils/services';
import ServerlessMigrationSingleIndexModal from '../../ServerlessMigration/ServerlessMigrationSingleIndexModal';
import {MIGRATION_DOCS_URL} from '../../ServerlessMigration/constants';
import StartBulkImportModal from '../../StartBulkImportModal/StartBulkImportModal';
import CreateBackupForm from '../../backups/CreateBackupForm/CreateBackupForm';
import ConfigureIndexForm from './ConfigureIndexForm/ConfigureIndexForm';
import CreateCollectionFromIndexForm from './CreateCollectionFromIndexForm/CreateCollectionFromIndexForm';
import DeleteIndexConfirmationForm from './DeleteIndexConfirmationForm/DeleteIndexConfirmationForm';

const styles = {
  link: {
    cursor: 'pointer',
  },
};

interface IndexActionsProps {
  service: string;
  serviceInfo?: V4IndexInfoResponse;
  placement?: PopperPlacementType;
}
function IndexActions({service, serviceInfo, placement = 'left-start'}: IndexActionsProps) {
  const showCollections = useCanCreateCollections(serviceInfo);
  const isPaid = useIsSelectedOrganizationSubscribed();
  const showBackups = canBackup(serviceInfo) && isPaid;
  const project = useSelectedGlobalProject();
  const description = useIndexStatsByGlobalProject(project?.id, service);
  const showMigrate = canMigrate(serviceInfo, description);
  const showBulkImport = useFlag(Flags.SHOW_BULK_IMPORT) && canImport(serviceInfo);

  const indexActionMenuAnchor = useRef(null);
  const {setTrue: showMenu, setFalse: closeMenu, val: menu} = useBooleanState();

  const {
    val: showImportForm,
    setTrue: setOpenImportForm,
    setFalse: setCloseImportForm,
  } = useBooleanState();

  const {
    val: showCollectionForm,
    setTrue: setOpenCollectionForm,
    setFalse: setCloseCollectionForm,
  } = useBooleanState();

  const {
    val: showConfigureIndexForm,
    setTrue: setOpenConfigureIndexForm,
    setFalse: setCloseConfigureIndexForm,
  } = useBooleanState();

  const {
    val: showDeleteForm,
    setTrue: setOpenDeleteForm,
    setFalse: setCloseDeleteForm,
  } = useBooleanState();

  const {
    val: showMigrationForm,
    setTrue: setOpenMigrationForm,
    setFalse: setCloseMigrationForm,
  } = useBooleanState();

  const {
    val: showBackupForm,
    setTrue: setOpenBackupForm,
    setFalse: setCloseBackupForm,
  } = useBooleanState();

  const isDeleteProtected = serviceInfo?.deletion_protection === DeletionProtection.ENABLED;
  const {go: goToBackups} = useNavigateToBackupDetailsPage(project.organization_id, project.id);
  return (
    <Box ref={indexActionMenuAnchor}>
      <IconButton
        id="index-actions-menu"
        color="secondary"
        icon={<MoreHorizIcon />}
        onClick={menu ? closeMenu : showMenu}
        ariaLabel="index-actions-menu"
      />
      <Menu
        id="index-actions-menu"
        open={menu}
        anchor={indexActionMenuAnchor.current}
        onClose={closeMenu}
        placement={placement as PopperPlacementType}
      >
        {showBulkImport && (
          <MenuItem
            id="index-import-data"
            onClick={(event) => {
              event.stopPropagation();
              setOpenImportForm();
              closeMenu();
            }}
          >
            <ListItemIcon>
              <FileDownloadOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Import data
          </MenuItem>
        )}
        {showCollections && (
          <MenuItem
            id="index-create-collection"
            onClick={(event) => {
              event.stopPropagation();
              setOpenCollectionForm();
              closeMenu();
            }}
            disabled={serviceInfo?.status.state !== IndexState.READY}
          >
            <ListItemIcon>
              <FiberSmartRecordOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Collection
          </MenuItem>
        )}
        {showBackups && (
          <>
            <MenuItem
              id="index-create-backup"
              onClick={(event) => {
                event.stopPropagation();
                setOpenBackupForm();
                closeMenu();
              }}
              disabled={serviceInfo?.status.state !== IndexState.READY}
            >
              <ListItemIcon>
                <FiberSmartRecordOutlinedIcon fontSize="small" />
              </ListItemIcon>
              Create a backup
            </MenuItem>
            <MenuItem
              id="index-view-backups"
              onClick={(event) => {
                event.stopPropagation();
                goToBackups(serviceInfo?.id || '');
              }}
            >
              <ListItemIcon>
                <RemoveRedEyeIcon />
              </ListItemIcon>
              View backups
            </MenuItem>
          </>
        )}
        <MenuItem
          id="index-configure"
          onClick={(event) => {
            event.stopPropagation();
            setOpenConfigureIndexForm();
            closeMenu();
          }}
          disabled={serviceInfo?.status.state === IndexState.FAILED}
        >
          <ListItemIcon>
            <SettingsOutlined fontSize="small" />
          </ListItemIcon>
          Configure
        </MenuItem>
        <Tooltip
          title={
            isDeleteProtected
              ? 'Deletion protection is enabled. To delete this index, first disable deletion protection in the configure settings.'
              : ''
          }
        >
          <MenuItem
            id="index-delete"
            onClick={(event) => {
              event.stopPropagation();
              setOpenDeleteForm();
              closeMenu();
            }}
            disabled={isDeleteProtected}
          >
            <ListItemIcon>
              <DeleteOutlinedIcon fontSize="small" />
            </ListItemIcon>
            {isDeleteProtected ? 'Delete protected' : 'Delete'}
          </MenuItem>
        </Tooltip>
        {showMigrate && [
          <Divider key="divider" />,
          <MenuItem
            key="migrate"
            id="index-migrate"
            onClick={(event) => {
              event.stopPropagation();
              setOpenMigrationForm();
              closeMenu();
            }}
          >
            <ListItemIcon>
              <CompareArrowsIcon fontSize="small" />
            </ListItemIcon>
            Migrate to serverless
            <Tooltip
              title={
                <Typography>
                  Migrate to serverless for cost efficiency, scalability and ease of management.{' '}
                  <Link href={MIGRATION_DOCS_URL} target="_blank" rel="noreferrer" sx={styles.link}>
                    Learn more
                  </Link>
                </Typography>
              }
            >
              <InfoOutlinedIcon
                color="secondary"
                fontSize="small"
                sx={{position: 'relative', top: 2}}
              />
            </Tooltip>
          </MenuItem>,
        ]}
      </Menu>
      <StartBulkImportModal
        selectedIndexName={service}
        open={showImportForm}
        close={setCloseImportForm}
      />
      <ConfigureIndexForm
        service={service}
        open={showConfigureIndexForm}
        close={setCloseConfigureIndexForm}
      />
      <CreateCollectionFromIndexForm
        service={service}
        open={showCollectionForm}
        close={setCloseCollectionForm}
      />
      <DeleteIndexConfirmationForm
        service={service}
        open={showDeleteForm}
        close={setCloseDeleteForm}
      />
      <ServerlessMigrationSingleIndexModal
        service={service}
        open={showMigrationForm}
        close={setCloseMigrationForm}
      />
      {serviceInfo && (
        <CreateBackupForm
          open={showBackupForm}
          close={setCloseBackupForm}
          sourceIndex={serviceInfo}
        />
      )}
    </Box>
  );
}

export default IndexActions;
