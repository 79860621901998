import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import {useState} from 'react';
import ModalForm from '../../../../../components/ModalForm';
import {type SsoConnectionConfig, type SsoConnectionRule} from '../../../../../types';
import SsoConfigForm from './SsoConfigForm/SsoConfigForm';
import SsoConfigInfo from './SsoConfigInfo/SsoConfigInfo';
import SsoConfigRules from './SsoConfigRules/SsoConfigRules';

type SsoFormProps = {
  open: boolean;
  close: () => void;
  orgId: string;
  data?: {
    connection?: SsoConnectionConfig;
    rules?: SsoConnectionRule[];
  };
  loading: boolean;
};

const Steps = [
  {
    label: 'IdP Details',
  },
  {
    label: 'SSO Keys',
  },
  {
    label: 'Manage Access',
  },
];

function SsoForm({open, close, data, orgId, loading}: SsoFormProps) {
  const [activeStep, setActiveStep] = useState(0);
  const handleClick = (index: number) => {
    setActiveStep(index);
  };
  return (
    <ModalForm
      open={open}
      close={close}
      form={
        <Box>
          <Box>
            <Stepper nonLinear={!!data?.connection} activeStep={activeStep}>
              {Steps.map((step, index) => (
                <Step key={step.label}>
                  <StepButton onClick={() => handleClick(index)}>
                    <StepLabel>{step.label}</StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <SsoConfigForm
                orgId={orgId}
                connection={data?.connection}
                loading={loading}
                goNext={() => setActiveStep(1)}
              />
            )}
            {activeStep === 1 && (
              <SsoConfigInfo
                close={close}
                goNext={() => setActiveStep(2)}
                connection={data?.connection}
              />
            )}
            {activeStep === 2 && <SsoConfigRules orgId={orgId} rules={data?.rules} />}
          </Box>
        </Box>
      }
      title="Configure your SSO"
      isValid
      onSubmit={() => {}}
      submitText="Submit"
      includeSubmit={false}
    />
  );
}

export default SsoForm;
