import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useRef} from 'react';
import {Link} from 'react-router-dom';
import {OrgSettingsPage, PUBLIC_DOCS_URL} from '../../../../constants';
import {useNavigateToOrgSettingsPage} from '../../../../hooks/navigation';
import {useBooleanState} from '../../../../hooks/utils';
import {useSelectedOrganization} from '../../../../selectors/organizations';
import {useSelectedGlobalProject} from '../../../../selectors/projects';
import {APP_BAR_HEIGHT} from '../../../../styles/theme';
import ThemeAwarePineconeLogo from '../../../Utilities/ThemeAwarePineconeLogo';
import {useActiveProjectNavSection} from '../hooks/projectNavSections';
import FeedbackModal from './FeedbackModal/FeedbackModal';
import GetHelpMenu from './GetHelpMenu/GetHelpMenu';
import {NavButtonStyles} from './NavButton/NavButton';
import OrganizationSelector from './OrganizationSelector/OrganizationSelector';
import ProjectSelector from './ProjectSelector/ProjectSelector';
import ThemeSwitcher from './ThemeSwitcher/ThemeSwitcher';
import UserMenu from './UserMenu/UserMenu';

const styles = {
  root: {
    height: APP_BAR_HEIGHT,
    width: '100%',
    gridArea: 'appBar',
    borderBottom: 1,
    borderColor: 'divider',
    backgroundColor: 'background.paper',
  },
  content: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2,
    px: 3,
  },
  logo: {
    mb: 0.5,
    mr: 0.5,
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  divider: {
    color: 'text.secondary',
    mb: '1px',
  },
  breadcrumb: {
    display: 'grid', // Ensures width of element is set by parent's flexbox.
  },
  section: {
    ml: 0.5,
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: 0,
  },
  externalLinks: {
    mx: 2,
  },
  navButton: {
    flexShrink: 0,
    px: 2,
  },
};

function AppBar() {
  const selectedOrg = useSelectedOrganization();
  const selectedProject = useSelectedGlobalProject();
  const {val: openFeedback, setTrue: setOpenFeedback, setFalse: closeFeedback} = useBooleanState();
  const feedbackAnchor = useRef(null);
  const goToOrgSettings = useNavigateToOrgSettingsPage(selectedOrg?.id);
  const activeProjectNavSection = useActiveProjectNavSection(selectedProject);

  return (
    <Box sx={styles.root} component="header">
      <Box sx={styles.content}>
        <Box sx={styles.breadcrumbs}>
          <Box sx={styles.logo}>
            <Link to="/">
              <ThemeAwarePineconeLogo fullLogo sx={{height: 21}} />
            </Link>
          </Box>
          <Typography sx={styles.divider}>/</Typography>
          <Box sx={styles.breadcrumb}>
            <OrganizationSelector />
          </Box>
          {!!selectedOrg && (
            <>
              <Typography sx={styles.divider}>/</Typography>
              <Box sx={styles.breadcrumb}>
                <ProjectSelector />
              </Box>
            </>
          )}
          {activeProjectNavSection && (
            <>
              <Typography sx={styles.divider}>/</Typography>
              <Box sx={styles.breadcrumb}>
                <Box sx={styles.section}>
                  {activeProjectNavSection.icon}
                  <Typography noWrap sx={NavButtonStyles}>
                    {activeProjectNavSection.title}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box sx={styles.actions}>
          <Box sx={[styles.actions, styles.externalLinks]}>
            <Button href={PUBLIC_DOCS_URL} target="_blank" sx={[NavButtonStyles, styles.navButton]}>
              Docs
            </Button>
            <Button
              onClick={() => goToOrgSettings(OrgSettingsPage.USAGE)}
              sx={[NavButtonStyles, styles.navButton]}
            >
              Settings
            </Button>
            <Button
              onClick={setOpenFeedback}
              ref={feedbackAnchor}
              sx={[NavButtonStyles, styles.navButton]}
            >
              Feedback
            </Button>
            <FeedbackModal
              open={openFeedback}
              close={closeFeedback}
              anchor={feedbackAnchor.current}
              placement="bottom-end"
            />
            <GetHelpMenu />
          </Box>
          <ThemeSwitcher />
          <UserMenu />
        </Box>
      </Box>
    </Box>
  );
}

export default AppBar;
