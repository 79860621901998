import {useTheme} from '@mui/material/styles';
import {DataVisColors} from '@pinecone-experience/theme/colors';
import {differenceInHours} from 'date-fns';
import {format} from 'date-fns/format';
import {useCallback, useMemo} from 'react';
import {type PrometheusLegacyResponse} from '../types';

export const useNivoTheme = () => {
  const theme = useTheme();
  return {
    primary: theme.palette.primary.main,
    textColor: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    grid: {
      line: {
        stroke: theme.palette.divider,
        strokeWidth: 1.25,
      },
    },
    axis: {
      ticks: {
        text: {
          fill: theme.palette.text.primary,
        },
      },
    },
    crosshair: {
      line: {
        stroke: theme.palette.text.secondary,
      },
    },
  };
};

export const MS_PER_DAY = 1000 * 60 * 60 * 24;

export type RequestType = {
  name: string;
  color: string;
};

export const requestTypes = [
  {name: 'query', color: DataVisColors.DARK_BLUE},
  {name: 'upsert', color: DataVisColors.AQUA},
  {name: 'update', color: DataVisColors.PURPLE},
  {name: 'delete', color: DataVisColors.ORANGE},
  {name: 'fetch', color: DataVisColors.GREEN},
];

export function prometheusResponseParser(data: PrometheusLegacyResponse) {
  const uniqueValues = new Set<string>();
  data.forEach((serie) => {
    const points = serie?.data || [];
    points.forEach((point) => {
      uniqueValues.add(point.x);
    });
  });
  return Array.from(uniqueValues);
}

export function useFormatDateTick({
  formattedData,
  parser = prometheusResponseParser,
}: {
  formattedData: any[];
  parser?: (data: any[]) => string[];
}) {
  const {hours, sorted} = useMemo(() => {
    const dates = parser(formattedData);
    const sortedDates = dates.sort((a, b) => Number(new Date(a)) - Number(new Date(b)));
    const startTimestamp = new Date(sortedDates[0]);
    const endTimestamp = new Date(sortedDates[sortedDates.length - 1]);
    return {
      sorted: sortedDates,
      hours: differenceInHours(endTimestamp, startTimestamp),
    };
  }, [parser, formattedData]);

  return useCallback(
    (date: string) => {
      // Handles empty data
      if (sorted.length === 0) {
        return () => '';
      }
      function formatTick(dateString: string) {
        let formatter = 'h:mm aa';
        if (hours > 11) {
          formatter = 'h aa';
          if (hours > 48) {
            formatter = 'M/d';
          }
        }
        return format(new Date(dateString), formatter);
      }
      return formatTick(date);
    },
    [hours, sorted.length],
  );
}

export function useFilterDataByRequestType({
  data,
  selectedRequestTypes,
}: {
  data: PrometheusLegacyResponse;
  selectedRequestTypes: {name: string}[];
}) {
  const selectedRequestTypeNames = selectedRequestTypes.map((type) => type.name);
  return useMemo(() => {
    const filteredData: PrometheusLegacyResponse = [];
    selectedRequestTypeNames.forEach((name) => {
      const series = data.find((d) => d.id === name);
      if (series) {
        filteredData.push(series);
      } else {
        filteredData.push({
          id: name,
          data: [],
        });
      }
    });
    return filteredData;
  }, [data, selectedRequestTypeNames]);
}

export function useGetYAxisLabel(formattedData: {id: string; data: {y: number}[]}[]) {
  return useMemo(() => {
    let maxValue = 0.8;
    if (formattedData.length > 0) {
      formattedData.forEach((series) => {
        series?.data?.forEach((point) => {
          if (point.y > maxValue) {
            maxValue = point.y;
          }
          if (maxValue === 0.8 && point.y > 0) {
            maxValue = point.y;
          }
        });
      });
    }
    return {yAxisMin: 0, yAxisMax: maxValue * 1.25};
  }, [formattedData]);
}
