import {matchPath} from 'react-router-dom';

export function matchesCurrentPath(
  locationPath: string,
  path: string,
  pathMatch?: string,
): boolean {
  if (pathMatch) {
    return !!matchPath(pathMatch, locationPath);
  }

  return !!matchPath(`${path}/*`, locationPath);
}
