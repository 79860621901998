import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import {type Theme} from '@mui/material/styles';
import {useEffect} from 'react';
import {getFreeTierDowngradeEligibility} from '../../actions/freeTierDowngradeEligibilityActions';
import {PlanNameMapping, Plans} from '../../constants';
import {useDashboardApi} from '../../hooks/use-api';
import {useBooleanState} from '../../hooks/utils';
import {useSelectedOrganizationId} from '../../selectors/params';
import {CONTENT_PADDING} from '../../styles/theme';
import BillingPlanAttribute from './BillingPlanAttribute/BillingPlanAttribute';
import BillingPlanProduct from './BillingPlanProduct/BillingPlanProduct';
import ChangePlanButton from './ChangePlanButton/ChangePlanButton';
import {BILLING_PLANS} from './constants/billingPlans';
import {BillingPlanAttributes, BillingPlanProducts} from './types/billingPlans.interface';

const styles = {
  columns: (planCount: number) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${planCount + 1}, 1fr)`,
  }),
  spanColumns: {
    gridColumn: '1/-1',
    display: 'grid',
    gridTemplateColumns: 'subgrid',
  },
  stickyHeader: {
    // Negative margin and padding allow for spacing above the sticky header
    // that has a background color.
    mt: -3,
    pt: 3,
    position: 'sticky',
    top: (theme: Theme) => `calc(-1 * ${theme.spacing(CONTENT_PADDING)})`,
    zIndex: (theme: Theme) => theme.zIndex.drawer,
    backgroundColor: 'background.default',
  },
  plansHeader: {
    backgroundColor: 'background.paper',
    gridTemplateRows: 'repeat(4, auto)',
    border: 1,
    borderColor: 'divider',
    borderRadius: (theme: Theme) =>
      `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    boxShadow: 8,
  },
  headerCell: {
    display: 'grid',
    gap: 2,
    gridTemplateRows: 'subgrid',
  },
  planTier: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 1,
  },
  plansContent: {
    backgroundColor: 'background.paper',
    border: 1,
    borderTop: 0,
    borderColor: 'divider',
    borderRadius: (theme: Theme) =>
      `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
  },
  row: {
    borderBottom: 1,
    borderColor: 'divider',
    '&:last-of-type': {
      borderBottom: 0,
    },
  },
  cell: {
    p: 3,
    borderLeft: 1,
    borderColor: 'divider',
    gridRow: '1/-1',
    '&:first-of-type': {
      borderLeft: 0,
    },
  },
  planDetails: {
    position: 'relative',
  },
  planDetailsButton: {
    gridColumn: '1/-1',
    my: 3,
    py: 2,
    borderColor: 'divider',
  },
  collapse: {
    '& > *': {
      gridColumn: '1/-1',
      display: 'grid',
      gridTemplateColumns: 'subgrid',
      '& > *': {
        gridColumn: '1/-1',
        display: 'grid',
        gridTemplateColumns: 'subgrid',
      },
    },
  },
  planProducts: {
    backgroundColor: 'background.paper',
    border: 1,
    borderColor: 'divider',
    borderRadius: 1,
    borderBottom: 0,
    mb: 16,
  },
};

function BillingPlans() {
  const selectedOrgId = useSelectedOrganizationId() || '';
  const {val: showPlanDetails, toggleVal: togglePlanDetails} = useBooleanState(false);

  const {setQueryData: setGetFreeTierDowngradeEligibilityData} = useDashboardApi<{
    organizationId: string;
  }>();

  useEffect(() => {
    if (selectedOrgId) {
      setGetFreeTierDowngradeEligibilityData({
        action: getFreeTierDowngradeEligibility,
        data: {
          organizationId: selectedOrgId,
        },
      });
    }
    // Run this only when the component is mounted.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.columns(BILLING_PLANS.length)} id="billing-plans">
      <Box sx={[styles.spanColumns, styles.stickyHeader]}>
        <Box sx={[styles.spanColumns, styles.plansHeader]}>
          <Box sx={styles.cell}>
            <Typography component="h2" variant="h5" gutterBottom>
              Choose your <br />
              plans and pricing
            </Typography>
            <Typography>
              Try the {PlanNameMapping[Plans.FREE]} plan for free with zero risk or pay-as-you-go
              with {PlanNameMapping[Plans.STANDARD]}. Cancel any time.
            </Typography>
          </Box>
          {BILLING_PLANS.map((plan) => (
            <Box key={plan.tier} sx={[styles.cell, styles.headerCell]}>
              <Box sx={styles.planTier}>
                <Typography variant="h5" component="span">
                  {PlanNameMapping[plan.tier]}
                </Typography>
                {plan.badge}
              </Box>
              <Typography variant="subtitle1" component="span" color="primary.light">
                {plan.price}
              </Typography>
              <Typography>{plan.description}</Typography>
              <ChangePlanButton desiredPlan={plan.tier} fullWidth />
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={[styles.spanColumns, styles.plansContent]}>
        {Object.values(BillingPlanAttributes).map((key) => (
          <BillingPlanAttribute
            key={key}
            attributeKey={key}
            plans={BILLING_PLANS}
            sx={[styles.spanColumns, styles.row]}
            cellSx={styles.cell}
          />
        ))}
      </Box>
      <Box sx={[styles.spanColumns, styles.planDetails]}>
        <Button
          sx={styles.planDetailsButton}
          fullWidth
          onClick={togglePlanDetails}
          color="inherit"
          variant="outlined"
          endIcon={
            showPlanDetails ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />
          }
        >
          Compare plan details
        </Button>
        <Collapse in={showPlanDetails} sx={[styles.spanColumns, styles.collapse]}>
          <Box sx={[styles.spanColumns, styles.planProducts]}>
            {Object.values(BillingPlanProducts).map((key) => (
              <BillingPlanProduct sx={styles.spanColumns} key={key} productKey={key} />
            ))}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
}

export default BillingPlans;
