import type * as React from 'react';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {createApiKey, listApiKeys} from '../../../../actions/apiKeyActions';
import {getAcceptedAssistantsTerms, listAssistants} from '../../../../actions/assistantActions';
import {listCollections} from '../../../../actions/collectionActions';
import {getNetworkAllowlist} from '../../../../actions/networkAllowlistActions';
import {NotificationTypes, enqueNotification} from '../../../../actions/notificationActions';
import {listPrivateEndpoints} from '../../../../actions/privateEndpointsActions';
import {listServices} from '../../../../actions/serviceActions';
import {DEEPLINK_TOKEN, LocalStorageKeys} from '../../../../constants';
import {Flags, useFlag} from '../../../../hooks/flags';
import {
  useNavigateToKeysPageGlobal,
  useNavigateToSelectProject,
} from '../../../../hooks/navigation';
import {useControllerApi, useDashboardApi} from '../../../../hooks/use-api';
import {useAcceptedAssistantsTerms} from '../../../../selectors/assistants';
import {
  useApiKeysByGlobalProjectId,
  useCreatedApiKey,
  useKeysAreLoadingByGlobalProjectId,
} from '../../../../selectors/keys';
import {
  useSelectedOrganization,
  useSelectedOrganizationPlan,
} from '../../../../selectors/organizations';
import {useSelectedGlobalProjectId, useSelectedOrganizationId} from '../../../../selectors/params';
import {
  useIsSelectedProjectValid,
  useProjectByLegacyId,
  useSelectedGlobalProject,
} from '../../../../selectors/projects';
import {type Organization} from '../../../../types';
import {canUsePrivateEndpoints} from '../../../../utils/planRules';
import {viewedProjectsHistory} from '../../../../utils/projects';
import {localStorageSet} from '../../../../utils/storage';
import SubstituteDefaultProject from '../../../Navigation/SubstituteDefaultProject';
import SubstituteWithGlobalProjectId from '../../../Navigation/SubstituteWithGlobalProjectId';
import ServerlessMigrationModal from '../../../ServerlessMigration/ServerlessMigrationModal';
import ApiKeyCreatedModal from '../../../projects/ApiKeyCreatedModal/ApiKeyCreatedModal';

type ProjectWrapperProps = {
  children: React.ReactElement;
};

type InvalidProjectIdProps = {
  org: Organization;
  projectId: string;
};

function InvalidProjectId({org, projectId}: InvalidProjectIdProps) {
  const dispatch = useDispatch();
  const {go: goToSelectProject} = useNavigateToSelectProject(org.id);

  useEffect(() => {
    dispatch(
      enqueNotification({
        type: NotificationTypes.ERROR,
        text: `Could not find project '${projectId}' in organization '${org.name}'.`,
      }),
    );
    goToSelectProject();
  }, [dispatch, goToSelectProject, org.name, projectId]);

  return null;
}

function useRefresh() {
  const selectedProject = useSelectedGlobalProject();

  const {setQueryData: queryApiKeys} = useDashboardApi<{globalProjectId: string}>();
  const {setQueryData: queryServices} = useControllerApi<{
    globalProjectId: string;
  }>(selectedProject);
  const {setQueryData: queryCollections} = useControllerApi<{
    globalProjectId: string;
  }>(selectedProject);
  const {setQueryData: queryArchived} = useDashboardApi<{globalProjectId: string}>();
  const {setQueryData: queryPrivateEndpoints} = useControllerApi<{
    globalProjectId: string;
  }>(selectedProject);
  const {setQueryData: queryNetworkAllowlist} = useControllerApi<{
    globalProjectId: string;
  }>(selectedProject);
  useControllerApi<{organizationId: string}>(selectedProject, getAcceptedAssistantsTerms, {
    organizationId: selectedProject.organization_id,
  });
  const {setQueryData: queryAssistants} = useControllerApi<{
    globalProjectId: string;
  }>(selectedProject);

  const plan = useSelectedOrganizationPlan();

  const acceptedAssistantsTermsResponse = useAcceptedAssistantsTerms(
    selectedProject.organization_id,
  );
  const hasAcceptedAssistantsTerms = acceptedAssistantsTermsResponse?.data;

  useEffect(() => {
    if (selectedProject?.id) {
      queryApiKeys({
        action: listApiKeys,
        data: {globalProjectId: selectedProject.id},
      });
      // collections, services, and private endpoints
      // are needed on various screens, fetch all eagerly here
      queryServices({
        action: listServices,
        data: {globalProjectId: selectedProject.id},
      });
      queryCollections({
        action: listCollections,
        data: {globalProjectId: selectedProject.id},
      });
      if (canUsePrivateEndpoints(plan)) {
        queryPrivateEndpoints({
          action: listPrivateEndpoints,
          data: {globalProjectId: selectedProject.id},
        });
        queryNetworkAllowlist({
          action: getNetworkAllowlist,
          data: {globalProjectId: selectedProject.id},
        });
      }
      if (hasAcceptedAssistantsTerms) {
        queryAssistants({
          action: listAssistants,
          data: {globalProjectId: selectedProject.id},
        });
      }
    }
  }, [
    selectedProject.id,
    queryApiKeys,
    queryServices,
    queryCollections,
    queryArchived,
    queryPrivateEndpoints,
    plan,
    queryNetworkAllowlist,
    hasAcceptedAssistantsTerms,
    queryAssistants,
  ]);
}

function useApiKeyGuard() {
  const apiKeyShownOnce = useFlag(Flags.API_KEY_SHOWN_ONCE);
  const selectedOrgId = useSelectedOrganizationId();
  const globalProjectId = useSelectedGlobalProjectId();
  const apiKeys = useApiKeysByGlobalProjectId(globalProjectId);
  const keysAreLoading = useKeysAreLoadingByGlobalProjectId(globalProjectId) ?? true;
  const {path: keysPath} = useNavigateToKeysPageGlobal(selectedOrgId, globalProjectId);
  const location = useLocation();
  const dispatch = useDispatch();
  const needsApiKey =
    !keysAreLoading && apiKeys.length === 0 && (apiKeyShownOnce || location.pathname !== keysPath);
  useEffect(() => {
    if (needsApiKey && !apiKeyShownOnce) {
      dispatch(
        enqueNotification({
          type: NotificationTypes.ERROR,
          text: 'Create an API key to use Pinecone',
        }),
      );
    }
  }, [dispatch, needsApiKey, apiKeyShownOnce]);

  return needsApiKey;
}

function ProjectWrapper({children}: ProjectWrapperProps) {
  const selectedOrgId = useSelectedOrganizationId();
  const selectedProjectId = useSelectedGlobalProjectId() || '';
  const apiKeyShownOnce = useFlag(Flags.API_KEY_SHOWN_ONCE);
  const createdKey = useCreatedApiKey(selectedProjectId);
  viewedProjectsHistory.append(selectedOrgId, selectedProjectId);

  useRefresh();

  const needsApiKey = useApiKeyGuard();
  const {setQueryData} = useDashboardApi<{
    label: string;
    globalProjectId: string;
  }>();
  useEffect(() => {
    if (needsApiKey && apiKeyShownOnce && !createdKey) {
      setQueryData({
        action: createApiKey,
        data: {
          globalProjectId: selectedProjectId,
          label: 'default',
        },
      });
    }
  }, [needsApiKey, selectedProjectId, setQueryData, apiKeyShownOnce, createdKey]);
  const {path} = useNavigateToKeysPageGlobal(selectedOrgId, selectedProjectId);
  if (needsApiKey && !apiKeyShownOnce) {
    return <Navigate to={path} />;
  }
  return children;
}

/**
 * Ensures that a valid project is selected for the given organization before rendering children.
 * ProjectGuard should be rendered as a child of OrganizationGuard.
 */
function ProjectGuard() {
  const selectedProjectId = useSelectedGlobalProjectId() || '';
  const projectIsValid = useIsSelectedProjectValid();
  // useSelectedGlobalProjectId takes the id directly from the URL.
  // This checks to see if the URL is actually a legacy project id
  // and converts it to the global
  const legacyProject = useProjectByLegacyId(selectedProjectId);
  const org = useSelectedOrganization();

  const apiKeyShownOnce = useFlag(Flags.API_KEY_SHOWN_ONCE);

  useEffect(() => {
    if (projectIsValid) {
      localStorageSet(LocalStorageKeys.LAST_SELECTED_PROJECT, selectedProjectId);
    }
  }, [projectIsValid, selectedProjectId]);

  if (selectedProjectId === DEEPLINK_TOKEN) {
    return <SubstituteDefaultProject />;
  }
  if (legacyProject?.id) {
    return <SubstituteWithGlobalProjectId projectId={legacyProject.id} />;
  }
  if (!projectIsValid) {
    return <InvalidProjectId org={org} projectId={selectedProjectId} />;
  }
  return (
    <ProjectWrapper>
      <>
        <Outlet />
        <ServerlessMigrationModal />
        {apiKeyShownOnce && <ApiKeyCreatedModal />}
      </>
    </ProjectWrapper>
  );
}

export default ProjectGuard;
