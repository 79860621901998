import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import {type Theme, alpha} from '@mui/material/styles';
import {useUserData} from '../selectors/userSession';
import {getUserInitials} from '../utils/user';

const styles = {
  emptyStateScreenContainer: {
    mt: 6,
    mx: 1,
    width: '90%',
    aspectRatio: '3/2',
    display: 'grid',
    gridTemplateColumns: '1fr 6fr',
    gridTemplateRows: '1fr 1fr 6fr',
    gridTemplateAreas: `
    'header header'
    'leftNav actionRow'
    'leftNav content'
    `,
    backgroundColor: 'background.default',
    border: 1,
    borderRadius: 3,
    borderColor: 'divider',
    boxShadow: 12,
  },
  emptyStateScreenLeftNav: {
    gridArea: 'leftNav',
    backgroundColor: 'background.paper',
    borderRight: 1,
    borderRadius: '0px 0 0 12px',
    borderColor: 'divider',
  },
  emptyStateScreenHeader: {
    gridArea: 'header',
    px: 3,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'background.paper',
    borderRadius: '12px 12px 0 0',
    borderBottom: 1,
    borderColor: 'divider',
  },
  emptyStateScreenActionRow: {
    gridArea: 'actionRow',
    mt: 5,
    mx: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  emptyStateScreenContent: {
    mb: 3,
    mt: 2,
    mx: 4,
    gridArea: 'content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  skeletonCardBackground: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.secondary.main, 0.08),
  },
};

const EmptyStateScreen = () => {
  const user = useUserData();
  const userInitials = getUserInitials(user);
  return (
    <Box sx={styles.emptyStateScreenContainer}>
      <Box sx={styles.emptyStateScreenLeftNav} />
      <Box sx={styles.emptyStateScreenHeader}>
        <Avatar
          sx={{
            width: '20px',
            height: '20px',
            color: 'text.secondary',
            backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.12),
            fontWeight: 800,
            fontSize: '6px',
          }}
        >
          {userInitials}
        </Avatar>
      </Box>
      <Box sx={styles.emptyStateScreenActionRow}>
        <Skeleton
          variant="rounded"
          animation={false}
          sx={[{width: '60%'}, styles.skeletonCardBackground]}
        />
        <Skeleton
          variant="rounded"
          animation={false}
          sx={[{width: '15%'}, styles.skeletonCardBackground]}
        />
      </Box>
      <Box sx={styles.emptyStateScreenContent}>
        <Skeleton
          variant="rounded"
          animation={false}
          sx={[{height: '100%', my: 1}, styles.skeletonCardBackground]}
        />
        <Skeleton
          variant="rounded"
          animation={false}
          sx={[{height: '100%', my: 1}, styles.skeletonCardBackground]}
        />
        <Skeleton
          variant="rounded"
          animation={false}
          sx={[{height: '100%', my: 1}, styles.skeletonCardBackground]}
        />
      </Box>
    </Box>
  );
};

export default EmptyStateScreen;
