import Box from '@mui/material/Box';
import Table from '@pinecone-experience/timber/Table';
import type Stripe from 'stripe';
import {GUTTER} from '../../../../styles/theme';
import PaymentHistoryCellRenderer from './PaymentHistoryCellRenderer/PaymentHistoryCellRenderer';

const styles = {
  tableContainer: {
    flexGrow: 1,
    overflow: 'auto',
  },
};

interface PaymentHistoryProps {
  pastInvoices: Stripe.Invoice[];
  isLoading: boolean;
}

function PaymentHistory({pastInvoices, isLoading}: PaymentHistoryProps) {
  const cellRenderer = (row: number, col: number) => (
    <PaymentHistoryCellRenderer invoice={pastInvoices[row]} col={col} />
  );
  return (
    <Box sx={styles.tableContainer}>
      <Table
        title="Payment History and Invoices"
        isLoading={isLoading}
        columns={[
          {name: 'Status', dataType: 'string'},
          {name: 'Created', dataType: 'string'},
          {name: 'Period', dataType: 'string'},
          {name: 'Amount', dataType: 'number'},
          {name: 'Invoice', dataType: 'actions'},
        ]}
        numRows={pastInvoices.length}
        cellRenderer={cellRenderer}
        gutter={GUTTER}
      />
    </Box>
  );
}

export default PaymentHistory;
