import {zodResolver} from '@hookform/resolvers/zod';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {z} from 'zod';
import {connectOrganizationOAuth} from '../../../../../actions/integrationActions';
import ThemeAwarePineconeLogo from '../../../../../components/Utilities/ThemeAwarePineconeLogo';
import OrganizationSelector from '../../../../../components/integrations/vercel/OrganizationSelector/OrganizationSelector';
import {useDashboardApi} from '../../../../../hooks/use-api';
import {useVercelIntegration} from '../../../../../selectors/integrations';
import {useOrganizations} from '../../../../../selectors/organizations';

const styles = {
  card: {
    maxWidth: 1200,
    p: 5,
    m: 2,
  },
  accountBoxWrapper: {
    mt: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      fontSize: 64,
      mr: 4,
    },
    '@media (max-width: 700px)': {
      flexDirection: 'column',
      '& svg': {
        transform: 'rotate(90deg)',
        mr: 0,
        my: 2,
      },
    },
  },
  accountBox: {
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    '& img': {
      width: 50,
      height: 50,
      pb: 2,
    },
    '@media (max-width: 700px)': {
      alignItems: 'center',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

function LinkOrganization() {
  const organizations = useOrganizations();
  const {data, loading} = useVercelIntegration();
  const schema = z.object({
    oauthId: z.string(),
    organizationId: z.string(),
  });

  const methods = useForm<z.infer<typeof schema>>({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });

  const {watch, setValue, handleSubmit, formState} = methods;
  const vercelName = data?.connection?.userInfo?.email || data?.connection?.teamInfo?.name;
  const vercelId = data?.connection?.userInfo?.id || data?.connection?.teamInfo?.id;

  const oauthId = data?.connection?.id;
  const organizationId = watch('organizationId');
  useEffect(() => {
    if (oauthId) {
      setValue('oauthId', oauthId as string);
    }
  }, [setValue, oauthId]);

  const {setQueryData} = useDashboardApi<{
    id: string;
    organizationId: string;
  }>();
  const connectOrganizationHandler = (formData: z.infer<typeof schema>) => {
    setQueryData({
      action: connectOrganizationOAuth,
      data: {id: formData.oauthId, organizationId: formData.organizationId},
    });
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(connectOrganizationHandler)} id="select-organization">
        <Paper sx={styles.card}>
          <LoadingContainer loading={loading || !vercelId || !vercelName}>
            <Typography variant="h4" gutterBottom>
              Integrate Pinecone with Vercel
            </Typography>
            <Typography>Select an Organization to link with Vercel</Typography>
            <Box height={20} />
            <OrganizationSelector />
            <Divider sx={{my: 5}} />
            <Box>
              <Typography variant="h6" gutterBottom>
                Confirm Access to Your Organization
              </Typography>
              <Typography>
                I acknowledge that I am giving Vercel access to my Pinecone Organization.
              </Typography>
              <Box sx={styles.accountBoxWrapper}>
                <Box sx={styles.accountBox}>
                  <Box
                    component="img"
                    src="https://assets.vercel.com/image/upload/front/favicon/vercel/180x180.png"
                  />
                  <Typography variant="h6">Vercel Account</Typography>
                  <Typography>{vercelName}</Typography>
                  <Typography>{vercelId}</Typography>
                </Box>
                <SwapHorizRoundedIcon fontSize="inherit" />
                <Box sx={styles.accountBox}>
                  <ThemeAwarePineconeLogo />
                  <Typography variant="h6">Pinecone Organization</Typography>
                  {organizationId ? (
                    <>
                      <Typography>{organizations[organizationId]?.name}</Typography>
                      <Typography>{organizationId}</Typography>
                    </>
                  ) : (
                    <Typography fontWeight="bold">Select an Organization above</Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Button
              type="submit"
              disabled={!formState.isValid}
              variant="contained"
              size="large"
              color="primary"
              sx={{my: 4}}
              fullWidth
            >
              Authorize
              {loading && (
                <CircularProgress size={20} sx={{color: 'primary.contrastText', ml: 2}} />
              )}
            </Button>
          </LoadingContainer>
        </Paper>
      </form>
    </FormProvider>
  );
}

export default LinkOrganization;
