import {type User} from '../types';

export function getUserName(user?: User) {
  if (!user) {
    return '';
  }
  if (user.params.firstName && user.params.lastName) {
    return `${user.params.firstName} ${user.params.lastName}`;
  }
  if (user.params.firstName) {
    return user.params.firstName;
  }
  return user.name || user.email;
}

export function getUserInitials(user?: User) {
  const userName = getUserName(user);
  if (!userName) {
    return [];
  }
  return userName?.split(' ').map((word) => (word[0] ? word[0].toUpperCase() : '')) || [];
}

export function getUserFirstNameIfExists(user?: User) {
  return user?.params.firstName || '';
}
