import {useEffect} from 'react';
import EmptyState from '../../../components/layout/EmptyState/EmptyState';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {useNavigateToBillingPlans} from '../../../hooks/navigation';
import {usePastInvoicesAreLoading} from '../../../selectors/billing';
import {
  useIsOwnerOfOrganization,
  useOrganizationsAreLoading,
} from '../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../selectors/params';
import {useShouldShowBilling} from '../hooks/billing';
import BillingDetails from './BillingDetails/BillingDetails';
import PaymentHistory from './PaymentHistory/PaymentHistory';

function BillingPage() {
  const selectedOrgId = useSelectedOrganizationId();
  const orgIsLoading = useOrganizationsAreLoading();
  const isOwner = useIsOwnerOfOrganization();
  const isInvoiceDataLoading = usePastInvoicesAreLoading();
  const redirectToBillingPlans = useNavigateToBillingPlans(selectedOrgId, {replace: true});

  const {showBillingDetails, showInvoices, showBillingPage, pastInvoices, shouldFetchInvoices} =
    useShouldShowBilling();

  useEffect(() => {
    if (!showBillingPage && shouldFetchInvoices && !isInvoiceDataLoading && !orgIsLoading) {
      redirectToBillingPlans();
    }
  }, [
    showBillingPage,
    redirectToBillingPlans,
    shouldFetchInvoices,
    isInvoiceDataLoading,
    orgIsLoading,
  ]);

  return (
    <Page
      width="wide"
      loading={orgIsLoading || (shouldFetchInvoices && isInvoiceDataLoading)}
      header={
        <PageHeader
          titleHidden={!showBillingDetails}
          title="Invoices"
          description={
            showBillingDetails &&
            'View and manage your current subscription plan, past invoices, and access your billing details.'
          }
        />
      }
    >
      {isOwner ? (
        <>
          {showBillingDetails && <BillingDetails />}
          {showInvoices && (
            <PaymentHistory pastInvoices={pastInvoices} isLoading={isInvoiceDataLoading} />
          )}
        </>
      ) : (
        <EmptyState
          heading="Unauthorized"
          description="You must be an organization owner to view billing details"
        />
      )}
    </Page>
  );
}

export default BillingPage;
