import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import CodeIcon from '@mui/icons-material/Code';
import {GuideId} from '../../../GetStartedPage/Guides/types';

export interface GuideCard {
  title: string;
  description: string;
  icon: React.ReactNode;
  guideId: GuideId;
}

export const GuideCardInfo: GuideCard[] = [
  {
    title: 'Developer quickstart',
    description: `Connect and interact with the Pinecone Vector Database using your preferred SDK.`,
    icon: <CodeIcon color="primary" fontSize="inherit" />,
    guideId: GuideId.DEVELOPER_QUICKSTART,
  },
  {
    title: 'Explore vectors',
    description: `Learn how to generate, store, and query vectors in Pinecone.`,
    icon: <AutoAwesomeMosaicOutlinedIcon color="primary" fontSize="inherit" />,
    guideId: GuideId.INTERACTIVE_DEMO,
  },
];
