const gridStyles = ({gap = 1.5, gridMinWidth = 100}: {gap?: number; gridMinWidth?: number}) => ({
  grid: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: `repeat(auto-fit, minmax(${gridMinWidth || 100}px, 1fr))`,
    gap,
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap,
  },
  stack: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap,
    flexDirection: 'column',
    alignItems: 'start',
  },
});

export const getLayout = (
  layout?: 'grid' | 'flex' | 'stack',
  gap?: number,
  gridMinWidth?: number,
) => {
  switch (layout) {
    case 'grid':
      return gridStyles({gap, gridMinWidth}).grid;
    case 'stack':
      return gridStyles({gap, gridMinWidth}).stack;
    case 'flex':
      return gridStyles({gap, gridMinWidth}).flex;

    default:
      return gridStyles({gap, gridMinWidth}).flex;
  }
};
