import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CopyButton from '@pinecone-experience/timber/CopyButton';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {type SsoConnectionConfig} from '../../../../../../types';

type SsoConfigInfoProps = {
  close: () => void;
  goNext: () => void;
  connection?: SsoConnectionConfig;
};

function CopyRow({title, text}: {title: string; text: string}) {
  return (
    <TextInput
      label={title}
      readOnly
      fullWidth
      value={text}
      onChange={() => {}}
      endSlot={<CopyButton text={text} />}
    />
  );
}

function SsoConfigInfo({close, goNext, connection}: SsoConfigInfoProps) {
  return (
    <LoadingContainer loading={!connection}>
      <Box>
        <Typography
          variant="body2"
          py={3}
        >{`Success! Your EntityID and details are now integrated. Next, enter these values into your IdP's SSO setup fields.`}</Typography>
        <CopyRow
          title="entityId"
          text={`urn:auth0:production-v2-pinecone-io:${connection?.name}`}
        />
        <CopyRow
          title="Metadata URL"
          text={`https://login.pinecone.io/samlp/metadata?connection=${connection?.name}`}
        />
        <CopyRow
          title="AssertionConsumerServiceLocation"
          text={`https://login.pinecone.io/login/callback?connection=${connection?.name}`}
        />
        <Box display="flex" sx={{justifyContent: 'flex-end', width: '100%', mt: 2, mb: 0}}>
          <Button onClick={close} sx={{ml: 1, color: 'secondary.main', mr: 3}}>
            Finish Setup
          </Button>
          <Button type="submit" variant="contained" color="primary" onClick={goNext}>
            Manage Access
          </Button>
        </Box>
      </Box>
    </LoadingContainer>
  );
}

export default SsoConfigInfo;
