import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@pinecone-experience/timber/Alert';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import {type ReactElement} from 'react';

const styles = {
  chartBox: {
    position: 'relative',
    height: 400,
    p: 3,
  },
  titleBar: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 1,
  },
  title: {
    display: 'flex',
    letterSpacing: 1,
    alignItems: 'center',
  },
};

interface ChartLegendProps {
  title: string;
  chart: ReactElement;
  filters?: ReactElement;
  tooltip?: string;
  legend?: ReactElement;
  loading: boolean;
  error?: string | Error | null;
}
const ChartLayout = ({
  title,
  chart,
  filters,
  tooltip,
  legend,
  loading,
  error,
}: ChartLegendProps) => {
  let height = 300;
  if (error) {
    height -= 100;
  }
  if (legend) {
    height -= 20;
  }

  return (
    <Paper sx={styles.chartBox}>
      {error && (
        <Alert
          severity="error"
          title="Error"
          description={`Data failed to load: ${error}`}
          sx={{my: 1}}
        />
      )}
      <Box sx={styles.titleBar}>
        <Box sx={styles.title}>
          <Typography variant="overline">{title}</Typography>
          <Tooltip title={tooltip}>
            <InfoOutlinedIcon sx={{ml: 1}} fontSize="small" />
          </Tooltip>
        </Box>
        {filters}
      </Box>
      <Box height={height}>
        <LoadingContainer loading={loading} showChildrenInBackground>
          {chart}
        </LoadingContainer>
      </Box>
      {legend}
    </Paper>
  );
};

export default ChartLayout;
