import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import {Link as RouterLink} from 'react-router-dom';
import {LANGUAGE_CONTENT} from './constants';
import {useSelectedLanguage} from './utils';

const styles = {
  subtext: {
    pb: 2,
  },
};

function CreateIndex() {
  const selectedLanguage = useSelectedLanguage();
  const selectedLanguageContent = LANGUAGE_CONTENT[selectedLanguage];
  return (
    <Box role="region" aria-labelledby="install">
      <Typography variant="subtitle1" id="create-index-snippet">
        Create index
      </Typography>
      <Typography sx={styles.subtext}>
        {selectedLanguageContent.createIndexSubtitleOverride ||
          `Then create a serverless index named "quickstart" that performs nearest-neighbor search
          using the `}
        <Link
          color="secondary"
          component={RouterLink}
          to="https://docs.pinecone.io/guides/indexes/understanding-indexes#distance-metrics"
          rel="noopener"
          target="_blank"
        >
          cosine distance metric
        </Link>{' '}
        for 2-dimensional vectors:
      </Typography>
      <CodeBlock code={selectedLanguageContent.createIndexCode()} language={selectedLanguage} />
    </Box>
  );
}

export default CreateIndex;
