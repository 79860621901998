import Box from '@mui/material/Box';
import {DataVisColorsArray} from '@pinecone-experience/theme/colors';
import Alert from '@pinecone-experience/timber/Alert';
import {useEffect, useMemo, useState} from 'react';
import type * as React from 'react';
import {getUsageData} from '../../../../actions/dashboardActions';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useSelectedOrganizationId} from '../../../../selectors/params';
import {useGlobalProjectsFromOrganizationById} from '../../../../selectors/projects';
import {useUsageData, useUsageIsLoading} from '../../../../selectors/usageData';
import {type GlobalProject} from '../../../../types';
import dayjs from '../../../../utils/dayjs';
import {getLegacyId} from '../../../../utils/projects';
import UsageChart from './UsageChart/UsageChart';
import UsageReportControls from './UsageReportControls/UsageReportControls';
import UsageSummary from './UsageSummary/UsageSummary';
import UsageTable from './UsageTable/UsageTable';
import {
  DATE_FORMAT,
  GroupByOptions,
  type GroupByTypes,
  TimespanMap,
  type TimespanTypes,
  type UsageDatapoint,
} from './config';

export const filterData = (
  data: UsageDatapoint[],
  selectedKeys: string[],
  groupBy: GroupByTypes,
) => {
  return data.filter((event: UsageDatapoint) => selectedKeys.includes(event[groupBy]));
};

export const augmentUsageData = (
  usageData: UsageDatapoint[],
  projects: Record<string, GlobalProject>,
) => {
  const byLegacyId: Record<string, GlobalProject> = {};
  Object.values(projects).forEach((proj) => {
    byLegacyId[getLegacyId(proj)] = proj;
  });
  return usageData.map((event: UsageDatapoint) => ({
    ...event,
    date: dayjs(event.date).format(DATE_FORMAT),
    projectId: byLegacyId[event.projectId]?.name || event.projectId,
    environment: event.projectId.split(':')[0],
  }));
};

function getKeys(data: UsageDatapoint[], groupBy: GroupByTypes) {
  if (!data) {
    return [];
  }
  const keys = new Set<string>();
  data.forEach((point) => {
    keys.add(point[groupBy]);
  });
  return [...keys.keys()];
}

const Usage: React.FC = () => {
  const organizationId = useSelectedOrganizationId();
  const projects = useGlobalProjectsFromOrganizationById(organizationId);
  const isLoadingUsage = useUsageIsLoading();
  const [timespan, setTimespan] = useState(Object.keys(TimespanMap)[0] as TimespanTypes);
  const [groupBy, setGroupBy] = useState(Object.keys(GroupByOptions)[0] as GroupByTypes);

  const {setQueryData} = useDashboardApi<{
    organizationId: string;
    startTimestamp: number;
    endTimestamp: number;
  }>();
  useEffect(() => {
    setQueryData({
      action: getUsageData,
      data: {
        organizationId: organizationId || '',
        startTimestamp: TimespanMap[timespan].start,
        endTimestamp: TimespanMap[timespan].end,
      },
    });
  }, [organizationId, setQueryData, timespan]);
  const data = useUsageData(organizationId || '');
  const {usageData} = data || {};

  const augmentedUsageData = useMemo<UsageDatapoint[]>(
    () => augmentUsageData(usageData || [], projects),
    [usageData, projects],
  );

  const keys = useMemo(() => getKeys(augmentedUsageData, groupBy), [augmentedUsageData, groupBy]);

  const [selectedKeys, setSelectedKeys] = useState(keys);

  const keyToColor: Record<string, string> = {};
  keys.forEach((key, i) => {
    keyToColor[key] = DataVisColorsArray[i % DataVisColorsArray.length];
  });

  useEffect(() => {
    setSelectedKeys(keys);
  }, [data, groupBy, keys]);

  const filteredData = filterData(augmentedUsageData, selectedKeys, groupBy);

  const totalSelectedCost = filteredData.reduce(
    (tot: number, point: UsageDatapoint) => tot + point.subtotalCharge,
    0,
  );

  return (
    <Box>
      <Box display="flex">
        <UsageSummary
          timespan={timespan}
          totalSelectedCost={totalSelectedCost}
          loading={isLoadingUsage}
        />
        <UsageReportControls
          timespan={timespan}
          setTimespan={setTimespan}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          keys={keys}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
        />
      </Box>
      {!isLoadingUsage && filteredData.length === 0 ? (
        <Alert description="No data for selected range" />
      ) : (
        <>
          <UsageChart
            data={filteredData}
            groupBy={groupBy}
            keys={selectedKeys}
            keyToColor={keyToColor}
            timespan={timespan}
            loading={isLoadingUsage}
          />
          <UsageTable
            data={filteredData}
            groupBy={groupBy}
            keys={selectedKeys}
            keyToColor={keyToColor}
            loading={isLoadingUsage}
          />
        </>
      )}
    </Box>
  );
};

export default Usage;
