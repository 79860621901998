import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useSelectedLanguage} from '../../../../../components/CodeSnippets/utils';
import Block from '../Block/Block';
import {type Section} from '../types';
import {GuideStatus, useGuideContext} from '../utilities/GuideContext';

const styles = {
  root: {
    mb: 3,
    px: 3,
    pt: 3,
    pb: 1,
    width: '100%',
  },
  incomplete: {
    color: 'action.disabled',
  },
  sectionComponent: {
    my: 3,
  },
  stretch: {
    display: 'flex',
    flexWrap: 'wrap-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 1,
  },
};

function SectionComponent({section}: {section: Section}) {
  const {state, updateSectionStatus, supportedLanguages} = useGuideContext();
  const selectedLanguage = useSelectedLanguage(supportedLanguages);
  const Component = section.component;
  const sectionStatus = state.sections[section.index].status;
  const sectionStatusText = sectionStatus === GuideStatus.IN_PROGRESS ? 'In progress' : '';

  if (section.supportedLanguages && !section.supportedLanguages.includes(selectedLanguage)) {
    if (sectionStatus === GuideStatus.IN_PROGRESS) {
      updateSectionStatus(section.index, GuideStatus.SKIPPED);
    }
    return null;
  }

  return (
    <Paper id={`section-${section.index}`} sx={styles.root}>
      <Box sx={[styles.stretch, sectionStatus === GuideStatus.INCOMPLETE && styles.incomplete]}>
        <Typography variant="h5">{section.title}</Typography>
        <Typography color="text.secondary">{sectionStatusText}</Typography>
      </Box>
      <Box
        sx={[
          styles.sectionComponent,
          sectionStatus === GuideStatus.INCOMPLETE && styles.incomplete,
        ]}
      >
        {Component && <Component />}
      </Box>
      <Box>
        {Object.values(section.blocks).map((block) => (
          <Block key={block.title} block={block} sectionIndex={section.index} />
        ))}
      </Box>
    </Paper>
  );
}

export default SectionComponent;
