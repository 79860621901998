import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useForm} from 'react-hook-form';
import ModalForm from './ModalForm';

type DeleteConfirmationModalProps = {
  close: () => void;
  open: boolean;
  itemType: string;
  submitItemType?: string;
  itemDescription?: string;
  name: string;
  doDelete: () => void;
  warningText?: string;
  action?: string;
  titleAction?: string;
  bottomText?: string;
  skipCheck?: boolean;
  title?: string;
};

function DeleteConfirmationModal({
  close,
  open,
  itemType,
  submitItemType,
  itemDescription,
  name,
  doDelete,
  warningText = '',
  action = 'Delete',
  titleAction = 'Deletion',
  bottomText = '',
  skipCheck = false,
  title,
}: DeleteConfirmationModalProps) {
  const {handleSubmit, control, setValue, watch} = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });
  const value = watch('name');
  const isCorrect = value === name || skipCheck;
  const closeForm = () => {
    close();
    setValue('name', '');
  };
  const onSubmit = () => {
    closeForm();
    doDelete();
  };
  return (
    <ModalForm
      open={open}
      close={close}
      form={
        <Box>
          <Box sx={{mb: 1}}>
            <Typography component="span">
              {`Are you sure you want to ${action.toLowerCase()} `}
              <strong>{itemDescription || name}</strong>?
            </Typography>
            <Typography component="span">
              {` `}
              {warningText}
            </Typography>
          </Box>
          {skipCheck ? null : (
            <TextInput
              name="name"
              label={`Enter ${submitItemType || itemType} Name`}
              control={control}
              required
              placeholder={`Type "${name}" to confirm`}
              error={!isCorrect ?? 'Name must match'}
              autoFocus
              fullWidth
            />
          )}
          <Typography>{bottomText}</Typography>
        </Box>
      }
      title={title ?? `Confirm ${itemType} ${titleAction}`}
      isValid={isCorrect}
      onSubmit={handleSubmit(onSubmit)}
      submitText={`${action} ${itemType}`}
      submitColor="error"
    />
  );
}

export default DeleteConfirmationModal;
