import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type * as React from 'react';
import {useAnimatedDots} from '../../../hooks/utils';
import {ReactComponent as ProjectCreationIcon} from '../../../icons/project_creation.svg';
import {useGlobalProjectIsCreating} from '../../../selectors/projects';

const styles = {
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 800,
    margin: 'auto',
    paddingTop: 16,
  },
};

type ProjectCreationGuardProps = {
  globalProjectId: string;
  children: React.ReactElement;
};

function ProjectCreationGuard({globalProjectId, children}: ProjectCreationGuardProps) {
  const isCreating = useGlobalProjectIsCreating(globalProjectId);
  const dots = useAnimatedDots();
  if (isCreating) {
    return (
      <Box sx={styles.iconContainer}>
        <ProjectCreationIcon />
        <Box>
          <span style={{width: 30, display: 'inline-block'}} />
          <Typography component="span" variant="h6" color="text.primary">
            Project Initializing
          </Typography>
          <Typography component="span" variant="h6" sx={{width: 30, display: 'inline-block'}}>
            {dots}
          </Typography>
        </Box>
        <Typography>Thanks for your patience while we initialize your project.</Typography>
        <Typography>{`Hang tight, we'll have you up and running soon!`}</Typography>
      </Box>
    );
  }
  return children;
}

export default ProjectCreationGuard;
