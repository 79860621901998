import {getPastInvoices} from '../../../actions/organizationActions';
import {Plans} from '../../../constants';
import {useDashboardApi} from '../../../hooks/use-api';
import {usePastInvoices, usePastInvoicesAreLoading} from '../../../selectors/billing';
import {
  useIsOwnerOfOrganization,
  useIsSelectedOrganizationSubscribed,
  useIsSelectedOrganiztionMarketplace,
  useOrganizationsAreLoading,
  useSelectedOrganizationPlan,
} from '../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../selectors/params';

export function useShouldShowBilling() {
  const selectedOrgId = useSelectedOrganizationId();
  const orgIsLoading = useOrganizationsAreLoading();
  const isMarketplace = useIsSelectedOrganiztionMarketplace();
  const plan = useSelectedOrganizationPlan();
  const isOwner = useIsOwnerOfOrganization();
  const isInvoiceDataLoading = usePastInvoicesAreLoading();
  const isSubscribed = useIsSelectedOrganizationSubscribed();

  const shouldFetchInvoices =
    isOwner && !(isMarketplace || [Plans.DEDICATED, Plans.INTERNAL].includes(plan));
  useDashboardApi(getPastInvoices, {organizationId: selectedOrgId || ''}, shouldFetchInvoices);
  const pastInvoices = usePastInvoices() || [];

  const showInvoices = shouldFetchInvoices && !isInvoiceDataLoading && pastInvoices.length > 0;
  const showBillingDetails = isSubscribed || isMarketplace;

  return {
    shouldFetchInvoices,
    showBillingDetails,
    showInvoices,
    showBillingPage: orgIsLoading || showBillingDetails || showInvoices,
    pastInvoices,
  };
}

export function useShouldShowBillingPlans() {
  const orgIsLoading = useOrganizationsAreLoading();
  const plan = useSelectedOrganizationPlan();

  return !orgIsLoading && ![Plans.DEDICATED, Plans.INTERNAL].includes(plan);
}
