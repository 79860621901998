import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useForm} from 'react-hook-form';
import {requestRegion} from '../../../../actions/userActions';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useSelectedGlobalProjectId, useSelectedOrganizationId} from '../../../../selectors/params';
import ModalForm from '../../../ModalForm';
import {type SchemaType, schema} from './utilities/schema';

type RegionRequestModalProps = {
  open: boolean;
  close: () => void;
};

function RegionRequestModal({open, close}: RegionRequestModalProps) {
  const {
    control,
    formState: {isValid},
    handleSubmit,
  } = useForm({
    defaultValues: {
      provider: '',
      region: '',
    },
    resolver: zodResolver(schema),
  });
  const {setQueryData} = useDashboardApi<{
    requestData: SchemaType;
    context: {projectId?: string; orgId?: string};
  }>();
  const projectId = useSelectedGlobalProjectId();
  const orgId = useSelectedOrganizationId();
  const onSubmit = (data: SchemaType) => {
    setQueryData({
      action: requestRegion,
      data: {
        requestData: data,
        context: {
          projectId,
          orgId,
        },
      },
    });
    close();
  };
  return (
    <ModalForm
      title="Request a new region"
      onSubmit={handleSubmit(onSubmit)}
      submitText="Submit request"
      isValid={isValid}
      open={open}
      close={close}
      form={
        <Box>
          <SelectInput
            placeholder="Select an option"
            fullWidth
            control={control}
            name="provider"
            label="Select your cloud service provider"
            options={[{value: 'AWS'}, {value: 'GCP'}, {value: 'Azure'}]}
          />
          <TextInput
            fullWidth
            placeholder="Ex. Europe West (europe-west1)"
            control={control}
            name="region"
            label="Type the desired region below"
          />
        </Box>
      }
    />
  );
}

export default RegionRequestModal;
