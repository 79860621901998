import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import SelectBoxInput from '@pinecone-experience/timber/Inputs/SelectBoxInput';
import {type Control, useWatch} from 'react-hook-form';
import {Link as RouterLink} from 'react-router-dom';
import {getOrgBillingPlansPath} from '../../../../hooks/navigation';
import {useSelectedOrganizationId} from '../../../../selectors/params';
import {ControlPlaneRoles, DataPlaneRoles, ProjectRoles} from '../../../../types';
import {BaseOptions, type FormWithRoles, ProjectOptions} from '../types';
import {RbacRoleDisplays} from '../utils';
import RoleLayerControl from './RoleLayerControl/RoleLayerControl';

type ApiKeyPermissionControlProps<T extends FormWithRoles> = {
  control: T extends FormWithRoles ? Control<T> : never;
  locked: boolean;
};

function ApiKeyPermissionControl<T extends FormWithRoles>({
  control,
  locked,
}: ApiKeyPermissionControlProps<T>) {
  const data = useWatch({control});
  const baseOptions = ProjectOptions.map((role) => ({
    label: RbacRoleDisplays[role],
    value: role,
    disabled: locked && role !== ProjectRoles.PROJECT_EDITOR,
  }));
  const orgId = useSelectedOrganizationId();
  const orgBillingPlansPath = getOrgBillingPlansPath(orgId);
  const disabled = locked || data.baseRole !== BaseOptions.CUSTOM;
  return (
    <Box display="flex" flexDirection="column">
      <SelectBoxInput
        control={control}
        name="baseRole"
        label="Permissions"
        highlightStyle="background"
        hideError
        gap={0}
        options={baseOptions}
      />
      <Box sx={{display: disabled ? 'none' : ''}}>
        <RoleLayerControl
          control={control}
          name="controlPlaneRole"
          title="Control plane"
          read={ControlPlaneRoles.INDEX_RESOURCE_VIEWER}
          write={ControlPlaneRoles.INDEX_RESOURCE_EDITOR}
          baseRole={data.baseRole}
          disabled={disabled}
          descriptions={{
            '': 'No control plane permissions.',
            [ControlPlaneRoles.INDEX_RESOURCE_VIEWER]:
              'Permission to list and describe indexes and backups.',
            [ControlPlaneRoles.INDEX_RESOURCE_EDITOR]:
              'Permission to list, describe, create, delete, and configure indexes and backups.',
          }}
        />
        <Divider sx={{marginTop: 2}} />
        <RoleLayerControl
          control={control}
          name="dataPlaneRole"
          title="Data plane"
          read={DataPlaneRoles.INDEX_DATA_VIEWER}
          write={DataPlaneRoles.INDEX_DATA_EDITOR}
          baseRole={data.baseRole}
          disabled={disabled}
          descriptions={{
            '': 'No data plane permissions.',
            [DataPlaneRoles.INDEX_DATA_VIEWER]:
              'Permission to query, fetch, list ID, and view stats.',
            [DataPlaneRoles.INDEX_DATA_EDITOR]:
              'Permission to query, fetch, list ID, view stats, add, update, and delete index data.',
          }}
          warningText="Note: For pod-based indexes, the data plane is fixed at ReadWrite."
        />
      </Box>
      {locked && (
        <Typography>
          <Link component={RouterLink} to={orgBillingPlansPath}>
            Upgrade now
          </Link>{' '}
          to access advanced permissions, including the ability to manage and configure indexes and
          backups.
        </Typography>
      )}
    </Box>
  );
}

export default ApiKeyPermissionControl;
