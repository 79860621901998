import Box from '@mui/material/Box';
import Image from '../../components/Utilities/Image/Image';
import {getAssetUrl} from '../../utils/assets';
import {RegistrationStepMap} from './contants';
import {useRegistrationFormContext} from './utilities/RegistrationFormContext';

const BLACK_HOLE_BG = getAssetUrl('registration/black-hole-hero-bg.png');
const MAX_IMAGE_WIDTH = 968;

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'grid',
    placeItems: 'center',
    backgroundImage: `url(${BLACK_HOLE_BG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  image: {
    width: '50%',
    maxWidth: MAX_IMAGE_WIDTH,
  },
};

function MarketingContent() {
  const {step} = useRegistrationFormContext();
  return (
    <Box sx={styles.container}>
      <Image src={RegistrationStepMap[step].marketingImage} alt="Testimonial" sx={styles.image} />
    </Box>
  );
}

export default MarketingContent;
