import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import {useMemo} from 'react';
import {type Control} from 'react-hook-form';
import {PUBLIC_DOCS_URL} from '../../../../../constants';
import {useIsSelectedOrganizationSubscribed} from '../../../../../selectors/organizations';

interface PodSizeForm {
  replicas: number;
  podSize: string;
}

interface PodSizeSelectorProps<T extends PodSizeForm> {
  control: T extends PodSizeForm ? Control<T> : never;
  currentSize: string;
  isFreeIndex: boolean;
}

function PodSizeSelector<T extends PodSizeForm>({
  control,
  currentSize,
  isFreeIndex,
}: PodSizeSelectorProps<T>) {
  const canScale = useIsSelectedOrganizationSubscribed();
  const options = useMemo(() => {
    const sizes = ['x1', 'x2', 'x4', 'x8'];
    let seenCurrent = false;
    return sizes.map((size) => {
      if (size === currentSize) {
        seenCurrent = true;
      }
      return {
        value: size,
        disabled: !seenCurrent,
      };
    });
  }, [currentSize]);

  return (
    <SelectInput
      name="podSize"
      control={control}
      label="Pod Size"
      options={options}
      disabled={!canScale || isFreeIndex}
      fullWidth
      tooltip={
        <Box>
          <p>
            Larger sizes can hold more vectors. See{' '}
            <Link
              href={`${PUBLIC_DOCS_URL}/guides/indexes/choose-a-pod-type-and-size`}
              rel="noreferrer"
              target="_blank"
            >
              here
            </Link>{' '}
            for more info.
          </p>
        </Box>
      }
    />
  );
}

export default PodSizeSelector;
