import Box from '@mui/material/Box';
import Chip, {type ChipProps} from '@mui/material/Chip';
import {type EmbeddingModel} from '../../../types';
import Image from '../../Utilities/Image/Image';

function ModelChip({model, sx}: {model: EmbeddingModel; sx?: ChipProps['sx']}) {
  const {name, icon, iconDark} = model;
  const iconComponent = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image alt={name} srcLight={icon} srcDark={iconDark || icon} height={20} />
    </Box>
  );
  return <Chip size="small" icon={iconComponent} label={name} sx={sx} />;
}

export default ModelChip;
