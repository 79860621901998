import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {useEffect, useState} from 'react';
import {useSelectedLanguage} from '../../../../../components/CodeSnippets/utils';
import NextButton from '../NextButton/NextButton';
import {type Block as BlockType} from '../types';
import {GuideStatus, useGuideContext} from '../utilities/GuideContext';
import {StatusMarker, getTitleColor} from './utilities';

const styles = {
  root: {
    my: 2,
    width: '100%',
    display: 'grid',
    gap: 2,
    gridTemplateColumns: '45px 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `
      "left right"
    `,
  },
  left: {
    gridArea: 'left',
    display: 'flex',
    alignItems: 'flex-start',
  },
  right: {
    gridArea: 'right',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    minWidth: 0,
  },
  componentWrapper: {
    mt: 2,
    gridColumn: 'span 2',
  },
};

function Block({block, sectionIndex}: {block: BlockType; sectionIndex: number}) {
  const {state, supportedLanguages, updateBlockStatus} = useGuideContext();
  const selectedLanguage = useSelectedLanguage(supportedLanguages);
  const Component = block.component;
  const sectionStatus = state.sections[sectionIndex].status;
  const blockStatus = state.sections[sectionIndex].blocks[block.index].status;
  const isFinalBlock =
    !state.sections[sectionIndex].blocks[block.index + 1] && !state.sections[sectionIndex + 1];
  const [open, setOpen] = useState<boolean>(blockStatus === GuideStatus.IN_PROGRESS);

  useEffect(() => {
    if (blockStatus === GuideStatus.IN_PROGRESS) {
      setOpen(true);
    }
  }, [blockStatus]);

  const toggleBlock = () => {
    setOpen((prev) => !prev);
  };

  const handleNext = () => {
    updateBlockStatus(sectionIndex, block.index, GuideStatus.COMPLETE);
    setOpen(false);
  };

  if (block.supportedLanguages && !block.supportedLanguages.includes(selectedLanguage)) {
    if (blockStatus === GuideStatus.IN_PROGRESS) {
      updateBlockStatus(sectionIndex, block.index, GuideStatus.SKIPPED);
    }
    return null;
  }

  return (
    <>
      {block.index !== 0 && <Divider sx={{my: 2}} />}
      <Box id={`section-${sectionIndex}-block-${block.index}`} sx={styles.root}>
        <Box sx={styles.left}>
          <StatusMarker status={blockStatus} open={open} />
        </Box>
        <Box sx={styles.right}>
          <Typography
            variant="h6"
            data-testid={`section-${sectionIndex}-block-${block.index}-toggle`}
            color={getTitleColor({
              sectionStatus,
              blockStatus,
            })}
            onClick={toggleBlock}
            sx={{
              mt: 0.5,
              cursor: 'pointer',
            }}
          >
            {block.title}
          </Typography>
          <Collapse in={open} sx={{width: '100%'}}>
            <Box sx={styles.componentWrapper}>
              <Component
                handleNext={handleNext}
                status={blockStatus}
                blockTitle={block.title}
                sectionIndex={sectionIndex}
                blockIndex={block.index}
              />
            </Box>
            {!block.hideNextButton && (
              <NextButton
                blockIndex={block.index}
                blockTitle={block.title}
                onClick={handleNext}
                sectionIndex={sectionIndex}
                status={blockStatus}
                title={isFinalBlock ? 'Complete tutorial' : 'Next'}
              />
            )}
          </Collapse>
        </Box>
      </Box>
    </>
  );
}

export default Block;
