import Box, {type BoxProps} from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import BillingPlanItemStatus from '../../BillingPlanItemStatus/BillingPlanItemStatus';
import {BILLING_PLAN_PRODUCTS} from '../constants/billingPlans';
import {type BillingPlanProducts} from '../types/billingPlans.interface';
import BillingPlanProductFeature from './BillingPlanProductFeature/BillingPlanProductFeature';

interface BillingPlanProductProps {
  productKey: BillingPlanProducts;
  sx?: Required<BoxProps['sx']>;
}

const styles = {
  header: {
    mt: 3,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 1,
    px: 2,
    py: 1,
    borderBottom: 1,
    borderColor: 'divider',
  },
  title: {
    display: 'flex',
    gap: 2,
  },
};

export default function BillingPlanProduct({productKey, sx = {}}: BillingPlanProductProps) {
  const product = BILLING_PLAN_PRODUCTS[productKey];

  return (
    <Box sx={sx}>
      <Box sx={[sx, styles.header]}>
        <Box sx={styles.title}>
          <Typography variant="h6" component="h3">
            {product.name}
          </Typography>
          {product.status && <BillingPlanItemStatus status={product.status} />}
        </Box>
        <Link target="_blank" href={product.docsLink}>
          Docs
        </Link>
      </Box>
      {product.features.map((feature, index) => (
        <BillingPlanProductFeature
          key={feature.name?.toString()}
          sx={sx}
          feature={feature}
          productKey={productKey}
          index={index}
        />
      ))}
    </Box>
  );
}
