import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import CodingLanguageSelector from '../../../../../../components/CodeSnippets/CodingLanguageSelector';
import {useSelectedLanguage} from '../../../../../../components/CodeSnippets/utils';
import MetadataTable from '../../../../../../components/datasets/MetadataTable/MetadataTable';
import {moviesDataset} from '../../../../../../components/datasets/data/movies';
import ModelCard from '../../../../../../components/models/ModelCard/ModelCard';
import {shortenVector} from '../../../../../../utils/format';
import NextButton from '../../NextButton/NextButton';
import {type BlockComponentProps} from '../../types';
import {CREATE_VECTOR_EMBEDDINGS, INTERACTIVE_DEMO_LANGUAGE_OPTIONS} from './constants';

export const Section1Header = () => {
  return (
    <Box>
      <Typography mb={2}>
        This demo illustrates how you can use the Pinecone Inference API to generate vector
        embeddings for your data, then use the Pinecone Vector Database to store and query the
        vectors.
      </Typography>
      <Typography> Begin by generating vector embeddings for a dataset:</Typography>
    </Box>
  );
};

export const ViewSampleData = () => {
  return (
    <Box>
      <Typography>
        This sample dataset includes information about blockbuster movies. You will use this sample
        data to generate vector embeddings.
      </Typography>
      <Box mt={2}>
        <MetadataTable dataset={moviesDataset} />
      </Box>
    </Box>
  );
};

export const CreateVectorEmbeddings = ({
  handleNext,
  status,
  blockTitle,
  sectionIndex,
  blockIndex,
}: BlockComponentProps) => {
  const selectedLanguage = useSelectedLanguage(INTERACTIVE_DEMO_LANGUAGE_OPTIONS);
  return (
    <Box>
      <Typography mb={2}>
        A vector embedding is a series of numerical values that represent different dimensions of
        the data. This is essential for understanding patterns, relationships, and underlying
        structures (i.e., its semantic information). The numerical representations of your data
        enable search and analysis capabilities. You create vector embeddings using an embedding
        model.
      </Typography>
      <Typography>
        The following code sample shows how you would use the Pinecone Inference API to access the{' '}
        <strong>{moviesDataset.embeddingModel.name}</strong> embedding model and create an embedding
        of the first movie’s plot summary.
      </Typography>
      <ModelCard model={moviesDataset.embeddingModel} />
      <CodingLanguageSelector
        label="View sample code in your preferred language"
        languageOptions={INTERACTIVE_DEMO_LANGUAGE_OPTIONS}
        sx={{
          width: '100%',
          mb: 0,
        }}
      />
      <CodeBlock
        code={CREATE_VECTOR_EMBEDDINGS[selectedLanguage]}
        language={selectedLanguage}
        copyButton={false}
      />
      <NextButton
        sectionIndex={sectionIndex}
        blockIndex={blockIndex}
        title="Create embedding"
        blockTitle={blockTitle}
        status={status}
        onClick={handleNext}
        loadingTimeout={0.25}
      />
    </Box>
  );
};

const formattedExampleVector = {
  ...moviesDataset.exampleVectorData[0],
  values: shortenVector(moviesDataset.exampleVectorData[0].values, 10),
};

export const UnderstandRecords = () => {
  return (
    <Box>
      <Typography>
        Once embeddings are generated, a <strong>record</strong> can be constructed. Each record
        contains the following information:
      </Typography>
      <Box mb={2}>
        <ul>
          <li>
            <strong>id:</strong> A unique identifier
          </li>
          <li>
            <strong>values:</strong> The vector embedding
          </li>
          <li>
            <strong>metadata:</strong> Optional, additional information to provide context and
            enable query filtering
          </li>
        </ul>
      </Box>
      <CodeBlock
        code={JSON.stringify(formattedExampleVector, null, 2)}
        language="javascript"
        showLineNumbers={false}
        copyButton={false}
      />
    </Box>
  );
};
