import {z} from 'zod';
import {indexName} from '../../pages/indexes/IndexCreationPage/utilities/schema';

const indexTarget = z.object({
  sourceName: z.string(),
  cloud: z.string(),
  region: z.string(),
  targetName: indexName,
});
export type IndexTargetType = z.infer<typeof indexTarget>;

export const migrationSchema = z.object({
  indexes: z.array(indexTarget),
});
export type MigrationSchemaType = z.infer<typeof migrationSchema>;
