import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {type Theme} from '@mui/material/styles';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {Outlet, useLocation} from 'react-router-dom';
import {AlertBarContext} from '../../../context/alert-bar';
import {RegistrationFormContext} from '../../../context/registration-form';
import {useNavigateToBilling, useNavigateToOrgMembers} from '../../../hooks/navigation';
import SuspendedPage from '../../../pages/app/SuspendedPage/SuspendedPage';
import {useSelectedOrganizationIsSuspended} from '../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../selectors/params';
import {useUserIsLoading} from '../../../selectors/userSession';
import {
  ALERT_BAR_HEIGHT,
  APP_BAR_HEIGHT,
  APP_MIN_WIDTH,
  CONTENT_PADDING,
} from '../../../styles/theme';
import ChurnModal from '../../app-modals/ChurnModal/ChurnModal';
import PricingChangeNotification from '../../app-modals/PricingChangeNotification/PricingChangeNotification';
import RegistrationModal from '../../app-modals/RegistrationModal/RegistrationModal';
import {useInitializeRegistrationFormContext} from '../../app-modals/RegistrationModal/hooks';
import AppBar from './AppBar/AppBar';
import CreateIndexWithDataTracker from './CreateIndexWithDataTracker/CreateIndexWithDataTracker';
import NavigationBar from './NavigationBar/NavigationBar';
import NotificationAlert from './NotificationAlert/NotificationAlert';
import PaymentAlertBar from './PaymentAlertBar/PaymentAlertBar';
import SupportTrialModal from './SupportTrialModal/SupportTrialModal';
import {useInitializeAlertBarContext} from './hooks/alert-bar';

const styles = {
  root: {
    height: '100vh',
    // Calculates 100vw minus system UI (e.g. vertical scrollbars)
    width: 'calc(100vw - (100vw - 100%));',
    minWidth: APP_MIN_WIDTH,
    overflowX: 'auto',
    overflowY: 'hidden',
    display: 'grid',
    gridTemplateColumns: `max-content 1fr`,
    gridTemplateAreas: `
      'alert alert'
      'appBar appBar'
      'navBar main'
      `,
    gridTemplateRows: 'max-content max-content 1fr',
  },
  main: (hasAlertBar: boolean) => ({
    gridArea: 'main',
    overflowY: 'auto',
    minHeight: `calc(100vh - ${APP_BAR_HEIGHT} ${hasAlertBar ? `- ${ALERT_BAR_HEIGHT}` : ''})`,
  }),
  skipLink: {
    position: 'absolute',
    top: 0,
    left: (theme: Theme) => theme.spacing(2),
    transform: 'translateY(-150%)',
    transition: ' transform 0.3s',
    backgroundColor: 'background.paper',
    '&:focus': {
      transform: (theme: Theme) => `translateY(${theme.spacing(2.2)})`,
    },
  },
  content: {
    backgroundColor: 'background.default',
    display: 'flex',
    flexDirection: 'column',
    px: CONTENT_PADDING,
    overflowX: 'auto',
    pb: 8,
    mx: 'auto',
    width: '100%',
  },
};

function MainLayout() {
  const alertBarContext = useInitializeAlertBarContext();
  const registrationFormContext = useInitializeRegistrationFormContext();

  const userIsLoading = useUserIsLoading();
  const selectedOrganizationId = useSelectedOrganizationId();
  const {path: billingPath} = useNavigateToBilling(selectedOrganizationId || '');
  const {path: membersPath} = useNavigateToOrgMembers(selectedOrganizationId);
  const location = useLocation();
  const isSuspended = useSelectedOrganizationIsSuspended();
  const showSuspended =
    isSuspended && billingPath !== location.pathname && membersPath !== location.pathname;
  return (
    <AlertBarContext.Provider value={alertBarContext}>
      <RegistrationFormContext.Provider value={registrationFormContext}>
        <LoadingContainer loading={userIsLoading}>
          <RegistrationModal />
          <ChurnModal />
          <PricingChangeNotification />
          <SupportTrialModal />
          <Box sx={styles.root}>
            <NotificationAlert />
            <PaymentAlertBar />
            <Button sx={styles.skipLink} href="#main" variant="outlined">
              Skip to content
            </Button>
            <AppBar />
            <NavigationBar hide={isSuspended} />
            <Box sx={styles.main(alertBarContext.pageHasAlertBar)}>
              <Box sx={styles.content} component="main" id="main">
                {showSuspended ? <SuspendedPage billingPath={billingPath} /> : <Outlet />}
              </Box>
            </Box>
          </Box>
          <CreateIndexWithDataTracker />
        </LoadingContainer>
      </RegistrationFormContext.Provider>
    </AlertBarContext.Provider>
  );
}

export default MainLayout;
