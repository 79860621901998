import LockIcon from '@mui/icons-material/LockOutlined';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import {getFlag} from '../../../utils/regions';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    pt: 2.5,
    gap: 1,
    width: 150,
    height: 148,
    borderBox: 'border-box',
  },
};

interface EnvironmentCardProps {
  region?: string;
  location?: string;
  flag?: string;
  isFree?: boolean;
  viewerHasSubscription: boolean;
}

function EnvironmentCard({
  region = '',
  location = '',
  flag,
  isFree,
  viewerHasSubscription,
}: EnvironmentCardProps) {
  return (
    <Box sx={[styles.root]}>
      <SvgIcon id="flag">{getFlag(flag)}</SvgIcon>
      <Typography variant="body1" fontWeight="bold">
        {location}
      </Typography>
      <Typography variant="subtitle2">{region}</Typography>
      {!viewerHasSubscription && !isFree ? (
        <span aria-label="Only available for paid plans">
          <LockIcon color="disabled" />
        </span>
      ) : null}
    </Box>
  );
}

export default EnvironmentCard;
