import Button from '@mui/material/Button';
import {useEffect} from 'react';
import {listAssistants} from '../../../actions/assistantActions';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {useControllerApi} from '../../../hooks/use-api';
import {useBooleanState} from '../../../hooks/utils';
import {useAssistantsAreLoading, useAssistantsList} from '../../../selectors/assistants';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import AssistantCreateModal from './AssistantCreateModal/AssistantCreateModal';
import AssistantEmptyState from './AssistantEmptyState/AssistantEmptyState';
import AssistantTable from './AssistantTable/AssistantTable';

export default function AssistantListPage() {
  const project = useSelectedGlobalProject();
  const assistantsLoading = useAssistantsAreLoading(project.id);
  const assistantList = useAssistantsList(project.id);
  const {setQueryData} = useControllerApi<{globalProjectId: string}>(project);
  const {
    val: openCreateModal,
    setTrue: showCreateModal,
    setFalse: hideCreateModal,
  } = useBooleanState();

  const hasAssistants = assistantList.length > 0;
  const createActionLoading = assistantsLoading && openCreateModal;
  const listLoading = assistantsLoading && !openCreateModal;

  useEffect(() => {
    if (project?.id) {
      setQueryData({action: listAssistants, data: {globalProjectId: project.id}});
    }
  }, [project.id, setQueryData]);

  if ((createActionLoading || !assistantsLoading) && assistantList.length === 0) {
    return (
      <>
        <AssistantEmptyState onOpenCreateModal={showCreateModal} />
        <AssistantCreateModal
          open={openCreateModal}
          close={hideCreateModal}
          loading={createActionLoading}
        />
      </>
    );
  }

  return (
    <>
      <Page
        loading={listLoading && !hasAssistants}
        header={
          <PageHeader
            title={hasAssistants ? `Assistants` : ''}
            actions={
              hasAssistants && (
                <Button variant="contained" onClick={showCreateModal}>
                  Create an assistant
                </Button>
              )
            }
          />
        }
      >
        <AssistantTable loading={listLoading} assistants={assistantList} project={project} />
      </Page>
      <AssistantCreateModal
        open={openCreateModal}
        close={hideCreateModal}
        loading={createActionLoading}
      />
    </>
  );
}
