interface ThemePalette {
  PRIMARY_MAIN: string;
  PRIMARY_DARK: string;
  PRIMARY_LIGHT: string;
  PRIMARY_CONTRAST_TEXT: string;
  BACKGROUND: string;
  PAPER: string;
  SURFACE: string;
  DIVIDER: string;
  SECONDARY: string;
  ERROR: string;
  WARNING: string;
  SUCCESS: string;
  INFO: string;
}

const PineconeColors: Record<string, ThemePalette> = {
  LIGHT: {
    PRIMARY_MAIN: '#1B17F5',
    PRIMARY_DARK: '#030080',
    PRIMARY_LIGHT: '#3C72FF',
    PRIMARY_CONTRAST_TEXT: '#FFF',
    BACKGROUND: '#FBFBFC',
    PAPER: '#FFF',
    SURFACE: '#F1F5F8',
    DIVIDER: '#D8DDDF',
    SECONDARY: '#72788D',
    ERROR: '#E31957',
    WARNING: '#DDB15D',
    SUCCESS: '#15B077',
    INFO: '#1B17F5',
  },
  DARK: {
    PRIMARY_MAIN: '#9F9DFB',
    PRIMARY_DARK: '#9F9DFB',
    PRIMARY_LIGHT: '#9F9DFB',
    PRIMARY_CONTRAST_TEXT: '#09091E',
    BACKGROUND: '#09091E',
    PAPER: '#001',
    SURFACE: '#333',
    DIVIDER: '#353742',
    SECONDARY: '#AED4E6',
    ERROR: '#F44336',
    WARNING: '#FFA726',
    SUCCESS: '#66BB6A',
    INFO: '#29B6F6',
  },
};

export default PineconeColors;
