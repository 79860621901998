import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import AuthPage from '../../../../components/layout/AuthPage/AuthPage';
import {DEEPLINK_TOKEN} from '../../../../constants';
import {useNavigateToKeysPageGlobal} from '../../../../hooks/navigation';
import {useConnectIntegration} from '../../../../selectors/integrations';
import ApiKeyWidget from './ApiKeyWidget/ApiKeyWidget';

function ConnectFinishPage() {
  const connection = useConnectIntegration();
  const apiKey = connection.data?.apiKey;
  const {path: keysPath} = useNavigateToKeysPageGlobal(
    connection.data?.organizationId ?? DEEPLINK_TOKEN,
    connection.data?.globalProjectId ?? DEEPLINK_TOKEN,
  );

  return (
    <AuthPage header="Connect in seconds...">
      {apiKey ? (
        <>
          <Box sx={{height: 24}} />
          <Typography>Retrieve your API key below.</Typography>
          <Box sx={{height: 12}} />
          <ApiKeyWidget apiKey={apiKey.value} />
          <Box sx={{height: 12}} />
          <Typography>
            You can manage your API keys in the{' '}
            <Link to={keysPath} target="_blank">
              Pinecone console
            </Link>
            .
          </Typography>
        </>
      ) : (
        <Typography color="error">
          {connection.error?.toString() || 'An unknown error has occurred.'}
        </Typography>
      )}
    </AuthPage>
  );
}

export default ConnectFinishPage;
