import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {type Theme, useTheme} from '@mui/material/styles';
import {Bar, type BarTooltipProps} from '@nivo/bar';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import AutoSizer from 'react-virtualized-auto-sizer';
import StyledChip from '../../../../../components/StyledChip';
import dayjs from '../../../../../utils/dayjs';
import {formatMoney} from '../../../../../utils/format';
import {
  DATE_FORMAT,
  GroupByOptions,
  type GroupByTypes,
  TimespanMap,
  type TimespanTypes,
  type UsageDatapoint,
} from '../config';

const chartTheme = (theme: Theme) => ({
  textColor: theme.palette.text.secondary,
  fontFamily: ['mediumllweb', 'sans-serif'].join(','),
  fontSize: 12,
  legends: {
    title: {
      text: {
        fontSize: 14,
        fill: theme.palette.text.primary,
      },
    },
  },
  grid: {
    line: {
      stroke: theme.palette.divider,
    },
  },
});

const styles = {
  chartContainer: {
    height: 500,
    my: 2,
    p: 3,
  },
  tooltipContainer: {
    p: 2,
    minWidth: 200,
    boxShadow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  center: {
    display: 'grid',
    placeItems: 'center',
  },
};

function groupPoint(points: UsageDatapoint[], groupBy: GroupByTypes) {
  const usage: Record<string, number> = {};
  points.forEach((point) => {
    if (usage[point[groupBy]]) {
      usage[point[groupBy]] += point.subtotalCharge;
    } else {
      usage[point[groupBy]] = point.subtotalCharge;
    }
  });
  return {
    date: points[0].date,
    ...usage,
  };
}

function formatDataAsChart(data: UsageDatapoint[], groupBy: GroupByTypes, timespan: TimespanTypes) {
  if (!data.length) {
    return [];
  }

  const MILLISECONDS_PER_DAY = 86400000;
  const {start, end} = TimespanMap[timespan];

  const groups: Record<string, UsageDatapoint[]> = {};

  for (let i = start; i <= end; i += MILLISECONDS_PER_DAY) {
    const formattedDate = dayjs.utc(i).format(DATE_FORMAT);
    groups[formattedDate] = [];
  }

  data.forEach((point) => {
    if (groups[point.date]) {
      groups[point.date].push(point);
    } else {
      groups[point.date] = [point];
    }
  });

  return Object.entries(groups).map(([key, value]) => {
    if (value.length > 0) {
      return groupPoint(value, groupBy);
    }
    return {date: key};
  });
}

type UsageChartProps = {
  data: UsageDatapoint[];
  groupBy: GroupByTypes;
  keys: string[];
  keyToColor: Record<string, string>;
  timespan: TimespanTypes;
  loading: boolean;
};

function UsageChart({data, groupBy, keys, keyToColor, timespan, loading}: UsageChartProps) {
  const theme = useTheme();

  const formattedData = formatDataAsChart(data, groupBy, timespan);

  // width and bar limits calculated from point where x-axis ticks begin to overlap
  function showValue(date: string, width: number) {
    const day = dayjs(date).dayOfYear();
    let factor = 1;
    if (width < 900 || formattedData.length > 35) {
      factor = 7;
    }
    return day % factor === 0;
  }
  const createTooltip = ({id, formattedValue, indexValue, color, label}: BarTooltipProps<any>) => (
    <Paper key={label} sx={styles.tooltipContainer}>
      <Typography variant="overline" color="text.secondary">
        Cost:
      </Typography>
      <Typography variant="h6" color="primary.dark">
        {formattedValue}
      </Typography>
      <Divider flexItem sx={{my: 1}} />
      <Typography
        variant="overline"
        color="text.secondary"
      >{`${GroupByOptions[groupBy]}:`}</Typography>
      <StyledChip size="small" color={color} label={String(id)} sx={{mx: 0}} />
      <Divider flexItem sx={{my: 1}} />
      <Typography variant="overline" color="text.secondary">
        Date:
      </Typography>
      <Typography variant="body2" fontWeight="bold">
        {dayjs(indexValue).format('MMMM Do YYYY')}
      </Typography>
    </Paper>
  );

  return (
    <Paper sx={styles.chartContainer}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">Usage Over Time</Typography>
        <Box>
          {keys.map((key, index) => (
            <StyledChip label={key} key={index} size="small" color={keyToColor[key]} />
          ))}
        </Box>
      </Box>
      <LoadingContainer loading={loading}>
        <AutoSizer>
          {({height, width}) => (
            <Bar
              height={height}
              width={width}
              theme={chartTheme(theme)}
              data={formattedData}
              keys={keys}
              indexBy="date"
              colors={(bar) => keyToColor[bar.id]}
              margin={{top: 50, right: 25, bottom: 75, left: 75}}
              enableLabel={false}
              labelSkipWidth={12}
              labelSkipHeight={12}
              axisBottom={{
                tickRotation: 30,
                tickPadding: 10,
                format: (value) => (showValue(value, width) ? value : ''),
              }}
              axisLeft={{
                format: (v) => formatMoney(v),
              }}
              valueFormat={(v) => formatMoney(v)}
              tooltip={createTooltip}
            />
          )}
        </AutoSizer>
      </LoadingContainer>
    </Paper>
  );
}

export default UsageChart;
