import Check from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import type React from 'react';
import RowWithIcon from './RowWithIcon';

type RowWithCheckProps = {
  text: string | React.ReactNode;
  sx?: object;
};

const styles = {
  checkIcon: {
    color: 'primary.main',
  },
};

function RowWithCheck({text, sx}: RowWithCheckProps) {
  return (
    <RowWithIcon
      icon={
        <Box sx={styles.checkIcon}>
          <Check />
        </Box>
      }
      sx={sx}
    >
      {text}
    </RowWithIcon>
  );
}

export default RowWithCheck;
