import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useElements, useStripe} from '@stripe/react-stripe-js';
import {useFormContext} from 'react-hook-form';

type PaymentFormButtonsProps = {
  submitText: string;
  includeCancel: boolean;
  onCancel: () => void;
};

const styles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 2,
    mt: 3,
  },
};

function PaymentFormButtons({submitText, includeCancel, onCancel}: PaymentFormButtonsProps) {
  const stripe = useStripe();
  const elements = useElements();
  const {
    formState: {isSubmitting},
  } = useFormContext();
  const disableSubmitButton = isSubmitting || !stripe || !elements;

  return (
    <Box sx={styles.buttonContainer}>
      {includeCancel && (
        <Button color="secondary" id="edit-billing-cancel" onClick={onCancel}>
          Cancel
        </Button>
      )}
      <Button
        id="edit-billing-submit"
        type="submit"
        variant="contained"
        disabled={disableSubmitButton}
      >
        {isSubmitting ? '...' : submitText}
      </Button>
    </Box>
  );
}

export default PaymentFormButtons;
