import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Alert from '@pinecone-experience/timber/Alert';
import {useEffect} from 'react';
import {createVercelOAuthConnection} from '../../../../actions/integrationActions';
import {useQueryParams} from '../../../../hooks/navigation';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useVercelIntegration} from '../../../../selectors/integrations';
import ConnectIndex from './ConnectIndex/ConnectIndex';
import LinkOrganization from './LinkOrganization/LinkOrganization';

const styles = {
  root: {
    backgroundColor: 'background.default',
    height: '100vh',
    width: '100vw',
    maxHeight: '100%',
    overflow: 'auto',
  },
  header: {
    width: '100%',
  },
  content: {
    display: 'grid',
    placeItems: 'center',
    margin: 'auto',
    mt: 2,
  },
};

function OAuthVercel2() {
  const {getQueryParam, setQueryParams} = useQueryParams();
  const {data, error: integrationError} = useVercelIntegration();
  const code = getQueryParam('code');
  const oauthId = getQueryParam('oauthId');
  const {setQueryData} = useDashboardApi<{code: string; oauthId?: string}>();
  useEffect(() => {
    if (code) {
      setQueryData({
        action: createVercelOAuthConnection,
        data: {code, oauthId},
      });
    }
  }, [code, oauthId, setQueryData]);

  useEffect(() => {
    if (!oauthId && data?.connection?.id) {
      setQueryParams({oauthId: data.connection.id});
    }
  }, [oauthId, data?.connection?.id, setQueryParams]);

  const error = (integrationError || '') as string;
  const linkedOrgId = data?.connection?.organizationId;

  return (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Button
          sx={{m: 2}}
          startIcon={<ExpandMoreIcon fontSize="large" sx={{transform: 'rotate(90deg)'}} />}
          href="https://vercel.com"
        >
          Back to Vercel
        </Button>
        <Divider />
      </Box>
      {error ? (
        <Alert
          severity="error"
          title="Vercel Integration Error"
          description={
            <Typography>
              {`${error}. Please restart the integration and if the error persists, please contact `}
              <Link
                sx={{textDecoration: 'underline'}}
                href="mailto:support@pinecone.io"
                target="_blank"
              >
                support@pinecone.io
              </Link>
              .
            </Typography>
          }
          sx={{mx: 4}}
        />
      ) : (
        <Box sx={styles.content}>{linkedOrgId ? <ConnectIndex /> : <LinkOrganization />}</Box>
      )}
    </Box>
  );
}

export default OAuthVercel2;
