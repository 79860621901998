import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {Link as RouterLink} from 'react-router-dom';
import {PUBLIC_DOCS} from '../../../../constants';
import {getOrgBillingPlansPath} from '../../../../hooks/navigation';
import dayjs from '../../../../utils/dayjs';

const RESET_DATE = dayjs().utc().add(1, 'month').startOf('month').format('MMMM Do, YYYY (UTC)');

export interface QuotaUnitTooltipProps {
  limit: number;
}

const styles = {
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    maxWidth: '40ch',
  },
};

export const StorageTooltip = ({limit}: QuotaUnitTooltipProps) => (
  <Box sx={styles.tooltip}>
    <Typography>
      <Link href={PUBLIC_DOCS.UNDERSTANDING_COST_STORAGE} target="_blank" rel="noreferrer">
        Storage
      </Link>{' '}
      is the total size of all indexes in your project.
    </Typography>
    <Typography>
      The max storage for this project is {limit.toLocaleString()}GB. Once you reach this limit,
      updates and upserts will be paused.
    </Typography>
    <Typography>
      To avoid interruptions,{' '}
      <Link component={RouterLink} to={getOrgBillingPlansPath()}>
        upgrade now
      </Link>
      .
    </Typography>
  </Box>
);

export const RuTooltip = ({limit}: QuotaUnitTooltipProps) => (
  <Box sx={styles.tooltip}>
    <Typography>
      <Link href={PUBLIC_DOCS.UNDERSTANDING_COST_RU} target="_blank" rel="noreferrer">
        Read units (RUs)
      </Link>{' '}
      measure the resources used by read operations such as query, fetch, and list.
    </Typography>
    <Typography>
      Your monthly RU limit is {limit.toLocaleString()}. Once you reach this limit, read access will
      be paused until {RESET_DATE}.
    </Typography>
    <Typography>
      To avoid interruptions,{' '}
      <Link component={RouterLink} to={getOrgBillingPlansPath()}>
        upgrade now
      </Link>
      .
    </Typography>
  </Box>
);

export const WuTooltip = ({limit}: QuotaUnitTooltipProps) => (
  <Box sx={styles.tooltip}>
    <Typography>
      <Link href={PUBLIC_DOCS.UNDERSTANDING_COST_WU} target="_blank" rel="noreferrer">
        Write units (WUs)
      </Link>{' '}
      measure the resources used by write operations such as upsert, update, and delete.
    </Typography>
    <Typography>
      Your monthly WU limit is {limit.toLocaleString()}. Once you reach this limit, write access
      will be paused until {RESET_DATE}.
    </Typography>
    <Typography>
      To avoid interruptions,{' '}
      <Link component={RouterLink} to={getOrgBillingPlansPath()}>
        upgrade now
      </Link>
      .
    </Typography>
  </Box>
);

export const AssistantStorageTooltip = ({limit}: QuotaUnitTooltipProps) => (
  <Box sx={styles.tooltip}>
    <Typography>Storage is the space used by Assistant files for this project.</Typography>
    <Typography>
      The max storage for this project is {limit.toLocaleString()}GB. Once you reach this limit, you
      won’t be able to upload new files unless you remove existing files.
    </Typography>
  </Box>
);

export const AssistantQueriesTooltip = ({limit}: QuotaUnitTooltipProps) => (
  <Box sx={styles.tooltip}>
    <Typography>Queries are the number of requests you make to Assistant.</Typography>
    <Typography>
      The query limit for this project is {limit.toLocaleString()}. Once you reach this limit,
      queries will be paused.
    </Typography>
  </Box>
);

export const AssistantPromptTokensTooltip = ({limit}: QuotaUnitTooltipProps) => (
  <Box sx={styles.tooltip}>
    <Typography>
      Input tokens are the tokens used to create the input to the answer generation process for
      Assistant.
    </Typography>
    <Typography>
      Each query to Assistant uses input tokens. The token limit for this project is{' '}
      {limit.toLocaleString()}. Once you reach this limit, queries will be paused.
    </Typography>
  </Box>
);
export const AssistantCompletionTokensTooltip = ({limit}: QuotaUnitTooltipProps) => (
  <Box sx={styles.tooltip}>
    <Typography>
      Output tokens are the tokens generated by Assistant in response to a query.
    </Typography>
    <Typography>
      Each query to Assistant creates output tokens. The token limit for this project is{' '}
      {limit.toLocaleString()}. Once you reach this limit, queries will be paused.
    </Typography>
  </Box>
);
