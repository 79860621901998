import Chip, {type ChipProps} from '@mui/material/Chip';

type FreeChipProps = {
  size?: ChipProps['size'];
  sx?: ChipProps['sx'];
};
const FreeChip = ({size = 'small', sx}: FreeChipProps) => (
  <Chip label="Starter plan" variant="outlined" size={size} sx={{ml: 1, ...sx}} />
);
export default FreeChip;
