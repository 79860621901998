import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useForm} from 'react-hook-form';
import {inviteToOrg} from '../actions/organizationActions';
import {Roles} from '../constants';
import {useDashboardApi} from '../hooks/use-api';
import {useBooleanState} from '../hooks/utils';
import {useIsOwnerOfOrganization, useSelectedOrganization} from '../selectors/organizations';
import {
  useGlobalProject,
  useGlobalProjectsFromSelectedOrganization,
  useIsOwnerOfSelectedProject,
  useSelectedGlobalProject,
} from '../selectors/projects';
import {isEmail} from '../utils/format';
import {getLegacyId} from '../utils/projects';
import {TRACKING_EVENTS, sendToGa} from '../utils/tracking';
import CreateButton from './CreateButton';
import ModalForm from './ModalForm';

const styles = {
  chip: {
    mr: 0.5,
    mt: 0.5,
  },
};

type InviteUsersButtonProps = {
  isOrg?: boolean;
};

function ProjectChip({projectId}: {projectId: string}) {
  const project = useGlobalProject(projectId);
  return <Chip label={project?.name} sx={{mr: 1}} />;
}

function InviteUsersButton({isOrg = false}: InviteUsersButtonProps) {
  const org = useSelectedOrganization();
  const selectedProject = useSelectedGlobalProject() || {};
  const isOrgOwner = useIsOwnerOfOrganization();
  const isProjectOwner = useIsOwnerOfSelectedProject();
  const projects = useGlobalProjectsFromSelectedOrganization();
  const projectOptions = projects.map((project) => ({
    label: project.name,
    value: project.id,
  }));

  const {val: showForm, setTrue: setShowForm, setFalse: setHideForm} = useBooleanState(false);
  const {watch, control, handleSubmit, setValue} = useForm({
    defaultValues: {
      emails: '',
      orgRole: Roles.OWNER,
      projRole: Roles.OWNER,
      projects: [],
    },
  });
  const shouldDisable = isOrg ? !isOrgOwner : !isProjectOwner;
  const orgRole = watch('orgRole');
  const emails = watch('emails');
  const selectedProjects = watch('projects');
  const stringToEmailArray = (stringInput: string) =>
    stringInput?.split(',').map((email) => email.replace(/\s+/g, '')) || [];
  const validatedArray = stringToEmailArray(emails).filter((email) => isEmail(email));
  const validateEmails = stringToEmailArray(emails).every((email) => isEmail(email));
  const {setQueryData} = useDashboardApi<{
    projects: string[];
    emails: string[];
    orgRole: Roles;
    organizationId: string;
    projectRole: Roles;
    organization_roles: Record<string, {role: Roles}>;
    project_roles: Record<string, {role: Roles}>;
  }>();
  const handleInviteUser = (formData: {
    emails: string;
    orgRole: string;
    projRole: string;
    projects: string[];
  }) => {
    const globalProjects = isOrg
      ? projects.filter((proj) => formData.projects.includes(proj.id))
      : [selectedProject];

    setQueryData({
      action: inviteToOrg,
      data: {
        projects: globalProjects.map((proj) => getLegacyId(proj)),
        emails: validatedArray,
        orgRole: isOrg ? (formData.orgRole as Roles) : Roles.USER,
        organizationId: org.id,
        projectRole: formData.projRole as Roles,
        organization_roles: {
          [org.id]: {role: isOrg ? (formData.orgRole as Roles) : Roles.USER},
        },
        project_roles: globalProjects.reduce((acc, proj) => {
          acc[proj.id] = {role: formData.projRole as Roles};
          return acc;
        }, {} as Record<string, {role: Roles}>),
      },
    });
    setValue('emails', '');
    setHideForm();
    sendToGa(TRACKING_EVENTS.INVITE_USER);
  };
  const tooltipMessage = `You must be ${
    isOrg ? 'an organization' : 'a project'
  } owner to invite users`;
  return (
    <>
      <CreateButton
        text="Invite Member"
        onClick={setShowForm}
        disabled={shouldDisable}
        tooltip={shouldDisable ? tooltipMessage : ''}
        customId={`invite-user-to-${isOrg ? 'org' : 'project'}-button`}
        dataCy={`invite-user-to-${isOrg ? 'org' : 'project'}-button`}
      />
      <ModalForm
        title="Invite Member"
        isValid
        onSubmit={handleSubmit(handleInviteUser)}
        submitText="Invite Member"
        open={showForm}
        close={setHideForm}
        form={
          <>
            {isOrg && (
              <SelectInput
                name="orgRole"
                control={control}
                options={[
                  {label: 'Owner', value: Roles.OWNER},
                  {label: 'User', value: Roles.USER},
                ]}
                autoFocus
                label="Organization Role"
                fullWidth
              />
            )}
            {orgRole === Roles.USER && (
              <SelectInput
                name="projects"
                control={control}
                options={projectOptions}
                autoFocus
                label="Projects"
                renderValue={() => {
                  if (!selectedProjects.length) {
                    return <Typography color="action.disabled">No Projects Selected</Typography>;
                  }
                  return selectedProjects.map((projId) => (
                    <ProjectChip key={projId} projectId={projId} />
                  ));
                }}
                multiple
                fullWidth
              />
            )}
            {(orgRole === Roles.USER || !isOrg) && (
              <SelectInput
                name="projRole"
                control={control}
                options={[
                  {label: 'Owner', value: Roles.OWNER},
                  {label: 'User', value: Roles.USER},
                ]}
                autoFocus
                label="Project Role"
                fullWidth
              />
            )}
            {validatedArray?.map((email) => (
              <Chip key={email} label={email} sx={styles.chip} />
            ))}
            <TextInput
              name="emails"
              control={control}
              error={!validateEmails && 'Invalid email'}
              required
              autoFocus
              fullWidth
              label="Enter emails"
              placeholder="user@domain.com"
              multiline
            />
          </>
        }
      />
    </>
  );
}

export default InviteUsersButton;
