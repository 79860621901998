import Box, {type BoxProps} from '@mui/material/Box';
import Button, {type ButtonProps} from '@mui/material/Button';
import Typography, {type TypographyProps} from '@mui/material/Typography';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import type * as React from 'react';
import {MODAL_WIDTH_SMALL} from '../styles/theme';

interface ModalFormProps {
  open: boolean;
  close: () => void;
  closeText?: string;
  form: React.ReactNode;
  title: string;
  subtitle?: string | React.ReactNode;
  isValid: boolean;
  onSubmit: () => void;
  submitText: string;
  submitButtonId?: string;
  submitColor?: ButtonProps['color'];
  bottomSection?: React.ReactNode;
  includeSubmit?: boolean;
  width?: BoxProps['width'];
  actionInfo?: React.ReactNode;
  customId?: string;
  titleSx?: TypographyProps['sx'];
  includeClose?: boolean;
  secondaryButton?: React.ReactNode;
  hideBackdrop?: boolean;
}

function ModalForm({
  open,
  close,
  form,
  title,
  subtitle = '',
  isValid,
  onSubmit,
  submitText,
  submitButtonId = '',
  submitColor = 'primary',
  bottomSection = null,
  includeSubmit = true,
  width = MODAL_WIDTH_SMALL,
  actionInfo = <div />,
  customId = '',
  titleSx = {},
  includeClose = true,
  closeText = 'Cancel',
  hideBackdrop,
  secondaryButton,
}: ModalFormProps) {
  const idString = customId || title.toLowerCase().split(' ').join('-');
  return (
    <BaseModal
      id={customId}
      open={open}
      onClose={close}
      hideBackdrop={hideBackdrop}
      showCloseIcon={!includeClose && !secondaryButton}
    >
      <form>
        <Box width={width} position="relative">
          <Box mb={2}>
            <Typography variant="h5" sx={titleSx}>
              {title}
            </Typography>
            <Typography>{subtitle}</Typography>
          </Box>
          {form}
          {includeSubmit ? (
            <Box display="flex" sx={{justifyContent: 'space-between'}}>
              {actionInfo}
              <Box display="flex" sx={{justifyContent: 'flex-end', width: '100%', mt: 2, mb: 0}}>
                {secondaryButton ||
                  (includeClose && (
                    <Button
                      id={`${idString}-cancel-button`}
                      onClick={close}
                      sx={{ml: 1, color: 'secondary.main', mr: 3}}
                    >
                      {closeText}
                    </Button>
                  ))}
                <Button
                  type="submit"
                  id={submitButtonId || `${idString}-submit-button`}
                  disabled={!isValid}
                  variant="contained"
                  color={submitColor}
                  onClick={onSubmit}
                  fullWidth={!includeClose}
                >
                  {submitText}
                </Button>
              </Box>
            </Box>
          ) : (
            ''
          )}
          {bottomSection}
        </Box>
      </form>
    </BaseModal>
  );
}

export default ModalForm;
