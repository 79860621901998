import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import {type Theme} from '@mui/material/styles';
import NumberInput from '@pinecone-experience/timber/Inputs/NumberInput';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useParams} from 'react-router-dom';
import ProjectEncryptionBadge from '../../../components/ProjectEncryptionBadge/ProjectEncryptionBadge';
import DeletionProtectionControl from '../../../components/indexes/DeletionProtectionControl/DeletionProtectionControl';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {CreateIndexTabs} from '../../../constants';
import {useCreateIndexHandler} from '../../../hooks/indexes';
import {useOrganizationEnvironments} from '../../../selectors/environments';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import CapacityModes from './CapacityModes/CapacityModes';
// TODO: move and rename
import IndexCreationFooter from './IndexCreationFooter/IndexCreationFooter';
import SetupWithModelButton from './SetupWithModelButton/SetupWithModelButton';
import {useSelectedIndex} from './hooks/selectedIndex';
import {type IndexSchema, indexSchema} from './utilities/schema';
import {getDefaults, getProviderFromEnv, getQueryParams, metricTypes} from './utilities/utils';

const styles = {
  nameCard: {
    py: 0.75,
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  nameField: {
    minWidth: '36ch',
    mt: 1.5,
  },
  inputRow: (disabled: boolean, theme: Theme) => ({
    mt: 1,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 2,
    opacity: disabled ? theme.palette.action.disabledOpacity : 1,
  }),
  metricsTooltip: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
};

const getTitle = (from_collection: boolean, from_backup: boolean) => {
  const base = `Create a new index`;
  if (from_collection) {
    return `${base} from a collection`;
  }
  if (from_backup) {
    return `${base} from a backup`;
  }
  return base;
};

const IndexCreationPage = () => {
  const project = useSelectedGlobalProject();
  const location = useLocation();

  const allEnvInfo = useOrganizationEnvironments(project.organization_id);
  const defaults = useMemo(() => {
    const serverlessFree = allEnvInfo.find((env) => {
      return env.is_free_tier_eligible;
    });
    return getDefaults(location.search, allEnvInfo, serverlessFree);
  }, [location.search, allEnvInfo]);

  const {
    handleSubmit,
    formState: {errors, isValid},
    watch,
    control,
    reset,
    setValue,
  } = useForm<IndexSchema>({
    mode: 'onChange',
    defaultValues: defaults,
    resolver: zodResolver(indexSchema),
  });

  useEffect(() => {
    reset(defaults, {keepDirtyValues: true});
  }, [defaults, reset]);

  // Ensure that environment info in the query param is used when tabs change.
  useEffect(() => {
    const {environment} = getQueryParams(location.search);

    if (environment) {
      setValue('provider', getProviderFromEnv(environment || '', allEnvInfo) || '');
      setValue('environment', environment || '');
    }
    // Cannot include allEnvInfo as a dep due to re-rendering loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setValue]);

  const params = useParams();
  const indexType = params['*'];
  const onServerlessSelectionPage = indexType === CreateIndexTabs.SERVERLESS;
  const createIndexHandler = useCreateIndexHandler(project, onServerlessSelectionPage);

  const data = watch();

  const selectedIndex = useSelectedIndex({
    formData: data,
    projectEnvironment: undefined,
    onServerlessSelectionPage,
  });

  return (
    <form onSubmit={handleSubmit(createIndexHandler)} autoComplete="off" id="create-index-form">
      <Page
        width="narrow"
        header={
          <PageHeader
            title={getTitle(!!defaults.from_collection, !!defaults.from_backup)}
            badge={<ProjectEncryptionBadge cmekEncrypted={project.force_encryption_with_cmek} />}
          />
        }
        footer={
          <IndexCreationFooter
            selectedIndex={selectedIndex}
            isValid={isValid && Object.keys(errors).length === 0}
          />
        }
      >
        <Card sx={styles.nameCard}>
          <Typography component="span" variant="h6">
            {project.name}
          </Typography>
          <Typography component="span" variant="h6">
            /
          </Typography>
          <TextInput
            name="name"
            control={control}
            placeholder="Enter index name"
            autoFocus
            sx={styles.nameField}
          />
        </Card>
        <Card>
          <Typography component="h2" variant="h6">
            Configuration
          </Typography>
          <Typography mb={2}>The dimensions and metric depend on the model you select.</Typography>
          <Box sx={(theme) => styles.inputRow(false, theme)}>
            <NumberInput
              name="dimensions"
              control={control}
              label="Dimensions"
              placeholder="Enter dimensions"
              disabled={!!defaults.from_collection || !!defaults.from_backup}
              tooltip="The length of the vector. Larger dimensions require more storage."
              sx={{width: 275}}
            />
            <SelectInput
              name="metric"
              control={control}
              options={metricTypes}
              label="Metric"
              placeholder="Select Metric"
              tooltip={
                <Box sx={styles.metricsTooltip}>
                  <span>How to measure the distance between the query and stored vectors.</span>
                  <span>The metric is model specific but most frequently &quot;cosine&quot;.</span>
                </Box>
              }
              sx={{width: 275}}
            />
            <Box sx={{pt: 1}}>
              <SetupWithModelButton setValue={setValue} />
            </Box>
          </Box>
        </Card>
        <CapacityModes
          control={control}
          setValue={setValue}
          watch={watch}
          fromCollection={!!defaults.from_collection}
          fromBackup={!!defaults.from_backup}
        />
        <DeletionProtectionControl control={control} />
      </Page>
    </form>
  );
};

export default IndexCreationPage;
