import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import {useEffect, useState} from 'react';
import {LANGUAGE_CONTENT} from '../../../../../../components/CodeSnippets/constants';
import {useSelectedLanguage} from '../../../../../../components/CodeSnippets/utils';
import {useBooleanState} from '../../../../../../hooks/utils';
import {useCreatedApiKey} from '../../../../../../selectors/keys';
import {useSelectedGlobalProjectId} from '../../../../../../selectors/params';
import {useSelectedGlobalProject} from '../../../../../../selectors/projects';
import CreateApiKeyModal from '../../../../ApiKeysPage/CreateApiKeyButton/CreateApiKeyModal';
import type {BlockComponentProps} from '../../types';
import {useGuideContext} from '../../utilities/GuideContext';
import {DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS} from './constants';

export const Section2Header = () => {
  return (
    <Box>
      <Typography mb={2}>
        Initialize a client connection to Pinecone and begin managing your data.
      </Typography>
    </Box>
  );
};

export const GetYourApiKey = ({sectionIndex}: BlockComponentProps) => {
  const {val, setTrue, setFalse} = useBooleanState();
  const {updateSectionState} = useGuideContext();
  const [showKey, setShowKey] = useState(true);
  const projectId = useSelectedGlobalProjectId();
  const createdKeyState = useCreatedApiKey(projectId);
  const [createdKeyValue, setCreatedKeyValue] = useState<string>();
  const displayedKey = showKey && createdKeyValue ? createdKeyValue : 'YOUR_API_KEY';

  useEffect(() => {
    if (createdKeyState?.data?.value) {
      setCreatedKeyValue(createdKeyState.data.value);
      updateSectionState(sectionIndex, 'api-key', createdKeyState.data.value);
    }
  }, [createdKeyState?.data?.value, sectionIndex, updateSectionState]);

  return (
    <Box>
      <Typography mb={2}>
        You need an API key to make API calls to your Pinecone project.
      </Typography>
      <CreateApiKeyModal open={val} onClose={setFalse} />
      <CodeBlock
        code={`PINECONE_API_KEY="${displayedKey}"`}
        language="text"
        showLineNumbers={false}
        controls={
          createdKeyValue ? (
            <IconButton
              id="copy-button"
              tooltip={showKey ? 'Hide API key' : 'Show API key'}
              icon={
                showKey ? <VisibilityIcon color="inherit" /> : <VisibilityOffIcon color="inherit" />
              }
              onClick={() => setShowKey((prev) => !prev)}
            />
          ) : (
            <Button onClick={setTrue} sx={{mr: 2}}>
              Generate API key
            </Button>
          )
        }
      />
    </Box>
  );
};

export const InitializeClient = ({sectionIndex}: BlockComponentProps) => {
  const selectedLanguage = useSelectedLanguage(DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS);
  const project = useSelectedGlobalProject();
  const {getSectionState} = useGuideContext();
  const createdKeyValue = getSectionState(sectionIndex, 'api-key');
  const [showKey, setShowKey] = useState(true);
  const selectedLanguageContent = LANGUAGE_CONTENT[selectedLanguage];
  return (
    <Box>
      {selectedLanguageContent.connectTextTemplate ? (
        <>
          <Typography mb={2}>
            Using your API key, initialize your client connection to Pinecone:
          </Typography>
          <CodeBlock
            language={selectedLanguage}
            code={selectedLanguageContent.connectTextTemplate({
              apiKey: showKey ? createdKeyValue : 'YOUR_API_KEY',
              withGetIndex: false,
              project,
              host: 'test',
            })}
            controls={
              createdKeyValue && (
                <IconButton
                  id="copy-button"
                  tooltip={showKey ? 'Hide API key' : 'Show API key'}
                  icon={
                    showKey ? (
                      <VisibilityIcon color="inherit" />
                    ) : (
                      <VisibilityOffIcon color="inherit" />
                    )
                  }
                  onClick={() => setShowKey((prev) => !prev)}
                />
              )
            }
          />
        </>
      ) : (
        <Typography>{`There is no initialize step needed for ${selectedLanguage}`}</Typography>
      )}
    </Box>
  );
};

export const InstallTheSDK = () => {
  const selectedLanguage = useSelectedLanguage(DEVELOPER_QUICKSTART_LANGUAGE_OPTIONS);
  const selectedLanguageContent = LANGUAGE_CONTENT[selectedLanguage];
  return (
    <Box>
      {selectedLanguageContent?.installText ? (
        <>
          <Typography mb={2}>
            Use an official Pinecone SDK for convenient access to the Pinecone API:
          </Typography>
          <CodeBlock
            language={selectedLanguage}
            code={selectedLanguageContent?.installText}
            showLineNumbers={false}
          />
        </>
      ) : (
        <Typography>{`There is no initialize step needed for ${selectedLanguage}`}</Typography>
      )}
    </Box>
  );
};
