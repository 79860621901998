import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@pinecone-experience/timber/Alert';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {getMarketplaceSetupEntity} from '../../actions/marketplaceActions';
import {type Providers} from '../../constants';
import {useNavigateToOrg, useQueryParams} from '../../hooks/navigation';
import {useDashboardApi} from '../../hooks/use-api';
import {useMarketplaceSetup} from '../../selectors/marketplaces';
import {type Marketplace} from '../../types';
import MarketplaceOrganizationConnector from './MarketplaceOrganizationConnector/MarketplaceOrganizationConnector';
import {validateMarketplaceSetup} from './utils';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  errorContainer: {
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
};

export const ERROR_MESSAGE = 'Error fetching marketplace information.';

function MarketplaceSetup() {
  const params = useParams();
  const marketplaceSetup = useMarketplaceSetup();
  const {go: goToConsole} = useNavigateToOrg();
  const {isLoading, hasError, error, marketplace: marketplaceRecord} = marketplaceSetup;

  const {getQueryParam} = useQueryParams();
  const setupTokenQueryParam = getQueryParam('setup_token');

  const {marketplaceType, marketplaceId, setupToken, validationError} = validateMarketplaceSetup({
    params,
    setup_token: setupTokenQueryParam,
  });

  const {setQueryData} = useDashboardApi<{
    marketplaceType: string;
    setupToken: string | null;
    marketplaceId: string;
  }>();

  useEffect(() => {
    const skip = hasError || isLoading || marketplaceRecord;
    if (!skip && marketplaceType && marketplaceId) {
      setQueryData({
        action: getMarketplaceSetupEntity,
        data: {
          marketplaceType,
          setupToken: setupToken || null,
          marketplaceId,
        },
      });
    }
  }, [
    marketplaceType,
    marketplaceRecord,
    marketplaceId,
    hasError,
    setupToken,
    isLoading,
    setQueryData,
  ]);

  if (validationError || hasError) {
    return (
      <Box sx={styles.errorContainer}>
        <Alert
          severity="error"
          title="Marketplace Setup Error"
          description={validationError || error || ERROR_MESSAGE}
          sx={{width: '100%'}}
        />
        <Button fullWidth onClick={() => goToConsole()} variant="contained">
          Continue to Console
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={styles.root}>
      <LoadingContainer loading={isLoading}>
        <MarketplaceOrganizationConnector
          marketplaceRecord={marketplaceRecord as Marketplace}
          marketplaceType={marketplaceType as Providers}
          setupToken={setupToken}
        />
      </LoadingContainer>
    </Box>
  );
}

export default MarketplaceSetup;
