import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

type SuccessCardProps = {
  title: string;
  text: string | React.ReactNode;
  ctaText: string;
  cta: () => void;
  show?: boolean;
};

const styles = {
  circleHighlight: {
    borderRadius: '50%',
    backgroundColor: 'action.selected',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48,
  },
};

function SuccessCard({title, text, ctaText, cta, show}: SuccessCardProps) {
  return (
    <Box sx={{textAlign: 'center', display: show ? 'block' : 'none'}}>
      <Box sx={styles.circleHighlight}>
        <CheckIcon color="info" />
      </Box>
      <Typography color="primary.dark" variant="h5" mt={2} mb={1}>
        {title}
      </Typography>
      <Typography variant="body1" mb={2}>
        {text}
      </Typography>
      <Button variant="contained" onClick={cta}>
        {ctaText}
      </Button>
    </Box>
  );
}

export default SuccessCard;
