import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@pinecone-experience/timber/Alert';
import CopyButton from '@pinecone-experience/timber/CopyButton';
import StatusBox from '../../../../../components/StatusBox';
import FreeChip from '../../../../../components/Utilities/FreeChip';
import ConnectIndexButton from '../../../../../components/indexes/ConnectIndexButton/ConnectIndexButton';
import IndexActions from '../../../../../components/indexes/IndexActions/IndexActions';
import MigrationModal from '../../../../../components/indexes/MigrationModal/MigrationModal';
import {PROVIDER_INFORMATION_MAP, type Providers} from '../../../../../constants';
import {useIndexStatusBox} from '../../../../../hooks/indexes';
import {useNavigateToIndexPageGlobal} from '../../../../../hooks/navigation';
import {isUpgrading, useIsFreeIndex, useIsStarter} from '../../../../../hooks/specUtils';
import {useBooleanState} from '../../../../../hooks/utils';
import {useSelectedOrganizationId} from '../../../../../selectors/params';
import {usePrivateEndpointLink} from '../../../../../selectors/privateEndpoints';
import {useSelectedGlobalProject} from '../../../../../selectors/projects';
import {type V4IndexInfoResponse} from '../../../../../types';
import {getTotalPods} from '../../../../../utils/indexes';
import {getSpecs} from '../../../../../utils/services';

const styles = {
  root: {
    p: 3,
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: 8,
    },
  },
  actions: {
    position: 'absolute',
    top: 16,
    right: 20,
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
};

const InfoItem = ({
  label,
  value,
  seperator = true,
}: {
  label: string;
  value?: string | number;
  seperator?: boolean;
}) => {
  return (
    <Box sx={styles.row}>
      <Typography fontWeight="bold" color="text.secondary">
        {label}:
      </Typography>
      <Typography ml={1} color="text.secondary">
        {value}
      </Typography>
      {seperator && (
        <Typography mx={1} fontWeight="bold" color="text.secondary">
          •
        </Typography>
      )}
    </Box>
  );
};

const MigrationAlert = ({
  upgrading,
  isStarter,
  setShowMigrate,
}: {
  upgrading?: boolean;
  isStarter: boolean;
  setShowMigrate: () => void;
}) => {
  if (upgrading || !isStarter) {
    return null;
  }
  return (
    <Alert
      icon={<RocketLaunchIcon sx={{color: 'text.primary'}} />}
      description={
        <>
          <Typography component="span" sx={{fontWeight: 'bold', pr: 1}}>
            New!
          </Typography>
          Convert this into a serverless index and scale past 100k records, all in the Starter tier.
        </>
      }
      action={
        <Button sx={(theme) => ({color: theme.palette.primary.dark})} onClick={setShowMigrate}>
          Start Conversion
        </Button>
      }
    />
  );
};

const IndexCard = ({
  service,
  serviceData,
}: {
  service: string;
  serviceData?: V4IndexInfoResponse;
}) => {
  const prevent = (evt: React.MouseEvent) => evt.stopPropagation();
  const selectedOrgId = useSelectedOrganizationId();
  const selectedProject = useSelectedGlobalProject();
  const {go: goToIndexPage} = useNavigateToIndexPageGlobal(selectedOrgId, selectedProject.id);
  const {statusText, statusColor} = useIndexStatusBox(service);
  const host = `https://${serviceData?.host || ''}`;
  const {podSpec, serverlessSpec} = getSpecs(serviceData);
  const privateLink = usePrivateEndpointLink(
    selectedProject.id,
    serverlessSpec?.cloud || '',
    serverlessSpec?.region || '',
    host,
  );
  const isFree = useIsFreeIndex(serviceData);
  const isStarter = useIsStarter(serviceData);
  const upgrading = isUpgrading(serviceData);
  const {val: showMigrate, setTrue: setShowMigrate, setFalse: hideMigrate} = useBooleanState();
  return (
    <Paper
      sx={styles.root}
      onClick={() => goToIndexPage(service)}
      data-testid="index-card"
      id="index-card"
      component="button"
    >
      <MigrationModal service={service} open={showMigrate} close={hideMigrate} />
      <Box mb={1} display="flex" alignItems="center">
        <Typography variant="h6" color="primary.dark">
          {service}
        </Typography>
        {isFree && <FreeChip sx={{ml: 1}} />}
        <StatusBox color={statusColor} statusText={statusText} sx={{ml: 1}} />
      </Box>
      {serviceData && (
        <Box sx={{textAlign: 'left'}}>
          <Box sx={styles.row} my={0.5}>
            {privateLink ? (
              <Box sx={{mr: 3, display: 'flex'}}>
                <InfoItem label="Private Endpoint" value={privateLink} seperator={false} />
                <CopyButton text={privateLink} description="Private Endpoint" sx={{my: 0}} />
              </Box>
            ) : null}
            <InfoItem label="Host" value={host} seperator={false} />
            <CopyButton text={host} description="host" sx={{my: 0}} />
          </Box>
          <Box sx={styles.row} flexWrap="wrap">
            {podSpec && <InfoItem label="Environment" value={podSpec.environment} />}
            {serverlessSpec && (
              <InfoItem
                label="Cloud"
                value={PROVIDER_INFORMATION_MAP[serverlessSpec.cloud as Providers]?.name}
              />
            )}
            {serverlessSpec && <InfoItem label="Region" value={serverlessSpec.region} />}
            <InfoItem label="Type" value={serverlessSpec ? 'Serverless' : 'Pods'} />
            {podSpec && <InfoItem label="Pod Type" value={podSpec.pod_type} />}
            <InfoItem label="Dimension" value={serviceData?.dimension} seperator={!!podSpec} />
            {podSpec && (
              <InfoItem label="Total Pods" value={getTotalPods(podSpec)} seperator={false} />
            )}
          </Box>
        </Box>
      )}
      <Box onClick={prevent}>
        <MigrationAlert
          upgrading={upgrading}
          isStarter={isStarter}
          setShowMigrate={setShowMigrate}
        />
      </Box>
      <Box sx={styles.actions} onClick={prevent}>
        <IndexActions serviceInfo={serviceData} service={service} />
        <ConnectIndexButton />
      </Box>
    </Paper>
  );
};

export default IndexCard;
