import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {type Theme} from '@mui/material/styles';
import {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {registerUser} from '../../actions/userActions';
import {
  useCompletedRegistration,
  useSkipRegistration,
} from '../../components/app-modals/RegistrationModal/utils/registration';
import {Purpose} from '../../constants';
import {
  useNavigateToAssistants,
  useNavigateToCreateIndexGlobal,
  useNavigateToDefaultOrg,
  useNavigateToGetStartedPage,
  useNavigateToInferenceGlobal,
} from '../../hooks/navigation';
import {useDashboardApi} from '../../hooks/use-api';
import {useAuthData} from '../../selectors/auth';
import {useOrganizations} from '../../selectors/organizations';
import {useRegistrationNeeded} from '../../selectors/userSession';
import {APP_MIN_WIDTH, MODAL_WIDTH_MEDIUM} from '../../styles/theme';
import {HEAP_TRACKING_EVENTS, sendToHeap} from '../../utils/tracking';
import MarketingContent from './MarketingContent';
import StepButtons from './StepButtons';
import {HasEmbeddingModel} from './contants';
import Embeddings from './steps/Embeddings';
import UseCases from './steps/UseCases';
import UserInformation from './steps/UserInformation';
import {RegistrationFormProvider, RegistrationSteps} from './utilities/RegistrationFormContext';
import schema, {type RegistrationData} from './utilities/schema';

// Must match background color of Auth0 login page.
const REGISTRATION_PAGE_BACKGROUND_COLOR = '#01004B';

// Must match breakpoint in Auth0 login page.
const MOBILE_BREAKPOINT = APP_MIN_WIDTH;

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
    height: '100vh',
    width: '100vw',
    position: 'relative',
    background: REGISTRATION_PAGE_BACKGROUND_COLOR,
    [`@media (max-width: ${MOBILE_BREAKPOINT})`]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr auto',
    },
  },
  form: {
    width: '100%',
    height: '100%',
    px: 12,
    py: 14,
    backgroundColor: 'background.paper',
    overflowY: 'scroll',
    [`@media (max-width: ${MOBILE_BREAKPOINT})`]: {
      p: 2,
      pt: 4,
    },
  },
  formContent: {
    height: '100%',
    mx: 'auto',
    maxWidth: MODAL_WIDTH_MEDIUM,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  marketing: {
    display: 'grid',
  },
  marketingContent: {
    [`@media (max-width: ${MOBILE_BREAKPOINT})`]: {
      display: 'none',
    },
  },
  skipButton: {
    position: 'absolute',
    bottom: (theme: Theme) => theme.spacing(14),
    right: (theme: Theme) => theme.spacing(12),
    color: 'common.white',
    borderRadius: 2,
    backdropFilter: 'blur(5px)',
    [`@media (max-width: ${MOBILE_BREAKPOINT})`]: {
      position: 'relative',
      bottom: 'unset',
      right: 'unset',
      justifySelf: 'flex-end',
      pr: 2,
    },
  },
};

function RegistrationPage() {
  const {go: goToConsole} = useNavigateToDefaultOrg();
  const isRegistrationNeeded = useRegistrationNeeded();
  const {setCompletedRegistration} = useCompletedRegistration();
  const {setSkipRegistration} = useSkipRegistration();
  const {go: goToAssistants} = useNavigateToAssistants();
  const {go: goToCreateIndex} = useNavigateToCreateIndexGlobal();
  const {go: goToGetStarted} = useNavigateToGetStartedPage();
  const {go: goToInference} = useNavigateToInferenceGlobal();
  const userOrganizations = useOrganizations();
  const userOrganizationId = Object.keys(userOrganizations)[0];

  useEffect(() => {
    if (!isRegistrationNeeded) {
      goToConsole({replace: true});
    }
  }, [isRegistrationNeeded, goToConsole]);

  useEffect(() => {
    sendToHeap(HEAP_TRACKING_EVENTS.REGISTRATION_VIEWED);
  }, []);

  const auth0Data = useAuthData();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: auth0Data?.given_name,
      lastName: auth0Data?.family_name,
      purpose: Purpose.BUSINESS,
      company: undefined,
      hasEmbeddingModel: undefined,
      useCase: undefined,
      language: undefined,
    } as unknown as RegistrationData,
    resolver: zodResolver(schema),
  });

  const {setValue, watch, handleSubmit} = methods;

  const purpose = watch('purpose');
  const hasEmbeddingModel = watch('hasEmbeddingModel');

  useEffect(() => {
    if (purpose === Purpose.PERSONAL) {
      setValue('company', 'N/A');
    }
    if (purpose === Purpose.BUSINESS) {
      setValue('company', '');
    }
  }, [setValue, watch, purpose]);

  const onSkipRegistration = () => {
    setSkipRegistration();
    sendToHeap(HEAP_TRACKING_EVENTS.REGISTRATION_SKIPPED);
    goToConsole();
  };

  const {setQueryData} = useDashboardApi<{
    orgId?: string;
    projectId?: string;
    registrationData: RegistrationData;
  }>();

  const registrationFormSubmitHandler = (data: RegistrationData) => {
    setQueryData({
      action: registerUser,
      data: {
        orgId: userOrganizationId,
        registrationData: data,
      },
    });

    /*
      Because the user will be navigated before the registerUser call is completed, we set this flag
      to prevent the user from being redirected back to the registration page. This flag becomes
      irrelevant once the registerUser call is successful.
    */
    setCompletedRegistration();

    switch (hasEmbeddingModel) {
      case HasEmbeddingModel.YES:
        goToCreateIndex();
        break;
      case HasEmbeddingModel.DECIDING:
        goToInference();
        break;
      case HasEmbeddingModel.WHAT_ARE_EMBEDDINGS:
        goToGetStarted();
        break;
      case HasEmbeddingModel.USING_ASSISTANT:
        goToAssistants();
        break;
      default:
        goToCreateIndex();
    }
  };

  return (
    <RegistrationFormProvider>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(registrationFormSubmitHandler)}>
          <Box sx={styles.root}>
            <Box sx={styles.form}>
              <Box sx={styles.formContent}>
                <UserInformation step={RegistrationSteps.USER_INFORMATION} />
                <UseCases step={RegistrationSteps.USE_CASES} />
                <Embeddings step={RegistrationSteps.EMBEDDINGS} />
                <Box flexGrow={1} />
                <StepButtons />
              </Box>
            </Box>
            <Box sx={styles.marketing}>
              <Box sx={styles.marketingContent}>
                <MarketingContent />
              </Box>
              <Button color="inherit" onClick={onSkipRegistration} sx={styles.skipButton}>
                <u>Skip Registration</u>
              </Button>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </RegistrationFormProvider>
  );
}

export default RegistrationPage;
