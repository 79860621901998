import Box from '@mui/material/Box';
import {Plans} from '../../../../constants';
import {
  useSelectedOrganizationIsSuspended,
  useSelectedOrganizationPlan,
} from '../../../../selectors/organizations';
import BillingContactCard from './BillingContactCard/BillingContactCard';
import CurrentPlanCard from './CurrentPlanCard/CurrentPlanCard';
import SpecialPlanCard from './SpecialPlanCard/SpecialPlanCard';
import SuspendedPlanCard from './SuspendedPlanCard/SuspendedPlanCard';

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr',
    gap: 3,
    mb: 3,
  },
};

const BillingDetails = () => {
  const currentPlan = useSelectedOrganizationPlan();
  const isSuspended = useSelectedOrganizationIsSuspended();
  if (currentPlan === Plans.DEDICATED || currentPlan === Plans.INTERNAL) {
    return <SpecialPlanCard />;
  }
  return (
    <Box sx={styles.container}>
      <BillingContactCard />
      {isSuspended ? <SuspendedPlanCard /> : <CurrentPlanCard />}
    </Box>
  );
};

export default BillingDetails;
