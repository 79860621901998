import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import Table from '@pinecone-experience/timber/Table';
import {useMemo, useState} from 'react';
import {PUBLIC_DOCS_URL} from '../../../../constants';
import {GUTTER} from '../../../../styles/theme';
import {sortNamespaces} from '../../../../utils/data';
import {type IndexExplorerState} from '../IndexExplorer/utilities/utils';
import IndexTabHeader from '../IndexTabHeader/IndexTabHeader';
import {InternetAccessDisabled} from '../InternetAccessDisabled/InternetAccessDisabled';
import NamespaceActions from './NamespaceActions';

type IndexNamespaceListProps = {
  indexExplorerState: IndexExplorerState;
  internetDisabled?: boolean;
};

function IndexNamespaceList({indexExplorerState, internetDisabled}: IndexNamespaceListProps) {
  const {indexStats} = indexExplorerState;
  const [filter, setFilter] = useState('');
  const namespaceData = useMemo(() => {
    const namespaces = sortNamespaces(indexStats)
      .map((namespace) => {
        return {
          name: namespace,
          vectorCount: indexStats?.namespaces?.[namespace].vectorCount,
        };
      })
      .filter((n) => {
        return n.name.includes(filter);
      });
    if (namespaces.length === 0 && !filter) {
      return [{name: '', vectorCount: 0}];
    }
    return namespaces;
  }, [indexStats, filter]);
  const cellRenderer = (row: number, col: number) => {
    const namespace = namespaceData[row];

    if (col === 0) {
      return (
        <Box>
          <Typography color={namespace.name ? '' : 'secondary'}>
            {namespace.name || '( Default )'}
          </Typography>
        </Box>
      );
    }
    if (col === 1) {
      return (
        <Box>
          <Typography>{namespace.vectorCount}</Typography>
        </Box>
      );
    }
    if (col === 2) {
      return (
        <NamespaceActions indexExplorerState={indexExplorerState} namespace={namespace.name} />
      );
    }
    return '';
  };

  if (internetDisabled) {
    return <InternetAccessDisabled />;
  }

  return (
    <Box>
      <IndexTabHeader
        title="Namespaces"
        subtitle={
          <>
            Namespaces let you partition an index into groupings for multi-tenancy or query speed.{' '}
            <Link
              href={`${PUBLIC_DOCS_URL}/guides/indexes/use-namespaces`}
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </Link>
            .
          </>
        }
        actions={
          <TextInput
            id="namespace-filter"
            searchIcon
            value={filter}
            onChange={setFilter}
            placeholder="Search Namespaces"
            hideError
            disabled={!indexStats}
          />
        }
      />
      <Table
        isLoading={!indexStats}
        columns={[
          {name: 'Name', dataType: 'string'},
          {name: 'Number of Vectors', dataType: 'number'},
          {name: 'Actions', dataType: 'actions'},
        ]}
        numRows={namespaceData.length}
        cellRenderer={cellRenderer}
        pagination={{labelled: false, sizeControl: true}}
        bodyCellStyleOverrides={{
          pt: 1,
          pb: 1,
        }}
        gutter={GUTTER}
      />
    </Box>
  );
}

export default IndexNamespaceList;
