import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Alert from '@pinecone-experience/timber/Alert';
import EmptyState from '../../../components/layout/EmptyState/EmptyState';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {PUBLIC_DOCS, PlanNameMapping} from '../../../constants';
import {Flags, useFlag} from '../../../hooks/flags';
import {
  useIsOwnerOfOrganization,
  useIsSelectedOrganizationSubscribed,
  useSelectedOrganization,
} from '../../../selectors/organizations';
import Usage from './Usage/Usage';

function UsagePage() {
  const selectedOrg = useSelectedOrganization();
  const isSubscribed = useIsSelectedOrganizationSubscribed();
  const shouldShowUsageReportExperience = useFlag(Flags.SHOULD_SHOW_USAGE_REPORT_EXPERIENCE);
  const isOwner = useIsOwnerOfOrganization();
  if (!shouldShowUsageReportExperience) {
    return (
      <Page header={<PageHeader title="Usage" />}>
        <EmptyState
          heading="Unavailable"
          description="Usage is not available for your organization."
        />
      </Page>
    );
  }

  if (!isSubscribed) {
    return (
      <Page header={<PageHeader title="Usage" />}>
        <EmptyState
          heading="Unavailable"
          description={`Usage is not available on the ${PlanNameMapping[selectedOrg.plan]} plan.`}
        />
      </Page>
    );
  }

  if (!isOwner) {
    return (
      <Page header={<PageHeader title="Usage" />}>
        <EmptyState
          heading="Unauthorized"
          description="Usage is only available to owners of the organization."
        />
      </Page>
    );
  }

  return (
    <Page
      header={
        <PageHeader
          title="Usage"
          description={
            <>
              Track and monitor resource allocation across your <strong>{selectedOrg?.name}</strong>{' '}
              Projects
            </>
          }
        />
      }
    >
      <Box sx={{display: 'flex'}}>
        <Alert
          sx={{my: 0, width: '100%'}}
          description={
            <Typography>
              Usage and cost for public preview products, including{' '}
              <Link href={PUBLIC_DOCS.INFERENCE} target="_blank" underline="always">
                Pinecone Inference
              </Link>{' '}
              and{' '}
              <Link href={PUBLIC_DOCS.ASSISTANT} target="_blank" underline="always">
                Pinecone Assistant
              </Link>
              , aren&lsquo;t yet displayed in this view.
            </Typography>
          }
        />
      </Box>
      <Usage />
    </Page>
  );
}
export default UsagePage;
