import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SelectBoxInput from '@pinecone-experience/timber/Inputs/SelectBoxInput';
import {useFormContext} from 'react-hook-form';
import {HasEmbeddingModelOptions} from '../contants';
import {type RegistrationSteps} from '../utilities/RegistrationFormContext';
import StepWrapper from './StepWrapper';

const styles = {
  header: {
    mb: 2,
    maxWidth: '50ch',
  },
  inputGrid: {
    display: 'flex',
    gap: 1,
    '> div': {
      flex: 1,
    },
  },
};

function Embeddings({step}: {step: RegistrationSteps}) {
  const {control} = useFormContext();

  return (
    <StepWrapper step={step}>
      <>
        <Box sx={styles.header}>
          <Typography component="h1" variant="h5">
            Do you have an embedding model or are you using Pinecone Assistant?
          </Typography>
        </Box>
        <Box>
          <SelectBoxInput
            layout="stack"
            name="hasEmbeddingModel"
            control={control}
            options={HasEmbeddingModelOptions}
            gap={3}
          />
        </Box>
      </>
    </StepWrapper>
  );
}

export default Embeddings;
