import {useCallback, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {
  acceptAssistantsTerms,
  getAcceptedAssistantsTerms,
  getAssistantsUsage,
} from '../../../actions/assistantActions';
import Page from '../../../components/layout/Page/Page';
import {useAssistantDataApi, useControllerApi} from '../../../hooks/use-api';
import {useBooleanState} from '../../../hooks/utils';
import {useAcceptedAssistantsTerms} from '../../../selectors/assistants';
import {useOrganizationsAreLoading} from '../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../selectors/params';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import AssistantWelcomeDialog from './AssistantWelcomeDialog/AssistantWelcomeDialog';

export default function AssistantsGuard() {
  const orgId = useSelectedOrganizationId();
  const orgIsLoading = useOrganizationsAreLoading();
  const project = useSelectedGlobalProject();
  const acceptedTermsResponse = useAcceptedAssistantsTerms(orgId);
  const {setQueryData} = useControllerApi<{organizationId: string}>(
    project,
    getAcceptedAssistantsTerms,
    {organizationId: orgId},
  );
  const {setQueryData: setUsageQueryData} = useAssistantDataApi<{globalProjectId: string}>(
    project?.id,
  );

  // Strictly check for true/false to ignore error and undefined cases
  const hasNotAcceptedTerms = acceptedTermsResponse?.data === false;
  const hasAcceptedTerms = acceptedTermsResponse?.data === true;
  const loadingResponse =
    acceptedTermsResponse?.data === undefined && acceptedTermsResponse?.loading;

  const {
    val: showWelcomeDialog,
    setTrue: openWelcomeDialog,
    setFalse: hideWelcomeDialog,
  } = useBooleanState(hasNotAcceptedTerms);

  useEffect(() => {
    if (hasAcceptedTerms && showWelcomeDialog) {
      hideWelcomeDialog();
      // Refetch the usage data after accepting the terms
      setUsageQueryData({
        action: getAssistantsUsage,
        data: {globalProjectId: project.id},
      });
    }
  }, [project.id, hasAcceptedTerms, showWelcomeDialog, hideWelcomeDialog, setUsageQueryData]);

  useEffect(() => {
    if (hasNotAcceptedTerms) {
      openWelcomeDialog();
    }
  }, [hasNotAcceptedTerms, openWelcomeDialog]);

  const handleAccept = useCallback(() => {
    setQueryData({
      action: acceptAssistantsTerms,
      data: {organizationId: orgId},
    });
  }, [setQueryData, orgId]);

  if (orgIsLoading || !orgId || !project?.id || !acceptedTermsResponse || loadingResponse) {
    return <Page loading />;
  }

  if (showWelcomeDialog) {
    return (
      <>
        <Page loading />
        <AssistantWelcomeDialog
          open={showWelcomeDialog}
          loading={acceptedTermsResponse?.loading}
          onClose={handleAccept}
        />
      </>
    );
  }

  return <Outlet />;
}
