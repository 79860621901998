import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import SelectBoxInput from '@pinecone-experience/timber/Inputs/SelectBoxInput';
import {useEffect, useMemo} from 'react';
import {
  type Control,
  type Path,
  type PathValue,
  type UseFormSetValue,
  type UseFormWatch,
} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {PROVIDER_INFORMATION_MAP, type Providers} from '../../../constants';
import {useBooleanState} from '../../../hooks/utils';
import {useSelectableFreeEnvironments} from '../../../selectors/environments';
import {useSelectedOrganizationId} from '../../../selectors/params';
import {type Environment} from '../../../types';
import EnvironmentCard from '../EnvironmentCard/EnvironmentCard';
import ProviderCard from '../ProviderCard/ProviderCard';
import RegionRequestModal from './RegionRequestModal/RegionRequestModal';

type FormValuesWithRegionInfo = {
  provider: string;
  environment: string;
};

type RegionPickerProps<T extends FormValuesWithRegionInfo> = {
  control: T extends FormValuesWithRegionInfo ? Control<T> : never;
  watch: UseFormWatch<T>;
  availableEnvironments: Environment[];
  setValue: UseFormSetValue<T>;
  isServerless?: boolean;
  locked: boolean;
  allowRegionRequests?: boolean;
  viewerHasSubscription: boolean;
};

function RegionPicker<T extends FormValuesWithRegionInfo>({
  control,
  availableEnvironments,
  watch,
  setValue,
  isServerless = false,
  locked,
  allowRegionRequests = true,
  viewerHasSubscription,
}: RegionPickerProps<T>) {
  const orgId = useSelectedOrganizationId();
  const params = useParams();
  const currentTab = params['*'];
  const availableProviders = useMemo(
    () => new Set(availableEnvironments.map((env) => env.provider)),
    [availableEnvironments],
  );
  const providerOptions = useMemo(() => {
    return [...availableProviders].map((value: Providers) => {
      const {logo, logoDark} = PROVIDER_INFORMATION_MAP[value];
      return {
        value,
        component: () => ProviderCard(value, logo, logoDark),
        disabled: locked,
      };
    });
  }, [availableProviders, locked]);
  const provider = watch('provider' as Path<T>) as string;
  const environment = watch('environment' as Path<T>) as string;
  const filteredEnvironments = useMemo(
    () => availableEnvironments.filter((env) => env.provider === provider),
    [provider, availableEnvironments],
  );
  const freeEnvironments = useSelectableFreeEnvironments(orgId);

  const environmentOptions = useMemo(() => {
    return filteredEnvironments.map((env) => {
      const isFree = freeEnvironments.some((freeEnv) => freeEnv.name === env.name);
      const freeUserPaidEnv = !viewerHasSubscription && !isFree;
      const isUpgradable = isServerless && freeUserPaidEnv;
      const unavailable = freeUserPaidEnv || locked;

      return {
        value: env.name,
        component: () =>
          EnvironmentCard({
            region: isServerless ? env?.provider_region : env?.name,
            location: env?.region_location,
            flag: env?.region_flag,
            isFree,
            viewerHasSubscription,
          }),
        disabled: !isUpgradable && unavailable,
      };
    });
  }, [freeEnvironments, filteredEnvironments, viewerHasSubscription, isServerless, locked]);

  useEffect(() => {
    if (
      (!provider || !providerOptions.find((opt) => opt.value === provider)) &&
      providerOptions.length > 0
    ) {
      setValue('provider' as Path<T>, providerOptions[0].value as PathValue<T, Path<T>>);
    }
  }, [provider, providerOptions, setValue, currentTab]);

  useEffect(() => {
    if (
      (!environment || !filteredEnvironments.find((env) => env.name === environment)) &&
      filteredEnvironments.length > 0
    ) {
      setValue('environment' as Path<T>, filteredEnvironments[0].name as PathValue<T, Path<T>>);
    }
  }, [provider, filteredEnvironments, setValue, availableEnvironments, environment]);
  const {
    val: requestRegionIsOpen,
    setTrue: openRequestRegion,
    setFalse: closeRequestRegion,
  } = useBooleanState();
  return (
    <>
      <Card>
        <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Cloud provider</Typography>
          {!allowRegionRequests ? (
            <Typography variant="body2">
              <em>More providers and regions coming soon</em>
            </Typography>
          ) : null}
        </Box>
        <SelectBoxInput name="provider" control={control} options={providerOptions} />
        {!viewerHasSubscription && (
          <Typography variant="body2" color="text.secondary" my={2}>
            * Upgrade to Standard or Enterprise Plans for full access to all cloud providers
          </Typography>
        )}
      </Card>
      <Card>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" mb={1}>
            Region
          </Typography>
          {allowRegionRequests ? (
            <Typography>
              <Button variant="text" onClick={openRequestRegion}>
                Request a region
              </Button>
            </Typography>
          ) : null}
          <RegionRequestModal open={requestRegionIsOpen} close={closeRequestRegion} />
        </Box>
        <SelectBoxInput name="environment" control={control} options={environmentOptions} />
      </Card>
    </>
  );
}

export default RegionPicker;
