import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PaginationControls from '@pinecone-experience/timber/PaginationControls';
import {type PaginationState} from '@pinecone-experience/timber/PaginationControls/hooks';
import Tooltip from '@pinecone-experience/timber/Tooltip';

interface ExplorerResultsFooterProps<T> {
  paginationState: PaginationState<T>;
  readUnits?: number;
}

function ExplorerResultsFooter<T>({paginationState, readUnits}: ExplorerResultsFooterProps<T>) {
  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
      <Box sx={{display: 'flex', alignItems: 'center', pl: 1}}>
        <Typography id="explorer-read-units" color="text.secondary">
          {readUnits && (
            <Box sx={{display: 'flex', alignItems: 'end'}}>
              Query used {readUnits} RUs
              <Box sx={{pl: 0.5, mb: -0.25}}>
                <Tooltip title="Read units measure the compute, I/O, and network resources used by read operations such as query, fetch, and list.">
                  <InfoOutlinedIcon fontSize="inherit" />
                </Tooltip>
              </Box>
            </Box>
          )}
        </Typography>
      </Box>
      <PaginationControls paginationState={paginationState} includeSizeControls />
    </Box>
  );
}

export default ExplorerResultsFooter;
