import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';
import PageFooter from '../../../../components/layout/PageFooter/PageFooter';
import {PlanNameMapping, type Plans} from '../../../../constants';
import {useNavigateToBillingPlans} from '../../../../hooks/navigation';
import {useSelectedOrganization} from '../../../../selectors/organizations';
import {type SelectedIndex} from '../hooks/selectedIndex';
import PriceEstimator from './PriceEstimator/PriceEstimator';

const styles = {
  buttonContainer: {
    display: 'flex',
  },
};

interface IndexCreationFooterProps {
  selectedIndex: SelectedIndex;
  isValid: boolean;
  upsertCost?: number;
}
const IndexCreationFooter = ({
  isValid,
  upsertCost,
  selectedIndex: {environment, isServerless, requiresSubscriptionUpgrade, ...formData},
}: IndexCreationFooterProps) => {
  const navigate = useNavigate();
  const organization = useSelectedOrganization();
  const goToBillingPlans = useNavigateToBillingPlans(organization.id);
  const plan = organization.plan as Plans;

  if (requiresSubscriptionUpgrade) {
    return (
      <PageFooter>
        <Box>
          <Typography color="text.secondary">{`Not included in your ${PlanNameMapping[plan]} plan`}</Typography>
          <Typography display="inline" variant="h6" color="primary.dark">
            Upgrade to continue
          </Typography>
        </Box>
        <Box sx={styles.buttonContainer}>
          <Button sx={{ml: 1, color: 'secondary.main', mr: 3}} onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button type="button" variant="contained" color="primary" onClick={goToBillingPlans}>
            See pricing plans
          </Button>
        </Box>{' '}
      </PageFooter>
    );
  }

  return (
    <PageFooter>
      <PriceEstimator
        upsertCost={upsertCost}
        isServerless={isServerless}
        environment={environment}
        data={formData}
      />
      <Box sx={styles.buttonContainer}>
        <Button sx={{ml: 1, color: 'secondary.main', mr: 3}} onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button type="submit" disabled={!isValid} variant="contained" color="primary">
          Create index
        </Button>
      </Box>
    </PageFooter>
  );
};

export default IndexCreationFooter;
