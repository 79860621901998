export const SECTION_ITEMS_MAX_BEFORE_OVERFLOW = 5;

export const styles = {
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    mb: 3,
    minHeight: 120,
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionItems: {
    display: 'flex',
    gap: 4,
    '& > *': {
      flex: 1,
      textAlign: 'start',
    },
  },
  sectionItemsOverflow: {
    overflowX: 'auto',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    '& > *': {
      flex: 'none',
      minWidth: 250,
    },
  },
  sectionItemCard: {
    p: 2,
    mb: 2,
    '&:hover, &:focus': {
      cursor: 'pointer',
      boxShadow: 8,
    },
  },
  getStartedSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};
