import {type IndexTargetType} from '../components/ServerlessMigration/schema';
import {
  DeletionProtection,
  GLOBAL_CONTROL_PLANE_URL,
  USE_CONTROL_PLANE_OVERRIDE,
  freeEnvironmentPodName,
} from '../constants';
import {
  type Assistant,
  type AssistantFormData,
  type Backup,
  type CidrConfig,
  type CmekKey,
  type Collection,
  type ImportOperationResponse,
  type InferenceModels,
  type InferenceResponse,
  type MigrationOperationResponse,
  type PrincipalTypes,
  type PrivateEndpointFormData,
  type RbacRoles,
  type RoleBinding,
  type StorageIntegration,
  type V4IndexInfoResponse,
} from '../types';
import {
  type CreateCmekKeyRequest,
  type CreateStorageIntegrationRequest,
  type ListCmekResponse,
  type ListStorageIntegrationsResponse,
  type UpdateStorageIntegrationRequest,
} from './types/controllerApi';

enum ApiVersion {
  UNSTABLE = 'unstable',
  '2024-07' = '2024-07',
}

class ControllerError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

class ControllerApi {
  projectId: string;

  token: string;

  constructor({projectId, token}: {projectId: string; token: string}) {
    this.projectId = projectId;
    this.token = token;
  }

  fetch(url: string, options = {}, apiVersion = ApiVersion.UNSTABLE): Promise<Response> {
    const headers: Record<string, string> = {
      'x-project-id': this.projectId,
      Authorization: `Bearer ${this.token}`,
      'X-Pinecone-Api-Version': apiVersion,
    };
    return fetch(`${GLOBAL_CONTROL_PLANE_URL}/${url}`, {
      ...options,
      headers,
    }).then((res) => {
      if (!res.ok) {
        return res.text().then((text) => {
          throw new ControllerError(text, res.status);
        });
      }
      return res;
    });
  }

  listServices() {
    return this.fetch('indexes').then((res) => res.json()) as Promise<{
      indexes: V4IndexInfoResponse[];
    }>;
  }

  getService(serviceName: string) {
    return this.fetch(`indexes/${serviceName}`).then((res) =>
      res.json(),
    ) as Promise<V4IndexInfoResponse>;
  }

  deleteService(serviceName: string) {
    return this.fetch(`indexes/${serviceName}`, {method: 'DELETE'}).then((res) => ({
      success: res.ok,
    }));
  }

  createService(data: {
    name: string;
    dimensions: number | string;
    pods: number | string;
    replicas: number | string;
    metric: string;
    podType: string;
    podSize: string;
    from_collection?: string;
    from_backup?: string;
    provider: string;
    region: string;
    environment: string;
    isServerless: boolean;
    deletionProtection: boolean;
  }) {
    return this.fetch('indexes', {
      method: 'POST',
      body: JSON.stringify({
        name: data.name,
        dimension: parseInt(data.dimensions as string, 10),
        metric: data.metric,
        deletion_protection: data.deletionProtection
          ? DeletionProtection.ENABLED
          : DeletionProtection.DISABLED,
        spec: data.isServerless
          ? {
              serverless: {
                cloud: data.provider.toLowerCase(),
                region: data.region,
                source_collection: data.from_collection,
                source_backup_id: data.from_backup,
              },
            }
          : {
              pod: {
                replicas: parseInt(data.replicas as string, 10),
                shards: parseInt(data.pods as string, 10),
                pod_type:
                  data.podType === freeEnvironmentPodName.value
                    ? freeEnvironmentPodName.displayName
                    : `${data.podType}.${data.podSize || 'x1'}`,
                environment: data.environment,
                source_collection: data.from_collection,
              },
            },
      }),
    });
  }

  upgradeService(sourceIndex: string, region: string) {
    return this.fetch(`upgrade/starter`, {
      method: 'POST',
      body: JSON.stringify({
        index_name: sourceIndex,
        target: USE_CONTROL_PLANE_OVERRIDE
          ? {
              override: {
                environment: 'stage-aws-2-paid',
              },
            }
          : {
              serverless: {
                cloud: 'aws',
                region,
              },
            },
      }),
    });
  }

  migrateService(serviceInfo: IndexTargetType) {
    return this.fetch(`upgrade/pods`, {
      method: 'POST',
      body: JSON.stringify({
        index_name: serviceInfo.sourceName,
        target_name: serviceInfo.targetName,
        target: {
          serverless: {
            cloud: serviceInfo.cloud.toLowerCase(),
            region: serviceInfo.region,
          },
        },
      }),
    }).then((res) => res.json()) as Promise<MigrationOperationResponse>;
  }

  getMigrationOperation(operationId: string) {
    return this.fetch(`upgrade/pods/operations/${operationId}`).then((res) =>
      res.json(),
    ) as Promise<MigrationOperationResponse>;
  }

  listMigrationOperations() {
    return this.fetch(`upgrade/pods/operations`).then((res) => res.json()) as Promise<{
      operations: MigrationOperationResponse[];
    }>;
  }

  getImportOperation(operationId: string) {
    return this.fetch(`upgrade/import/operations/${operationId}`).then((res) =>
      res.json(),
    ) as Promise<ImportOperationResponse>;
  }

  patchService(
    name: string,
    replicas: string,
    podType: string,
    deletionProtection: boolean,
    isServerless: boolean,
  ) {
    const protection = deletionProtection
      ? DeletionProtection.ENABLED
      : DeletionProtection.DISABLED;
    const body = {
      deletion_protection: protection,
      spec: isServerless
        ? undefined
        : {
            pod: {
              replicas: parseInt(replicas, 10),
              pod_type: podType,
            },
          },
    };
    return this.fetch(`indexes/${name}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
    }).then((res) => res.json()) as Promise<V4IndexInfoResponse>;
  }

  createCollection(indexName: string, collectionName: string) {
    return this.fetch('collections', {
      method: 'POST',
      body: JSON.stringify({
        source: indexName,
        name: collectionName,
      }),
    });
  }

  listCollections() {
    return this.fetch('collections').then((res) => res.json()) as Promise<{
      collections: Collection[];
    }>;
  }

  getCollection(name: string) {
    return this.fetch(`collections/${name}`)
      .then((res) => res.json())
      .then((res) => {
        if (res.collection) {
          return res.collection;
        }
        return res;
      }) as Promise<Collection>;
  }

  deleteCollection(collectionId: string) {
    return this.fetch(`collections/${collectionId}`, {method: 'DELETE'}).then(() => ({
      success: true,
    }));
  }

  listPrivateEndpoints(globalProjectId: string) {
    return this.fetch(`projects/${globalProjectId}/private-endpoints`).then((res) => res.json());
  }

  createPrivateEndpoint(globalProjectId: string, formData: PrivateEndpointFormData) {
    return this.fetch(`projects/${globalProjectId}/private-endpoints`, {
      method: 'POST',
      body: JSON.stringify(formData),
    });
  }

  deletePrivateEndpoint(endpointId: string) {
    return this.fetch(`private-endpoints/${endpointId}`, {
      method: 'DELETE',
    });
  }

  getNetworkAllowlist(globalProjectId: string) {
    return this.fetch(`projects/${globalProjectId}/allowlist`).then((res) => res.json());
  }

  createNetworkAllowlistEntry(globalProjectId: string, cidr: CidrConfig) {
    return this.fetch(`projects/${globalProjectId}/allowlist`, {
      method: 'POST',
      body: JSON.stringify(cidr),
    });
  }

  deleteNetworkAllowlistEntry(entryId: string) {
    return this.fetch(`allowlist/${entryId}`, {
      method: 'DELETE',
    });
  }

  getAcceptedAssistantsTerms(orgId: string) {
    return this.fetch(`assistant/assistants/tos/${orgId}`).then((res) => res.json());
  }

  acceptAssistantsTerms(orgId: string) {
    return this.fetch(`assistant/assistants/tos`, {
      method: 'POST',
      body: JSON.stringify({organization_id: orgId}),
    }).then((res) => res.json());
  }

  listAssistants() {
    return this.fetch('assistant/assistants').then((res) => res.json()) as Promise<{
      assistants: Assistant[];
    }>;
  }

  createAssistant(formData: AssistantFormData) {
    return this.fetch(`assistant/assistants`, {
      method: 'POST',
      body: JSON.stringify(formData),
    }).then((res) => res.json()) as Promise<Assistant>;
  }

  updateAssistantInstructions(
    assistantName: string,
    instructions: string,
    metadata: Record<string, string> | null,
  ) {
    return this.fetch(`assistant/assistants/${assistantName}`, {
      method: 'PATCH',
      body: JSON.stringify({instructions, metadata}),
    }).then((res) => res.json()) as Promise<{instructions: string}>;
  }

  getAssistant(assistantName: string) {
    return this.fetch(`assistant/assistants/${assistantName}`).then((res) =>
      res.json(),
    ) as Promise<Assistant>;
  }

  deleteAssistant(assistantName: string) {
    return this.fetch(`assistant/assistants/${assistantName}`, {
      method: 'DELETE',
    });
  }

  createFromDataset(data: {
    name: string;
    dimensions: number | string;
    metric: string;
    source_collection?: string;
    provider: string;
    region: string;
  }) {
    return this.fetch('indexes/from-dataset', {
      method: 'POST',
      body: JSON.stringify({
        name: data.name,
        dimension: parseInt(data.dimensions as string, 10),
        metric: data.metric,
        spec: {
          serverless: {
            cloud: data.provider.toLowerCase(),
            region: data.region,
            source_collection: data.source_collection,
          },
        },
      }),
    });
  }

  editRolesForPrincipal(principalId: string, principalType: PrincipalTypes, roles: RbacRoles[]) {
    return this.fetch(`principals/${principalId}/roles`, {
      method: 'PATCH',
      body: JSON.stringify({
        roles,
        principal_type: principalType,
      }),
    }).then((res) => res.json()) as Promise<RoleBinding[]>;
  }

  getRolesByPrincipal(principalId: string) {
    return this.fetch(`principals/${principalId}/roles`).then((res) => res.json()) as Promise<
      RoleBinding[]
    >;
  }

  createStorageIntegration(data: CreateStorageIntegrationRequest) {
    return this.fetch(`storage-integrations`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then((res) => res.json()) as Promise<StorageIntegration>;
  }

  listStorageIntegrations() {
    return this.fetch(`storage-integrations`).then((res) =>
      res.json(),
    ) as Promise<ListStorageIntegrationsResponse>;
  }

  getStorageIntegration(integrationId: string) {
    return this.fetch(`storage-integrations/${integrationId}`).then((res) =>
      res.json(),
    ) as Promise<StorageIntegration>;
  }

  updateStorageIntegration(integrationId: string, data: UpdateStorageIntegrationRequest) {
    return this.fetch(`storage-integrations/${integrationId}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    }).then((res) => res.json()) as Promise<StorageIntegration>;
  }

  validateStorageIntegration(integrationId: string) {
    return this.fetch(`storage-integrations/${integrationId}/validate`, {method: 'POST'}).then(
      (res) => res.json(),
    ) as Promise<StorageIntegration>;
  }

  deleteStorageIntegration(integrationId: string) {
    return this.fetch(`storage-integrations/${integrationId}`, {
      method: 'DELETE',
    });
  }

  createBackup(indexId: string, name: string) {
    return this.fetch('backups', {
      method: 'POST',
      body: JSON.stringify({
        index_id: indexId,
        name,
      }),
    }).then((res) => res.json()) as Promise<Backup>;
  }

  listBackups() {
    return this.fetch(`backups`).then((res) => res.json()) as Promise<{backups: Backup[]}>;
  }

  deleteBackup(backupId: string) {
    return this.fetch(`backups/${backupId}`, {method: 'DELETE'});
  }

  getBackup(backupId: string) {
    return this.fetch(`backups/${backupId}`).then((res) => res.json()) as Promise<Backup>;
  }

  embed(model: InferenceModels, query: string) {
    return this.fetch(
      'embed',
      {
        method: 'POST',
        body: JSON.stringify({
          model,
          parameters: {
            input_type: 'query',
            truncate: 'END',
          },
          inputs: [
            {
              text: query,
            },
          ],
        }),
      },
      ApiVersion['2024-07'],
    ).then((res) => res.json()) as Promise<InferenceResponse>;
  }

  listCmekKeys(projectId: string) {
    return this.fetch(`projects/${projectId}/cmek?include_indexes_using_key=true`).then((res) =>
      res.json(),
    ) as Promise<ListCmekResponse>;
  }

  createCmekKey(projectId: string, data: CreateCmekKeyRequest) {
    return this.fetch(`projects/${projectId}/cmek`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then((res) => res.json()) as Promise<CmekKey>;
  }

  deleteCmekKey(cmekId: string) {
    return this.fetch(`cmek/${cmekId}`, {
      method: 'DELETE',
    });
  }
}

export default ControllerApi;
