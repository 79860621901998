import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LockOutlined from '@mui/icons-material/LockOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import {useLocation} from 'react-router-dom';
import {ProjectPage} from '../../../../constants';
import {Flags, useFlag} from '../../../../hooks/flags';
import {useNavigateToProjectPageGlobal} from '../../../../hooks/navigation';
import {
  HomePageConfigurationFlags,
  useHomePageConfigurationKey,
} from '../../../../pages/projects/HomePage/utilities';
import {useAssistantsList} from '../../../../selectors/assistants';
import {useSelectedOrganizationPlan} from '../../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../../selectors/params';
import {useServicesByGlobalProject} from '../../../../selectors/services';
import {type GlobalProject} from '../../../../types';
import {canUsePrivateEndpoints} from '../../../../utils/planRules';
import {type NavigationSection} from '../types/navigation.interface';
import {matchesCurrentPath} from '../utils/matchesCurrentPath';

export enum ProjectNavigationSection {
  GET_STARTED = 'get-started',
  DATABASE = 'database',
  ASSISTANT = 'assistant',
  INFERENCE = 'inference',
  API_KEYS = 'api-keys',
  MANAGE = 'manage',
}

function getTitleWithCount(title: string, count: number) {
  return `${title}${count ? ` (${count})` : ''}`;
}

export const useProjectNavSections = (project?: GlobalProject): NavigationSection[] => {
  const projectId = project?.id || '';
  const enableGetStartedGuide = useFlag(Flags.ENABLE_GET_STARTED_GUIDE);
  const enableInferencePage = useFlag(Flags.SHOULD_SHOW_INFERENCE_PAGE);
  const enableStorageIntegrationsPage = useFlag(Flags.ENABLE_STORAGE_INTEGRATIONS_PAGE);
  const enableCmekPage = useFlag(Flags.ENABLE_CMEK_PROJECTS);
  const enableHomePage = useHomePageConfigurationKey(HomePageConfigurationFlags.ENABLE_HOME_PAGE);

  const orgId = useSelectedOrganizationId();
  const plan = useSelectedOrganizationPlan();
  const {getPath: getProjectPagePath} = useNavigateToProjectPageGlobal(orgId);
  const indexCount = useServicesByGlobalProject(projectId)?.length ?? 0;
  const assistantCount = useAssistantsList(projectId)?.length ?? 0;
  const getPath = (page: ProjectPage) => {
    return getProjectPagePath(projectId, page);
  };

  return [
    enableHomePage
      ? {
          id: 'home-nav-section',
          icon: <HomeOutlinedIcon fontSize="small" color="secondary" />,
          title: 'Home',
          path: getPath(ProjectPage.HOME),
        }
      : undefined,
    enableGetStartedGuide
      ? {
          id: 'get-started-nav-section',
          icon: <RocketLaunchOutlinedIcon fontSize="small" color="secondary" />,
          title: 'Get started',
          path: getPath(ProjectPage.GET_STARTED),
        }
      : undefined,
    {
      id: 'database-nav-section',
      icon: <TableRowsOutlinedIcon fontSize="small" color="secondary" />,
      title: 'Database',
      items: [
        {
          title: getTitleWithCount('Indexes', indexCount),
          path: getPath(ProjectPage.INDEXES),
        },
        {
          title: 'Create',
          path: getPath(ProjectPage.CREATE_INDEX),
          hidden: true,
        },
        {
          title: 'Create from dataset',
          path: getPath(ProjectPage.DATASET_GALLERY),
          hidden: true,
        },
        !project?.force_encryption_with_cmek
          ? {
              title: 'Backups',
              path: getPath(ProjectPage.BACKUPS),
            }
          : undefined,
      ].filter((item) => item !== undefined),
    },
    {
      id: 'assistant-nav-section',
      icon: <EmojiObjectsOutlinedIcon fontSize="small" color="secondary" />,
      title: 'Assistant',
      items: [
        {
          title: getTitleWithCount('Assistants', assistantCount),
          path: getPath(ProjectPage.ASSISTANTS),
        },
      ],
    },
    enableInferencePage
      ? {
          id: 'inference-nav-section',
          icon: <OfflineBoltOutlinedIcon fontSize="small" color="secondary" />,
          title: 'Inference',
          path: getPath(ProjectPage.MODELS),
        }
      : undefined,
    {
      id: 'api-keys-nav-section',
      icon: <LockOutlined fontSize="small" color="secondary" />,
      title: 'API keys',
      path: getPath(ProjectPage.KEYS),
    },
    {
      id: 'manage-nav-section',
      icon: <SettingsOutlinedIcon fontSize="small" color="secondary" />,
      title: 'Manage',
      items: [
        {
          title: 'Members',
          path: getPath(ProjectPage.MEMBERS),
        },
        enableCmekPage
          ? {
              title: 'CMEK',
              path: getPath(ProjectPage.CMEK),
            }
          : undefined,
        enableStorageIntegrationsPage
          ? {
              title: 'Storage integrations',
              path: getPath(ProjectPage.STORAGE),
            }
          : undefined,
        canUsePrivateEndpoints(plan)
          ? {
              title: 'Network',
              path: getPath(ProjectPage.NETWORK),
            }
          : undefined,
      ].filter((item) => item !== undefined),
    },
  ].filter((item) => item !== undefined);
};

export const useActiveProjectNavSection = (
  project?: GlobalProject,
): NavigationSection | undefined => {
  const sections = useProjectNavSections(project);
  const {pathname} = useLocation();

  if (!project) {
    return undefined;
  }

  const activeSection = sections.find((section) => {
    if (section.path) {
      return matchesCurrentPath(pathname, section.path, section.pathMatch);
    }

    return section.items?.find((item) => {
      return matchesCurrentPath(pathname, item.path, item.pathMatch);
    });
  });

  return activeSection;
};
