import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Alert from '@pinecone-experience/timber/Alert';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {editProject} from '../../../../actions/dashboardActions';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useIsSelectedOrganizationSubscribed} from '../../../../selectors/organizations';
import {type GlobalProject} from '../../../../types';
import {TRACKING_EVENTS, sendToGa} from '../../../../utils/tracking';
import ModalForm from '../../../ModalForm';
import QuotaFormElement from '../../../QuotaFormElement';
import StyledDescriptiveInput from '../../../StyledDescriptiveInput';

type EditProjectFormProps = {
  open: boolean;
  close: () => void;
  project: GlobalProject;
};

export const schema = z.object({
  name: z
    .string()
    .min(1, 'Project name is required')
    .max(512, 'Project name must be 512 characters or less')
    .refine((name) => !name.includes('\0'), {
      message: 'Project name cannot contain null characters',
    }),
  quota: z.coerce
    .number()
    .int('Quota must be a non-negative integer')
    .nonnegative('Quota must be a non-negative integer')
    .finite('Quota must be a non-negative integer'),
});

function ConfigureProjectForm({open, close, project}: EditProjectFormProps) {
  const projectQuota = project.quota;
  const isSubscribed = useIsSelectedOrganizationSubscribed();
  const {
    handleSubmit,
    formState: {isValid, isDirty},
    control,
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: project.name,
      quota: projectQuota || 0,
    },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (projectQuota) {
      reset({name: project.name, quota: projectQuota});
    }
  }, [projectQuota, project.name, reset]);
  const data = watch();
  const {setQueryData} = useDashboardApi<{
    globalProjectId: string;
    organizationId: string;
    editData: {quota: number; name: string};
    setQuota: boolean;
  }>();
  const onSubmit = (editData: {quota: number; name: string}) => {
    setQueryData({
      action: editProject,
      data: {
        globalProjectId: project.id,
        organizationId: project.organization_id,
        editData: {
          quota: editData.quota,
          name: editData.name,
        },
        setQuota: projectQuota !== editData.quota,
      },
    });
    close();
    sendToGa(TRACKING_EVENTS.EDIT_PROJECT);
  };

  return (
    <ModalForm
      open={open}
      close={close}
      subtitle={<Typography variant="overline">{project.name}</Typography>}
      form={
        <Box>
          <TextInput
            label="Project Name"
            name="name"
            control={control}
            required
            fullWidth
            autoFocus
          />
          {project.force_encryption_with_cmek && (
            <Alert
              severity="info"
              title="Encrypted with Customer managed encryption key (CMEK)"
              description="This project is secured with customer-managed encryption keys (CMEK) for all indexes."
            />
          )}
          <Divider sx={{my: 3}} />
          <StyledDescriptiveInput
            header="Pod Limit"
            description="Set the maximum number of pods that can be
                used in total across all pod-based indexes in this project."
            paid={!isSubscribed}
            inputComponent={<QuotaFormElement value={data.quota} control={control} />}
          />
          <Typography>Applies to pod-based indexes only.</Typography>
        </Box>
      }
      title="Configure Project"
      isValid={isValid && isDirty}
      onSubmit={handleSubmit(onSubmit)}
      submitText="Save Changes"
    />
  );
}

export default ConfigureProjectForm;
