import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import {useIntegration} from '../../../../../hooks/integrations';
import {useIntegrationLogo} from '../../../../../selectors/integrations';

type IntegrationChipProps = {
  /** The ID of the integration. The chip will not show if this is invalid or absent. */
  integrationId?: string;
};

/** A chip to indicate that an API key is associated with an integration. */
function IntegrationChip({integrationId}: IntegrationChipProps) {
  const integration = useIntegration(integrationId);
  const logoUrl = useIntegrationLogo(integrationId ?? '');

  if (!integration?.data) {
    return null;
  }

  return (
    <Chip
      variant="outlined"
      size="small"
      label={integration.data.profile.name}
      icon={
        <Box
          component="img"
          src={logoUrl}
          alt="Logo"
          sx={{borderRadius: 2, border: 0, width: 16, height: 16}}
        />
      }
      sx={{ml: 1, px: 0.5}}
    />
  );
}

export default IntegrationChip;
