import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Navigate, Outlet, useNavigate} from 'react-router-dom';
import {NotificationTypes, enqueNotification} from '../../../../actions/notificationActions';
import {getOrganizations} from '../../../../actions/organizationActions';
import {getUserInfo} from '../../../../actions/userActions';
import {LocalStorageKeys} from '../../../../constants';
import {useNavigateToCreateOrganization} from '../../../../hooks/navigation';
import {useDashboardApi} from '../../../../hooks/use-api';
import LoadingPage from '../../../../pages/app/LoadingPage/LoadingPage';
import {useOrganizations, useOrganizationsAreLoading} from '../../../../selectors/organizations';
import {useUserData} from '../../../../selectors/userSession';
import {localStorageGet, localStorageSet} from '../../../../utils/storage';
import {useShouldShowRegistration} from '../../../app-modals/RegistrationModal/utils/registration';

function useRefreshOrgData() {
  useDashboardApi(getOrganizations);
  useDashboardApi(getUserInfo);
}

function useMultipleOrgsNotification() {
  const dispatch = useDispatch();
  const organizations = useOrganizations();
  const orgIds = Object.keys(organizations);
  const hasSeenOrgNotification = localStorageGet(LocalStorageKeys.MULTIPLE_ORGS_NOTIFICATION);

  useEffect(() => {
    if (orgIds.length > 1 && !hasSeenOrgNotification) {
      localStorageSet(LocalStorageKeys.MULTIPLE_ORGS_NOTIFICATION, 'true');
      dispatch(
        enqueNotification({
          type: NotificationTypes.INFO,
          text: `You've been granted access to a new organization. Use the navigation menu to switch.`,
        }),
      );
    }
  }, [dispatch, hasSeenOrgNotification, orgIds.length]);
}

/**
 * Wraps all routes that depend on the `organizations` data. Ensures that organizations are present
 * in the store.
 */
function OrganizationDataGuard() {
  const organizationsAreLoading = useOrganizationsAreLoading();
  const navigate = useNavigate();
  const organizations = useOrganizations();
  const orgIds = Object.keys(organizations);
  const {path: createOrgPath} = useNavigateToCreateOrganization();
  const user = useUserData();

  useMultipleOrgsNotification();
  useRefreshOrgData();

  const shouldShowRegistration = useShouldShowRegistration();

  useEffect(() => {
    if (shouldShowRegistration) {
      navigate('/registration', {replace: true});
    }
  }, [user, navigate, shouldShowRegistration]);

  if (organizationsAreLoading) {
    return <LoadingPage />;
  }

  if (orgIds.length === 0) {
    return <Navigate to={createOrgPath} />;
  }

  return <Outlet />;
}

export default OrganizationDataGuard;
