import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import {sub} from 'date-fns/sub';
import {type MouseEvent, useMemo, useState} from 'react';
import {PUBLIC_DOCS} from '../../../../constants';
import {useHasCapacityInfo} from '../../../../hooks/specUtils';
import {useInterval} from '../../../../hooks/utils';
import {useServiceByGlobalProject} from '../../../../selectors/services';
import {type GlobalProject} from '../../../../types';
import {getSpecs} from '../../../../utils/services';
import LatencyChart from './LatencyChart/LatencyChart';
import PodFullnessChart from './PodFullnessChart/PodFullnessChart';
import RequestsChart from './RequestsChart/RequestsChart';
import RequestsErrorsChart from './RequestsChart/RequestsErrorsChart/RequestsErrorsChart';
import ServerlessReadUnitsChart from './ServerlessReadUnitsChart/ServerlessReadUnitsChart';
import ServerlessStorageUnitsChart from './ServerlessStorageUnitsChart/ServerlessStorageUnitsChart';
import WriteUnitsChart from './ServerlessWriteUnitsChart/ServerlessWriteUnitsChart';
import VectorCountChart from './VectorCountChart/VectorCountChart';
import {
  autoRefreshPollingInterval,
  formatDateToString,
  timeOptions,
} from './utilities/MetricsUtils';

const styles = {
  root: {
    mb: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'text.primary',
    gap: 1,
  },
  rangeToggleButtonGroup: {
    height: 30,
    backgroundColor: 'background.paper',
  },
};

interface LegacyIndexMetricsProps {
  service: string;
  project: GlobalProject;
}

function LegacyIndexMetrics({service, project}: LegacyIndexMetricsProps) {
  const [timeRange, setTimeRange] = useState(timeOptions[2].value);
  const [refresh, setRefresh] = useState(new Date());
  const {startTime, endTime} = useMemo(() => {
    const loadTime = new Date();
    const start = formatDateToString(sub(loadTime, {minutes: timeRange}));
    const end = formatDateToString(loadTime);
    return {startTime: start, endTime: end};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange, refresh]);
  const handleTimeRangeChange = (_event: MouseEvent<HTMLElement>, newTimeRange: number | null) => {
    if (newTimeRange !== null) {
      setTimeRange(newTimeRange);
    }
  };

  // Auto refresh every 2 minutes
  useInterval(() => {
    setRefresh(new Date());
  }, autoRefreshPollingInterval);

  const serviceData = useServiceByGlobalProject(project.id, service);
  const {serverlessSpec} = getSpecs(serviceData);
  const shouldShowV4Metrics = !!serverlessSpec;
  const hasCapacityInfo = useHasCapacityInfo(serviceData);
  return (
    <>
      <Box sx={styles.root}>
        <Typography color="secondary" variant="body1">
          Note: Metrics shown here may be delayed. Set up{' '}
          <Link rel="noreferrer" target="_blank" href={PUBLIC_DOCS.PROMETHEUS_MONITORING}>
            Datadog/Prometheus
          </Link>{' '}
          for real-time data.
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography noWrap variant="overline" textTransform="capitalize" mr={1}>
            Past
          </Typography>
          <ToggleButtonGroup
            sx={styles.rangeToggleButtonGroup}
            value={timeRange}
            exclusive
            size="small"
            onChange={handleTimeRangeChange}
            aria-label="Index-Metrics-Time-Range"
          >
            {timeOptions.map((option) => (
              <ToggleButton sx={{textWrap: 'nowrap'}} key={option.name} value={option.value}>
                {option.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </Box>
      <Grid container spacing={3} alignItems="stretch">
        {shouldShowV4Metrics && (
          <>
            <Grid item xs={12}>
              <ServerlessReadUnitsChart
                service={service}
                start={startTime.toString()}
                end={endTime.toString()}
              />
            </Grid>
            <Grid item xs={12}>
              <WriteUnitsChart
                service={service}
                start={startTime.toString()}
                end={endTime.toString()}
              />
            </Grid>
            <Grid item xs={12}>
              <ServerlessStorageUnitsChart
                service={service}
                start={startTime.toString()}
                end={endTime.toString()}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <RequestsChart service={service} start={startTime.toString()} end={endTime.toString()} />
        </Grid>
        <Grid item xs={12}>
          <RequestsErrorsChart
            service={service}
            start={startTime.toString()}
            end={endTime.toString()}
          />
        </Grid>
        <Grid item xs={12}>
          <LatencyChart service={service} start={startTime.toString()} end={endTime.toString()} />
        </Grid>

        <Grid item xs={12}>
          <VectorCountChart
            service={service}
            start={startTime.toString()}
            end={endTime.toString()}
          />
        </Grid>
        {hasCapacityInfo && (
          <Grid item xs={12}>
            <PodFullnessChart
              service={service}
              start={startTime.toString()}
              end={endTime.toString()}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default LegacyIndexMetrics;
