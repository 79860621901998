import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useBooleanState} from '../../../../hooks/utils';
import {useIsOwnerOfOrganization} from '../../../../selectors/organizations';
import {type IntegrationCreateData} from '../../../../types';
import IntegrationForm from '../IntegrationForm/IntegrationForm';
import {type CreateIntegrationFormData} from '../IntegrationForm/utilities/schema';

type CreateIntegrationButtonProps = {
  /** Callback for when the create integration form is completed. */
  onCreate: (data: IntegrationCreateData) => void;
};

/** A button for creating a new integration. */
function CreateIntegrationButton({onCreate}: CreateIntegrationButtonProps) {
  const [searchParams] = useSearchParams();
  const shouldShowCreateForm = searchParams.get('create') === 'true';

  const {
    val: createFormOpen,
    setFalse: hideCreateForm,
    setTrue: showCreateForm,
  } = useBooleanState(shouldShowCreateForm);

  const onSubmit = useCallback(
    (data: CreateIntegrationFormData) => {
      const createData: IntegrationCreateData = {
        slug: data.slug,
        name: data.name,
        logo: data.logo,
        return_mechanism: data.return_mechanism,
        allowed_origins: data.allowed_origins.map((origin) => origin.origin),
      };
      onCreate(createData);
    },
    [onCreate],
  );

  const isOrgOwner = useIsOwnerOfOrganization();

  return (
    <>
      <Tooltip
        title={
          isOrgOwner ? undefined : 'You must be an organization owner to create an integration'
        }
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={showCreateForm}
          disabled={!isOrgOwner}
        >
          Create Integration
        </Button>
      </Tooltip>
      <IntegrationForm
        open={createFormOpen}
        close={hideCreateForm}
        title="Create integration"
        submitText="Create"
        onSubmit={onSubmit}
      />
    </>
  );
}

export default CreateIntegrationButton;
