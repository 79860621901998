import CheckIcon from '@mui/icons-material/Check';
import MuiAlert, {type AlertProps as MuiAlertProps} from '@mui/material/Alert';
import MuiAlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import type * as React from 'react';

const styles = {
  root: {
    my: 2,
    alignItems: 'flex-start',
  },
};

type AlertProps = {
  id?: string;
  title?: string;
  description?: string | React.ReactElement;
  variant?: MuiAlertProps['variant'];
  severity?: MuiAlertProps['severity'];
  action?: MuiAlertProps['action'];
  sx?: MuiAlertProps['sx'];
  icon?: MuiAlertProps['icon'];
};
const Alert: React.FC<AlertProps> = ({
  title,
  description,
  variant,
  severity = 'info',
  icon,
  id,
  action,
  sx,
}) => (
  <MuiAlert
    id={id}
    icon={icon}
    iconMapping={{success: <CheckIcon fontSize="inherit" />}}
    variant={variant}
    severity={severity}
    action={action}
    sx={{
      ...styles.root,
      color: variant === 'filled' ? 'common.white' : undefined,
      ...sx,
    }}
  >
    {title && <MuiAlertTitle sx={{fontWeight: 'bold'}}>{title}</MuiAlertTitle>}
    {typeof description === 'string' ? <Typography>{description}</Typography> : description}
  </MuiAlert>
);

export default Alert;
