import DownloadIcon from '@mui/icons-material/Download';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {type FC} from 'react';
import type Stripe from 'stripe';
import {downloadInvoice} from '../../../../../actions/organizationActions';
import {useDashboardApi} from '../../../../../hooks/use-api';
import {useInvoiceIsLoading} from '../../../../../selectors/billing';
import {useSelectedOrganizationId} from '../../../../../selectors/params';
import {formatMoney} from '../../../../../utils/format';
import PaymentHistoryStatusCell from './PaymentHistoryStatusCell/PaymentHistoryStatusCell';

interface Params {
  col: number;
  invoice: Stripe.Invoice;
}

const styles = {
  invoiceCellStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const getFormattedDate = (dateNumber: number) =>
  dateNumber
    ? new Date(dateNumber * 1000)?.toLocaleDateString(undefined, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      })
    : '';

const getPeriods = (invoice: Stripe.Invoice) => {
  let periodStart;
  let periodEnd;

  if (invoice.lines.data.length === 0) {
    periodStart = invoice.period_start;
    periodEnd = invoice.period_end;
  } else {
    periodStart = Math.min(...invoice.lines.data.map((item) => item.period.start));
    periodEnd = Math.max(...invoice.lines.data.map((item) => item.period.end));
  }

  return {periodStart, periodEnd};
};

const PaymentHistoryCellRenderer: FC<Params> = ({col, invoice}) => {
  /* eslint-disable @typescript-eslint/naming-convention */
  const {created, id, status, hosted_invoice_url, amount_due, amount_remaining} = invoice;
  const {periodStart, periodEnd} = getPeriods(invoice);

  const {setQueryData} = useDashboardApi<{invoiceId: string; organizationId: string}>();
  const orgId = useSelectedOrganizationId();
  const loading = useInvoiceIsLoading(id);
  /* eslint-enable @typescript-eslint/naming-convention */
  switch (col) {
    case 0:
      return <PaymentHistoryStatusCell status={status} />;
    case 1:
      return <Typography>{getFormattedDate(created)}</Typography>;
    case 2:
      if (!['paid', 'void', 'open'].includes(status as string)) {
        return <span />;
      }
      return (
        <Typography>
          {`${getFormattedDate(periodStart)} - ${getFormattedDate(periodEnd)}`}
        </Typography>
      );
    case 3:
      return <Typography>{formatMoney(amount_due)}</Typography>;
    case 4:
      const doDownload = () => {
        setQueryData({
          action: downloadInvoice,
          data: {
            invoiceId: id,
            organizationId: orgId,
          },
        });
      };
      const payInvoice = () => {
        window.open(hosted_invoice_url || undefined, '_blank');
      };
      const notFullyPaid = status !== 'paid' && (amount_due !== 0 || amount_remaining !== 0);
      return (
        <Box sx={styles.invoiceCellStyle}>
          <ButtonGroup disableElevation size="large" variant="text">
            <LoadingContainer loading={loading}>
              <Button startIcon={<DownloadIcon fontSize="small" />} onClick={doDownload}>
                Download
              </Button>
            </LoadingContainer>
            {notFullyPaid && (
              <Button sx={{minWidth: 0}} disabled={!hosted_invoice_url} onClick={payInvoice}>
                Pay
              </Button>
            )}
          </ButtonGroup>
        </Box>
      );
    default:
      return <span />;
  }
};

export default PaymentHistoryCellRenderer;
