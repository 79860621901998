import {useElements, useStripe} from '@stripe/react-stripe-js';
import {type PropsWithChildren} from 'react';
import {useFormContext} from 'react-hook-form';
import {type UpdateBillingDetailsData} from '../../../../actions/types/organizationActions.types';
import {useSelectedOrganizationId} from '../../../../selectors/params';
import {useStripeSetupPaymentMethod} from '../hooks/stripe';
import type {PaymentFormType} from '../utilities/schema';

type PaymentProcessorProps = PropsWithChildren<{
  /** This function is called if all validation and payment method setup is
   * successful. It should perform the actual form submission. */
  onSuccess: (billingDetailsData: UpdateBillingDetailsData) => void;
}>;

/** This wrapper handles the form validation and submission. */
function PaymentProcessor({onSuccess, children}: PaymentProcessorProps) {
  const elements = useElements();
  const stripe = useStripe();
  const selectedOrgId = useSelectedOrganizationId();
  const stripeSetupPaymentMethod = useStripeSetupPaymentMethod();
  const {handleSubmit} = useFormContext<PaymentFormType>();

  const submit = async (formData: PaymentFormType) => {
    if (!stripe || !elements) {
      return;
    }

    const res = await stripeSetupPaymentMethod(window.location.href, formData.email);

    if (res?.setupIntent?.status === 'succeeded') {
      const paymentMethod = res.setupIntent.payment_method as string;

      const addressElem = elements.getElement('address');
      const address = await addressElem?.getValue();
      const queryData = {
        email: formData.email,
        name: formData.name,
        organizationId: selectedOrgId,
        paymentMethodId: paymentMethod,
        taxIdType: formData.taxIdType,
        taxIdValue: formData.taxIdValue,
        ...(address?.complete ? {company: address.value.name, address: address.value.address} : {}),
      };
      onSuccess(queryData);
    }
  };

  return <form onSubmit={handleSubmit(submit)}>{children}</form>;
}

export default PaymentProcessor;
