import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type RowWithIconProps = {
  children: React.ReactNode;
  icon: React.ReactNode;
  sx?: object;
};

const styles = {
  iconRow: {
    display: 'flex',
    gap: 2,
    py: 1,
    alignItems: 'center',
    textAlign: 'left',
  },
  icon: {
    width: '24px',
    height: '24px',
  },
};

function RowWithIcon({children, icon, sx}: RowWithIconProps) {
  return (
    <Box sx={{...styles.iconRow, ...sx}}>
      <Box sx={styles.icon}>{icon}</Box>
      <Typography width="100%">{children}</Typography>
    </Box>
  );
}

export default RowWithIcon;
