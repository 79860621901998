import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import CodeIcon from '@mui/icons-material/Code';
import ElectricalServicesOutlinedIcon from '@mui/icons-material/ElectricalServicesOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import {PINECONE_URLS, PUBLIC_DOCS, PUBLIC_DOCS_URL} from '../../../../../constants';

interface LowerSectionItem {
  icon: React.ReactNode;
  title: string;
  description: string;
  href: string;
}

export const lowerSectionItems: LowerSectionItem[] = [
  {
    icon: <CodeIcon color="secondary" />,
    title: 'Documentation',
    description: 'Access guides and references to understand and use our platform.',
    href: PUBLIC_DOCS_URL,
  },
  {
    icon: <LockOutlinedIcon color="secondary" />,
    title: 'API reference',
    description: 'Get detailed info on API endpoints, examples and best practices.',
    href: PUBLIC_DOCS.API_REFERENCE,
  },
  {
    icon: <ElectricalServicesOutlinedIcon color="secondary" />,
    title: 'Integrations',
    description: 'Learn to integrate our services with your existing tools.',
    href: PUBLIC_DOCS.INTEGRATIONS,
  },
  {
    icon: <AutoAwesomeMosaicOutlinedIcon color="secondary" />,
    title: 'Examples',
    description: 'Explore sample projects and code snippets for your development.',
    href: PUBLIC_DOCS.EXAMPLES,
  },
  {
    icon: <OfflineBoltOutlinedIcon color="secondary" />,
    title: 'Model gallery',
    description: 'Find Pinecone-hosted and popular third-party models to start quickly.',
    href: PUBLIC_DOCS.MODEL_GALLERY,
  },
  {
    icon: <GroupOutlinedIcon color="secondary" />,
    title: 'Community forum',
    description: 'Join to ask questions, share insights, and collaborate with others.',
    href: PINECONE_URLS.COMMUNITY_FORUM,
  },
];
