import Box from '@mui/material/Box';
import {type BoxProps} from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BillingPlanItem from '../../BillingPlanItem/BillingPlanItem';
import {
  type BillingPlan,
  type BillingPlanAttributes,
  type BillingPlanItem as BillingPlanItemType,
} from '../types/billingPlans.interface';

interface BillingPlanAttributeProps {
  attributeKey: BillingPlanAttributes;
  plans: BillingPlan[];
  sx?: Required<BoxProps['sx']>;
  cellSx?: Required<BoxProps['sx']>;
}

const styles = {
  item: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    containerType: 'inline-size',
  },
};

export default function BillingPlanAttribute({
  attributeKey,
  plans,
  sx = {},
  cellSx = {},
}: BillingPlanAttributeProps) {
  return (
    <Box sx={sx}>
      <Box sx={cellSx}>
        <Typography variant="h6" component="h3">
          {attributeKey}
        </Typography>
      </Box>

      {plans.map((plan: BillingPlan) => (
        <Box key={plan.tier} sx={[styles.item, cellSx]}>
          {plan.attributes[attributeKey]?.map((item: BillingPlanItemType) => (
            <BillingPlanItem key={item.name?.toString()} item={item} />
          ))}
        </Box>
      ))}
    </Box>
  );
}
