import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useMemo, useState} from 'react';
import ProjectEncryptionBadge from '../../../components/ProjectEncryptionBadge/ProjectEncryptionBadge';
import Page from '../../../components/layout/Page/Page';
import PageControls from '../../../components/layout/PageControls/PageControls';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import ProjectCreationGuard from '../../../components/projects/ProjectCreationGuard/ProjectCreationGuard';
import {IndexState, LocalStorageKeys, PodTypes} from '../../../constants';
import {isUpgrading} from '../../../hooks/specUtils';
import {useLocalStorageState} from '../../../hooks/utils';
import {useKeysAreLoadingByGlobalProjectId} from '../../../selectors/keys';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import {
  useServiceSpecsByGlobalProject,
  useServicesAreLoadingByGlobalProject,
} from '../../../selectors/services';
import {type V4IndexInfoResponse} from '../../../types';
import {getRecentIndexesToDisplay} from '../../../utils/recent-items';
import CreateIndexButton from './CreateIndexButton/CreateIndexButton';
import IndexList from './IndexList/IndexList';
import IndexListEmptyState from './IndexListEmptyState/IndexListEmptyState';

enum SortOptions {
  VIEWED = 'viewed',
  ALPHABETICALLY = 'alphabetically',
  STATUS = 'status',
}

enum FilterOptions {
  ALL = 'all',
  SERVERLESS = 'serverless',
  PODS = 'pods',
  STARTER = 'starter',
}

const StatusOrder = [
  IndexState.FAILED,
  // Migrating is not a real response, we mock it by checking various fields of the spec
  'migrating',
  IndexState.SCALING_UP,
  IndexState.SCALING_UP_PODS,
  IndexState.SCALING_DOWN,
  IndexState.SCALING_DOWN_PODS,
  IndexState.INITIALIZING,
  IndexState.UNKNOWN,
  IndexState.READY,
];

const getStateIndex = (spec: V4IndexInfoResponse) => {
  return StatusOrder.indexOf(isUpgrading(spec) ? 'migrating' : spec.status.state);
};

const sortServices = (sort: SortOptions, specs: V4IndexInfoResponse[], globalProjectId: string) => {
  const sortedList = [...specs];

  switch (sort) {
    case SortOptions.VIEWED:
      const viewedHistory = getRecentIndexesToDisplay(
        globalProjectId,
        specs.map(({name}) => name),
      );

      return sortedList.sort(
        (spec1, spec2) => viewedHistory.indexOf(spec1.name) - viewedHistory.indexOf(spec2.name),
      );
    case SortOptions.ALPHABETICALLY:
      return sortedList.sort((a, b) => a.name.localeCompare(b.name));
    case SortOptions.STATUS:
      return sortedList.sort((spec1, spec2) => {
        return getStateIndex(spec1) - getStateIndex(spec2);
      });
    default:
      return sortedList;
  }
};

const filterServices = (filter: FilterOptions, specs: V4IndexInfoResponse[]) => {
  switch (filter) {
    case FilterOptions.SERVERLESS:
      return specs.filter((service) => 'serverless' in service.spec);
    case FilterOptions.PODS:
      return specs.filter(
        (service) => 'pod' in service.spec && service.spec.pod.pod_type !== PodTypes.STARTER,
      );
    case FilterOptions.STARTER:
      return specs.filter(
        (service) => 'pod' in service.spec && service.spec.pod.pod_type === PodTypes.STARTER,
      );
    case FilterOptions.ALL:
    default:
      return specs;
  }
};

function IndexListPage() {
  const selectedProject = useSelectedGlobalProject();
  const keysLoading = useKeysAreLoadingByGlobalProjectId(selectedProject?.id);
  const servicesLoading = useServicesAreLoadingByGlobalProject(selectedProject?.id);
  const serviceSpecs = useServiceSpecsByGlobalProject(selectedProject?.id || '');

  const [searchText, setSearchText] = useState('');
  const {val: sort, setVal: setSort} = useLocalStorageState<SortOptions>(
    LocalStorageKeys.INDEX_SORT_ORDER,
    SortOptions.VIEWED,
  );
  const [filter, setFilter] = useState(FilterOptions.ALL);
  const filteredServices = useMemo(() => {
    const searchFiltered = serviceSpecs.filter((service) => service.name?.includes(searchText));
    const filtered = filterServices(filter, searchFiltered);
    const sorted = sortServices(sort, filtered, selectedProject?.id);
    return sorted.map((spec) => spec.name);
  }, [serviceSpecs, searchText, sort, filter, selectedProject?.id]);

  if (servicesLoading || keysLoading) {
    return (
      <ProjectCreationGuard globalProjectId={selectedProject.id}>
        <LoadingContainer loading={servicesLoading || keysLoading}>
          <Box sx={{height: '128px'}} />
        </LoadingContainer>
      </ProjectCreationGuard>
    );
  }

  if (serviceSpecs.length === 0) {
    return (
      <ProjectCreationGuard globalProjectId={selectedProject.id}>
        <IndexListEmptyState />
      </ProjectCreationGuard>
    );
  }

  return (
    <ProjectCreationGuard globalProjectId={selectedProject.id}>
      <Page
        header={
          <PageHeader
            title="Indexes"
            actions={<CreateIndexButton />}
            badge={
              <ProjectEncryptionBadge cmekEncrypted={selectedProject.force_encryption_with_cmek} />
            }
          />
        }
      >
        <PageControls>
          <Box flex={1}>
            <TextInput
              fullWidth
              hideError
              placeholder="Search indexes"
              value={searchText}
              onChange={setSearchText}
              searchIcon
              clearable
            />
          </Box>
          <SelectInput
            hideError
            sx={{minWidth: 180}}
            value={sort}
            onChange={setSort}
            options={[
              {value: SortOptions.VIEWED, label: 'Sort by last viewed'},
              {value: SortOptions.ALPHABETICALLY, label: 'Sort alphabetically'},
              {value: SortOptions.STATUS, label: 'Sort by status'},
            ]}
          />
          <SelectInput
            hideError
            value={filter}
            sx={{minWidth: 180}}
            onChange={setFilter}
            options={[
              {value: FilterOptions.ALL, label: 'Filter by'},
              {value: FilterOptions.SERVERLESS, label: 'Filter by serverless'},
              {value: FilterOptions.PODS, label: 'Filter by pods'},
              {value: FilterOptions.STARTER, label: 'Filter by starter'},
            ]}
          />
        </PageControls>
        {filteredServices.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="60%">
            <Box textAlign="center">
              <Typography color="text.primary" variant="h5" pb={2}>
                No results found
              </Typography>
              <Typography color="text.primary">Please check your filters and try again.</Typography>
            </Box>
          </Box>
        ) : (
          <IndexList services={filteredServices} />
        )}
      </Page>
    </ProjectCreationGuard>
  );
}

export default IndexListPage;
