import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type {Section} from '../types';
import {
  type GuideContextState,
  GuideStatus,
  type Section as SectionContext,
} from '../utilities/GuideContext';

const styles = {
  statusBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    my: 1,
  },
  statusBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 24,
    minHeight: 24,
    borderRadius: '100%',
    fontSize: 18,
  },
  statusBoxIncomplete: {
    border: 1,
    borderStyle: 'dashed',
    borderColor: 'action.disabled',
  },
};

export function calculatePercentage(state: GuideContextState) {
  const totalBlocks = state.sections.reduce((acc: number, section: SectionContext) => {
    return acc + section.blocks.length;
  }, 0);
  const completedBlocks = state.sections.reduce((acc: number, section: SectionContext) => {
    return (
      acc +
      section.blocks.filter(
        (block) => block.status === GuideStatus.COMPLETE || block.status === GuideStatus.SKIPPED,
      ).length
    );
  }, 0);
  return Math.round((completedBlocks / totalBlocks) * 100);
}

export function SectionStatus({
  section,
  sectionStatus,
}: {
  section: Section;
  sectionStatus: GuideStatus;
}) {
  switch (sectionStatus) {
    case GuideStatus.IN_PROGRESS:
      return (
        <Box sx={styles.statusBoxContainer}>
          <Box sx={styles.statusBox}>
            <ArrowForwardIcon color="primary" fontSize="inherit" />
          </Box>
          <Typography variant="body2" fontWeight="bold">
            {section.title}
          </Typography>
        </Box>
      );
    case GuideStatus.COMPLETE:
      return (
        <Box sx={styles.statusBoxContainer}>
          <Box sx={styles.statusBox}>
            <CheckIcon color="success" fontSize="inherit" />
          </Box>
          <Typography variant="body2">{section.title}</Typography>
        </Box>
      );
    case GuideStatus.INCOMPLETE:
      return (
        <Box sx={styles.statusBoxContainer}>
          <Box sx={[styles.statusBox, styles.statusBoxIncomplete]} />
          <Typography variant="body2" color="text.secondary">
            {section.title}
          </Typography>
        </Box>
      );
    default:
      return null;
  }
}
