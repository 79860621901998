import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CodeBlock from '@pinecone-experience/timber/CodeBlock';
import {LANGUAGE_CONTENT} from './constants';
import {useSelectedLanguage} from './utils';

type QueryDataProps = {
  host: string;
};

const styles = {
  subtext: {
    pb: 2,
    maxWidth: '80%',
  },
};

function QueryData({host}: QueryDataProps) {
  const selectedLanguage = useSelectedLanguage();
  const selectedLanguageContent = LANGUAGE_CONTENT[selectedLanguage];
  const code = selectedLanguageContent.queryText(host);
  return (
    <Box role="region" aria-labelledby="install">
      <Typography variant="h6" id="install">
        Query
      </Typography>
      <Typography sx={styles.subtext}>
        {`Search the "ns1" namespace in your index for the 2 vectors that are most similar to an
        example vector, filtering for results that match a specific metadata value:`}
      </Typography>
      <CodeBlock code={code} language={selectedLanguage} />
    </Box>
  );
}

export default QueryData;
