import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import InlineCode from '@pinecone-experience/timber/InlineCode';
import {Link as RouterLink} from 'react-router-dom';
import {FreeTierDowngradeRestrictionKeys} from '../../../../../constants';
import {type FreeTierDowngradeRestriction} from '../../../../../types';

import RowWithIcon from '../../../../Utilities/RowWithIcon';

interface FreeTierRestrictionsProps {
  organizationId: string;
  restrictions: FreeTierDowngradeRestriction[];
}

const styles = {
  root: {
    mt: 2,
    backgroundColor: 'background.surface',
    p: 2,
  },
  list: {
    m: 0,
    p: 0,
  },
  listItem: {
    p: 0,
    color: 'text.secondary',
    display: 'list-item',
  },
  exhaustedItem: {
    color: 'text.primary',
  },
};

const getRestrictionContent = (
  organizationId: string,
  {restriction, limit}: FreeTierDowngradeRestriction,
) => {
  switch (restriction) {
    case FreeTierDowngradeRestrictionKeys.SERVERLESS_FREE_TIER_INDEXES:
      return (
        <>
          You have no more than {limit} serverless{' '}
          <Link component={RouterLink} to={`/organizations/${organizationId}/projects/-/indexes`}>
            indexes
          </Link>{' '}
          in <InlineCode text="us-east-1" />.
        </>
      );
    case FreeTierDowngradeRestrictionKeys.STORAGE_UNITS_GBS:
      return <>Your database storage is below {limit}GB.</>;
    case FreeTierDowngradeRestrictionKeys.PROJECT_COUNT:
      return (
        <>
          You have no more than {limit}{' '}
          <Link component={RouterLink} to={`/organizations/${organizationId}/projects`}>
            project
          </Link>
          .
        </>
      );
    case FreeTierDowngradeRestrictionKeys.NON_SERVERLESS_FREE_TIER_INDEXES:
      return (
        <>
          You have no pod-based{' '}
          <Link component={RouterLink} to={`/organizations/${organizationId}/projects/-/indexes`}>
            indexes
          </Link>{' '}
          and no serverless{' '}
          <Link component={RouterLink} to={`/organizations/${organizationId}/projects/-/indexes`}>
            indexes
          </Link>{' '}
          in regions other than <InlineCode text="us-east-1" />.
        </>
      );
    case FreeTierDowngradeRestrictionKeys.PRIVATE_ENDPOINTS:
      return (
        <>
          You aren&apos;t using any active{' '}
          <Link component={RouterLink} to={`/organizations/${organizationId}/projects/-/network`}>
            PrivateLink
          </Link>{' '}
          connections.
        </>
      );
    case FreeTierDowngradeRestrictionKeys.ASSISTANT_COUNT:
      return (
        <>
          You have no more than {limit}{' '}
          <Link component={RouterLink} to={`/organizations/${organizationId}/projects/-/assistant`}>
            Assistants
          </Link>
          .
        </>
      );
    case FreeTierDowngradeRestrictionKeys.ASSISTANT_FILE_COUNT:
      return (
        <>
          You have no more than {limit} files per{' '}
          <Link component={RouterLink} to={`/organizations/${organizationId}/projects/-/assistant`}>
            Assistant
          </Link>
          .
        </>
      );
    case FreeTierDowngradeRestrictionKeys.ASSISTANT_STORAGE_GBS:
      return (
        <>
          Your{' '}
          <Link component={RouterLink} to={`/organizations/${organizationId}/projects/-/assistant`}>
            Assistant
          </Link>{' '}
          storage is below {limit}GB.
        </>
      );

    default:
      return undefined;
  }
};

export default function FreeTierRestrictions({
  organizationId,
  restrictions,
}: FreeTierRestrictionsProps) {
  return (
    <Card sx={styles.root}>
      <Typography mb={1}>
        <strong>You must meet the following requirements for downgrading:</strong>
      </Typography>
      <List sx={styles.list}>
        {restrictions.map((restriction) => {
          const {isExhausted, restriction: restrictionName} = restriction;
          const content = getRestrictionContent(organizationId, restriction);

          return content ? (
            <ListItem
              sx={[styles.listItem, isExhausted && styles.exhaustedItem]}
              key={restrictionName}
            >
              <RowWithIcon icon={isExhausted ? <Close color="error" /> : <Check />}>
                <Typography color="inherit">{content}</Typography>
              </RowWithIcon>
            </ListItem>
          ) : null;
        })}
      </List>
    </Card>
  );
}
