import Chip, {type ChipProps} from '@mui/material/Chip';
import {type SxProps, type Theme, alpha, darken, lighten} from '@mui/material/styles';
import {PineconeColors} from '@pinecone-experience/theme/colors';
import type * as React from 'react';
import {useIsDarkTheme} from '../selectors/ui';

const styles = {
  chip: (
    theme: Theme,
    color: string,
    selected: boolean,
    size: ChipProps['size'],
    getAccentColor: (color: string, offset: number) => string,
  ) =>
    ({
      backgroundColor: selected ? color : 'background.paper',
      color: selected ? theme.palette.getContrastText(color) : getAccentColor(color, 0.35),
      border: size === 'small' ? 0 : 1,
      borderColor: darken(color, 0.2),
      borderRadius: size === 'small' ? 1 : 2,
      '&:hover': {
        backgroundColor: selected ? alpha(color, 0.8) : alpha(color, 0.5),
      },
      fontWeight: theme.typography.fontWeightBold,
      m: 0.25,
    } as SxProps<Theme>),
};

type StyledChipProps = {
  label: string;
  color?: string;
  size?: ChipProps['size'];
  selected?: boolean;
  onClick?: () => void;
  sx?: ChipProps['sx'];
};
const StyledChip: React.FC<StyledChipProps> = ({
  label,
  color = PineconeColors.LIGHT.SECONDARY,
  size = 'medium',
  selected = true,
  onClick,
  sx,
}) => {
  const isDarkTheme = useIsDarkTheme();
  const getAccentColor = isDarkTheme ? lighten : darken;
  return (
    <Chip
      sx={(theme) => ({
        ...styles.chip(theme, color, selected, size, getAccentColor),
        ...sx,
      })}
      size={size}
      label={label}
      onClick={onClick}
    />
  );
};

export default StyledChip;
