import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import {alpha} from '@mui/material/styles';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import Menu from '@pinecone-experience/timber/Menus/Menu';
import {useCallback, useRef, useState} from 'react';
import {OrgSettingsPage, Plans} from '../../../../../constants';
import {useAuth} from '../../../../../hooks/auth';
import {useNavigateToOrgSettingsPage} from '../../../../../hooks/navigation';
import {useSelectedOrganization} from '../../../../../selectors/organizations';
import {useUserData} from '../../../../../selectors/userSession';
import {getUserInitials, getUserName} from '../../../../../utils/user';

const styles = {
  button: {
    p: 0,
  },
  menu: {
    m: 1,
    width: 250,
  },
  username: {
    // Override disabled button styles
    p: '0 !important',
    opacity: '1 !important',
    display: 'block !important',
    textWrap: 'wrap',
    overflowWrap: 'break-word',
  },
  menuItem: {
    justifyContent: 'space-between',
    borderRadius: 1,
    px: 1,
    py: 0.5,
    gap: 1.5,
  },
  billingItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 1.5,
  },
};

function UserMenu() {
  const {logout} = useAuth();
  const selectedOrg = useSelectedOrganization();
  const isOrgFree = selectedOrg?.plan === Plans.FREE;
  const user = useUserData();

  const goToOrgSettings = useNavigateToOrgSettingsPage(selectedOrg?.id);

  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => {
    return setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    return setOpen(false);
  }, []);

  const handleAccountNavigation = useCallback(() => {
    setOpen(false);
    goToOrgSettings(OrgSettingsPage.MANAGE);
  }, [goToOrgSettings]);

  const handleBillingNavigation = useCallback(() => {
    setOpen(false);
    goToOrgSettings(OrgSettingsPage.BILLING);
  }, [goToOrgSettings]);

  const handleLogout = useCallback(() => {
    setOpen(false);
    logout({returnTo: window.location.origin});
  }, [logout]);

  const menuAnchor = useRef(null);
  const userName = getUserName(user);
  const userInitials = getUserInitials(user);

  return (
    <>
      <IconButton
        id="user-menu-button"
        ariaLabel="open user menu"
        ariaControls={open ? 'user-menu' : undefined}
        ariaHaspopup
        ariaExpanded={open ? true : undefined}
        onClick={handleOpen}
        ref={menuAnchor}
        shape="circle"
        sx={styles.button}
        icon={
          <Avatar
            sx={(theme) => ({
              color: 'text.primary',
              backgroundColor: alpha(theme.palette.primary.main, 0.08),
              fontWeight: 800,
              fontSize: userInitials.length > 2 ? '10px' : '16px',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            })}
          >
            {userInitials}
          </Avatar>
        }
      />
      <Menu
        id="user-menu"
        labelledBy="user-menu-button"
        anchor={menuAnchor.current}
        open={open}
        onClose={handleClose}
        sx={styles.menu}
      >
        {/* Username must be wrapped in MenuItem to preserve keyboard navigation of entire menu. */}
        <MenuItem sx={styles.username} disabled>
          <Typography variant="subtitle1" px={1}>
            {userName}
          </Typography>
          <Typography color="text.secondary" variant="subtitle2" fontWeight="400" px={1}>
            {user?.email}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem sx={styles.menuItem} onClick={handleBillingNavigation}>
          <Box sx={styles.billingItem}>
            Billing{' '}
            {isOrgFree && (
              <Chip
                label="Upgrade"
                variant="outlined"
                size="small"
                color="info"
                sx={{cursor: 'pointer'}}
              />
            )}
          </Box>
        </MenuItem>
        <MenuItem sx={styles.menuItem} onClick={handleAccountNavigation}>
          Organization settings
        </MenuItem>
        <Divider />
        <MenuItem sx={styles.menuItem} onClick={handleLogout}>
          Log out
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserMenu;
