import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import StarterBenefitsModal from '../../../../../components/StarterBenefitsModal/StarterBenefitsModal';
import {PlanNameMapping} from '../../../../../constants';
import {useCanShowPrice} from '../../../../../hooks/environmentCapabilities';
import {useBooleanState} from '../../../../../hooks/utils';
import calculatePrice from '../../../../../mirrored/calculatePrice';
import {Plans, PodTypes} from '../../../../../mirrored/pricingConstants';
import {useIsFreeEnvironment} from '../../../../../selectors/environments';
import {useSelectedOrganization} from '../../../../../selectors/organizations';
import {capitalize, formatMoney} from '../../../../../utils/format';
import {getServerlessWriteTooltip} from './utilities/utils';

interface PriceEstimatorProps {
  data?: any;
  environment: string;
  isServerless: boolean;
  upsertCost?: number;
}

function PriceEstimator({data = {}, environment, isServerless, upsertCost}: PriceEstimatorProps) {
  const {pods, podType, podSize, replicas} = data;
  const organization = useSelectedOrganization();
  const plan = organization.plan as Plans;
  const isFreeOrg = plan === Plans.FREE;
  const isSelectedEnvironmentFree = useIsFreeEnvironment(environment, organization.id);
  const showPrice = useCanShowPrice(environment, organization.id);

  let title;
  let message;
  const {
    val: showStarterBenefitsModal,
    setTrue: setShowStarterBenefitsModal,
    setFalse: setHideStarterBenefitsModal,
  } = useBooleanState();

  if (isServerless) {
    if (upsertCost) {
      return (
        <Box>
          <Box sx={{display: 'flex', alignItems: 'center', color: 'text.secondary'}}>
            <Typography display="inline" variant="h6" color="primary.dark">
              {PlanNameMapping[plan]} Plan
            </Typography>
          </Box>
          <Typography color="text.secondary">
            <Box display="flex" gap={1} alignItems="center">
              <Typography>{`Your initial data upsert will consume ${upsertCost} Write Units`}</Typography>
              <Tooltip title={<Typography>{getServerlessWriteTooltip(upsertCost)}</Typography>}>
                <InfoOutlinedIcon sx={{fontSize: '1rem', position: 'relative', top: 2}} />
              </Tooltip>
            </Box>
          </Typography>
        </Box>
      );
    }

    title = <Typography color="secondary">You are currently on the</Typography>;
    message = (
      <>
        <Box display="flex" alignItems="center">
          {PlanNameMapping[plan]} Plan
          {isFreeOrg && (
            <IconButton
              id="starter-benefits-info"
              color="secondary"
              sx={{m: 0}}
              onClick={setShowStarterBenefitsModal}
              icon={<InfoOutlinedIcon fontSize="small" />}
            />
          )}
        </Box>
        <StarterBenefitsModal
          open={showStarterBenefitsModal}
          onClose={setHideStarterBenefitsModal}
        />
      </>
    );
  } else if (isFreeOrg) {
    title = 'No monthly cost, included in:';
    message = 'Starter Plan';
  } else if (isSelectedEnvironmentFree || podType === PodTypes.nano) {
    title = 'No monthly cost:';
    message = `Free Tier Index`;
  } else {
    const price = calculatePrice({
      organization,
      environment,
      pods: Number(pods),
      productType: podType as PodTypes,
      podSize: Number(podSize.replace('x', '')),
      replicas: Number(replicas),
    });

    title = 'Estimated cost:';
    message = formatMoney(price);
  }
  if (!showPrice) {
    return (
      <Box>
        <Typography color="text.secondary">You are currently on the</Typography>
        <Typography display="inline" variant="h6" color="primary.dark">
          {`${capitalize(organization.plan)} Plan`}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography color="text.secondary">{title}</Typography>
      <Box sx={{display: 'flex', alignItems: 'center', color: 'text.secondary'}}>
        <Typography display="inline" variant="h6" color="primary.dark">
          {message}
        </Typography>
        {isServerless || isFreeOrg || isSelectedEnvironmentFree ? '' : <span> &nbsp; / month</span>}
      </Box>
    </Box>
  );
}

export default PriceEstimator;
