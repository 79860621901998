import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import {PINECONE_URLS, Plans} from '../../../constants';
import {
  type BillingPlan,
  BillingPlanAttributes,
  type BillingPlanProduct,
  BillingPlanProducts,
} from '../types/billingPlans.interface';

export const BILLING_PLAN_PRODUCTS: Record<BillingPlanProducts, BillingPlanProduct> = {
  [BillingPlanProducts.Serverless]: {
    name: 'Serverless Indexes',
    docsLink: 'https://docs.pinecone.io/guides/indexes/understanding-indexes#serverless-indexes',
    features: [
      {name: 'Storage'},
      {name: 'Write Units'},
      {name: 'Read Units'},
      {name: 'Import from object storage', status: 'public-preview'},
      {name: 'Projects per Organization'},
      {name: 'Indexes per Project'},
      {
        name: 'Namespace per Index',
      },
      {name: 'Pod Based Indexes'},
      {name: 'Backup Storage', status: 'public-preview'},
      {name: 'Restore from backup', status: 'public-preview'},
    ],
  },
  [BillingPlanProducts.Inference]: {
    name: 'Inference',
    docsLink: 'https://docs.pinecone.io/guides/inference/understanding-inference',
    status: 'public-preview',
    features: [{name: 'Embed API'}, {name: 'Rerank API'}],
  },
  [BillingPlanProducts.Assistant]: {
    name: 'Pinecone Assistant',
    status: 'public-preview',
    docsLink: 'https://docs.pinecone.io/guides/assistant/understanding-assistant',
    features: [
      {name: 'Storage'},
      {name: 'Input Tokens'},
      {name: 'Output Tokens'},
      {name: 'Assistants per day'},
    ],
  },
};

export const BILLING_PLANS: BillingPlan[] = [
  {
    tier: Plans.FREE,
    description: 'For trying out and for small applications.',
    price: 'Free',
    attributes: {
      [BillingPlanAttributes.Features]: [
        {name: 'Pinecone Serverless'},
        {name: 'Pinecone Inference'},
        {name: 'Pinecone Assistant'},
        {name: 'Console Metrics'},
        {name: 'Community Support'},
      ],
      [BillingPlanAttributes.Regions]: [{name: 'AWS us-east-1'}],
    },
    productItems: {
      [BillingPlanProducts.Serverless]: [
        {name: '2GB included'},
        {name: '2M/mo. included'},
        {name: '1M/mo. included'},
        {name: '—'},
        {name: 1},
        {name: 5},
        {name: 100},
        {name: '—'},
        {name: '—'},
        {name: '—'},
      ],
      [BillingPlanProducts.Inference]: [{name: '5M tokens/mo. included'}, {name: '—'}],

      [BillingPlanProducts.Assistant]: [
        {name: '1GB Storage included'},
        {name: '1.5M included'},
        {name: '200k included'},
        {name: '—'},
      ],
    },
  },
  {
    tier: Plans.STANDARD,
    badge: <Chip label="Popular" color="primary" variant="outlined" />,
    description: 'For production applications at any scale.',
    price: 'Pay as you go',
    attributes: {
      [BillingPlanAttributes.Features]: [
        {name: 'Unlimited Serverless, Inference, and Assistant usage'},
        {name: 'Choose your cloud and region'},
        {name: 'Import from object storage'},
        {name: 'Multiple projects and users'},
        {name: 'RBAC'},
        {name: 'Backups'},
        {name: 'Prometheus metrics', status: 'coming-soon'},
        {
          name: (
            <>
              Access to{' '}
              <Link target="_blank" href={PINECONE_URLS.SUPPORT_PRICING}>
                Developer
              </Link>{' '}
              and{' '}
              <Link target="_blank" href={PINECONE_URLS.SUPPORT_PRICING}>
                Pro support
              </Link>
            </>
          ),
        },
        {name: 'Pod Based Indexes'},
      ],
      [BillingPlanAttributes.Regions]: [
        {
          name: (
            <>
              Choose your{' '}
              <Link target="_blank" href={PINECONE_URLS.PRICING}>
                cloud and pricing
              </Link>
            </>
          ),
        },
      ],
    },
    productItems: {
      [BillingPlanProducts.Serverless]: [
        {name: '$0.33/GB/mo.'},
        {name: 'Starting at $4/M'},
        {name: 'Starting at $16/M'},
        {name: '$1/GB'},
        {name: '20'},
        {name: '20'},
        {name: '10000'},
        {name: 'Unlimited'},
        {name: 'Starting at $0.12/GB/mo.'},
        {name: 'Starting at $0.50/GB'},
      ],
      [BillingPlanProducts.Inference]: [{name: '$0.08/M tokens'}, {name: '$2/1k requests'}],
      [BillingPlanProducts.Assistant]: [
        {name: '$3 per GB/mo.'},
        {name: '$8 per 1M'},
        {name: '$15 per 1M'},
        {name: '$0.20/Assistant per day'},
      ],
    },
  },
  {
    tier: Plans.ENTERPRISE,
    description: 'For mission-critical production applications.',
    price: 'Custom',
    attributes: {
      [BillingPlanAttributes.Features]: [
        {name: 'Everything in Standard'},
        {name: '99.95% Uptime SLA'},
        {name: 'Single sign-on'},
        {name: 'Private Link', status: 'public-preview'},
        {name: 'CMEK', status: 'coming-soon'},
        {name: 'Audit Logs', status: 'coming-soon'},
        {
          name: (
            <>
              <Link target="_blank" href={PINECONE_URLS.SUPPORT_PRICING}>
                Enterprise support
              </Link>{' '}
              included
            </>
          ),
        },
      ],
      [BillingPlanAttributes.Regions]: [
        {
          name: (
            <>
              Choose your{' '}
              <Link target="_blank" href={PINECONE_URLS.PRICING}>
                cloud and pricing
              </Link>
            </>
          ),
        },
      ],
    },
    productItems: {
      [BillingPlanProducts.Serverless]: [
        {name: '$0.33/GB/mo.'},
        {name: 'Starting at $6/M'},
        {name: 'Starting at $24/M'},
        {name: '$1/GB'},
        {name: '100'},
        {name: '200'},
        {name: '100000'},
        {name: 'Unlimited'},
        {name: 'Starting at $0.12/GB/mo.'},
        {name: 'Starting at $0.50/GB'},
      ],
      [BillingPlanProducts.Inference]: [{name: '$0.08/M tokens'}, {name: '$2/1k requests'}],
      [BillingPlanProducts.Assistant]: [
        {name: '$3 per GB/mo.'},
        {name: '$8 per 1M'},
        {name: '$15 per 1M'},
        {name: '$0.20/Assistant per day'},
      ],
    },
  },
];
