import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function PodCard(headline: string, description: string, paid?: boolean) {
  return (
    <Box height={100} p={1}>
      <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
        <Typography variant="h5">{headline}</Typography>
        {paid && <LockIcon sx={{ml: 1}} color="disabled" fontSize="small" />}
      </Box>
      <Typography align="left" mb={3}>
        {description}
      </Typography>
    </Box>
  );
}

export default PodCard;
