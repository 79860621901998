import Box from '@mui/material/Box';
import {type Theme} from '@mui/material/styles';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useContext} from 'react';
import {AlertBarContext} from '../../../context/alert-bar';
import {ALERT_BAR_HEIGHT, APP_BAR_HEIGHT, CONTENT_PADDING, GUTTER} from '../../../styles/theme';
import DrawerContainer, {type DrawerConfig} from './DrawerContainer/DrawerContainer';
import {type PageWidth, getPageColumns} from './styles/columns';

export interface PageProps {
  children?: React.ReactNode;
  /**
   * @recommended `Page/<PageHeader />`
   */
  header?: React.ReactNode;
  /**
   * @recommended `Page/<PageFooter />`
   */
  footer?: React.ReactNode;
  loading?: boolean;
  /**
   * Constraint page content to a defined max-width.
   */
  width?: PageWidth;
  /**
   * Drawer to be displayed on the right side of the page.
   */
  drawer?: DrawerConfig;
  /**
   * Wraps children in a column flex container with gap.
   * @default true
   */
  flexChildren?: boolean;
  /**
   * Overrides margins set by Console's MainContent component.
   * TODO(cboltt): Remove this override once all pages are using `<Page />`
   */
  overrideAppMargins?: boolean;
}

const styles = {
  appMarginsOverride: {
    mx: -1 * CONTENT_PADDING,
    mb: -1 * 8,
  },
  root: (theme: Theme, width: PageWidth, hasAlertBar: boolean) => ({
    mx: 'auto',
    height: `calc(100vh - ${APP_BAR_HEIGHT} ${hasAlertBar ? `- ${ALERT_BAR_HEIGHT}` : ''})`,
    display: 'grid',
    gridTemplateRows: '1fr auto',
    gridTemplateColumns: getPageColumns(width, theme.spacing(GUTTER)),
    gridTemplateAreas: `
      "scrollBox scrollBox scrollBox scrollBox scrollBox"
      "scrollBox scrollBox scrollBox scrollBox scrollBox"
      ". footer footer footer ."  
    `,
  }),
  scrollBox: (theme: Theme, width: PageWidth) => ({
    pt: width === 'fullscreen' ? 0 : CONTENT_PADDING,
    pb: width === 'fullscreen' ? 0 : GUTTER,
    display: 'grid',
    gridArea: 'scrollBox',
    overflowY: 'auto',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: getPageColumns(width, theme.spacing(GUTTER)),
    gridTemplateAreas: `
      ". . header . . "
      ". . content . ."`,
  }),
  header: {
    gridArea: 'header',
  },
  content: {
    gridArea: 'content',
  },
  flexContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: GUTTER,
  },
  footer: {
    gridArea: 'footer',
  },
};

export default function Page({
  header,
  children,
  footer,
  loading,
  width = 'variable',
  overrideAppMargins = true,
  flexChildren = true,
  drawer,
}: PageProps) {
  const {pageHasAlertBar} = useContext(AlertBarContext);

  return (
    <Box sx={[overrideAppMargins && styles.appMarginsOverride]}>
      <DrawerContainer gutter={width !== 'fullscreen'} config={drawer}>
        <Box sx={(theme) => styles.root(theme, width, pageHasAlertBar)}>
          <Box sx={(theme) => styles.scrollBox(theme, width)}>
            {header ? <Box sx={styles.header}>{header}</Box> : null}
            <Box sx={[styles.content, flexChildren && styles.flexContent]}>
              {loading ? <LoadingContainer loading /> : children}
            </Box>
          </Box>
          {footer ? <Box sx={styles.footer}>{footer}</Box> : null}
        </Box>
      </DrawerContainer>
    </Box>
  );
}
