import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {deleteSsoConfig} from '../../../../../../../actions/organizationActions';
import ModalForm from '../../../../../../../components/ModalForm';
import {useDashboardApi} from '../../../../../../../hooks/use-api';
import {useBooleanState} from '../../../../../../../hooks/utils';

type DeleteSsoConfigButtonProps = {
  orgId: string;
};

function DeleteSsoConfigButton({orgId}: DeleteSsoConfigButtonProps) {
  const {val: isOpenConfirm, setTrue: openConfirm, setFalse: closeConfirm} = useBooleanState();
  const {setQueryData} = useDashboardApi<{organizationId: string}>();
  const doDelete = () => {
    setQueryData({
      action: deleteSsoConfig,
      data: {organizationId: orgId},
    });
  };
  return (
    <>
      <Button startIcon={<DeleteOutlinedIcon />} onClick={openConfirm} color="error">
        Remove connection
      </Button>
      <ModalForm
        open={isOpenConfirm}
        close={closeConfirm}
        title="Confirm removal?"
        isValid
        form={
          <Typography>
            Are you sure you want to remove the Single Sign-On (SSO) connection? This action will
            disable SSO for all users and cannot be undone. Users will no longer be able to log in
            with their SSO credentials and may lose access to certain services until an alternative
            login method is configured.
          </Typography>
        }
        submitText="Remove SSO configuration"
        submitColor="error"
        onSubmit={doDelete}
      />
    </>
  );
}

export default DeleteSsoConfigButton;
