import {useAppSelector as useSelector} from '../app/store';
import {SupportTiers} from '../constants';
import {useSelectedOrganization} from './organizations';

export const useSetupIntent = () => {
  return useSelector((state) => state.billing.setupIntent);
};

export const useUpcomingInvoice = () => {
  return useSelector((state) => state.billing.upcomingInvoice);
};

export const usePastInvoices = () => {
  return useSelector((state) => state.billing.pastInvoices);
};

export const useBillingDetails = () => {
  return useSelector((state) => state.billing.billingDetails);
};

export const useCurrentSubscription = () => {
  return useSelector((state) => state.billing.currentSubscription);
};

export const usePastInvoicesAreLoading = () => {
  return useSelector((state) => state.billing.pastInvoicesLoading);
};

export const useShouldShowChurn = () => {
  return useSelector((state) => state.billing.showChurn);
};

export const useIsUpgradeLoading = () => {
  return useSelector((state) => {
    return state.billing.upgradeLoading;
  });
};

export const useInvoiceIsLoading = (invoiceId: string) => {
  return useSelector((state) => {
    return state.billing.invoicesLoading[invoiceId];
  });
};

export const useSupportLoading = () => {
  return useSelector((state) => state.billing.support.loading);
};

export const useIsSupportIncluded = () => {
  return useSelector((state) => state.billing.support.data?.isSupportIncluded);
};

export const useSupportChangeCompleted = () => {
  return useSelector((state) => state.billing.support.data?.completedAction);
};

export const useCurrentSupport = () => {
  return useSelector((state) => {
    const supportIntervals = state.billing.support.data?.supportIntervals;
    return supportIntervals ? supportIntervals[0] : null;
  });
};

export const useScheduledSupport = () => {
  return useSelector((state) => {
    const supportIntervals = state.billing.support.data?.supportIntervals;
    return supportIntervals && supportIntervals.length > 1 ? supportIntervals[1] : null;
  });
};

export const useCurrentSupportTier = () => {
  const selectedOrg = useSelectedOrganization();
  return useSelector((state) => {
    const supportIntervals = state.billing.support.data?.supportIntervals;
    return (
      supportIntervals?.[0]?.tier ??
      (selectedOrg ? selectedOrg.support_tier : undefined) ??
      SupportTiers.FREE
    );
  });
};
