import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LinearProgress from '@pinecone-experience/timber/Progress/LinearProgress';
import {useSelectedLanguage} from '../../../../../components/CodeSnippets/utils';
import {PUBLIC_DOCS, PUBLIC_DOCS_URL} from '../../../../../constants';
import {getStartedUtmParameters} from '../../constants';
import {type Guide} from '../types';
import {useGuideContext} from '../utilities/GuideContext';
import {SectionStatus, calculatePercentage} from './utilities';

const styles = {
  root: {
    width: '100%',
    position: 'sticky',
    top: 0,
  },
  card: {
    p: 3,
  },
  stretch: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 1,
  },
  additionalResources: {
    p: 2,
    mt: 6,
    display: 'flex',
    flexDirection: 'column',
  },
  summaryLink: {
    width: '100%',
    borderRadius: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    color: 'text.primary',
  },
};

function Summary({guide}: {guide: Guide}) {
  const {state, supportedLanguages, resetGuide} = useGuideContext();
  const selectedLanguage = useSelectedLanguage(supportedLanguages);
  const percentage = calculatePercentage(state);
  return (
    <Box sx={styles.root}>
      <Paper sx={styles.card}>
        <Box mb={2}>
          <Box sx={styles.stretch}>
            <Typography fontWeight="bold">Progress</Typography>
            <Typography variant="body2">{`${percentage}% complete`}</Typography>
          </Box>
          <LinearProgress value={percentage} />
        </Box>
        <Box mt={2}>
          {Object.values(state.sections).map(
            (section) =>
              (!guide.sections[section.index]?.supportedLanguages ||
                guide.sections[section.index]?.supportedLanguages?.includes(selectedLanguage)) && (
                <Link
                  key={section.index}
                  sx={styles.summaryLink}
                  href={`#section-${section.index}`}
                >
                  <SectionStatus
                    key={section.index}
                    section={guide.sections[section.index]}
                    sectionStatus={section.status}
                  />
                </Link>
              ),
          )}
          {percentage === 100 && (
            <Button
              onClick={resetGuide}
              variant="outlined"
              fullWidth
              sx={{
                borderColor: 'divider',
                mt: 1,
              }}
            >
              Reset guide
            </Button>
          )}
        </Box>
      </Paper>
      <Box sx={styles.additionalResources}>
        <Typography fontWeight="bold" mb={2}>
          Additional resources
        </Typography>
        <Link
          href={`${PUBLIC_DOCS.EXAMPLES}${getStartedUtmParameters}`}
          target="_blank"
          sx={{mb: 1}}
        >
          Example notebooks
        </Link>
        <Link
          href={`${PUBLIC_DOCS.API_REFERENCE}${getStartedUtmParameters}`}
          target="_blank"
          sx={{mb: 1}}
        >
          API reference
        </Link>
        <Link href={`${PUBLIC_DOCS_URL}${getStartedUtmParameters}`} target="_blank">
          Documentation
        </Link>
      </Box>
    </Box>
  );
}

export default Summary;
