import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LinearProgress from '@pinecone-experience/timber/Progress/LinearProgress';
import {useNavigateToIndexPageGlobal} from '../../../hooks/navigation';
import {type GlobalProject, type MigrationOperationDetails, OperationStatus} from '../../../types';
import {getEffectivePercent} from '../../../utils/migrations';

function OperationProgress({
  ops,
  project,
  close,
}: {
  ops: MigrationOperationDetails;
  project: GlobalProject;
  close?: () => void;
}) {
  const {go: goToIndex} = useNavigateToIndexPageGlobal(project.organization_id, project.id);
  const onClickView = () => {
    goToIndex(ops.migrationOperation.target_index_name);
    if (close) {
      close();
    }
  };
  if (ops.importOperation?.status === OperationStatus.COMPLETED) {
    return (
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={1}>
          <CheckIcon color="success" />
          <Typography color="success.main">Migration Complete</Typography>
        </Box>
        <Button onClick={onClickView} variant="outlined" color="secondary">
          View Index
        </Button>
      </Box>
    );
  }
  if (
    ops.importOperation?.status === OperationStatus.FAILED ||
    ops.importOperation?.status === OperationStatus.CANCELLED ||
    ops.migrationOperation.status === OperationStatus.FAILED ||
    ops.migrationOperation.status === OperationStatus.CANCELLED
  ) {
    return (
      <Box>
        <Typography color="secondary">Migration Failed</Typography>
        <LinearProgress value={0} error />
      </Box>
    );
  }

  return (
    <Box>
      <Typography color="secondary">
        {!ops.migrationOperation.import_operation_id ? 'Creating collection' : 'Importing data'}
      </Typography>
      <LinearProgress value={getEffectivePercent(ops.migrationOperation, ops.importOperation)} />
    </Box>
  );
}

function OperationDisplay({
  ops,
  project,
  close,
}: {
  ops: MigrationOperationDetails;
  project: GlobalProject;
  close?: () => void;
}) {
  return (
    <Box>
      <Typography fontWeight="fontWeightBold">
        {ops.migrationOperation.target_index_name}
      </Typography>
      <OperationProgress ops={ops} project={project} close={close} />
    </Box>
  );
}

export default OperationDisplay;
