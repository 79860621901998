import * as Sentry from '@sentry/react';
import {type BulkImport, type BulkImportErrorMode, type IndexStatsDescription} from '../types';
import {type MetadataEntry, type MetadataFilter} from '../types/metadata/language';

export type Pagination = {
  next: string;
};

export interface PaginatedResponseRequestParams {
  limit?: number;
  paginationToken?: string;
}

export type Usage = {
  readUnits?: number;
};

export interface Vector {
  id: string;
  values: number[];
  sparseValues?: {
    indices: number[];
    values: number[];
  };
  metadata: Record<string, MetadataEntry>;
}

export interface UpsertRequest {
  vectors: Vector[];
  namespace?: string;
}

export interface UpsertResponse {
  upsertedCount: number;
}

export type QueryData = {id: string} | {vector: number[]};

export type QueryRequest = {
  namespace?: string;
  topK: number;
  filter?: MetadataFilter;
  includeValues: boolean;
  includeMetadata: boolean;
} & QueryData;

export type VectorResponse = Vector & {score: number};

export interface QueryResponse {
  namespace: string;
  matches: VectorResponse[];
  usage: {
    readUnits: number;
  };
}

export interface UpdateRequest {
  id: string;
  values?: number[];
  setMetadata?: Record<string, MetadataEntry>;
  namespace?: string;
}

export interface UpdateResponse {}

export interface FetchRequest {
  ids: string[];
  namespace?: string;
}

export interface FetchResponse {
  vectors: Record<string, Vector>;
  namespace: string;
  usage: Usage;
}

export interface DeleteRequest {
  ids: string[];
  deleteAll?: boolean;
  namespace?: string;
  filter?: MetadataFilter;
}

export interface DeleteResponse {}

export interface ListVectorsRequest {
  prefix: string;
  namespace: string;
  limit: number;
}

export interface ListVectorsResponse {
  vectors: {
    id: string;
  }[];
  namespace: string;
  pagination: Pagination;
  usage: Usage;
}

export interface DescribeIndexStatsRequest {
  filter?: MetadataFilter;
}

export interface StartBulkImportRequest {
  integration_id?: string;
  uri: string;
  errorMode: {
    onError: BulkImportErrorMode;
  };
}

export interface StartBulkImportResponse {
  id: string;
}

export interface ListBulkImportsResponse {
  data: BulkImport[];
  pagination: Pagination;
}

// TODO: make a base api class that handles most of the get/post/fetch params stuff
export default class DataPlaneApi {
  rootUrl: string;

  apiKey: string;

  token: string;

  isServerless: boolean;

  constructor(rootUrl: string, apiKey: string, token: string, isServerless: boolean) {
    this.rootUrl = `https://${rootUrl}`;
    this.apiKey = apiKey;
    this.token = token;
    this.isServerless = isServerless;
  }

  fetch<T>(url: string, options = {}) {
    const headers: Record<string, string> = this.isServerless
      ? {
          Authorization: `Bearer ${this.token}`,
        }
      : {
          'api-key': this.apiKey,
        };
    return fetch(`${this.rootUrl}/${url}`, {
      ...options,
      headers,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.message) {
          if (res.status >= 500 && res.status < 600) {
            Sentry.captureException(res.message);
            throw new Error('A data plane error has occurred.');
          } else {
            throw new Error(res.message);
          }
        }
        return res as T;
      });
  }

  post<T>(url: string, params = {}) {
    const options = {
      method: 'POST',
      body: JSON.stringify(params),
    };
    return this.fetch<T>(url, options);
  }

  get<T>(url: string, params = new URLSearchParams(), options = {}) {
    return this.fetch<T>(`${url}?${params}`, options);
  }

  delete<T>(url: string, params = new URLSearchParams(), options = {}) {
    return this.fetch<T>(`${url}?${params}`, {method: 'DELETE', ...options});
  }

  updateVectors(data: UpdateRequest) {
    return this.post<UpdateResponse>('vectors/update', data);
  }

  fetchVectors(ids: string[], namespace: string) {
    const params = new URLSearchParams();
    ids.forEach((id) => {
      params.append('ids', id);
    });
    if (namespace) {
      params.append('namespace', namespace);
    }
    return this.get<FetchResponse>('vectors/fetch', params);
  }

  deleteVectors(ids: string[], namespace = '') {
    const params = {ids, namespace};
    return this.post<DeleteResponse>('vectors/delete', params);
  }

  deleteNamespace(namespace: string) {
    const params = {namespace, deleteAll: true};
    return this.post<DeleteResponse>('vectors/delete', params);
  }

  queryVectors(data: QueryRequest) {
    return this.post<QueryResponse>('query', data);
  }

  upsertMultipleVectorRequests(vectorRequests: UpsertRequest[]) {
    return Promise.all(vectorRequests.map((request) => this.upsertVectors(request)));
  }

  upsertVectors(data: UpsertRequest) {
    return this.post<UpsertResponse>('vectors/upsert', data);
  }

  listVectors(data: ListVectorsRequest) {
    return this.get<ListVectorsResponse>(
      'vectors/list',
      // URLSearchParams only takes in Record<string, string> in ts, but handles number values fine
      new URLSearchParams(data as unknown as Record<string, string>),
    );
  }

  describeIndexStats() {
    return this.get<IndexStatsDescription>('describe_index_stats');
  }

  startBulkImport(data: StartBulkImportRequest) {
    return this.post<StartBulkImportResponse>('bulk/imports', data);
  }

  listBulkImports(data: PaginatedResponseRequestParams) {
    return this.get<ListBulkImportsResponse>(
      'bulk/imports',
      new URLSearchParams(data as Record<string, string>),
    );
  }

  getBulkImport(id: string) {
    return this.get<BulkImport>(`bulk/imports/${id}`);
  }

  cancelBulkImport(id: string) {
    return this.delete<{}>(`bulk/imports/${id}`);
  }
}
