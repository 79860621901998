import {zodResolver} from '@hookform/resolvers/zod';
import Add from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import {useMemo} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import {editSsoRules} from '../../../../../../actions/organizationActions';
import {type EditSsoRules} from '../../../../../../api/DashboardApi';
import RoleOptions from '../../../../../../components/RoleOptions';
import {Roles} from '../../../../../../constants';
import {useDashboardApi} from '../../../../../../hooks/use-api';
import {useGlobalProjectsFromSelectedOrganization} from '../../../../../../selectors/projects';
import {type SsoConnectionRule} from '../../../../../../types';
import {SsoRulesForm, type SsoRulesType} from '../utilities/schema';

type SsoConfigRulesProps = {
  rules?: SsoConnectionRule[];
  orgId: string;
};

function getFormValuesFromRules(rules?: SsoConnectionRule[]) {
  if (!rules || rules.length <= 0) {
    return undefined;
  }
  const baseRule = rules[0];
  return {
    orgRole: baseRule.organization_role,
    projects: rules
      .filter((rule) => rule.project_id && rule.project_role)
      .map((rule) => ({
        projectId: rule.project_id as string,
        role: rule.project_role as Roles,
      })),
  };
}

function SsoConfigRules({rules, orgId}: SsoConfigRulesProps) {
  const values = useMemo(() => getFormValuesFromRules(rules), [rules]);
  const {
    control,
    handleSubmit,
    watch,
    formState: {isValid},
  } = useForm<SsoRulesType>({
    defaultValues: {
      orgRole: values?.orgRole || Roles.OWNER,
      projects: values?.projects || [],
    },
    values,
    resolver: zodResolver(SsoRulesForm),
  });
  const orgRole = watch('orgRole');
  const selectedProjects = watch('projects');
  const {fields, append, remove} = useFieldArray({control, name: 'projects'});
  const {setQueryData} = useDashboardApi<{
    organizationId: string;
    rules: EditSsoRules;
  }>();
  const onSubmit = (form: SsoRulesType) => {
    setQueryData({
      action: editSsoRules,
      data: {
        organizationId: orgId,
        rules: {
          orgRole: form.orgRole,
          projects:
            form.orgRole === Roles.OWNER
              ? []
              : form.projects.map((project) => ({
                  id: project.projectId,
                  role: project.role,
                })),
        },
      },
    });
  };
  const allProjects = useGlobalProjectsFromSelectedOrganization();

  const unselectedProjects = useMemo(() => {
    return allProjects.filter((project) => {
      return !selectedProjects.find((p) => p.projectId === project?.id);
    });
  }, [allProjects, selectedProjects]);

  const addProject = () => {
    const adding = unselectedProjects[0];
    if (adding) {
      append({
        projectId: adding?.id,
        role: Roles.OWNER,
      });
    }
  };
  const baseOptions = unselectedProjects.map((proj) => {
    return {
      value: proj?.id,
      label: proj?.name,
    };
  });
  // For each dropdown, options should include the selected option for that row,
  // and all unselected projects
  const createTotalOptions = (proj: {projectId: string}) => {
    const selectedProj = allProjects.find((p) => p.id === proj.projectId);
    return [{value: proj?.projectId, label: selectedProj?.name}, ...baseOptions];
  };

  return (
    <Box>
      <Typography variant="body2" py={3}>
        Set default projects access for all SSO users (optional)
      </Typography>
      <RoleOptions control={control} name="orgRole" label="Default role" />
      {orgRole !== Roles.OWNER && (
        <Box>
          {fields.map((field, index) => (
            <Box key={field.id} display="flex" gap={3} width="100%" alignItems="center">
              <Box width="50%">
                <SelectInput
                  label={index === 0 ? 'Project name' : ''}
                  control={control}
                  name={`projects.${index}.projectId`}
                  options={createTotalOptions(selectedProjects[index])}
                  fullWidth
                />
              </Box>
              <Box flexGrow={1}>
                <RoleOptions
                  control={control}
                  name={`projects.${index}.role`}
                  label={index === 0 ? 'Project role' : ''}
                />
              </Box>
              <IconButton
                id="delete"
                onClick={() => remove(index)}
                icon={<DeleteOutlinedIcon />}
                sx={{mt: index === 0 ? '20px' : '-4px'}}
              />
            </Box>
          ))}
          <Button onClick={addProject} startIcon={<Add />}>
            Add a project
          </Button>
        </Box>
      )}
      <Box display="flex" sx={{justifyContent: 'flex-end', width: '100%', mt: 2, mb: 0}}>
        <Button
          disabled={!isValid}
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Finish Setup
        </Button>
      </Box>
    </Box>
  );
}

export default SsoConfigRules;
