import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import {type QueryData} from '../../../../../api/dataPlaneApi';
import {type IndexExplorerState} from '../utilities/utils';
import ListResults from './ListResults/ListResults';
import ListVectorControls from './ListVectorControls/ListVectorControls';

type ListVectorsTabProps = {
  indexExplorerState: IndexExplorerState;
  setQueryExplorerFormQuery: (data: QueryData, namespace: string) => void;
  onSubmitQueryExplorer: () => void;
};

const styles = {
  controlsContainer: {
    my: 2,
    p: 2,
  },
};

function ListVectorsTab({
  indexExplorerState,
  setQueryExplorerFormQuery,
  onSubmitQueryExplorer,
}: ListVectorsTabProps) {
  return (
    <>
      <Paper sx={styles.controlsContainer}>
        <ListVectorControls indexExplorerState={indexExplorerState} />
      </Paper>
      <Divider />
      <ListResults
        indexExplorerState={indexExplorerState}
        setQueryExplorerFormQuery={setQueryExplorerFormQuery}
        onSubmitQueryExplorer={onSubmitQueryExplorer}
      />
    </>
  );
}

export default ListVectorsTab;
