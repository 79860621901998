import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import {upgradeService} from '../../../actions/serviceActions';
import {useControllerApi} from '../../../hooks/use-api';
import {useSelectedOrganizationId} from '../../../selectors/params';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import MigrationDetails from '../MigrationDetails/MigrationDetails';

type MigrationModalProps = {
  open: boolean;
  close: () => void;
  service: string;
};

const styles = {
  container: {
    width: 400,
    p: 1,
  },
};

function MigrationModal({open, close, service}: MigrationModalProps) {
  const selectedProject = useSelectedGlobalProject();
  const orgId = useSelectedOrganizationId();
  const {setQueryData} = useControllerApi<{
    globalProjectId: string;
    name: string;
    organizationId: string;
    region: string;
  }>(selectedProject);
  const doMigrate = () => {
    setQueryData({
      action: upgradeService,
      data: {
        organizationId: orgId,
        globalProjectId: selectedProject.id,
        name: service,
        // TODO(@damarglis): Rethink how we get these values when these flows have stabalized
        // us-east-1 will allow for serverless free tier, us-west-2 will not
        region: 'us-east-1',
      },
    });
    close();
  };
  return (
    <BaseModal open={open} onClose={close} showCloseIcon>
      <Box sx={styles.container} position="relative">
        <Box>
          <Typography variant="h5" mb={2}>
            {`Convert your starter index to Pinecone's new serverless index.`}
          </Typography>
        </Box>
        <MigrationDetails onSubmit={doMigrate} />
      </Box>
    </BaseModal>
  );
}

export default MigrationModal;
