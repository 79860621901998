import {type ReactNode, createContext, useContext, useMemo, useState} from 'react';

export enum RegistrationSteps {
  USER_INFORMATION = 0,
  USE_CASES = 1,
  EMBEDDINGS = 2,
}

export const RegistrationFormContext = createContext(
  {} as {step: RegistrationSteps; setStep: (val: RegistrationSteps) => void},
);

export const RegistrationFormProvider = ({children}: {children: ReactNode}) => {
  const [formState, setFormState] = useState<{step: RegistrationSteps}>({
    step: RegistrationSteps.USER_INFORMATION,
  });

  const value = useMemo(
    () => ({
      step: formState.step,
      setStep: (val: RegistrationSteps) => setFormState({step: val}),
    }),
    [formState.step],
  );

  return (
    <RegistrationFormContext.Provider value={value}>{children}</RegistrationFormContext.Provider>
  );
};

export const useRegistrationFormContext = () => {
  return useContext(RegistrationFormContext);
};
