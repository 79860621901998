import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useCallback, useEffect, useMemo} from 'react';
import {useFormContext, useFormState, useWatch} from 'react-hook-form';
import {usePrevious} from '../../../../../hooks/utils';
import {COUNTRY_TAX_IDS, TAX_ID_OPTIONS, type TaxId} from '../../../../../utils/stripe/constants';
import {type PaymentFormType, TaxIdEmptyValue} from '../../utilities/schema';

type TaxIdFieldsProps = {
  countryCode?: string;
};

const styles = {
  taxIdContainer: {display: 'flex', flexDirection: 'column', marginTop: 2},
  taxIdFields: {
    display: 'grid',
    gridGap: 16,
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
  },
  clearTaxIdButton: {
    alignSelf: 'flex-end',
    fontWeight: '400',
    '&:hover': {backgroundColor: 'unset'},
    marginTop: -1,
    padding: 0,
  },
  taxIdErrorText: {
    marginBottom: 1,
    marginTop: -0.5,
    marginX: 2,
  },
};

function TaxIdFields({countryCode}: TaxIdFieldsProps) {
  const {control, clearErrors, setValue} = useFormContext<PaymentFormType>();
  const {errors} = useFormState();
  const taxIdType = useWatch({control, name: 'taxIdType'});
  const taxIdValue = useWatch({control, name: 'taxIdValue'});

  const prevCountryCode = usePrevious(countryCode);
  const isTaxIdVisible = countryCode && countryCode in COUNTRY_TAX_IDS;

  const clearTaxId = useCallback(() => {
    // TODO: use resetField to do this when we upgrade react-hook-form
    clearErrors(['taxIdType', 'taxIdValue']);
    setValue('taxIdType', TaxIdEmptyValue.value);
    setValue('taxIdValue', TaxIdEmptyValue.value);
  }, [setValue, clearErrors]);

  useEffect(() => {
    // clear away all taxId data anytime country code changes
    // if going from no country code to a country code, no need to clear
    if (prevCountryCode && prevCountryCode !== countryCode) {
      clearTaxId();
    }
  }, [countryCode, prevCountryCode, clearTaxId]);

  useEffect(() => {
    // forcibly wipe an unsupported Tax ID type on billing details updates
    if (
      countryCode &&
      taxIdType &&
      !(COUNTRY_TAX_IDS[countryCode] || []).includes(taxIdType as TaxId)
    ) {
      clearTaxId();
    }
  }, [countryCode, taxIdType, clearTaxId]);

  const taxIdOptions = useMemo(() => {
    return countryCode && countryCode in COUNTRY_TAX_IDS
      ? COUNTRY_TAX_IDS[countryCode].map((taxId) => ({
          ...TAX_ID_OPTIONS[taxId],
          disabled: false,
        }))
      : [];
  }, [countryCode]);

  if (!isTaxIdVisible) {
    return null;
  }

  return (
    <Box sx={styles.taxIdContainer}>
      <Typography color="primary.dark" variant="body1" fontWeight={500}>
        Tax ID (optional)
      </Typography>
      <Box sx={styles.taxIdFields}>
        <SelectInput
          control={control}
          id="tax-id-type"
          label="Type"
          name="taxIdType"
          options={taxIdOptions}
          placeholder="Type"
          fullWidth
        />
        <Box>
          <TextInput
            control={control}
            fullWidth
            label="Tax ID"
            name="taxIdValue"
            placeholder="Tax ID"
          />
        </Box>
      </Box>
      {errors.taxIdType || errors.taxIdValue ? (
        <FormHelperText error sx={styles.taxIdErrorText}>
          Please provide both Type and Tax ID or omit both.
        </FormHelperText>
      ) : null}
      {!!(taxIdType || taxIdValue) && (
        <Button
          disableTouchRipple
          onClick={clearTaxId}
          size="small"
          sx={styles.clearTaxIdButton}
          variant="text"
        >
          Clear Tax ID
        </Button>
      )}
    </Box>
  );
}

export default TaxIdFields;
