import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useCallback, useState} from 'react';
import {updateAssistantInstructions} from '../../../../../../actions/assistantActions';
import {useControllerApi} from '../../../../../../hooks/use-api';
import {useAssistantsAreLoading} from '../../../../../../selectors/assistants';
import {useGlobalProject} from '../../../../../../selectors/projects';
import {type Assistant} from '../../../../../../types';

interface AssistantInstructionsProps {
  projectId: string;
  assistant: Assistant;
}

// Ensures no jank when save button appears
const INSTRUCTIONS_MIN_HEIGHT = 113;

const styles = {
  instructions: {
    minHeight: INSTRUCTIONS_MIN_HEIGHT,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    gap: 0.5,
    justifyContent: 'flex-end',
  },
};

export function AssistantInstructions({projectId, assistant}: AssistantInstructionsProps) {
  const assistantLoading = useAssistantsAreLoading(projectId);
  const project = useGlobalProject(projectId);
  const {setQueryData: setInstructionsData} = useControllerApi<{
    globalProjectId: string;
    assistantName: string;
    instructions: string;
    metadata: Record<string, string> | null;
  }>(project);

  const [draftInstructions, setDraftInstructions] = useState<string>(assistant.instructions || '');
  const isDirty = draftInstructions !== (assistant.instructions || '');

  const updateInstructions = useCallback(
    (value: string) => {
      setInstructionsData({
        action: updateAssistantInstructions,
        data: {
          globalProjectId: projectId,
          assistantName: assistant.name,
          instructions: value,
          metadata: assistant.metadata,
        },
      });
    },
    [setInstructionsData, projectId, assistant],
  );

  const onUpdateInstructions = useCallback(() => {
    updateInstructions(draftInstructions);
  }, [draftInstructions, updateInstructions]);

  const onDraftInstructionsChange = (value: string) => {
    setDraftInstructions(value);
  };

  return (
    <Box sx={styles.instructions}>
      <TextInput
        multiline
        maxRows={10}
        fullWidth
        label="Assistant instructions"
        value={draftInstructions}
        onChange={(value) => onDraftInstructionsChange(value)}
        placeholder={
          isDirty
            ? '(empty)'
            : 'Outline the assistant’s behavior, tone, or any additional context. Applies to every conversation.'
        }
        hideError
      />
      <Box sx={styles.buttons}>
        {isDirty && (
          <Button
            type="submit"
            disabled={assistantLoading}
            size="small"
            variant="contained"
            onClick={onUpdateInstructions}
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
}
