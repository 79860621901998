import {type Options} from '@pinecone-experience/timber/Inputs/SelectBoxInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {type Control} from 'react-hook-form';

// Helper function to check if a value exists in options
const valueExistsInOptions = (value: string | undefined, options: Options<string>) => {
  return (
    // Check in flat options
    options.some((opt: any) => 'value' in opt && opt.value === value) ||
    // Check in grouped options
    options.some((group) => 'options' in group && group.options?.some((opt) => opt.value === value))
  );
};

function OtherOption({
  fieldName,
  fieldValue,
  optionsArray,
  control,
}: {
  fieldName: string;
  fieldValue?: string;
  optionsArray: Options<string>;
  control: Control<any, any>;
}) {
  // Determine whether to show the "Add Other" option
  const showAddOther =
    fieldValue === '' ||
    (fieldValue !== undefined && !valueExistsInOptions(fieldValue, optionsArray));

  return showAddOther ? (
    <TextInput name={fieldName} control={control} placeholder="Add an option" hideError />
  ) : null;
}

export default OtherOption;
