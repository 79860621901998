import {type AlertColor} from '@mui/material/Alert';
import Button from '@mui/material/Button';
import {PaymentStatus} from '../../../../constants';
import {useNavigateToBilling, useNavigateToOrgMembers} from '../../../../hooks/navigation';
import {
  useIsOwnerOfOrganization,
  useSelectedOrganizationPaymentStatus,
} from '../../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../../selectors/params';
import AlertBar from '../AlertBar/AlertBar';

const styles = {
  action: {
    position: 'relative',
    top: -3,
  },
};

const PaymentAlertTextOptions = {
  [PaymentStatus.ACTIVE]: null,
  [PaymentStatus.PAST_DUE]: {
    ownerText: 'Attention required: We noticed your payment is overdue. Please review and fix.',
    userText:
      'Attention required: We noticed your payment is overdue. Please reach out to your organization owner to fix the issue.',
    severity: 'info' as AlertColor,
  },
  [PaymentStatus.RESTRICTED]: {
    ownerText:
      "Final Reminder: If the due amount isn't settled, you will lose access to your Projects and Indexes.",
    userText:
      'Final Reminder: You are about to lose access to your Projects and Indexes. Please reach out to your organization owner to fix the issue.',

    severity: 'warning' as AlertColor,
  },
  [PaymentStatus.SUSPENDED]: null,
  [PaymentStatus.DEACTIVATED]: null,
};

function PaymentAlertBar() {
  const paymentStatus = useSelectedOrganizationPaymentStatus();
  const info = PaymentAlertTextOptions[paymentStatus];
  const selectedOrgId = useSelectedOrganizationId();
  const {path: billingPath} = useNavigateToBilling(selectedOrgId || '');
  const {path: membersPath} = useNavigateToOrgMembers(selectedOrgId);
  const isOwner = useIsOwnerOfOrganization();

  if (!info) {
    return null;
  }

  const alertText = isOwner ? info.ownerText : info.userText;
  return (
    <AlertBar
      severity={info.severity}
      action={
        isOwner ? (
          <Button sx={styles.action} href={billingPath}>
            Fix Issue
          </Button>
        ) : (
          <Button sx={styles.action} href={membersPath}>
            View Owners
          </Button>
        )
      }
    >
      {alertText}
    </AlertBar>
  );
}

export default PaymentAlertBar;
