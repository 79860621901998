import {zodResolver} from '@hookform/resolvers/zod';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {editOrganization} from '../../../../actions/organizationActions';
import ModalForm from '../../../../components/ModalForm';
import {useDashboardApi} from '../../../../hooks/use-api';
import {useSelectedOrganization} from '../../../../selectors/organizations';

interface EditOrgNameModalProps {
  open: boolean;
  onClose: () => void;
}

const schema = z.object({
  name: z
    .string()
    .min(1, 'Organization name is required')
    .max(512, 'Organization name must be 512 characters or less')
    .refine((name) => !name.includes('\0'), {
      message: 'Organization name cannot contain null characters',
    }),
});

function EditOrgNameModal({open, onClose}: EditOrgNameModalProps) {
  const org = useSelectedOrganization();
  const {control, handleSubmit} = useForm({
    mode: 'onChange',
    defaultValues: {
      name: org.name,
    },
    resolver: zodResolver(schema),
  });
  const {setQueryData} = useDashboardApi<{organizationId: string; name: string}>();
  const handleEditOrgName = (formData: {name: string}) => {
    setQueryData({
      action: editOrganization,
      data: {
        organizationId: org.id,
        name: formData.name,
      },
    });
  };
  return (
    <ModalForm
      title="Edit Organization"
      isValid
      onSubmit={handleSubmit(handleEditOrgName)}
      submitText="Save"
      open={open}
      close={onClose}
      form={
        <TextInput
          name="name"
          control={control}
          required
          autoFocus
          fullWidth
          label="Name"
          placeholder="Organization Name"
        />
      }
    />
  );
}

export default EditOrgNameModal;
