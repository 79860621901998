import {useTheme} from '@mui/material/styles';
import {AssistantFileStatus, IndexState} from '../constants';
import {type BulkImportStatus, StorageIntegrationStatus} from '../types';

export function useIndexStatusColorMap(status: IndexState) {
  const theme = useTheme();
  const StatusColorMap = {
    [IndexState.READY]: theme.palette.success.main,
    [IndexState.INITIALIZING]: theme.palette.warning.main,
    [IndexState.SCALING_DOWN]: theme.palette.warning.main,
    [IndexState.SCALING_UP]: theme.palette.warning.main,
    [IndexState.SCALING_DOWN_PODS]: theme.palette.warning.main,
    [IndexState.SCALING_UP_PODS]: theme.palette.warning.main,
    [IndexState.TERMINATING]: theme.palette.error.main,
    [IndexState.UNKNOWN]: theme.palette.error.main,
  } as Record<IndexState, string>;
  return StatusColorMap[status] || theme.palette.error.main;
}

export function useAssistantFileStatusColorMap(status: AssistantFileStatus) {
  const theme = useTheme();
  const StatusColorMap = {
    [AssistantFileStatus.AVAILABLE]: theme.palette.success.main,
    [AssistantFileStatus.DELETING]: theme.palette.error.main,
    [AssistantFileStatus.PROCESSING]: theme.palette.warning.main,
  } as Record<AssistantFileStatus, string>;
  return StatusColorMap[status] || theme.palette.error.main;
}

export function useStorageIntegrationStatusColorMap(status: StorageIntegrationStatus) {
  const theme = useTheme();
  const StatusColorMap = {
    [StorageIntegrationStatus.VALIDATED]: theme.palette.success.main,
    [StorageIntegrationStatus.INVALID]: theme.palette.error.main,
  } as Record<string, string>;
  return StatusColorMap[status] || theme.palette.error.main;
}

export function useBulkImportStatusColorMap(status: BulkImportStatus) {
  const theme = useTheme();
  const StatusColorMap = {
    Completed: theme.palette.success.main,
    InProgress: theme.palette.warning.main,
    Pending: theme.palette.warning.main,
  } as Record<BulkImportStatus, string>;
  return StatusColorMap[status] || theme.palette.error.main;
}
