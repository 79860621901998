import AddIcon from '@mui/icons-material/Add';
import FiberSmartRecordOutlined from '@mui/icons-material/FiberSmartRecordOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import SelectorMenuButton from '@pinecone-experience/timber/Menus/SelectorMenuButton';
import CreateBackupForm from '../../../../components/backups/CreateBackupForm/CreateBackupForm';
import {
  useNavigateToBackupDetailsPage,
  useNavigateToIndexPageGlobal,
} from '../../../../hooks/navigation';
import {useBooleanState} from '../../../../hooks/utils';
import {useIsSelectedOrganizationSubscribed} from '../../../../selectors/organizations';
import {useSelectedGlobalProjectId, useSelectedOrganizationId} from '../../../../selectors/params';
import {type V4IndexInfoResponse} from '../../../../types';

type BackupActionsProps = {
  sourceIndex?: V4IndexInfoResponse;
  indexId: string;
  isDeleted: boolean;
};

function BackupActions({sourceIndex, indexId, isDeleted}: BackupActionsProps) {
  const {val: createOpen, setTrue: openCreate, setFalse: closeCreate} = useBooleanState();
  const orgId = useSelectedOrganizationId();
  const projectId = useSelectedGlobalProjectId();
  const {go: goToBackupDetailsPage} = useNavigateToBackupDetailsPage(orgId, projectId);
  const {go: goToIndexDetailsPage} = useNavigateToIndexPageGlobal(orgId, projectId);
  const isPaid = useIsSelectedOrganizationSubscribed();
  return (
    <>
      <SelectorMenuButton
        id={`backup-actions-${indexId}`}
        items={[
          {
            id: 'create',
            name: 'Create a backup',
            icon: <AddIcon fontSize="small" />,
            onClick: isPaid && !isDeleted ? openCreate : undefined,
          },
          {
            id: 'view',
            name: 'View backups',
            icon: <FiberSmartRecordOutlined fontSize="small" />,
            onClick: () => goToBackupDetailsPage(indexId),
          },
          {
            id: 'view-source',
            name: 'View source index',
            icon: <TableRowsOutlinedIcon fontSize="small" />,
            onClick: sourceIndex ? () => goToIndexDetailsPage(sourceIndex?.name || '') : undefined,
          },
        ]}
      />
      <CreateBackupForm sourceIndex={sourceIndex} open={createOpen} close={closeCreate} />
    </>
  );
}

export default BackupActions;
