import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import NumberInput from '@pinecone-experience/timber/Inputs/NumberInput';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {
  type Control,
  type UseFormGetFieldState,
  type UseFormSetValue,
  type UseFormWatch,
  useFieldArray,
} from 'react-hook-form';
import {type QueryData} from '../../../../../../api/dataPlaneApi';
import ClearIcon from '../../../../../../components/StyledClearIcon';
import {useHasNamespaces} from '../../../../../../hooks/specUtils';
import {useServiceByGlobalProject} from '../../../../../../selectors/services';
import NamespacePicker from '../../NamespacePicker/NamespacePicker';
import {type SchemaType} from '../../utilities/schema';
import {type IndexExplorerState} from '../../utilities/utils';
import FilterInput from './FilterInput/FilterInput';

// When fetching with values and metadata, the highest topK our API supports
// https://docs.pinecone.io/reference/limits#queries
const MAX_TOP_K = 1000;

const styles = {
  paper: {
    my: 2,
    p: 2,
  },
  button: {
    m: 1,
    pr: 3,
    py: 1,
    ml: 0,
    pl: 0,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    py: 2,
    pb: 0,
  },
};

interface QueryControlsProps {
  indexExplorerState: IndexExplorerState;
  control: Control<SchemaType>;
  setFormQuery: (data: QueryData, namespace: string) => void;
  onSubmit: () => void;
  setValue: UseFormSetValue<SchemaType>;
  watch: UseFormWatch<SchemaType>;
  getFieldState: UseFormGetFieldState<SchemaType>;
}

function EndAdornment({
  hasData,
  clear,
  doRandom,
}: {
  hasData: boolean;
  clear: () => void;
  doRandom?: () => void;
}) {
  return (
    <InputAdornment position="end">
      {hasData ? (
        <Button id="explorer-clear-query" onClick={clear} endIcon={<ClearIcon />} />
      ) : (
        doRandom && (
          <Button
            id="explorer-random-query"
            onClick={doRandom}
            color="secondary"
            endIcon={<ShuffleIcon color="secondary" />}
          >
            Random
          </Button>
        )
      )}
    </InputAdornment>
  );
}

function QueryControls({
  control,
  indexExplorerState,
  onSubmit,
  setValue,
  watch,
  setFormQuery,
  getFieldState,
}: QueryControlsProps) {
  const {service, project, dimensions} = indexExplorerState;
  const formData = watch();
  const namespace = watch('namespace');
  const queryRandomVector = () => {
    const randomVector = new Array(dimensions)
      .fill(0)
      .map(() => parseFloat(Math.random().toFixed(2)));
    setFormQuery({vector: randomVector}, namespace || '');
    onSubmit();
  };
  const {append, remove, fields} = useFieldArray({control, name: 'filters'});
  const addFilterParam = () =>
    append({
      operation: '$and',
      nodes: [
        {
          key: '',
          op: '$eq',
          value: '',
          valueType: 'auto',
        },
      ],
    });
  const doSubmit = () => {
    onSubmit();
  };
  const serviceInfo = useServiceByGlobalProject(project.id, service);
  const hasNamespaces = useHasNamespaces(serviceInfo);

  return (
    <Paper sx={styles.paper}>
      <Box sx={styles.container}>
        {hasNamespaces && (
          <Box>
            <NamespacePicker
              indexExplorerState={indexExplorerState}
              control={control}
              id="query-namespace-picker"
            />
          </Box>
        )}
        <Box>
          <SelectInput
            name="queryMode"
            label="Query by"
            control={control}
            options={[
              {
                label: 'ID',
                value: 'id',
              },
              {
                label: 'dense vector value',
                value: 'vector',
              },
            ]}
          />
        </Box>
        <Box sx={{flex: 1}}>
          {formData.queryMode === 'id' && (
            <TextInput
              name="queryId"
              control={control}
              label="ID"
              fullWidth
              endAdornment={
                <EndAdornment hasData={!!formData.queryId} clear={() => setValue('queryId', '')} />
              }
            />
          )}
          {formData.queryMode === 'vector' && (
            <TextInput
              name="queryVectorText"
              control={control}
              placeholder="Ex: 0.123, 0.234, ..."
              label="vector"
              fullWidth
              endAdornment={
                <EndAdornment
                  hasData={formData.queryVectorText.length > 0}
                  clear={() => setValue('queryVectorText', [])}
                  doRandom={queryRandomVector}
                />
              }
            />
          )}
        </Box>
        <Box>
          <NumberInput
            name="topK"
            control={control}
            label="Top K"
            max={MAX_TOP_K}
            sx={{maxWidth: 80}}
          />
        </Box>
        <Box>
          <Button sx={{mt: 1.5}} id="explorer-query-submit" variant="contained" onClick={doSubmit}>
            Query
          </Button>
        </Box>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        {fields.map((field, index) => {
          return (
            <FilterInput
              key={field.id}
              prefix={`filters.${index}`}
              control={control}
              remove={() => remove(index)}
              setValue={setValue}
              getFieldState={getFieldState}
            />
          );
        })}
      </Box>
      <Box>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          color="secondary"
          onClick={addFilterParam}
          sx={styles.button}
          id="metadata-filter-add-base"
        >
          Metadata Filter
        </Button>
      </Box>
    </Paper>
  );
}

export default QueryControls;
