import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ComboBoxInput from '@pinecone-experience/timber/Inputs/ComboBoxInput';
import {useMemo} from 'react';
import {type Control} from 'react-hook-form';
import {Link as RouterLink} from 'react-router-dom';
import {IndexDetailTab} from '../../../../../constants';
import {useNavigateToIndexPageGlobal} from '../../../../../hooks/navigation';
import {sortNamespaces} from '../../../../../utils/data';
import {formatLargeNumber} from '../../../../../utils/format';
import {type IndexExplorerState} from '../utilities/utils';

type FormValuesWithNamespace = {
  namespace?: string | null;
  [key: string]: any;
};

type NamespacePickerProps<T extends FormValuesWithNamespace> = {
  // See https://github.com/orgs/react-hook-form/discussions/9789 (although no one seems to know why it works...)
  control: T extends FormValuesWithNamespace ? Control<T> : never;
  indexExplorerState: IndexExplorerState;
  id: string;
  disabled?: boolean;
};

function NamespacePicker<T extends FormValuesWithNamespace>({
  indexExplorerState,
  control,
  id,
  disabled,
}: NamespacePickerProps<T>) {
  const {project, organization, indexStats, service} = indexExplorerState;
  const {getPath} = useNavigateToIndexPageGlobal(organization.id, project.id);
  const namespacesPath = getPath(service, IndexDetailTab.NAMESPACES);
  const namespaces = useMemo(() => {
    return sortNamespaces(indexStats);
  }, [indexStats]);
  return (
    <ComboBoxInput
      id={id}
      fullWidth
      disabled={disabled}
      label="Namespace"
      name="namespace"
      control={control}
      options={namespaces}
      placeholder="( Default )"
      defaultSelectedDisplay="( Default )"
      freeSolo
      filterSelectedOptions
      selectOnFocus
      groupBy={() => ''}
      listFooter={
        <Box>
          <Button
            data-heap="namespace-picker-view-all-button"
            color="secondary"
            sx={{p: 1, pl: 5}}
            component={RouterLink}
            to={namespacesPath}
          >
            {`View ${formatLargeNumber(namespaces.length)} Namespaces`}
          </Button>
        </Box>
      }
    />
  );
}

export default NamespacePicker;
