import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useParams} from 'react-router-dom';
import {listBackups} from '../../../actions/backupActions';
import StyledTabs from '../../../components/StyledTabs';
import CreateBackupForm from '../../../components/backups/CreateBackupForm/CreateBackupForm';
import Page from '../../../components/layout/Page/Page';
import PageHeader from '../../../components/layout/PageHeader/PageHeader';
import {useControllerApi} from '../../../hooks/use-api';
import {useBooleanState} from '../../../hooks/utils';
import {type AsyncDataState} from '../../../reducers/utils';
import {useBackupsByProjectId} from '../../../selectors/backups';
import {useIsSelectedOrganizationSubscribed} from '../../../selectors/organizations';
import {useSelectedGlobalProject} from '../../../selectors/projects';
import {type Backup} from '../../../types';
import CollectionsList from '../CollectionsList/CollectionsList';
import BackupsEmptyState from './BackupsEmptyState/BackupsEmptyState';
import BackupsTable from './BackupsTable/BackupsTable';

function BaseBackupsList({backups}: {backups: AsyncDataState<Backup[]>}) {
  if (backups?.data?.length === 0 && !backups?.loading) {
    return <BackupsEmptyState />;
  }
  return (
    <Box py={2}>
      <BackupsTable loading={backups.loading} backups={backups.data || []} />
    </Box>
  );
}

function BackupsPage() {
  const project = useSelectedGlobalProject();
  const backups = useBackupsByProjectId(project?.id) || {};
  useControllerApi(project, listBackups, {globalProjectId: project.id});
  const {val: showForm, setTrue: setShowForm, setFalse: hideForm} = useBooleanState();
  const isSubscribed = useIsSelectedOrganizationSubscribed();
  const match = useParams();
  const isBackups = match['*'] === '';
  return (
    <Page
      header={
        <PageHeader
          title="Backups / Collections"
          actions={
            isSubscribed &&
            isBackups &&
            !!backups?.data?.length && (
              <Button variant="contained" color="primary" onClick={setShowForm}>
                Create a backup
              </Button>
            )
          }
        />
      }
    >
      <StyledTabs
        idPrefix="backups"
        defaultPath=""
        tabs={[
          {
            value: '',
            label: 'BACKUPS (SERVERLESS)',
            component: <BaseBackupsList backups={backups} />,
          },
          {
            value: 'collections',
            label: 'COLLECTIONS (PODS)',
            component: <CollectionsList />,
          },
        ]}
      />
      {showForm && <CreateBackupForm open={showForm} close={hideForm} />}
    </Page>
  );
}

export default BackupsPage;
