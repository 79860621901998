import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InlineCode from '@pinecone-experience/timber/InlineCode';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import {useEffect, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {z} from 'zod';
import {connectIndexVercel} from '../../../../../actions/integrationActions';
import IndexSelector from '../../../../../components/integrations/vercel/IndexSelector/IndexSelector';
import ProjectSelector from '../../../../../components/integrations/vercel/ProjectSelector/ProjectSelector';
import {useQueryParams} from '../../../../../hooks/navigation';
import {useDashboardApi} from '../../../../../hooks/use-api';
import {useVercelIntegration} from '../../../../../selectors/integrations';
import {useOrganizations} from '../../../../../selectors/organizations';
import {useGlobalProject} from '../../../../../selectors/projects';
import {
  dimensions,
  indexName,
  metric,
} from '../../../../indexes/IndexCreationPage/utilities/schema';

const styles = {
  card: {
    maxWidth: 1200,
    p: 5,
    m: 2,
  },
  inputGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 1,
  },
};

const schema = z
  .object({
    oauthId: z.string(),
    projectId: z.string(),
    vercelProjectIds: z.array(z.string()).min(1),
    indexHostName: z.string().optional(),
    embeddingModel: z.string().optional(),
    indexName: indexName.optional(),
    metric: metric.optional(),
    dimension: dimensions.optional(),
  })
  .refine((input) => {
    if (input.indexHostName) {
      return true;
    }
    return input.metric && input.dimension && input.indexName;
  });

export type ConnectIndexVercel = z.infer<typeof schema>;

function ConnectIndex() {
  const {getQueryParam} = useQueryParams();
  const {data, loading} = useVercelIntegration();

  const organizations = useOrganizations();
  const linkedOrgId = data?.connection?.organizationId;
  const selectedOrg = organizations[linkedOrgId || ''];
  const methods = useForm<ConnectIndexVercel>({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      metric: 'cosine',
      vercelProjectIds: [],
    },
  });
  const {watch, setValue, handleSubmit, formState} = methods;
  const selectedProject = useGlobalProject(watch('projectId'));
  const vercelProjects = useMemo(() => {
    return data?.connection?.projectInfo || [];
  }, [data]);

  const oauthId = data?.connection?.id;
  useEffect(() => {
    if (oauthId) {
      setValue('oauthId', oauthId as string);
    }
  }, [setValue, oauthId]);

  useEffect(() => {
    if (vercelProjects) {
      setValue(
        'vercelProjectIds',
        vercelProjects.map((p) => p.id),
      );
    }
  }, [setValue, vercelProjects]);

  const vercelProjectOptions =
    vercelProjects.map((p) => ({
      label: p.name,
      description: p.id,
      value: p.id,
    })) || [];
  const {setQueryData} = useDashboardApi<ConnectIndexVercel>();
  const connectIndexHandler = (formData: ConnectIndexVercel) => {
    setQueryData({
      action: connectIndexVercel,
      data: formData,
    });
  };

  useEffect(() => {
    const next = getQueryParam('next') || 'https://vercel.com';
    if (data?.success) {
      window.location.href = next;
    }
  }, [data, getQueryParam]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(connectIndexHandler)} id="select-organization">
        <Paper sx={styles.card}>
          <Typography variant="h4" gutterBottom>
            Connect an Index
          </Typography>
          <Typography>
            This will add or update the <InlineCode text="PINECONE_HOSTNAME" /> and{' '}
            <InlineCode text="PINECONE_API_KEY" /> environment variables to your Vercel project(s).
          </Typography>{' '}
          <Box height={20} />
          <Box sx={styles.inputGrid}>
            <ProjectSelector selectedOrg={selectedOrg} />
            <SelectInput
              name="vercelProjectIds"
              label="Vercel Projects"
              renderValue={(_selected) => `${watch('vercelProjectIds').length} selected`}
              multiple
              fullWidth
              control={methods.control}
              options={vercelProjectOptions}
            />
          </Box>
          <Divider sx={{my: 2}} />
          <IndexSelector selectedProject={selectedProject} />
          <Button
            type="submit"
            disabled={!formState.isValid || loading}
            variant="contained"
            size="large"
            color="primary"
            sx={{my: 4}}
            fullWidth
          >
            Connect
            {loading && <CircularProgress size={20} sx={{color: 'primary.contrastText', ml: 2}} />}
          </Button>
        </Paper>
      </form>
    </FormProvider>
  );
}

export default ConnectIndex;
