import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CopyButton from '@pinecone-experience/timber/CopyButton';
import SelectInput, {type Options} from '@pinecone-experience/timber/Inputs/SelectInput';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  clearAssistantChat,
  updateAssistantChatModel,
} from '../../../../../actions/assistantActions';
import AssistantStatusBox from '../../../../../components/AssistantStatusBox/AssistantStatusBox';
import ModalForm from '../../../../../components/ModalForm';
import {ASSISTANT_DATA_PLANE_URL} from '../../../../../constants';
import {useBooleanState} from '../../../../../hooks/utils';
import {useAssistantChat, useAssistantChatConfig} from '../../../../../selectors/assistants';
import {type Assistant, type AssistantChatModel} from '../../../../../types';
import {AssistantInstructions} from './AssistantInstructions/AssistantInstructions';

interface SettingsPanelProps {
  projectId: string;
  assistant: Assistant;
}

const styles = {
  settings: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  status: {
    display: 'flex',
    gap: 0.5,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  host: {
    display: 'flex',
    gap: 0.5,
    alignItems: 'center',
  },
};

const host = `https://${ASSISTANT_DATA_PLANE_URL}`;
const modelOptions: Options<AssistantChatModel> = [
  {description: 'OpenAI', label: 'GPT-4o', value: 'gpt-4o'},
  {description: 'Anthropic', label: 'Claude 3.5 Sonnet', value: 'claude-3-5-sonnet'},
];

export default function SettingsPanel({projectId, assistant}: SettingsPanelProps) {
  const dispatch = useDispatch();
  const hasConversation = useAssistantChat(projectId, assistant.name).data?.messages.length;
  const {model} = useAssistantChatConfig(projectId, assistant.name);

  const {
    val: showClearModal,
    setTrue: openClearModal,
    setFalse: closeClearModal,
  } = useBooleanState(false);
  const [draftModel, setDraftModel] = useState<AssistantChatModel | undefined>(undefined);

  const updateModel = (value: AssistantChatModel) => {
    dispatch(
      updateAssistantChatModel({
        globalProjectId: projectId,
        assistantName: assistant.name,
        model: value,
      }),
    );
  };

  const onUpdateModel = (value: AssistantChatModel) => {
    if (hasConversation) {
      setDraftModel(value);
      openClearModal();
    } else {
      updateModel(value);
    }
  };

  const onCloseClearModal = () => {
    setDraftModel(undefined);
    closeClearModal();
  };

  const onClearChatAndUpdateModel = () => {
    dispatch(clearAssistantChat({globalProjectId: projectId, assistantName: assistant.name}));
    onCloseClearModal();
    if (draftModel) {
      updateModel(draftModel);
    }
  };

  return (
    <Box sx={styles.settings}>
      <Box sx={styles.info}>
        <Typography sx={styles.status}>
          <strong>Status:</strong>
          <AssistantStatusBox assistantName={assistant.name} />
        </Typography>
        <Typography>
          <strong>Created: </strong>
          {new Date(assistant.created_at).toLocaleDateString()}
        </Typography>
        <Typography>
          <strong>Updated: </strong>
          {new Date(assistant.updated_at).toLocaleDateString()}
        </Typography>
        <Box sx={styles.host}>
          <Typography noWrap>
            <strong>Host: </strong>
            <Tooltip title={host}>
              <span>{host}</span>
            </Tooltip>
          </Typography>
          <CopyButton sx={{m: -1}} text={host} />
        </Box>
      </Box>
      <AssistantInstructions projectId={projectId} assistant={assistant} />
      <SelectInput
        tooltip="Select the model for the assistant to use in this conversation. To persist this choice in your application, remember to set the model in your API calls."
        fullWidth
        label="Chat model"
        value={model}
        options={modelOptions}
        onChange={onUpdateModel}
      />
      <ModalForm
        open={showClearModal}
        close={onCloseClearModal}
        onSubmit={onClearChatAndUpdateModel}
        title="Switch Assistant model?"
        submitText="Confirm"
        isValid
        form={
          <Typography>
            Switching to a new model will clear your current conversation and start fresh with the
            new model. Do you want to proceed?
          </Typography>
        }
      />
    </Box>
  );
}
