import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingContainer from '../LoadingContainer';

export type LoadingCardProps = {
  title: string;
  text: string;
  show?: boolean;
};

function LoadingCard({title, text, show}: LoadingCardProps) {
  return (
    <Box sx={{display: show ? 'block' : 'none', maxWidth: 400}}>
      <LoadingContainer loading>
        <Box sx={{width: 48, height: 48}} />
      </LoadingContainer>
      <Typography variant="h5" textAlign="center" py={2}>
        {title}
      </Typography>
      <Typography variant="body1" textAlign="center">
        {text}
      </Typography>
    </Box>
  );
}

export default LoadingCard;
