import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useNavigateToAssistants} from '../../../../../hooks/navigation';
import {
  useAcceptedAssistantsTerms,
  useAssistants,
  useAssistantsAreLoading,
} from '../../../../../selectors/assistants';
import {useSelectedOrganizationId} from '../../../../../selectors/params';
import {useSelectedGlobalProject} from '../../../../../selectors/projects';
import {SECTION_ITEMS_MAX_BEFORE_OVERFLOW, styles} from '../styles';
import AssistantCard from './AssistantCard';

function AssistantSection() {
  const organizationId = useSelectedOrganizationId();
  const project = useSelectedGlobalProject();

  const acceptedTermsResponse = useAcceptedAssistantsTerms(organizationId);
  const hasAcceptedTerms = acceptedTermsResponse?.data;

  const assistantsLoading = useAssistantsAreLoading(project.id);
  const loading = hasAcceptedTerms ? assistantsLoading : acceptedTermsResponse?.loading;

  const assistants = useAssistants(project.id) || {};
  const {go: goToAssistantListPage} = useNavigateToAssistants(organizationId, project.id);

  const hasAssistants = Object.keys(assistants).length > 0 && hasAcceptedTerms;

  const sectionItemsStyle =
    Object.keys(assistants).length >= SECTION_ITEMS_MAX_BEFORE_OVERFLOW
      ? [styles.sectionItems, styles.sectionItemsOverflow]
      : styles.sectionItems;

  return (
    <Box sx={styles.section}>
      <Box sx={styles.sectionHeader}>
        <Typography variant="h6" component="h2">
          Assistant
        </Typography>
        {hasAssistants && <Button onClick={goToAssistantListPage}>View All</Button>}
      </Box>
      <LoadingContainer loading={loading}>
        {hasAssistants ? (
          <Box sx={sectionItemsStyle}>
            {Object.values(assistants).map((assistant) => (
              <AssistantCard key={assistant.name} assistant={assistant} />
            ))}
          </Box>
        ) : (
          <Typography>No assistants found</Typography>
        )}
      </LoadingContainer>
    </Box>
  );
}

export default AssistantSection;
