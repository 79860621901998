import Box from '@mui/material/Box';
import ButtonBase, {type ButtonBaseProps} from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {type Theme, alpha} from '@mui/material/styles';
import type * as React from 'react';
import {useMemo} from 'react';
import {type Control, Controller, type Path} from 'react-hook-form';
import Tooltip from '../Tooltip';
import {getLayout} from '../Utils/styles';
import InputWrapper from './Utils/InputWrapper';
import {type CommonInputProps, type ElementType} from './Utils/types';

type HighlightStyle = 'border' | 'background';

const styles = {
  optionsContainer: {
    py: 1,
  },
  componentWrapper: {
    width: '100%',
    position: 'relative',
  },
  optionContainer: {
    p: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'background.paper',
    border: 1,
    '&:hover': {
      boxShadow: 6,
    },
    '&:disabled': {
      borderColor: (theme: Theme) => theme.palette.action.disabledOpacity,
      '& .MuiTypography-root, .MuiChip-label': {
        opacity: (theme: Theme) => theme.palette.action.disabledOpacity,
      },
      '& img, #flag': {
        opacity: (theme: Theme) => theme.palette.action.disabledOpacity,
        webkitFilter: 'grayscale(100%)',
        filter: 'grayscale(100%)',
      },
    },
  },
  optionContainerBorder: (
    selected: boolean,
    error: boolean,
    theme: Theme,
    noGap: boolean,
    highlightStyle: HighlightStyle,
  ) => ({
    borderColor: () => {
      if (highlightStyle !== 'border') {
        return theme.palette.divider;
      }
      if (selected && error) {
        return theme.palette.error.light;
      }
      if (selected) {
        return theme.palette.primary.main;
      }
      return theme.palette.divider;
    },
    backgroundColor: () => {
      if (highlightStyle !== 'background') {
        return theme.palette.background.paper;
      }
      if (selected && error) {
        return theme.palette.error.light;
      }
      if (selected) {
        return alpha(theme.palette.secondary.main, 0.12);
      }
      return theme.palette.background.paper;
    },
    // Changing the fontWeight causes the sizes of buttons to jump around.
    // Simulate bold text with textShadow instead
    textShadow: () => {
      if (highlightStyle === 'background' && selected) {
        return '-.25px 0px 0px, .25px 0px 0px';
      }
      return '';
    },
    borderRadius: noGap ? 0 : 1,
  }),
  roundLeft: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  roundRight: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  hideRight: {
    borderRight: 0,
  },
};

interface SelectBoxSpecificInputProps<U> {
  options: (Option<U> | OptionGroup<U>)[];
  multiple?: boolean;
  layout?: 'grid' | 'flex' | 'stack';
  gridMinWidth?: number;
  gap?: number;
  addOption?: React.ReactNode;
  containerSx?: ButtonBaseProps['sx'];
  highlightStyle?: HighlightStyle;
}

interface UnControlledSelectBoxInputProps<U>
  extends CommonInputProps,
    SelectBoxSpecificInputProps<U> {
  value: U;
  onChange: (value: U) => void;
}

interface ControlledSelectBoxInputProps<U, T extends Record<string, any>>
  extends CommonInputProps,
    SelectBoxSpecificInputProps<U> {
  name: Path<T>;
  control: Control<T>;
}

type SelectBoxInputProps<U, T extends Record<string, any>> =
  | UnControlledSelectBoxInputProps<U>
  | ControlledSelectBoxInputProps<U, T>;

interface Option<U> {
  label?: string;
  description?: string;
  icon?: React.ReactNode;
  value: ElementType<U>;
  disabled?: boolean;
  tooltip?: string;
  component?: (selected: boolean) => React.ReactNode;
}

interface OptionGroup<U> {
  title?: string;
  divider?: boolean;
  options: Option<U>[];
}

export type Options<U> = (Option<U> | OptionGroup<U>)[];

function handleChange<U>({
  value,
  option,
  onChange,
  multiple,
}: {
  value: U;
  option: ElementType<U>;
  onChange: (value: U) => void;
  multiple?: boolean;
}) {
  if (multiple && Array.isArray(value)) {
    if (value.includes(option)) {
      onChange(value.filter((val) => val !== option) as U);
    } else {
      onChange([...value, option] as U);
    }
  } else {
    onChange(option as U);
  }
}

function valueCheck<U>(value: U, option: ElementType<U>) {
  if (Array.isArray(value)) {
    return value.includes(option);
  }
  return value === option;
}

function renderOption<U>({
  value,
  onChange,
  error,
  option,
  multiple,
  index,
  noGap,
  totalOptions,
  containerSx,
  highlightStyle,
}: {
  value: U;
  onChange: (value: U) => void;
  error: boolean;
  option: Option<U>;
  multiple?: boolean;
  index: number;
  noGap: boolean;
  totalOptions: number;
  containerSx?: ButtonBaseProps['sx'];
  highlightStyle: HighlightStyle;
}) {
  const selected = valueCheck(value, option.value);
  return (
    <Tooltip key={`${option.value}-${index}`} title={option.tooltip}>
      <ButtonBase
        aria-selected={selected}
        value={option.value as any}
        onClick={() => handleChange({value, option: option.value, onChange, multiple})}
        sx={[
          styles.optionContainer,
          (theme) => styles.optionContainerBorder(selected, error, theme, noGap, highlightStyle),
          ...(Array.isArray(containerSx) ? containerSx : [containerSx]),
          noGap && index === 0 ? styles.roundLeft : undefined,
          noGap && index === totalOptions - 1 ? styles.roundRight : undefined,
          noGap && index !== totalOptions - 1 ? styles.hideRight : undefined,
        ]}
        disabled={option.disabled}
      >
        {option.component ? (
          <Box sx={styles.componentWrapper}>{option.component(selected)}</Box>
        ) : (
          <Box display="flex" alignItems="center">
            {option.icon && <Box mr={1}>{option.icon}</Box>}
            <Box>
              <Typography align="left" noWrap>
                {option.label || option.value}
              </Typography>
              {option.description && (
                <Typography variant="body2" align="left">
                  {option.description}
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </ButtonBase>
    </Tooltip>
  );
}

function renderOptions<U>({
  id,
  dataTestId,
  value,
  onChange,
  error,
  options,
  multiple,
  layout,
  gridMinWidth,
  gap,
  containerSx,
  highlightStyle,
  addOption,
}: {
  id?: string;
  dataTestId?: string;
  value: U;
  onChange: (value: U) => void;
  error: boolean;
  options: Options<U>;
  multiple?: boolean;
  layout?: 'grid' | 'flex' | 'stack';
  gridMinWidth?: number;
  gap?: number;
  containerSx?: ButtonBaseProps['sx'];
  highlightStyle: HighlightStyle;
  addOption?: React.ReactNode;
}) {
  if (!options.length) {
    return <Typography>No Options</Typography>;
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        id={id}
        data-testid={dataTestId}
        sx={[getLayout(layout, gap, gridMinWidth), styles.optionsContainer]}
      >
        {options.map((option, i) => {
          if ('options' in option) {
            return (
              <Box key={option.title} sx={{width: '100%'}}>
                <Typography variant="subtitle1">{option.title}</Typography>
                <Box sx={[getLayout(layout, gap, gridMinWidth)]}>
                  {option.options.map((opt, j) =>
                    renderOption({
                      value,
                      option: opt,
                      onChange,
                      error,
                      multiple,
                      index: j,
                      totalOptions: option.options.length,
                      noGap: gap === 0,
                      containerSx,
                      highlightStyle,
                    }),
                  )}
                  {!option.options.length && (
                    <Typography variant="subtitle1">No Options</Typography>
                  )}
                </Box>
                {option.divider && <Divider sx={{mt: gap || 2}} />}
              </Box>
            );
          }
          return renderOption({
            value,
            option,
            onChange,
            error,
            multiple,
            index: i,
            totalOptions: options.length,
            noGap: gap === 0,
            containerSx,
            highlightStyle,
          });
        })}
      </Box>
      {addOption}
    </Box>
  );
}

function SelectBoxInput<U, T extends Record<string, any>>(props: SelectBoxInputProps<U, T>) {
  const renderOptionsProps = {
    id: props.id,
    name: props.name,
    dataTestId: props.id || props.name || props.label?.toLowerCase(),
    options: props.options,
    multiple: props.multiple,
    size: 'small',
    margin: 'dense',
    layout: props.layout || 'flex',
    gridMinWidth: props.gridMinWidth || 100,
    gap: !Number.isNaN(props.gap) ? props.gap : 1,
    containerSx: props.containerSx,
    highlightStyle: props.highlightStyle || 'border',
    addOption: props.addOption,
  };

  const allValues = useMemo(() => {
    return props.options
      .flatMap((option) => ('options' in option ? option.options : option))
      .map((opt) => opt.value);
  }, [props.options]);

  if ('control' in props) {
    return (
      <Controller
        name={props.name}
        control={props.control}
        render={({field: {value, onChange}, fieldState: {error}}) => {
          const fieldValue =
            allValues.includes(value as ElementType<U>) || props.multiple
              ? value
              : (undefined as U);
          return (
            <InputWrapper {...props} error={props.error || error?.message}>
              {renderOptions({
                value: fieldValue,
                onChange,
                error: !!(props.error || error?.message),
                ...renderOptionsProps,
              })}
            </InputWrapper>
          );
        }}
      />
    );
  }
  return (
    <InputWrapper {...props}>
      {renderOptions({
        value: props.value,
        onChange: props.onChange,
        error: !!props.error,
        ...renderOptionsProps,
      })}
    </InputWrapper>
  );
}

export default SelectBoxInput;
