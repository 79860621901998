import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {PineconeShadows} from '@pinecone-experience/theme';
import {SupportTiers} from '../../constants';
import {useNavigateToSupportPlans} from '../../hooks/navigation';
import {useCurrentSupportTier, useIsSupportIncluded} from '../../selectors/billing';
import {useSelectedOrganizationId} from '../../selectors/params';
import {EmbeddedChat} from './InkeepChatbot';

const styles = {
  chatbot: {
    flex: 1,
    height: 600,
    backgroundColor: 'background.paper',
    borderRadius: 1,
    border: 1,
    borderColor: 'divider',
    boxShadow: `${PineconeShadows.LIGHT[3]}, ${PineconeShadows.LIGHT[2]}, ${PineconeShadows.LIGHT[1]}`,
  },
};

export function ChatBot() {
  const selectedOrgId = useSelectedOrganizationId();
  const {go: navigateToSupportPlans} = useNavigateToSupportPlans(selectedOrgId);
  const isSupportIncluded = useIsSupportIncluded();
  const currentSupportTier = useCurrentSupportTier();

  const showUpgradeText = currentSupportTier === SupportTiers.FREE && !isSupportIncluded;

  return (
    <Box sx={styles.chatbot}>
      <EmbeddedChat />
      {showUpgradeText && (
        <Typography sx={{mt: 4}}>
          <Link onClick={navigateToSupportPlans} style={{cursor: 'pointer'}}>
            Upgrade
          </Link>
          {' your support plan for prioritized assistance'}
        </Typography>
      )}
    </Box>
  );
}
