import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';
import SelectorMenuButton from '@pinecone-experience/timber/Menus/SelectorMenuButton';
import {deleteVectors} from '../../../../../../actions/dataActions';
import {type DeleteRequest} from '../../../../../../api/dataPlaneApi';
import DeleteConfirmationModal from '../../../../../../components/DeleteConfirmationModal';
import GlowWrapper from '../../../../../../components/Utilities/GlowWrapper';
import {LocalStorageKeys} from '../../../../../../constants';
import {useDataPlane} from '../../../../../../hooks/use-api';
import {useBooleanState} from '../../../../../../hooks/utils';
import {localStorageGet} from '../../../../../../utils/storage';
import VectorFormModal from '../../VectorFormControls/VectorFormModal';
import {type IndexExplorerState} from '../../utilities/utils';

type VectorActionsProps = {
  indexExplorerState: IndexExplorerState;
  vectorId: string;
  namespace: string;
  doQuery: () => void;
  first: boolean;
  variant?: 'menu' | 'buttons';
  doFetch?: () => void;
};

function VectorActions({
  vectorId,
  namespace,
  indexExplorerState,
  doQuery,
  first,
  variant = 'buttons',
  doFetch,
}: VectorActionsProps) {
  const {project, service, host} = indexExplorerState;
  const {val: deleteIsOpen, setTrue: openDelete, setFalse: closeDelete} = useBooleanState();
  const {
    val: updateVectorIsOpen,
    setTrue: openUpdateVector,
    setFalse: closeUpdateVector,
  } = useBooleanState();
  const {setQueryData} = useDataPlane<DeleteRequest>(project, service, host);
  const doDeleteVector = () => {
    setQueryData({
      action: deleteVectors,
      data: {
        ids: [vectorId],
        namespace,
      },
    });
  };
  const hasQueried = localStorageGet(LocalStorageKeys.SERVERLESS_RELEASE_HAS_QUERIED);
  return (
    <Box display="flex">
      {variant === 'buttons' ? (
        <>
          <Box m={0.5}>
            <GlowWrapper doGlow={first && !hasQueried} radius={6} sx={{borderRadius: 2}}>
              <IconButton
                id={`search-nearby-${vectorId}`}
                onClick={doQuery}
                icon={<SearchIcon fontSize="small" sx={{color: 'text.primary'}} />}
                tooltip="Search nearby vectors"
                dataHeap="search-nearby-vectors"
                sx={{m: 0}}
                border
              />
            </GlowWrapper>
          </Box>
          <IconButton
            id={`edit-vector-${vectorId}`}
            onClick={openUpdateVector}
            icon={<EditOutlinedIcon fontSize="small" sx={{color: 'text.primary'}} />}
            tooltip="Edit Vector"
            dataHeap="edit-vector"
            sx={{m: 0.5}}
            border
          />
          <IconButton
            id={`delete-vector-${vectorId}`}
            onClick={openDelete}
            icon={<DeleteOutlinedIcon fontSize="small" sx={{color: 'text.primary'}} />}
            tooltip="Delete Vector"
            dataHeap="delete-vector"
            sx={{m: 0.5}}
            border
          />
        </>
      ) : (
        <SelectorMenuButton
          id={`vector-actions-${vectorId}`}
          items={[
            ...(doFetch
              ? [
                  {
                    id: 'fetch',
                    name: 'Fetch',
                    icon: <VisibilityIcon fontSize="small" />,
                    onClick: doFetch,
                  },
                ]
              : []),
            {
              id: 'update',
              name: 'Update',
              icon: <EditOutlinedIcon fontSize="small" />,
              onClick: openUpdateVector,
            },
            {id: 'query', name: 'Query', icon: <SearchIcon fontSize="small" />, onClick: doQuery},
            {
              id: 'delete',
              name: 'Delete',
              icon: <DeleteOutlinedIcon color="error" fontSize="small" />,
              onClick: openDelete,
              color: 'error',
            },
          ]}
        />
      )}
      <VectorFormModal
        open={updateVectorIsOpen}
        close={closeUpdateVector}
        typeName="Update"
        indexExplorerState={indexExplorerState}
        vectorId={vectorId}
        namespace={namespace}
        allowAutoType={false}
        onlySubmitWhenDirty
      />
      <DeleteConfirmationModal
        open={deleteIsOpen}
        close={closeDelete}
        itemType="Vector"
        name={vectorId}
        doDelete={doDeleteVector}
        skipCheck
      />
    </Box>
  );
}

export default VectorActions;
