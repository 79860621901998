import {useElements, useStripe} from '@stripe/react-stripe-js';
import type {
  SetupIntentResult,
  Stripe,
  StripeElements,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import {createContext, useContext} from 'react';
import {useDispatch} from 'react-redux';
import type {Dispatch} from 'redux';
import type StripeType from 'stripe';
import {NotificationTypes, enqueNotification} from '../../../../actions/notificationActions';
import {useSetupIntent} from '../../../../selectors/billing';

const setupPaymentMethod = (
  stripe: Stripe,
  elements: StripeElements,
  returnUrl: string,
  billingEmail: string,
  dispatch: Dispatch,
): Promise<SetupIntentResult | void> =>
  stripe
    .confirmSetup({
      elements,
      confirmParams: {
        return_url: returnUrl,
        payment_method_data: {
          billing_details: {
            email: billingEmail,
          },
        },
      },
      redirect: 'if_required',
    })
    .then((res: SetupIntentResult) => {
      if (res.error) {
        dispatch(
          enqueNotification({
            type: NotificationTypes.ERROR,
            text: `Error processing card. Please check your card information and try again. ${res?.error?.message}`,
          }),
        );
      }
      return res;
    })
    .catch(() => {
      dispatch(
        enqueNotification({
          type: NotificationTypes.ERROR,
          text: 'Error processing card. Check the information entered and try again.',
        }),
      );
    });

// Use a React Context for stripe functions, to allow injection by tests and stories.
const StripeContext = createContext({
  elementsOptions: (setupIntent?: StripeType.SetupIntent): Partial<StripeElementsOptions> => {
    const clientSecret = setupIntent?.client_secret || '';
    return {clientSecret};
  },
  isLoading: (setupIntent?: StripeType.SetupIntent): boolean => {
    return !setupIntent?.client_secret;
  },
  setupPaymentMethod,
});

// Allows for tests/stories to inject stripe options. This should only be used in
// tests/stories.
export const StripeContextProvider = StripeContext.Provider;

export const useStripeElementsOptions = () => {
  const setupIntent = useSetupIntent();
  const {elementsOptions} = useContext(StripeContext);
  return elementsOptions(setupIntent);
};

export const useStripeIsLoading = () => {
  const setupIntent = useSetupIntent();
  const {isLoading} = useContext(StripeContext);
  return isLoading(setupIntent);
};

export const useStripeSetupPaymentMethod = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const {setupPaymentMethod: setup} = useContext(StripeContext);

  if (!stripe || !elements) {
    return () => Promise.resolve();
  }

  return (returnUrl: string, billingEmail: string) =>
    setup(stripe, elements, returnUrl, billingEmail, dispatch);
};
