import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {changeSupport, changeSupportReset} from '../../../../../../../actions/organizationActions';
import RowWithCheck from '../../../../../../../components/Utilities/RowWithCheck';
import {Plans, SupportTierNameMapping, type SupportTiers} from '../../../../../../../constants';
import {
  useNavigateToBilling,
  useNavigateToBillingPlans,
} from '../../../../../../../hooks/navigation';
import {useDashboardApi} from '../../../../../../../hooks/use-api';
import {
  useCurrentSupportTier,
  useScheduledSupport,
  useSupportChangeCompleted,
  useSupportLoading,
} from '../../../../../../../selectors/billing';
import {useSelectedOrganizationPlan} from '../../../../../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../../../../../selectors/params';
import {MODAL_WIDTH_SMALL} from '../../../../../../../styles/theme';
import {ChangeSupportAction, evaluateChangeSupportAction} from '../../../utils/supportPlans';
import {
  downgradeConfirmationItems,
  freePlanUpgradeConfirmation,
  upgradeConfirmation,
} from '../../constants/supportPlans';
import ChangeSupportSuccessCard from '../ChangeSupportSuccessCard/ChangeSupportSuccessCard';

interface ConfirmChangePlanProps {
  open: boolean;
  onClose: () => void;
  desiredSupportTier: SupportTiers;
}

const styles = {
  loadingCard: {
    width: MODAL_WIDTH_SMALL,
    my: 1,
  },
  title: {
    mb: 2,
  },
  buttonContainer: {
    display: 'flex',
    pt: 2,
  },
};

const getButtonText = (
  plan: Plans,
  actionToComplete: ChangeSupportAction,
  desiredSupportTier: SupportTiers,
): string => {
  if (actionToComplete === ChangeSupportAction.CANCEL_DOWNGRADE) {
    return 'Cancel downgrade';
  }

  return plan === Plans.FREE
    ? `Continue to upgrade`
    : `${actionToComplete} to ${desiredSupportTier} support`;
};

const getDescription = (
  plan: Plans,
  isUpgrading: boolean,
  desiredSupportTier: SupportTiers,
  actionToComplete: ChangeSupportAction,
) => {
  if (plan === Plans.FREE) {
    return (
      <Typography>
        To upgrade your support plan, you also need to upgrade your overall project plan to the
        Standard Pay-As-You-Go model. This upgrade will give you access to enhanced features and
        support, with costs based on usage.{' '}
        <em>Note: You will only be billed for the remaining days of the current month.</em>
      </Typography>
    );
  }
  if (isUpgrading) {
    return (
      <Typography>
        {upgradeConfirmation[desiredSupportTier as SupportTiers.DEVELOPER | SupportTiers.PRO]}
        <em>Note: You will only be billed for the remaining days of the current month.</em>
      </Typography>
    );
  }
  if (actionToComplete === ChangeSupportAction.DOWNGRADE) {
    return (
      <Typography>
        You are about to downgrade to the {desiredSupportTier} plan. This change will result in the
        following differences:
      </Typography>
    );
  }
  if (actionToComplete === ChangeSupportAction.CANCEL_DOWNGRADE) {
    return (
      <Typography>
        Are you sure you want to cancel the downgrade? By doing so, you will remain on the{' '}
        {SupportTierNameMapping[desiredSupportTier]} support plan, keeping all premium features and
        benefits.
      </Typography>
    );
  }
  return null;
};

function ConfirmChangePlan({open, onClose, desiredSupportTier}: ConfirmChangePlanProps) {
  const isLoading = useSupportLoading();
  const plan = useSelectedOrganizationPlan();
  const currentSupportTier = useCurrentSupportTier();
  const dispatch = useDispatch();
  const scheduledSupport = useScheduledSupport();
  const actionToComplete = currentSupportTier
    ? evaluateChangeSupportAction(currentSupportTier, desiredSupportTier, scheduledSupport)
    : ChangeSupportAction.NO_ACTION;
  const actionCompleted = useSupportChangeCompleted();
  const isUpgrading = actionToComplete === ChangeSupportAction.UPGRADE;
  const isCancellingDowngrade = actionToComplete === ChangeSupportAction.CANCEL_DOWNGRADE;

  const orgId = useSelectedOrganizationId();
  const goToBillingPlans = useNavigateToBillingPlans(orgId);
  const {path: billingPath} = useNavigateToBilling(orgId);

  const {setQueryData: setQueryDataChangePlan} = useDashboardApi<{
    organizationId: string;
    desiredSupportTier: SupportTiers;
    actionToComplete: ChangeSupportAction;
  }>();

  const handleClose = useCallback(() => {
    dispatch(changeSupportReset());
    onClose();
  }, [dispatch, onClose]);

  const handleConfirmChangePlan = async () => {
    if (plan === Plans.FREE) {
      goToBillingPlans();
    } else {
      setQueryDataChangePlan({
        action: changeSupport,
        data: {
          organizationId: orgId || '',
          desiredSupportTier,
          actionToComplete,
        },
      });
    }
  };

  const getItems = () => {
    if (plan === Plans.FREE) {
      return (
        <Box mt={1}>
          <RowWithCheck
            sx={{alignItems: 'flex-start'}}
            text={
              <Typography>
                <strong>Standard plan upgrade</strong>: Gain more resources, project capacity, and
                flexibility with a Pay-As-You-Go model—no extra costs unless additional resources
                are used. Learn more{' '}
                <Link to={billingPath} onClick={onClose}>
                  here
                </Link>
                .
              </Typography>
            }
          />
          <RowWithCheck
            sx={{alignItems: 'flex-start'}}
            text={
              <Typography>
                <strong>{SupportTierNameMapping[desiredSupportTier]} support plan</strong>:{' '}
                {
                  freePlanUpgradeConfirmation[
                    desiredSupportTier as SupportTiers.DEVELOPER | SupportTiers.PRO
                  ]
                }
              </Typography>
            }
          />
        </Box>
      );
    }
    if (actionToComplete === ChangeSupportAction.DOWNGRADE) {
      return (
        <Box mt={1}>
          {downgradeConfirmationItems[
            desiredSupportTier as SupportTiers.DEVELOPER | SupportTiers.FREE
          ].map((item, idx) => (
            <RowWithCheck
              sx={{alignItems: 'flex-start'}}
              key={idx}
              text={
                <Typography>
                  <strong>{item.item}: </strong>
                  {item.description}
                </Typography>
              }
            />
          ))}
        </Box>
      );
    }
    return null;
  };

  return (
    <BaseModal
      id={`${isUpgrading ? 'upgrade' : 'downgrade'}-support-tier-confirmation-modal`}
      open={open}
      onClose={handleClose}
      showCloseIcon
    >
      {!actionCompleted ? (
        <LoadingContainer loading={isLoading}>
          <Box sx={styles.loadingCard}>
            <Typography variant="h5" sx={styles.title}>
              Confirm your {actionToComplete?.toLowerCase()}
            </Typography>
            {getDescription(plan, isUpgrading, desiredSupportTier, actionToComplete)}
            {getItems()}

            <Box
              sx={[
                styles.buttonContainer,
                {justifyContent: 'center', gap: 0},
                isCancellingDowngrade && {justifyContent: 'flex-end', gap: 2},
              ]}
            >
              {isCancellingDowngrade && (
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
              )}
              <Button
                type="submit"
                id={`${isUpgrading ? 'upgrade' : 'downgrade'}-submit-button`}
                variant="contained"
                color={`${isUpgrading || isCancellingDowngrade ? 'primary' : 'error'}`}
                onClick={handleConfirmChangePlan}
                fullWidth={!isCancellingDowngrade}
              >
                {getButtonText(plan, actionToComplete, desiredSupportTier)}
              </Button>
            </Box>
          </Box>
        </LoadingContainer>
      ) : (
        <ChangeSupportSuccessCard show={actionCompleted !== undefined} />
      )}
    </BaseModal>
  );
}

export default ConfirmChangePlan;
