import Box, {type BoxProps} from '@mui/material/Box';
import {useTheme} from '@mui/material/styles';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import CopyButton from '../CopyButton';
import codeblockDark from './theme/codeblock-dark';
import codeblockLight from './theme/codeblock-light';

interface CodeBlockProps {
  code: string;
  language: string;
  label?: string;
  copyButton?: boolean;
  copyButtonValue?: string;
  controls?: React.ReactNode;
  theme?: 'light' | 'dark';
  customStyles?: object;
  showLineNumbers?: boolean;
  wrapLongLines?: boolean;
  sx?: BoxProps['sx'];
  condensed?: boolean;
}

const styles = {
  container: {
    position: 'relative',
    '& button': {
      color: 'text.primary',
    },
    '& pre': {
      borderRadius: 1,
    },
  },
  controls: {
    position: 'absolute',
    right: 4,
    top: 8,
    display: 'flex',
    borderRadius: 1,
  },
  condensedControls: {
    top: 6,
    backgroundColor: 'background.surface',
    boxShadow: 8,
  },
};

function CodeBlock({
  code,
  language = 'text',
  label,
  copyButton = true,
  copyButtonValue,
  controls,
  theme,
  customStyles,
  showLineNumbers = true,
  wrapLongLines = false,
  sx = {},
  condensed = false,
}: CodeBlockProps) {
  const muiTheme = useTheme()?.palette?.mode;
  const styleObject = (theme || muiTheme) === 'light' ? codeblockLight : codeblockDark;
  return (
    <Box sx={[styles.container, sx as object]} aria-label={label} data-testid={label}>
      <Box sx={[styles.controls, condensed && styles.condensedControls]}>
        {controls && <Box>{controls}</Box>}
        {copyButton && <CopyButton text={copyButtonValue || code} description="code" sx={{m: 0}} />}
      </Box>
      <SyntaxHighlighter
        language={language}
        style={styleObject as any}
        showLineNumbers={showLineNumbers}
        wrapLongLines={wrapLongLines}
        customStyle={{
          margin: 0,
          height: '100%',
          ...customStyles,
        }}
      >
        {code}
      </SyntaxHighlighter>
    </Box>
  );
}

export default CodeBlock;
