import {type EffectCallback, useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {localStorageGet, localStorageSet} from '../utils/storage';
import {useAuth} from './auth';

export function useBooleanState(defaultValue = false) {
  const [val, setVal] = useState(defaultValue);
  const setTrue = () => setVal(true);
  const setFalse = () => setVal(false);

  const toggleVal = () => setVal((prev) => !prev);

  return {
    val,
    setTrue,
    setFalse,
    toggleVal,
  };
}

export function useInterval(callback: Function, delay: number | null) {
  const savedCallback = useRef<Function>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    if (delay !== null) {
      if (savedCallback.current) {
        savedCallback.current();
      }
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useIsHovering() {
  const {val: isHovering, setTrue: onMouseEnter, setFalse: onMouseLeave} = useBooleanState();
  return {
    isHovering,
    props: {
      onMouseEnter,
      onMouseLeave,
    },
  };
}

export function useLogin() {
  const {loginWithRedirect} = useAuth();
  const location = useLocation();
  return ({
    sessionType = 'signup',
    returnUrl = location.pathname + location.search,
    sourceTag = undefined,
    integrationId = undefined,
  }: {
    sessionType?: string;
    returnUrl?: string;
    sourceTag?: string;
    integrationId?: string;
  }) =>
    loginWithRedirect({
      sessionType,
      sourceTag,
      integrationId,
      appState: {
        targetUrl: returnUrl,
      },
    });
}

/*
 * A util function to run an effect a single time, only after a condition has been met.
 */
export function useOnce(effect: EffectCallback, condition: boolean) {
  const prev = useRef(false);
  useEffect(() => {
    if (condition && !prev.current) {
      effect();
      prev.current = true;
    }
  }, [effect, condition]);
}

export function useAnimatedDots() {
  const [numDots, setNumDots] = useState(0);
  useInterval(() => {
    setNumDots((numDots + 1) % 4);
  }, 1000);
  return new Array(numDots + 1).join('.');
}

export function useLocalStorageState<T extends string>(key: string, defaultValue: T) {
  const [val, setVal] = useState<T>((localStorageGet(key) as T) || defaultValue);
  useEffect(() => {
    localStorageSet(key, val);
  }, [val, key]);
  return {
    val,
    setVal,
  };
}

/*
 * A hook to debounce a value
 * @param value The value to debounce
 * @param delay The delay in milliseconds after which if the value is unchanged the
 * updated value will be returned
 */
export function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [lastValue, setLastValue] = useState(value);

  useEffect(() => {
    // If the value is different from the last stored value, set it as the last value
    if (value !== lastValue) {
      setLastValue(value);
    }

    // Set a timeout to update debouncedValue if the value hasn't changed for `delay` milliseconds
    const handler = setTimeout(() => {
      if (value === lastValue) {
        setDebouncedValue(value);
      }
    }, delay);

    // Cleanup timeout if the value or delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [value, lastValue, delay]);

  return debouncedValue;
}
