import Box from '@mui/material/Box';
import {datasets} from '../../../../components/datasets/data/datasets';
import Page from '../../../../components/layout/Page/Page';
import PageHeader from '../../../../components/layout/PageHeader/PageHeader';
import DatasetCard from '../DatasetCard/DatasetCard';

const styles = {
  grid: {
    mt: 3,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
    gap: 3,
  },
};

function DatasetGalleryPage() {
  return (
    <Page
      header={
        <PageHeader
          title="Load sample data"
          description="Get hands-on experience with Pinecone by creating an index using sample data"
          breadcrumb={{
            text: 'Go back',
          }}
        />
      }
    >
      <Box sx={styles.grid}>
        {Object.entries(datasets).map(([key, dataset]) => (
          <DatasetCard key={key} id={key} dataset={dataset} />
        ))}
      </Box>
    </Page>
  );
}

export default DatasetGalleryPage;
