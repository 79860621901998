import Typography from '@mui/material/Typography';
import InlineCode from '@pinecone-experience/timber/InlineCode';
import {PUBLIC_DOCS_URL} from '../../constants';
import GoIcon from '../../icons/languages/go.svg';
import JavaIcon from '../../icons/languages/java.svg';
import JavascriptIcon from '../../icons/languages/javascript.svg';
import OtherLanguageIcon from '../../icons/languages/other.svg';
import PythonIcon from '../../icons/languages/python.svg';

import type {GlobalProject} from '../../types';

const BASE_DOCS = [{href: `${PUBLIC_DOCS_URL}/troubleshooting`, linkText: 'Troubleshooting'}];
const GLOBAL_BASE_DOCS = [
  {href: `${PUBLIC_DOCS_URL}/guides/get-started/quickstart`, linkText: 'Quickstart'},
  {href: `${PUBLIC_DOCS_URL}/guides/data/upsert-data`, linkText: 'Upsert data'},
];

const GLOBAL_CONNECT_DOCS = [
  {href: `${PUBLIC_DOCS_URL}/guides/data/upsert-data`, linkText: 'Upsert data'},
  {href: `${PUBLIC_DOCS_URL}/guides/data/query-data`, linkText: 'Query data'},
  {
    href: `${PUBLIC_DOCS_URL}/guides/data/filter-with-metadata`,
    linkText: 'Filter with metadata',
  },
];

export const LANGUAGE_OPTIONS = {
  javascript: 'javascript',
  python: 'python',
  java: 'java',
  go: 'go',
  shell: 'shell',
} as const;

type ConnectTextTemplateOptions = {
  project: GlobalProject;
  cloud?: string;
  region?: string;
  serviceName?: string;
  apiKey?: string;
  host: string;
  withGetIndex: boolean;
};
export type ConnectTextTemplate = (options: ConnectTextTemplateOptions) => string;

export type LanguageTypes = (typeof LANGUAGE_OPTIONS)[keyof typeof LANGUAGE_OPTIONS];
export const LANGUAGE_OPTIONS_LIST = Object.keys(LANGUAGE_OPTIONS) as LanguageTypes[];

const DEFAULT_API_KEY_TEXT = 'YOUR_API_KEY';

export const LANGUAGE_CONTENT = {
  [LANGUAGE_OPTIONS.javascript]: {
    display: 'Node',
    icon: JavascriptIcon,
    installText: 'npm install @pinecone-database/pinecone',
    connectTextTemplate: ({
      apiKey = DEFAULT_API_KEY_TEXT,
      withGetIndex,
      serviceName,
    }: ConnectTextTemplateOptions) => `import { Pinecone } from '@pinecone-database/pinecone';

const pc = new Pinecone({
  apiKey: '${apiKey}'
});${
      withGetIndex
        ? `
const index = pc.index('${serviceName}');`
        : ''
    }`,
    createIndexCode: (dimensions = 8) => `const indexName = 'quickstart';

await pc.createIndex({
  name: indexName,
  dimension: ${dimensions}, // Replace with your model dimensions
  metric: 'cosine', // Replace with your model metric
  spec: { 
    serverless: { 
      cloud: 'aws', 
      region: 'us-east-1' 
    }
  } 
});`,
    upsertText: () => `await index.namespace('ns1').upsert([
  {
     id: 'vec1', 
     values: [0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1],
     metadata: { genre: 'drama' }
  },
  {
     id: 'vec2', 
     values: [0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2],
     metadata: { genre: 'action' }
  },
  {
     id: 'vec3', 
     values: [0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3],
     metadata: { genre: 'drama' }
  },
  {
     id: 'vec4', 
     values: [0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4],
     metadata: { genre: 'action' }
  }
]);`,
    queryText: () => `await index.namespace('ns1').query({
  topK: 2,
  vector: [0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3],
  includeValues: true,
  includeMetadata: true,
  filter: { genre: { '$eq': 'action' }}
});`,
    createIndexSubtitleOverride: null,
    docs: [
      {
        href: `${PUBLIC_DOCS_URL}/reference/node-sdk`,
        linkText: 'Node.js SDK Documentation',
      },
      ...BASE_DOCS,
    ],
    globalDocs: [
      ...GLOBAL_BASE_DOCS,
      {
        href: `${PUBLIC_DOCS_URL}/reference/node-sdk`,
        linkText: 'Node.js SDK reference',
      },
    ],
    connectDocs: [
      ...GLOBAL_CONNECT_DOCS,
      {
        href: `${PUBLIC_DOCS_URL}/reference/node-sdk`,
        linkText: 'Node.js SDK reference',
      },
    ],
    connectFallback: null,
  },
  [LANGUAGE_OPTIONS.python]: {
    display: 'Python',
    icon: PythonIcon,
    installText: 'pip install pinecone',
    connectTextTemplate: ({
      apiKey = DEFAULT_API_KEY_TEXT,
      withGetIndex,
      serviceName,
    }: ConnectTextTemplateOptions) => `from pinecone import Pinecone${
      !withGetIndex ? ', ServerlessSpec' : ''
    }

pc = Pinecone(api_key="${apiKey}")${
      withGetIndex
        ? `
index = pc.Index("${serviceName}")`
        : ''
    }`,
    createIndexCode: (dimensions = 8) => `index_name = "quickstart"

pc.create_index(
    name=index_name,
    dimension=${dimensions}, # Replace with your model dimensions
    metric="cosine", # Replace with your model metric
    spec=ServerlessSpec(
        cloud="aws",
        region="us-east-1"
    ) 
)`,
    upsertText: () => `index.upsert(
    vectors=[
        {
            "id": "vec1", 
            "values": [0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1], 
            "metadata": {"genre": "drama"}
        }, {
            "id": "vec2", 
            "values": [0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2], 
            "metadata": {"genre": "action"}
        }, {
            "id": "vec3", 
            "values": [0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3], 
            "metadata": {"genre": "drama"}
        }, {
            "id": "vec4", 
            "values": [0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4], 
            "metadata": {"genre": "action"}
        }
    ],
    namespace= "ns1"
)`,
    queryText: () => `index.query(
    namespace="ns1",
    vector=[0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3],
    top_k=2,
    include_values=True,
    include_metadata=True,
    filter={"genre": {"$eq": "action"}}
)`,
    createIndexSubtitleOverride: null,
    docs: [
      {
        href: `${PUBLIC_DOCS_URL}/reference/python-sdk`,
        linkText: 'Python SDK Documentation',
      },
      {href: `${PUBLIC_DOCS_URL}/examples/notebooks`, linkText: 'Example Notebooks'},
      ...BASE_DOCS,
    ],
    globalDocs: [
      ...GLOBAL_BASE_DOCS,
      {
        href: `${PUBLIC_DOCS_URL}/reference/python-sdk`,
        linkText: 'Python SDK reference',
      },
      {href: `${PUBLIC_DOCS_URL}/examples/notebooks`, linkText: 'Example notebooks'},
    ],
    connectDocs: [
      ...GLOBAL_CONNECT_DOCS,
      {
        href: `${PUBLIC_DOCS_URL}/reference/python-sdk`,
        linkText: 'Python SDK reference',
      },
    ],
    connectFallback: null,
  },
  [LANGUAGE_OPTIONS.go]: {
    display: 'Go',
    icon: GoIcon,
    installText: `# Add a dependency to the current module:
go get github.com/pinecone-io/go-pinecone/pinecone`,
    connectTextTemplate: ({
      apiKey = DEFAULT_API_KEY_TEXT,
    }: ConnectTextTemplateOptions) => `package main

import (
    "context"
    "encoding/json"
    "fmt"
    "log"

    "github.com/pinecone-io/go-pinecone/pinecone"
)

func prettifyStruct(obj interface{}) string {
    bytes, _ := json.MarshalIndent(obj, "", "  ")
    return string(bytes)
}

func main() {
    ctx := context.Background()

    pc, err := pinecone.NewClient(pinecone.NewClientParams{
        ApiKey: "${apiKey}",
    })
    if err != nil {
        log.Fatalf("Failed to create Client: %v", err)
    }
}`,
    createIndexCode: (dimension = 8) => `// Add to the main function:

indexName := "quickstart"

idx, err := pc.CreateServerlessIndex(ctx, &pinecone.CreateServerlessIndexRequest{
    Name:      indexName,
    Dimension: ${dimension},
    Metric:    pinecone.Cosine,
    Cloud:     pinecone.Aws,
    Region:    "us-east-1",
})
if err != nil {
    log.Fatalf("Failed to create serverless index \\"%v\\": %v", indexName, err)
} else {
    fmt.Printf("Successfully created serverless index: %v", idx.Name)
}`,
    upsertText: (_host: string) => `// Add to the main function:

indexName := "quickstart"

idxModel, err := pc.DescribeIndex(ctx, indexName)
if err != nil {
    log.Fatalf("Failed to describe index \\"%v\\": %v", idx.Name, err)
}

idxConnection1, err := pc.Index(pinecone.NewIndexConnParams{Host: idxModel.Host, Namespace: "ns1"})
if err != nil {
    log.Fatalf("Failed to create IndexConnection1 for Host %v: %v", idxModel.Host, err)
}

idxConnection2, err := pc.Index(pinecone.NewIndexConnParams{Host: idxModel.Host, Namespace: "ns2"})
if err != nil {
    log.Fatalf("Failed to create IndexConnection1 for Host %v: %v", idxModel.Host, err)
}

vectors1 := []*pinecone.Vector{
    {
        Id:     "vec1",
        Values: []float32{1.0, 1.5},
    },
    {
        Id:     "vec2",
        Values: []float32{2.0, 1.0},
    },
    {
        Id:     "vec3",
        Values: []float32{0.1, 3.0},
    },   
}

vectors2 := []*pinecone.Vector{
    {
        Id:     "vec1",
        Values: []float32{1.0, -2.5},
    },
    {
        Id:     "vec2",
        Values: []float32{3.0, -2.0},
    },
    {
        Id:     "vec3",
        Values: []float32{0.5, -1.5},
    },
}

count1, err := idxConnection1.UpsertVectors(ctx, vectors1)
if err != nil {
    log.Fatalf("Failed to upsert vectors: %v", err)
} else {
    fmt.Printf("Successfully upserted %d vector(s)!\\n", count1)
}

count2, err := idxConnection2.UpsertVectors(ctx, vectors2)
if err != nil {
    log.Fatalf("Failed to upsert vectors: %v", err)
} else {
    fmt.Printf("Successfully upserted %d vector(s)!\\n", count2)
}`,
    queryText: (_host: string) => `// Add to the main function:

vectorId := "B"

res, err := idxConnection1.QueryByVectorId(ctx, &pinecone.&pinecone.QueryByVectorIdRequest{
    VectorId:      vectorId,
    TopK:          3,
    IncludeValues: true,
})
if err != nil {
    log.Fatalf("Error encountered when querying by vector ID \\"%v\\": %v", vectorId, err)
} else {
    fmt.Printf(prettifyStruct(res))
}`,
    createIndexSubtitleOverride: null,
    docs: [
      {
        href: `${PUBLIC_DOCS_URL}/reference/go-sdk`,
        linkText: 'Go SDK Documentation',
      },
      ...BASE_DOCS,
    ],
    globalDocs: [
      ...GLOBAL_BASE_DOCS,
      {
        href: `${PUBLIC_DOCS_URL}/reference/go-sdk`,
        linkText: 'Go SDK reference',
      },
    ],
    connectDocs: [
      ...GLOBAL_CONNECT_DOCS,
      {
        href: `${PUBLIC_DOCS_URL}/reference/go-sdk`,
        linkText: 'Go SDK reference',
      },
    ],
    connectFallback: null,
  },
  [LANGUAGE_OPTIONS.java]: {
    display: 'Java',
    icon: JavaIcon,
    installText: `// Add a dependency to the current module:
// Maven
<dependency>
  <groupId>io.pinecone</groupId>
  <artifactId>pinecone-client</artifactId>
  <version>2.0.0</version>
</dependency>

// Gradle
implementation "io.pinecone:pinecone-client:2.0.0"`,
    connectTextTemplate: ({
      apiKey = DEFAULT_API_KEY_TEXT,
    }: ConnectTextTemplateOptions) => `import io.pinecone.clients.Pinecone;
import io.pinecone.clients.Index;
import io.pinecone.proto.DescribeIndexStatsResponse;
import io.pinecone.unsigned_indices_model.QueryResponseWithUnsignedIndices;
import org.openapitools.control.client.model.DeletionProtection;

import java.util.Arrays;
import java.util.List;

public class DeveloperQuickstart {
    public static void main(String[] args) throws InterruptedException {
        Pinecone pc = new Pinecone.Builder("${apiKey}").build();
    }
}`,
    createIndexCode: (dimensions = 8) => `// Add to the main function:

String indexName = "quickstart";

pc.createServerlessIndex(indexName, "cosine", ${dimensions}, "aws", "us-east-1", DeletionProtection.DISABLED);`,
    upsertText: (_host: string) => `//Add to the main function:

indexName = "quickstart";

Index index = pc.getIndexConnection(indexName);

List<Float> values1 = Arrays.asList(1.0f, 1.5f);
List<Float> values2 = Arrays.asList(2.0f, 1.0f);
List<Float> values3 = Arrays.asList(0.1f, 3.0f);
List<Float> values4 = Arrays.asList(1.0f, -2.5f);
List<Float> values5 = Arrays.asList(3.0f, -2.0f);
List<Float> values6 = Arrays.asList(0.5f, -1.5f);

index.upsert("vec1", values1, "ns1");
index.upsert("vec2", values2, "ns1");
index.upsert("vec3", values3, "ns1");
index.upsert("vec1", values4, "ns2");
index.upsert("vec2", values5, "ns2");
index.upsert("vec3", values6, "ns2");`,
    queryText: (_host: string) => `// Add to the main function:

// Wait for the upserted vectors to be indexed
Thread.sleep(10000);

DescribeIndexStatsResponse indexStatsResponse = index.describeIndexStats(null);
System.out.println(indexStatsResponse);

List<Float> queryVector1 = Arrays.asList(1.0f, 1.5f);
List<Float> queryVector2 = Arrays.asList(1.0f, -2.5f);

QueryResponseWithUnsignedIndices queryResponse1 = index.query(3, queryVector1, null, null, null, "ns1", null, false, true);
QueryResponseWithUnsignedIndices queryResponse2 = index.query(3, queryVector2, null, null, null, "ns2", null, false, true);

System.out.println(queryResponse1);
System.out.println(queryResponse2);`,
    createIndexSubtitleOverride: null,
    docs: [
      {
        href: `${PUBLIC_DOCS_URL}/reference/java-sdk`,
        linkText: 'Java SDK Documentation',
      },
      ...BASE_DOCS,
    ],
    globalDocs: [
      ...GLOBAL_BASE_DOCS,
      {
        href: `${PUBLIC_DOCS_URL}/reference/java-sdk`,
        linkText: 'Java SDK reference',
      },
    ],
    connectDocs: [
      ...GLOBAL_CONNECT_DOCS,
      {
        href: `${PUBLIC_DOCS_URL}/reference/java-sdk`,
        linkText: 'Java SDK reference',
      },
    ],
    connectFallback: null,
  },
  [LANGUAGE_OPTIONS.shell]: {
    display: 'Shell',
    icon: OtherLanguageIcon,
    installText: '',
    connectTextTemplate: null,
    createIndexSubtitleOverride: `Ready to get started with Pinecone? Use the code below to create a serverless index named "quickstart" that performs nearest-neighbor search using the `,
    createIndexCode: (dimensions = 8) => `curl -s -X POST "https://api.pinecone.io/indexes"\\
       -H "Accept: application/json"\\
       -H "Content-Type: application/json"\\
       -H "Api-Key: YOUR_API_KEY"\\
       -d '{
             "name":  "quickstart",
             "dimension": ${dimensions}, # Replace with your model dimension
             "metric": "euclidean", # Replace with your model metric
             "spec": {
                 "serverless": {
                     "cloud": "aws",
                     "region": "us-east-1"
                 }
             }
           }'`,
    upsertText: (host: string) => `curl -X POST "${host}/vectors/upsert" \\
  -H "Api-Key: YOUR_API_KEY" \\
  -H 'Content-Type: application/json' \\
  -d '{
    "vectors": [
      {
        "id": "vec1", 
        "values": [0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1],
        "metadata": {"genre": "drama"}
      },
      {
        "id": "vec2", 
        "values": [0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2],
        "metadata": {"genre": "action"}
      },
      {
        "id": "vec3", 
        "values": [0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3],
        "metadata": {"genre": "drama"}
      },
      {
        "id": "vec4", 
        "values": [0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4],
        "metadata": {"genre": "action"}
      }
    ],
    "namespace": "ns1"
  }'`,
    queryText: (host: string) => `curl -X POST "${host}/query" \\
  -H "Api-Key: YOUR_API_KEY" \\
  -H 'Content-Type: application/json' \\
  -d '{
    "namespace": "ns1",
    "vector": [0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3],
    "topK": 2,
    "includeValues": true,
    "includeMetadata": true,
    "filter": {"genre": {"$eq": "action"}}
  }'`,
    docs: [
      {href: `${PUBLIC_DOCS_URL}/reference/api/`, linkText: 'API Reference Documentation'},
      ...BASE_DOCS,
    ],
    globalDocs: [
      ...GLOBAL_BASE_DOCS,
      {href: `${PUBLIC_DOCS_URL}/reference/api/`, linkText: 'API reference'},
    ],
    connectDocs: [
      ...GLOBAL_CONNECT_DOCS,
      {href: `${PUBLIC_DOCS_URL}/reference/api/`, linkText: 'API reference'},
    ],
    connectFallback: {
      title: 'Get index endpoint',
      subtext: (
        <Typography>
          {`Each Pinecone index has a unique DNS endpoint for performing data operations such as
          upsert, query, and delete. The Python and Node clients construct these index endpoints for
          you. However, you need to explicitly specify these endpoints when using the API directly.
          To get the endpoint for your "quickstart" index, use the `}
          <InlineCode text="describe_index" /> operation and check the <InlineCode text="host" />{' '}
          value in the response:
        </Typography>
      ),
      code: `curl -X GET "https://api.pinecone.io/indexes/quickstart" \\
    -H "Api-Key: YOUR_API_KEY"`,
    },
  },
} as const;
