import {zodResolver} from '@hookform/resolvers/zod';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SelectBoxInput from '@pinecone-experience/timber/Inputs/SelectBoxInput';
import SelectInput from '@pinecone-experience/timber/Inputs/SelectInput';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import {useContext, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {registerUser} from '../../../actions/userActions';
import {Purpose} from '../../../constants';
import {RegistrationFormContext} from '../../../context/registration-form';
import {Flags, useFlag} from '../../../hooks/flags';
import {useDashboardApi} from '../../../hooks/use-api';
import CSharpIcon from '../../../icons/languages/c_sharp.svg';
import JavaIcon from '../../../icons/languages/java.svg';
import JavascriptIcon from '../../../icons/languages/javascript.svg';
import OtherLanguageIcon from '../../../icons/languages/other.svg';
import PythonIcon from '../../../icons/languages/python.svg';
import RubyIcon from '../../../icons/languages/ruby.svg';
import {useAuthData} from '../../../selectors/auth';
import {useIsOnlyOwner} from '../../../selectors/organizations';
import {useSelectedOrganizationId} from '../../../selectors/params';
import {useRegistrationNeeded} from '../../../selectors/userSession';
import {MODAL_WIDTH_MEDIUM} from '../../../styles/theme';
import {shuffleArray} from '../../../utils/data';
import {HEAP_TRACKING_EVENTS, sendToHeap} from '../../../utils/tracking';
import ModalForm from '../../ModalForm';
import OtherOption from '../../Utilities/OtherOption';
import {useDelayRegistration, useSkipRegistration} from './utils/registration';
import schema, {type RegistrationData} from './utils/schema';

const styles = {
  subheader: {
    color: 'text.secondary',
    mt: -2,
    mb: 2,
  },
  inputGrid: {
    display: 'flex',
    gap: 1,
    '> div': {
      flex: 1,
    },
  },
};

const useCaseOptions = [
  ...shuffleArray([
    {
      value: 'AI Assistant',
    },
    {
      value: 'AI Search',
    },
    {
      value: 'Classification',
    },
  ]),
  {
    value: '',
    label: 'Other',
  },
];

const hasEmbeddingModelOptions = [
  {
    value: 'Yes',
  },
  {
    value: 'Still deciding',
  },
  {
    value: 'What are vector embeddings?',
  },
];

const languageOptions = [
  ...shuffleArray([
    {
      value: 'Python',
      icon: <Box component="img" src={PythonIcon} sx={{mt: 0.25}} />,
    },
    {
      value: 'JavaScript',
      icon: <Box component="img" src={JavascriptIcon} sx={{mt: 0.25}} />,
    },
    {
      value: 'Java',
      icon: <Box component="img" src={JavaIcon} sx={{mt: 0.25}} />,
    },
    {
      value: 'C#',
      icon: <Box component="img" src={CSharpIcon} sx={{mt: 0.25}} />,
    },
    {
      value: 'Ruby',
      icon: <Box component="img" src={RubyIcon} sx={{mt: 0.25}} />,
    },
  ]),
  {
    value: '',
    label: 'Other',
    icon: <Box component="img" src={OtherLanguageIcon} sx={{mt: 0.25}} />,
  },
];

function RegistrationModal() {
  const auth0Data = useAuthData();
  const showCompany = useIsOnlyOwner();
  const {watch, handleSubmit, control, setValue} = useForm({
    defaultValues: {
      firstName: auth0Data?.given_name,
      lastName: auth0Data?.family_name,
      purpose: showCompany ? Purpose.BUSINESS : Purpose.NONE,
      company: '',
      hasEmbeddingModel: undefined,
      useCase: undefined,
      language: undefined,
    } as unknown as RegistrationData,
    resolver: zodResolver(schema),
  });
  useEffect(() => {
    if (!showCompany) {
      setValue('purpose', Purpose.NONE);
    }
  }, [setValue, showCompany]);
  // If there is another owner of the org, we can assume that this user came from an invite
  // -- regardless, we don't want to overwrite the company name in this case.
  const purpose = watch('purpose');
  useEffect(() => {
    if (purpose === Purpose.PERSONAL) {
      setValue('company', '');
    }
  }, [setValue, watch, purpose]);
  const {setQueryData} = useDashboardApi<{
    orgId?: string;
    projectId?: string;
    registrationData: RegistrationData;
  }>();
  const selectedOrgId = useSelectedOrganizationId();
  const onSubmit = (data: RegistrationData) => {
    setQueryData({
      action: registerUser,
      data: {
        orgId: selectedOrgId,
        registrationData: data,
      },
    });
  };

  const {isShowingRegistration, setIsShowingRegistration} = useContext(RegistrationFormContext);
  const isRegistrationNeeded = useRegistrationNeeded();
  const {skipRegistration, setSkipRegistration} = useSkipRegistration();
  const delayRegistration = useDelayRegistration();
  const newRegistrationEnabled = useFlag(Flags.ENABLE_NEW_REGISTRATION_FLOW);
  const showRegistration =
    isRegistrationNeeded && !skipRegistration && !delayRegistration && !newRegistrationEnabled;
  useEffect(
    () => setIsShowingRegistration(showRegistration),
    [showRegistration, setIsShowingRegistration],
  );
  const close = () => {
    sendToHeap(HEAP_TRACKING_EVENTS.REGISTRATION_SKIPPED);
    setSkipRegistration();
    setIsShowingRegistration(false);
  };

  useEffect(() => {
    if (isShowingRegistration) {
      sendToHeap(HEAP_TRACKING_EVENTS.REGISTRATION_VIEWED);
    }
  }, [isShowingRegistration]);
  const hasEmbeddingModelVal = watch('hasEmbeddingModel');
  const useCaseVal = watch('useCase');
  const languageVal = watch('language');
  return (
    <ModalForm
      open={isShowingRegistration}
      close={close}
      closeText="Skip"
      title="Let's get started"
      titleSx={{color: 'unset'}}
      onSubmit={handleSubmit(onSubmit)}
      submitText="Complete setup"
      form={
        <>
          <Typography variant="subtitle2" sx={styles.subheader}>
            Help us personalize your Pinecone experience by answering a few questions.
          </Typography>
          <Box sx={styles.inputGrid}>
            <TextInput name="firstName" control={control} label="First Name" fullWidth />
            <TextInput name="lastName" control={control} label="Last Name" fullWidth />
          </Box>
          <Box sx={styles.inputGrid}>
            {showCompany && (
              <>
                <SelectInput
                  name="purpose"
                  control={control}
                  label="Purpose of use"
                  options={[{value: Purpose.BUSINESS}, {value: Purpose.PERSONAL}]}
                  fullWidth
                />
                {purpose === Purpose.BUSINESS && (
                  <TextInput name="company" control={control} label="Company" fullWidth />
                )}
              </>
            )}
          </Box>
          <SelectBoxInput
            label="Do you have an embedding model?"
            name="hasEmbeddingModel"
            control={control}
            options={hasEmbeddingModelOptions}
            addOption={OtherOption({
              fieldName: 'hasEmbeddingModel',
              fieldValue: hasEmbeddingModelVal,
              optionsArray: hasEmbeddingModelOptions,
              control,
            })}
          />
          <SelectBoxInput
            label="What is your use case?"
            name="useCase"
            control={control}
            options={useCaseOptions}
            addOption={OtherOption({
              fieldName: 'useCase',
              fieldValue: useCaseVal,
              optionsArray: useCaseOptions,
              control,
            })}
          />
          <SelectBoxInput
            label="What is your preferred coding language?"
            name="language"
            control={control}
            options={languageOptions}
            addOption={OtherOption({
              fieldName: 'language',
              fieldValue: languageVal,
              optionsArray: languageOptions,
              control,
            })}
          />
          <Box pb={2} />
        </>
      }
      isValid
      width={MODAL_WIDTH_MEDIUM}
    />
  );
}

export default RegistrationModal;
