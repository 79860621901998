import {useTheme} from '@mui/material/styles';
import {type CustomLayerProps} from '@nivo/line';

export function PulseSVG({
  x = 0,
  y = 0,
  primaryColor,
  secondaryColor,
}: {
  x?: number;
  y?: number;
  primaryColor: string;
  secondaryColor: string;
}) {
  return (
    <svg x={x} y={y} width="20" height="20" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" fill="none" r="10" stroke={primaryColor} strokeWidth="2">
        <animate
          attributeName="r"
          from="8"
          to="20"
          dur="1.5s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="1"
          to="0"
          dur="1.5s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="20" cy="20" fill={primaryColor} r="10" />
      <circle cx="20" cy="20" fill={secondaryColor} r="5" />
    </svg>
  );
}
export function Pulse({points}: CustomLayerProps) {
  const theme = useTheme();
  const end = points[points.length - 1];
  return (
    <PulseSVG
      x={(end?.x || -100) - 10}
      y={(end?.y || -100) - 10}
      primaryColor={theme.palette.primary.main}
      secondaryColor={theme.palette.background.paper}
    />
  );
}
