import {z} from 'zod';

export const createProjectSchema = z.object({
  name: z
    .string()
    .min(1, 'Project name is required')
    .max(512, 'Project name must be 512 characters or less')
    .refine((name) => !name.includes('\0'), {
      message: 'Project name cannot contain null characters',
    }),
  force_encryption_with_cmek: z.boolean(),
});

export type CreateProjectSchemaType = z.infer<typeof createProjectSchema>;
