import Typography from '@mui/material/Typography';
import {PROVIDER_INFORMATION_MAP} from '../../../../../constants';
import {type CmekKey} from '../../../../../types';
import CmekActions from './CmekActions/CmekActions';

type CmekCellProps = {
  cmek: CmekKey;
  col: number;
  onDelete: (cmekId: string) => void;
};

const CmekCell = ({cmek, col, onDelete}: CmekCellProps) => {
  switch (col) {
    case 0:
      return <Typography>{cmek.name}</Typography>;
    case 1:
      return <Typography>{cmek.aws?.role_arn ?? '-'}</Typography>;
    case 2:
      return <Typography>{cmek.aws?.key_arn ?? '-'}</Typography>;
    case 3:
      return (
        <Typography>{PROVIDER_INFORMATION_MAP[cmek.cloud]?.fullname ?? cmek.cloud}</Typography>
      );
    case 4:
      return (
        <CmekActions
          name={cmek.name}
          id={cmek.id}
          inUse={!!cmek.indexes_using_key?.length}
          onDelete={onDelete}
        />
      );
    default:
      return null;
  }
};

export default CmekCell;
