import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

interface BillingPlanItemStatusProps {
  status: 'coming-soon' | 'public-preview';
}

const styles = {
  badge: {
    px: '6px',
    py: '3px',
    backgroundColor: 'background.surface',
    lineHeight: '1',
  },
};

export default function BillingPlanItemStatus({status}: BillingPlanItemStatusProps) {
  switch (status) {
    case 'coming-soon':
      return (
        <Chip
          sx={styles.badge}
          size="small"
          label={
            <Typography fontSize={9} color="secondary" variant="overline">
              Coming Soon
            </Typography>
          }
        />
      );
    case 'public-preview':
      return (
        <Chip
          sx={styles.badge}
          size="small"
          label={
            <Typography fontSize={9} color="primary.main" variant="overline">
              Public Preview
            </Typography>
          }
        />
      );
    default:
      return null;
  }
}
