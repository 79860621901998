import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography, {type TypographyProps} from '@mui/material/Typography';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {GUTTER} from '../../../styles/theme';

export interface HeaderProps {
  /** Title of the page. Will be used as the HTML document title. */
  title: string;
  /** SEO title of the page. Will be used as the meta title if provided. */
  seoTitle?: string;
  /** Overwrite the styles for the title components */
  titleSx?: TypographyProps['sx'];
  /** A subtitle for the page. */
  subtitle?: string;
  /** A description of the page. */
  description?: React.ReactNode;
  /** Action buttons that will be displayed alongside the title. */
  actions?: React.ReactNode;
  /**
   * A breadcrumb button that will be displayed above the title.
   * If no action is passed, the button navigates back to the previously visited page.
   */
  breadcrumb?: {
    text: string;
    action?: () => void;
  };
  /** Whether to hide the title from displaying. HTML document title will still be set. */
  titleHidden?: boolean;
  /** Optional leading icon to display before the title */
  leadingIcon?: React.ReactNode;
  /** Optional badge to display after the title */
  badge?: React.ReactNode;
}

const styles = {
  header: (hasTitleHidden: boolean, hasDescription: boolean) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: hasDescription ? 'center' : 'flex-end',
    gap: 2,
    mb: hasTitleHidden ? 2 : GUTTER,
  }),
  text: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.25,
  },
  backButton: {
    width: 'fit-content',
  },
  actions: {
    display: 'flex',
    gap: 1,
  },
};

/** A page header with title and description. Also sets the HTML document title. */
export default function PageHeader({
  title,
  titleSx,
  seoTitle,
  subtitle,
  description,
  actions,
  breadcrumb,
  titleHidden,
  leadingIcon,
  badge,
}: HeaderProps) {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  useEffect(() => {
    if (seoTitle) {
      document.title = `${seoTitle} | Pinecone Console`;
    } else if (typeof title === 'string') {
      document.title = `${title} | Pinecone Console`;
    }
  }, [title, seoTitle]);

  useEffect(
    // Reset title when component dismounts
    () => () => {
      document.title = 'Pinecone Console';
    },
    [],
  );

  if (titleHidden && !description && !actions && !breadcrumb) {
    return null;
  }

  return (
    <Box>
      <Box sx={styles.header(!!titleHidden, !!description)}>
        <Box sx={styles.text}>
          {breadcrumb ? (
            <Button
              sx={styles.backButton}
              onClick={breadcrumb.action ?? goBack}
              fullWidth={false}
              startIcon={<KeyboardArrowLeft fontSize="large" />}
            >
              {breadcrumb.text}
            </Button>
          ) : null}
          <Box
            sx={titleHidden ? {display: 'none'} : titleSx}
            display="flex"
            gap={2}
            alignItems="center"
          >
            {leadingIcon}
            {typeof title === 'string' ? (
              <Typography variant="h5" component="h1">
                {title}
              </Typography>
            ) : (
              title
            )}
            {badge}
            {subtitle && <Typography>{subtitle}</Typography>}
          </Box>
          {description ? <Typography mt={0.75}>{description}</Typography> : null}
        </Box>
        <Box sx={styles.actions}>{actions}</Box>
      </Box>
    </Box>
  );
}
