import Box, {type BoxProps} from '@mui/material/Box';
import BillingPlanItem from '../../../BillingPlanItem/BillingPlanItem';
import {BILLING_PLANS} from '../../constants/billingPlans';
import {
  type BillingPlanItem as BillingPlanItemType,
  type BillingPlanProducts,
} from '../../types/billingPlans.interface';

interface BillingPlanProductFeatureProps {
  feature: BillingPlanItemType;
  productKey: BillingPlanProducts;
  index: number;
  sx?: Required<BoxProps['sx']>;
}

const styles = {
  root: {
    borderBottom: 1,
    borderColor: 'divider',
  },
  content: {
    px: 2,
    py: 1,
    borderLeft: 1,
    borderColor: 'divider',
    containerType: 'inline-size',
    '&:first-of-type': {
      borderLeft: 0,
    },
  },
};

export default function BillingPlanProductFeature({
  feature,
  productKey,
  index,
  sx = {},
}: BillingPlanProductFeatureProps) {
  return (
    <Box sx={[styles.root, sx]}>
      <Box sx={styles.content}>
        <BillingPlanItem item={feature} />
      </Box>
      {BILLING_PLANS.map((plan) => (
        <Box sx={styles.content} key={`${plan.tier}-${index}`}>
          <BillingPlanItem item={plan.productItems[productKey][index]} />
        </Box>
      ))}
    </Box>
  );
}
