import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {useNavigateToIndexPageGlobal} from '../../../../../hooks/navigation';
import {
  useSelectedGlobalProjectId,
  useSelectedOrganizationId,
} from '../../../../../selectors/params';
import {type V4IndexInfoResponse} from '../../../../../types';
import {styles} from '../styles';

function IndexCard({index}: {index: V4IndexInfoResponse}) {
  const {name, dimension, metric} = index;
  const selectedOrgId = useSelectedOrganizationId();
  const selectedProjectId = useSelectedGlobalProjectId();
  const {go: goToIndexPage} = useNavigateToIndexPageGlobal(selectedOrgId, selectedProjectId);

  return (
    <Paper sx={styles.sectionItemCard} onClick={() => goToIndexPage(name)} component="button">
      <Typography variant="body2" color="secondary">
        {dimension} - {metric}
      </Typography>
      <Typography variant="h6" component="h2">
        {name}
      </Typography>
    </Paper>
  );
}

export default IndexCard;
