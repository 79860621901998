import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/marketplaceActions';
import * as organizationsActions from '../actions/organizationActions';
import {type Marketplace} from '../types';

interface MarketplaceState {
  marketplaceSetup: {
    marketplaceType: string;
    setupToken: string | null;
    marketplaceId: string;
  };
  isLoading: boolean;
  marketplace: Marketplace | null;
  hasError: boolean;
  organizationId: string;
  error: string | null;
}

const initialState = {} as MarketplaceState;

const marketplaceSetupReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getMarketplaceSetupEntityRequest, (state, action) => {
      const {marketplaceId, setupToken, marketplaceType} = action.payload;
      state.marketplaceSetup = {marketplaceId, setupToken, marketplaceType};
      state.isLoading = true;
    })
    .addCase(actions.getMarketplaceSetupEntityRequestSuccess, (state, action) => {
      const {marketplace} = action.payload;
      state.marketplace = marketplace;
      state.hasError = false;
      state.error = null;
      state.isLoading = false;
    })
    .addCase(actions.getMarketplaceSetupEntityRequestError, (state, action) => {
      const {error} = action.payload;
      state.hasError = true;
      state.error = error?.message || 'Error fetching marketplace information';
      state.isLoading = false;
    })
    .addCase(
      organizationsActions.attachMarketplaceToOrganizationRequestSuccess,
      (state, action) => {
        const {organizationId} = action.payload;
        state.organizationId = organizationId;
      },
    );
});

export default marketplaceSetupReducer;
