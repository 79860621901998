import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import {type SupportInterval} from '../../../../../actions/types/organizationActions.types';
import {SupportTiers, SupportTiersInOrder} from '../../../../../constants';

dayjs.extend(localizedFormat);
dayjs.extend(utc);

export enum ChangeSupportAction {
  NO_ACTION = 'No Action',
  UPGRADE = 'Upgrade',
  DOWNGRADE = 'Downgrade',
  CANCEL_DOWNGRADE = 'Downgrade Cancellation',
}

export function evaluateChangeSupportAction(
  currentTier: SupportTiers,
  desiredTier: SupportTiers,
  scheduledSupport: SupportInterval | null,
) {
  if (currentTier === desiredTier) {
    return scheduledSupport ? ChangeSupportAction.CANCEL_DOWNGRADE : ChangeSupportAction.NO_ACTION;
  }
  return SupportTiersInOrder.indexOf(currentTier) < SupportTiersInOrder.indexOf(desiredTier)
    ? ChangeSupportAction.UPGRADE
    : ChangeSupportAction.DOWNGRADE;
}

// The order of evaluation:
// 1. Check if scheduled support exists and pointing to current tier
// 1. If not, return current plan
// 2. AC plans have plans included
// 3. The 'Contact Sales' button should take precedence for enterprise over any upgrade
// 4. Finally, decide whether the button is for an upgrade or a downgrade based on `isUpgrade`.

export const getSupportButton = (
  desiredTier: SupportTiers,
  action: ChangeSupportAction,
  isSupportIncluded: boolean,
): [string, string] => {
  if (action === ChangeSupportAction.CANCEL_DOWNGRADE && desiredTier !== SupportTiers.FREE) {
    return ['scheduled-plan-button', 'Cancel Downgrade'];
  }
  if (action === ChangeSupportAction.NO_ACTION) return ['current-plan-button', 'Current'];
  if (desiredTier === SupportTiers.ENTERPRISE) return ['contact-sales-button', 'Contact Sales'];
  if (isSupportIncluded) return ['included-plan-button', 'Included'];
  return action === ChangeSupportAction.UPGRADE
    ? [`upgrade-to-${desiredTier}-button`, 'Upgrade']
    : [`downgrade-to-${desiredTier}-button`, 'Downgrade'];
};

export const getRemainingDaysOfSupport = (activeSupport: SupportInterval) => {
  const endDate = activeSupport?.interval?.current_billing_period_end_date;
  if (!endDate) {
    return {endDate: null, daysRemaining: null};
  }
  const daysRemaining = dayjs(endDate).diff(dayjs().utc(), 'days');
  return {endDate: dayjs(endDate).format('LL'), daysRemaining};
};
