import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import {useMatch, useNavigate} from 'react-router-dom';
import {type NavigationItem} from '../../types/navigation.interface';

interface NavItemProps {
  item: NavigationItem;
}

function NavItem({item: {path, pathMatch, title, color}}: NavItemProps) {
  const navigate = useNavigate();
  const pathToMatch = pathMatch || `${path}/*`;
  const isSelected = useMatch(pathToMatch);

  return (
    <ListItemButton component="li" selected={!!isSelected} onClick={() => navigate(path)}>
      <Typography noWrap color={color}>
        {title}
      </Typography>
    </ListItemButton>
  );
}

export default NavItem;
